import {
  Button,
  Center,
  Grid,
  Loader,
  Paper,
  Text,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import Store from "../../utils/store";
import {
  GetVouchNumData,
  GetVouchNumField,
} from "../../utils/slices/VoucherNumSlice";
import { useDispatch, useSelector } from "react-redux";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const VoucherNumberForm = (props) => {
  const [group, setGroup] = useState({
    FIELD01: "PP",
    FIELD02: "",
    FIELD00: "",
    FIELD03: 0,
    FIELD04: "TB",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: 0.0,
    FIELD17: "",
    FIELD18: "",
    FIELD20: "",
    FLDUNQ: "",
    FLDAED: "A",
    FLDBRC: "",
  });
  const [editData, setEditData] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [invData, setInvData] = useState("");
  const [InvoiceType, setInvoiceType] = useState(null);
  // const { classes } = useStyles();
  const formRef = useRef(null);
  const dispatchData = useDispatch();
  const { isLoadingForm: isLoading, VoucherNumField: VField,hasError,ErrorMsg } = useSelector(
    (state) => state?.VoucherNum
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  // console.log(VField?.T92, "VField?.T92");
  useEffect(() => {
    let selectedVal = VField?.S11?.filter((v) => v?.S11F01 == group?.FIELD01);

    if (selectedVal != undefined) {
      if (selectedVal[0]?.S11F23?.trim() !== "") {
        console.log(
          selectedVal[0]?.S11F23.trim(),
          "selectedVal[0]?.S11F23.trim()",
          VField?.M45IDT
        );
        const invoiceFieldData = VField?.M45IDT?.filter(
          (v) => v?.FIELD21 == selectedVal[0]?.S11F23?.trim()
        );
        console.log(invoiceFieldData, "selectedVal[0]?.S11F23.trim()");
        if (invoiceFieldData?.length > 0) {
          setInvData(invoiceFieldData);
          setShowInvoice(true);
        } else {
          setShowInvoice(false);
        }
      } else {
        setShowInvoice(false);
      }
    }
  }, [group?.FIELD01]);
  function handleOkButton() {
    // console.log(group, "SendData", editData);
    // if()
    if (
      group?.FIELD02?.length > 6 ||
      group?.FIELD03?.toString().length > 11 ||
      group?.FIELD01?.length <= 0
    ) {
      GlobalClass.Notify(
        "warning",
        "Fill all the fileds correctly",
        "Please fill out all the fields correctly."
      );
    } else {
      if (JSON.stringify(editData) === JSON.stringify(group)) {
        Store.dispatch(ModalDelete(props.index));
      } else {
        // Promise.resolve(Store.dispatch(DataSaveLoading(true)))
        //   .then
        //   // GlobalClass.Notify(
        //   //   "info",
        //   //   "Submitting..",
        //   //   "Please Wait While Submitting Form."
        //   // )
        //   ();
        if (props?.cAction == "A" && InvoiceType) {
          // console.log("invData ", InvoiceType);
          Promise.resolve(
            InvoiceType?.map((e) => {
              Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(
                GlobalClass.Notify(
                  "info",
                  "Adding...",
                  "wait until we add data"
                )
              );
              let JsonData = { ...group, FIELD20: e?.FIELD01 };

              // console.log("JsonData==>", JsonData);

              var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: "",
                cSData: JSON.stringify(JsonData),
              };

              let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: "",
                // cSData: JSON.stringify(group),
              });

              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              };
              // console.log(requestOptions, "requestOptions");
              // try {
              fetch(
                GlobalClass.ApiUrl + GlobalClass.PostT92Ent + "?pa=" + param,
                requestOptions
              )
                .then((response) => response.json())
                .then((data) => {
                  // console.log("api data status=>", data?.status);

                  if (data.status == "SUCCESS") {
                    Promise.resolve(
                      Store.dispatch(DataSaveLoading(false))
                    ).then(() => {
                      Promise.resolve(dispatchData(GetVouchNumData())).then(
                        () =>
                          GlobalClass.Notify(
                            "success",
                            "Successfull",
                            "Data added successfully"
                          )
                      );
                    });
                    Store.dispatch(ModalDelete(props.index));
                  } else if (data.status == "FAIL") {
                    Promise.resolve(
                      Store.dispatch(DataSaveLoading(false))
                    ).then(() =>
                      GlobalClass.Notify(
                        "error",
                        "Error occured!",
                        `responded with status ${data?.status}`
                      )
                    );
                  }
                })
                .catch((e) => {
                  GlobalClass.Notify(
                    "error",
                    "Error occured!",
                    `responded with ${e}`
                  );
                  Store.dispatch(DataSaveLoading(false));
                });
            })
          );
        } else {
          Promise.resolve(Store.dispatch(DataSaveLoading(true))).then(
            GlobalClass.Notify(
              "info",
              props.cAction == "A" ? "Adding..." : "Editing",
              props.cAction == "A"
                ? "wait until we add data"
                : "Wait until we update data"
            )
          );
          var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: props ? props.cAction : "A",
            cCode: props.idToEdit ? props.idToEdit : "",
            cOPara: "",
            cSData: JSON.stringify(group),
          };
          let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: props ? props.cAction : "A",
            cCode: props.idToEdit ? props.idToEdit : "",
            cOPara: "",
          });

          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          };

          fetch(
            GlobalClass.ApiUrl + GlobalClass.PostT92Ent + "?pa=" + param,
            requestOptions
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              // console.log("api data Voucher=>", data);
              // console.log("api data status=>", data?.status);

              if (data?.status === "SUCCESS") {
                Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
                  () =>
                    Promise.resolve(dispatchData(GetVouchNumData())).then(() =>
                      GlobalClass.Notify(
                        "success",
                        "Successful",
                        props.cAction == "A"
                          ? "Added Successfully"
                          : "Edited Successfully"
                      )
                    )
                );

                Store.dispatch(ModalDelete(props.index));
              } else if (data?.status === "FAIL") {
                Promise.resolve(Store.dispatch(DataSaveLoading(false))).then(
                  () => GlobalClass.Notify("error", "Fail", "Failed to Add")
                );
              }
            })
            .catch((e) => {
              GlobalClass.Notify(
                "error",
                "Error occured!",
                `responded with ${e}`
              );
              Store.dispatch(DataSaveLoading(false));
            });
        }
      }
    }
  }

  useEffect(() => {
    if (props?.cAction == "A") {
      Store.dispatch(GetVouchNumField({ cAction: "A", cCode: "" }));
    } else {
      Promise.resolve(
        Store.dispatch(
          GetVouchNumField({ cAction: "E", cCode: props?.idToEdit })
        ).then((data) => {
          setEditData(data?.payload?.T92);
        })
      );
    }
  }, []);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  useEffect(() => {
    if (VField) setGroup(VField?.T92);
  }, [VField]);

  return (
    <div ref={formRef}>
      {hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
          if (props?.cAction == "A") {
            Store.dispatch(GetVouchNumField({ cAction: "A", cCode: "" }));
          } else {
            Promise.resolve(
              Store.dispatch(
                GetVouchNumField({ cAction: "E", cCode: props?.idToEdit })
              ).then((data) => {
                setEditData(data?.payload?.T92);
              })
            );
          }
          }}>Retry</Button>
        </>  : SaveLoading == true || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
        <Paper shadow="md" radius="md" p="xs" withBorder>
          <Grid style={{ display: "flex", justifyContent: Center }} gutter={4}>
            <Grid.Col
              span={12}
              // ml={10}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Voucher Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  value={group?.FIELD01}
                  dispexpr="S11F02"
                  valexpr="S11F01"
                  // disabled={docType.label == "Character" ? true : docType.label == "Numeric" ? true : docType.label == "Memo" ? true : docType.label == "Date" ? true : false}
                  data={VField?.S11}
                  setEdit={(e) => {
                    // DocTypeFun(e);
                    setGroup({ ...group, FIELD01: e });
                  }}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={12}
              // ml={10}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Voucher Prefix</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <PLTextBox
                  value={group?.FIELD02}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD02: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              // ml={10}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Last Voucher No.</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <PLNumberBox
                  value={group?.FIELD03}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD03: e });
                  }}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              // ml={10}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Bill Of Supply</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <PLComboBox
                disabled={"SS,HS,SR,PP,PR,HP,JJ,JZ"?.includes(group?.FIELD01)}
                  value={group?.FIELD04}
                  dispexpr="DisplayMember"
                  valexpr="ValueMember"
                  data={VField?.COPVNS}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD04: e });
                  }}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            {showInvoice ? (
              <Grid.Col
                span={12}
                // ml={10}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>Invoice Type</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLComboBox
                    disabled={
                      props?.cAction == "E" ? true : showInvoice ? false : true
                    }
                    value={InvoiceType}
                    multiple={props?.cAction == "A" ? true : false}
                    // multiple={ true }
                    data={invData}
                    dispexpr="FIELD02"
                    valexpr="FIELD01"
                    setEdit={(e) => {
                      // console.log("setInvoiceType=>", e);
                      setInvoiceType(e);
                    }}
                    onKeyDown={(e) => {
                      GlobalClass.formNextInput(e, formRef);
                    }}
                  />
                </Grid.Col>
                
              </Grid.Col>
              
            ) : (
              ""
            )}

            <Grid.Col span={12}>
            <Center>
                <Button
                  // disabled={group?.FIELD01?.length <= 0 }
                  size="xs"
                  style={{ width: "130px" }}
                  onClick={handleOkButton}
                  mx={4}
                  // className={classes.button}
                  compact
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      handleOkButton();
                    }
                  }}
                >
                  Ok
                </Button>
              </Center>
            </Grid.Col>
          </Grid>
          </Paper>
        </>
      )}
    </div>
  );
};

export default VoucherNumberForm;
