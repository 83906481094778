import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const GetAccountGroup = createAsyncThunk(
  "AccountGroup/GetAccountGroup",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM11 + "?pa=" + param
      );
      return response.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const DeleteAccData = createAsyncThunk(
  "AccountGroup/DeleteAccGroup",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
        Yrno:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM11 + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

        Store.dispatch(
          GetAccountList({
            id: "00540001",
            name: "A/c. Group",
            p0: "MT",
            p1: "T_M11",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["00540001"],
          })
        );
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);
const AccountGroupSlice = createSlice({
  name: "AccountGroup",
  initialState: {
    AccountGroupData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAccountGroup.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.AccountGroupData = [];
      })
      .addCase(GetAccountGroup.fulfilled, (state, action) => {
        state.AccountGroupData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAccountGroup.rejected, (state, action) => {
        state.hasError = true;
        state.ErrorMsg = action.payload?.error
        state.isLoading = false;
      })
      .addCase(DeleteAccData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteAccData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteAccData.rejected, (state, action) => {
        state.hasError = true;
        state.ErrorMsg = action.payload?.error
        state.isLoading = false;
      });
  },
});
export default AccountGroupSlice.reducer;
