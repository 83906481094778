import { ActionIcon, Button, Flex, Grid, Group, Modal, Text, Tooltip } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { useDispatch, useSelector } from 'react-redux'
import { TransactionApi } from '../../utils/TransactionSlices/Transaction'
import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import PLNarration from '../../PlusComponent/PLNarration'
import PLTextarea from '../../PlusComponent/PLTextarea'
import GlobalClass from '../../utils/GlobalClass'
import JBUserFieldEntry from '../JobWorkOpening/JBUserFieldEntry'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import { IconEdit } from '@tabler/icons-react'
import { IconTrash } from '@tabler/icons-react'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import gensetting from '../../utils/gensetting'
import { TabRemove } from '../../utils/slices/TabList'
import Store from '../../utils/store'

const TransactionLeadEntryForm = (props) => {
    const { obj } = props
    console.log("TransactionLeadEntryForm_props", props)
    const pr = useRef();
    const TableRef = useRef();
    const {
        isLoading: AccountisLoading,
        AccountListData: accList,
        TMFormatData: tmList
    } = useSelector((state) => state.AccountList);
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const { isLoading, hasError, ErrorMsg } = useSelector(
        (state) => state.Transaction
    );
    const GetGeneral = useSelector((state) => state?.Language?.General);
    const leadEntryData = useSelector((state) => state.Transaction?.TransactionData)
    console.log("leadEntryData", leadEntryData)
    const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
    const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
    const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
    const T02ENT = useSelector(
        (state) => state.Transaction?.TransactionData?.T02ENT
    );
    const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
    const T02UF = useSelector(
        (state) => state.Transaction?.TransactionData?.T02UF
    );
    const P_M02L = useSelector(
        (state) => state.Transaction?.TransactionData?.P_M02L
    );
    const P_M17 = useSelector(
        (state) => state.Transaction?.TransactionData?.P_M17
    );
    const PLDATA = useSelector((state) => state.Transaction?.TransactionData?.PLDATA);
    const { oYear } = useSelector((state) => state?.Language?.General);
    const columnHelper = createColumnHelper()

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });
    const [NarrationData, setNarrationData] = useState([]);
    const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [columns, setColumns] = useState([])
    const [T02Data, setT02Data] = useState([])
    const [T41Obj, setT41Obj] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": 0,
        "FIELD04": "",
        "FIELD05": "",
        "FIELD06": "0",
        "FIELD07": "0",
        "FIELD08": 0,
        "FIELD09": 0,
        "FIELD10": "",
        "FIELD11": "",
        "FIELD12": "",
        "FIELD13": "",
        "FIELD14": "N",
        "FIELD15": "",
        "FIELD16": "",
        "FIELD17": "",
        "FIELD18": 0,
        "FIELD21": "T",
        "FIELD22": "",
        "FIELD23": "",
        "FIELD30": "",
        "FIELD31": "",
        "FIELD32": "",
        "FIELD33": "",
        "FIELD70": "",
        "FIELD71": "",
        "FIELD75": "",
        "FIELD99": "",
        "FIELD72": "",
        "FIELD20": 0,
        "FIELD80": "",
        "FIELD51": 0,
        "FIELD34": "",
        "FIELD35": "",
        "FIELD36": "",
        "FIELD37": "",
        "FIELD38": "",
        "FLDJIO": "",
        "FIELD39": "",
        "FIELD40": "",
        "FIELD41": "",
        "FIELD42": "",
        "FIELD43": "",
        "FIELD44": "",
        "FIELD45": "",
        "FIELD46": "",
        "FIELD47": "",
        "FIELD48": "",
        "FIELD49": "",
        "FIELD50": "",
        "FIELD52": "",
        "FIELD53": "",
        "T41PVT": "LD",
        "FIELD54": "",
        "T41VNO": "",
        "CMID": 0,
        "ADDID": 0,
        "PRESCPATH": "",
        "OSTATUS": "",
        "DTYPE": "",
        "DELURL": "",
        "DELMBNO": "",
        "FIELD55": "",
        "FIELD56": "",
        "T41POS": "",
        "FLDUNQ": 0,
        "FLDAED": "A",
        "FLDBRC": "",
        "EA00000001": "",
        "EA00000002": "",
        "EA00000003": "",
        "EA00000004": "",
        "EA00000005": "",
        "EA00000006": "",
        "EA00000007": "",
        "EA00000008": "",
        "EA00000009": "",
        "EA00000010": "",
        "EA00000011": "",
        "EA00000012": "",
        "EA00000013": "",
        "EA00000014": "",
        "EA00000015": "",
        "ED00000001": 0,
        "ED00000002": 0,
        "ED00000003": 0,
        "ED00000004": 0,
        "ED00000005": 0,
        "ED00000006": 0,
        "ED00000007": 0,
        "ED00000008": 0,
        "ED00000009": 0,
        "ED00000010": 0,
        "ED00000011": 0,
        "ED00000012": 0,
        "ED00000013": 0,
        "ED00000014": 0,
        "ED00000015": 0,
        "EP00000001": 0,
        "EP00000002": 0,
        "EP00000003": 0,
        "EP00000004": 0,
        "EP00000005": 0,
        "EP00000006": 0,
        "EP00000007": 0,
        "EP00000008": 0,
        "EP00000009": 0,
        "EP00000010": 0,
        "EP00000011": 0,
        "EP00000012": 0,
        "EP00000013": 0,
        "EP00000014": 0,
        "EP00000015": 0,
        "PA000001": "",
        "PA000002": "",
        "PD000001": 0,
        "PD000002": 0,
        "U0000001": "",
        "UBILTO": "",
        "UCITY": "",
        "UCONTACT": "",
        "UCONUMBE": 0,
        "UCRE": "",
        "UCRE2": 0,
        "UDCNO": "",
        "UDE": "",
        "UDEL": "",
        "UDISP": "",
        "UDT": "",
        "UEMAIL": "",
        "UGSTR": "",
        "ULEN": "",
        "ULRD": "",
        "UNAME": "",
        "UOD": "",
        "UODD": "",
        "UODP": "",
        "UOP": "",
        "UORD": "",
        "UPAYT": "",
        "UPR": "",
        "URA": "",
        "UREMA": "",
        "USAL": 0,
        "USALM": "",
        "UTD": "",
        "UTR": "",
        "UTRA": "",
        "EA00000016": "",
        "ED00000016": 0,
        "EP00000016": 0,
        "EA00000017": "",
        "ED00000017": 0,
        "EP00000017": 0,
        "EA00000019": "",
        "ED00000019": 0,
        "EP00000019": 0,
        "EA00000020": "",
        "ED00000020": 0,
        "EP00000020": 0,
        "EA00000021": "",
        "ED00000021": 0,
        "EP00000021": 0,
        "EA00000018": "",
        "ED00000018": 0,
        "EP00000018": 0,
        "EA00000022": "",
        "ED00000022": 0,
        "EP00000022": 0,
        "EA00000023": "",
        "ED00000023": 0,
        "EP00000023": 0,
        "EA00000025": "",
        "ED00000025": 0,
        "EP00000025": 0,
        "EA00000024": "",
        "ED00000024": 0,
        "EP00000024": 0,
        "FIELD60": "",
        "EA00000026": "",
        "ED00000026": 0,
        "EP00000026": 0,
        "EA00000027": "",
        "ED00000027": 0,
        "EP00000027": 0,
        "EA00000028": "",
        "ED00000028": 0,
        "EP00000028": 0,
        "DSDATE": "2024-04-01T00:00:00+05:30",
        "DEDATE": "2025-03-31T00:00:00+05:30",
        "CACTION": "A",
        "lInvType": false,
        "lMTaxEnt": false,
        "T92F00": "",
        "FIELD38NM": "",
        "CFRMTITLE": "Add _VTLLD",
        "BTOBEFF": "N",
        "M01BAL": "0",
        "M01GRP": "",
        "M01OPPGRP": "",
        "M01CT": "",
        "F07ACC": "",
        "LBLF08AMT": "0.00   ",
        "LBLF07AMT": "0.00   ",
        "LBLF08SYMB": null,
        "LBLF07SYMB": null
    })
    const [T02Obj, setT02Obj] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": "",
        "FIELD04": "",
        "FIELD05": "",
        "FIELD06": 0,
        "FIELD07": 0,
        "FIELD08": 0,
        "FIELD09": 0,
        "FIELD10": 0,
        "FIELD11": "",
        "FIELD12": "",
        "FIELD56": "",
        "FIELD13": "",
        "FIELD14": "",
        "FIELD15": "",
        "FIELD16": "",
        "FIELD17": "",
        "FIELD18": "",
        "FIELD19": "",
        "FIELD20": "",
        "FIELD21": 0,
        "FIELD22": 0,
        "FIELD23": 0,
        "FIELD24": "",
        "FIELD25": 0,
        "FIELD26": "",
        "FIELD27": "",
        "FIELD28": false,
        "FIELD29": 0,
        "FIELD30": "",
        "FIELD31": 0,
        "FIELD32": 0,
        "FIELD33": 0,
        "FIELD41": "",
        "FIELD50": 0,
        "FIELD51": "",
        "FIELD52": 0,
        "FIELD71": "",
        "FIELD81": 0,
        "FIELD82": "",
        "FIELD83": "",
        "FIELD84": "",
        "FIELD85": "",
        "FIELD86": "",
        "FIELD61": 0,
        "FIELD62": 0,
        "FIELD63": "",
        "FIELD64": 0,
        "FIELD65": "",
        "FIELD66": 0,
        "FIELD67": 0,
        "FIELD72": "",
        "FIELD68": "",
        "FIELD69": "",
        "FIELD53": "",
        "FIELD54": 0,
        "FIELD55": 0,
        "FIELD091": 1,
        "FIELD58": "",
        "FIELD57": "",
        "FIELD60": 0,
        "T41PVT": "",
        "FIELD87": 0,
        "FIELD88": "",
        "FLDUNQ": 0,
        "FLDAED": "",
        "FLDBRC": "",
        "FIELD90": 0,
        "IA00000001": "",
        "IA00000002": "",
        "IA00000003": "",
        "IA00000004": "",
        "IA00000005": "",
        "IA00000006": "",
        "IA00000007": "",
        "IA00000008": "",
        "IA00000009": "",
        "IA00000010": "",
        "IA00000011": "",
        "IA00000012": "",
        "IA00000013": "",
        "IA00000014": "",
        "IA00000015": "",
        "IA00000016": "",
        "IA00000017": "",
        "IA00000018": "",
        "IA00000019": "",
        "IA00000020": "",
        "IA00000021": "",
        "IA00000022": "",
        "IA00000023": "",
        "IA00000024": "",
        "IA00000025": "",
        "ID00000001": 0,
        "ID00000002": 0,
        "ID00000003": 0,
        "ID00000004": 0,
        "ID00000005": 0,
        "ID00000006": 0,
        "ID00000007": 0,
        "ID00000008": 0,
        "ID00000009": 0,
        "ID00000010": 0,
        "ID00000011": 0,
        "ID00000012": 0,
        "ID00000013": 0,
        "ID00000014": 0,
        "ID00000015": 0,
        "ID00000016": 0,
        "ID00000017": 0,
        "ID00000018": 0,
        "ID00000019": 0,
        "ID00000020": 0,
        "ID00000021": 0,
        "ID00000022": 0,
        "ID00000023": 0,
        "ID00000024": 0,
        "ID00000025": 0,
        "IP00000001": 0,
        "IP00000002": 0,
        "IP00000003": 0,
        "IP00000004": 0,
        "IP00000005": 0,
        "IP00000006": 0,
        "IP00000007": 0,
        "IP00000008": 0,
        "IP00000009": 0,
        "IP00000010": 0,
        "IP00000011": 0,
        "IP00000012": 0,
        "IP00000013": 0,
        "IP00000014": 0,
        "IP00000015": 0,
        "IP00000016": 0,
        "IP00000017": 0,
        "IP00000018": 0,
        "IP00000019": 0,
        "IP00000020": 0,
        "IP00000021": 0,
        "IP00000022": 0,
        "IP00000023": 0,
        "IP00000024": 0,
        "IP00000025": 0,
        "IT00000001": "",
        "IT00000002": "",
        "IT00000003": "",
        "IT00000004": "",
        "IT00000005": "",
        "IT00000006": "",
        "IT00000007": "",
        "IT00000008": "",
        "IT00000009": "",
        "IT00000010": "",
        "IT00000011": "",
        "IT00000012": "",
        "IT00000013": "",
        "IT00000014": "",
        "IT00000015": "",
        "IT00000016": "",
        "IT00000017": "",
        "IT00000018": "",
        "IT00000019": "",
        "IT00000020": "",
        "IT00000021": "",
        "IT00000022": "",
        "IT00000023": "",
        "IT00000024": "",
        "IT00000025": "",
        "UAP": 0,
        "UBA": "",
        "UDDATE": "",
        "UDIS": 0,
        "UDISC": 0,
        "UGRO": 0,
        "UISSUERA": 0,
        "UMUR": 0,
        "UPE": 0,
        "URATE": 0,
        "URE": "",
        "UREMARK": "",
        "IA00000026": "",
        "ID00000026": 0,
        "IP00000026": 0,
        "IT00000026": "",
        "IA00000027": "",
        "ID00000027": 0,
        "IP00000027": 0,
        "IT00000027": "",
        "IA00000028": "",
        "ID00000028": 0,
        "IP00000028": 0,
        "IT00000028": "",
        "IA": "",
        "ID": 0,
        "IP": 0,
        "IT": "",
        "USERFLD": "",
        "F03NM": "",
        "F20NM": "",
        "CATNM": "",
        "GRPNM": "",
        "CATCD": "",
        "GRPCD": "",
        "USERFLDB": "",
        "SRDETAIL": "",
        "EXPDUMMY": " ",
        "BARCODE": "",
        "OLDPRD": "",
        "OLDQTY": 0,
        "OQTY2": 0,
        "OLDRATE": 0,
        "LADDNEW": "Y",
        "LVALID": "Y",
        "PRICELIST": " ",
        "_BATSTOCK": " ",
        "_LOCWISE": " ",
        "LDUALSTK": " ",
        "SRNOSTK": " ",
        "MRPSTK": " ",
        "RORI": " ",
        "ORDDUMMY": " ",
        "JODUMMY": " ",
        "SRIDUMMY": " ",
        "SRRDUMMY": " ",
        "SRIDUMMYF": " ",
        "SRRDUMMYF": " ",
        "SRISSCD": "",
        "OLDSRNO": "",
        "SCNOCL": "",
        "SCNOCL1": "",
        "MANUDATE": "",
        "EXPDATE": "",
        "T02F08EXP": 0,
        "_EXPTOTALAMT": 0
    })
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [selectedProductObj, setSelectedProductObj] = useState({});
    const [priceListParemeter, setPriceListParemeter] = useState({
        priceListCode: "",
        productCode: "",
        accountCode: "",
    });
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
        "Group": "",
        "Catogary": "",
        "Product": ""
    })
    const [f02, setF02] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        if (obj) {
            dispatch(TransactionApi(obj))
        }
    }, [obj])

    useEffect(() => {
        if (T02) {
            setT02Data(T02)
            if (obj.p0 == "E") {
                setT02Obj(T02?.[T02?.length - 1]);
            } else {
                setT02Obj(T02?.[0])
            }
        }
    }, [T02])

    useEffect(() => {
        if (T41) {
            setT41Obj(T41)
        }
    }, [T41])

    useEffect(() => {
        // if (T02Obj?.FIELD33 !== null || T02Obj?.FIELD07 !== null) {
        setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
        // }
    }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

    // pricelist value set
    useEffect(() => {
        setT02Obj({
            ...T02Obj,
            FIELD27: priceListParemeter?.priceListCode
        })
    }, [priceListParemeter])

    let stringOfNames;
    useEffect(() => {
        if (SelectedNarrationData.length >= 0) {
            const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
            stringOfNames = stringifyArray.join(" ");
            console.log("stringOfNames", stringOfNames);
        }
        setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
        // setT41Obj({...T41Obj,FIELD15:})
    }, [stringOfNames, SelectedNarrationData]);

    function dateFormater(date) {
        let temp = date?.split("T");
        let temp1 = temp[0]?.replaceAll("-", "")
        return temp1;
    }

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus()
        }
    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const HiddenButtonField = (uID, v, c, cellid, index) => {
        switch (c.S53F02) {
            case "E00ORDDUM": // Dummy Order
                NextIndexFun(index)
                console.log('Dummy Order')
                // setDrawerObj({
                //     ...dra,
                //     title: <Text fw={700}>{c.S53F03}</Text>,
                //     body: <>
                //         <Text>{c.S53F03}</Text>
                //         <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                //             <Button
                //                 onClick={() => {
                //                     // setDrawerObj({ ...DrawerObj, open: false })
                //                     Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                //                         .then(() => {
                //                             if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
                //                                 NextIndexFun(index)
                //                             } else {
                //                                 NextIndexFun(index + 1)
                //                             }
                //                         }
                //                         )
                //                 }}
                //                 onKeyDown={(e) => {
                //                     if (e.key == "Enter") {
                //                         Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                //                             .then(() => {
                //                                 if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
                //                                     NextIndexFun(index)
                //                                 } else {
                //                                     NextIndexFun(index + 1)
                //                                 }
                //                             }
                //                             )
                //                     }
                //                 }}
                //             >Ok</Button>
                //         </div>
                //     </>,
                //     open: true,
                //     size: "sm",
                //     position: "right"
                // })
                break;
            case "E00USERFLD": // User Field
                console.log('user field')
                if (T02UF && T02UF.length > 0) {
                    setDrawerObj({
                        ...dra, title: <Text fw={700}>User Field</Text>,
                        onClickCloseButton: () => {
                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                        },
                        body: <>
                            <JBUserFieldEntry
                                T02UF={T02UF}
                                T02ObjSend={T02Obj}
                                setT02Objsend={(e) => {
                                    Promise.resolve(setT02Obj(e)).then(() => {
                                        if (selectedProductObj?.FIELD59 == "N") {
                                            setTimeout(() => {
                                                NextIndexFun(index + 2)
                                            }, 500)
                                        } else {
                                            setTimeout(() => {
                                                NextIndexFun(index)
                                            }, 500)
                                        }
                                    })
                                    // console.log("FormUserField=>",e);
                                }}
                                DrawerObjSend={DrawerObj}
                                setDrawerObjSend={(e) => {
                                    setDrawerObj(e)
                                }}
                            />
                        </>, open: true, size: "sm", position: "right"
                    })
                } else {
                    NextIndexFun(index)
                }
                break;
            case "E00T02F23": // PriceList
                if (PLDATA && PLDATA?.length > 0) {
                    console.log('priceList')
                    if (selectedProductObj?.FIELD53 == "N") {
                        // setTimeout(() => {
                        NextIndexFun(index)
                        // }, 500)
                    } else {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>{c.S53F03}</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                            },
                            body: <>
                                <PLComboBox
                                    initiallyOpened={true}
                                    cmbid={`T02Ent_${index}`}
                                    data={typeof PLDATA == "string" ? [{}] : PLDATA}
                                    value={T02Obj?.[c?.S53F04]}
                                    dispexpr={"FIELD02"}
                                    valexpr={"FIELD01"}
                                    setEdit={(e) => {
                                        // console.log("Pricelist OBJ=>", e);
                                        Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                                            if (T41Obj.FIELD16 == "C") {
                                                setPriceListParemeter({
                                                    ...priceListParemeter,
                                                    accountCode: T41Obj.FIELD31,
                                                    priceListCode: e,
                                                });
                                            } else {
                                                setPriceListParemeter({
                                                    ...priceListParemeter,
                                                    accountCode: T41Obj.FIELD04,
                                                    priceListCode: e,
                                                });
                                            }
                                        })
                                    }}
                                />
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                                    <Button
                                        onClick={() => {
                                            // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                                            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                                                .then(() => {
                                                    if (selectedProductObj?.FIELD59 == "N" && T02UF?.length == 0) {
                                                        // setTimeout(() => {
                                                        NextIndexFun(index + 3)
                                                        // }, 500)
                                                    } else if (T02UF?.length == 0 && selectedProductObj?.FIELD59 !== "N") {
                                                        NextIndexFun(index + 1)
                                                    } else {
                                                        NextIndexFun(index)
                                                    }
                                                })
                                        }}
                                        onKeyDown={(e) => {
                                            // e.stopPropagation();
                                            if (e.key == "Enter") {
                                                Promise.resolve()
                                                    .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                                                    .then(() => {
                                                        setTimeout(() => {
                                                            NextIndexFun(index)
                                                        }, 500)
                                                    })
                                            }
                                        }}
                                    >OK</Button>
                                </div>
                            </>, open: true, size: "sm", position: "right"
                        })
                    }
                } else {
                    NextIndexFun(index)
                }
                break;
            default:
                break;
        }
    }

    const renderInputBox = (uID, v, c, cellid, index) => {
        // console.log('uID=>', uID)
        // console.log('v=>', v)
        // console.log('c=>', c)
        if (c?.S53F07 == 0) {
            return <Button
                id={`T02Ent_${index}`}
                variant="default"
                onFocus={() => {
                    document.activeElement.blur()
                    console.log("Test=>", `T02Ent_${index}`)
                    HiddenButtonField(uID, v, c, cellid, index)
                }}
                onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
                style={{ padding: "0px", margin: "0px" }}
            />
        } else {
            let setInput;
            switch (c?.S53F14) {
                case "PLPOPUP":
                    if (c.S53F02 == "E000T02F03") { // Product Name
                        setInput = <PLDataGrid
                            ref={pr}
                            id={`T02Ent_${index}`}
                            width={'500px'}
                            // autoFocus={true}
                            value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM == "" ? "Select" : T02Obj?.F03NM }}
                            p1={seperate(c.S53F15)[1]}
                            cWhere={`M21.FIELD11 LIKE '%${T02Obj?.GRPCD ?? ""}%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
                            // TmData={tmList["P_M21"]?.cSTID}
                            // data={accList["P_M21"]}
                            setEdit={(e) => {
                                console.log("PLDataGrid==>", e);
                                Promise.resolve(setT02Obj({
                                    ...T02Obj,
                                    [c?.S53F04]: e?.FIELD01,
                                    F03NM: e?.FIELD02,
                                    FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28
                                }))
                                    .then(() => setSelectedProductObj(e))
                                    .then(() => {
                                        setTimeout(() => {
                                            NextIndexFun(index)
                                        }, 500)
                                    })
                            }}
                        />
                    } else {
                        setInput = (
                            <PLDataGrid
                                id={`T02Ent_${index}`}
                                width={"500px"}
                                value={T02Obj[c?.S53F04]}
                                p1={seperate(c.S53F15)[1]}
                                setEdit={(e) => {
                                    // console.log('DataGrid object=>>', e)
                                    setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                                }}
                            />
                        );
                    }
                    break;
                case "COMBOBOX":
                    if (c.S53F02 == "E00T02RORI") { // Receipt/Issue
                        setInput = (
                            <PLComboBox
                                cmbid={`T02Ent_${index}`}
                                // autoFocus={true}
                                value={T02Obj[c?.S53F04]}
                                copno={seperate(c.S53F15)[1]}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log('reciept/issue value', e)
                                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))
                                        // .then(() => )
                                        .then(() => {
                                            setTimeout(() => {
                                                if (oYear?.OSC?._LOTNO == "Y") {
                                                    setTimeout(() => {
                                                        NextIndexFun(index)
                                                    }, 500)
                                                } else {
                                                    if (selectedProductObj?.FIELD51 !== "Y" && T02Obj?.FIELD05 !== "D" && T02UF?.length == 0 && selectedProductObj?.FIELD59 == "N") {
                                                        NextIndexFun(index + 6)
                                                    } else if (selectedProductObj?.FIELD51 !== "N") {
                                                        console.log('if')
                                                        setTimeout(() => {
                                                            NextIndexFun(index + 1)
                                                        }, 500)
                                                    } else {
                                                        console.log('else')
                                                        NextIndexFun(index + 2)
                                                    }
                                                }
                                            }, 500)
                                        })
                                }}
                            />
                        );
                    } else {
                        setInput = (
                            <PLComboBox
                                cmbid={`T02Ent_${index}`}
                                value={T02Obj[c?.S53F04]}
                                copno={seperate(c.S53F15)[1]}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                    console.log('combo val', e)
                                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })).then(() => {
                                        setTimeout(() => {
                                            NextIndexFun(index)
                                        }, 500)
                                    })
                                }}
                            />
                        );
                    }
                    break;
                case "TEXTBOX":
                    setInput = (
                        <PLTextBox
                            id={`T02Ent_${index}`}
                            value={T02Obj[c?.S53F04]}
                            onKeyDown={(e) => {
                                if (e.key == "Tab") {
                                    // setTimeout(() => {
                                    NextIndexFun(index)
                                    // }, 500)
                                }
                            }}
                            setEdit={(e) => {
                                setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                            }}
                        />
                    );
                    break;
                case "NUMBOX":
                    setInput = (
                        <PLNumberBox
                            // disabled={(c?.S53F02 == "E00T02F21" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : (c?.S53F02 == "E00T02F22" && oYear?.OSC?._DUALSTOCK == "Y") ? selectedProductObj != null ? (selectedProductObj?.FIELD59 == "N" ? true : false) : false : false}

                            decimalSeparator={true}
                            precision={3}
                            id={`T02Ent_${index}`}
                            value={T02Obj[c?.S53F04]}
                            setEdit={(e) => {
                                setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                            }}
                            onKeyDown={(e) => {
                                if (e.key == "Tab" || e.key == "Enter") {
                                    // e.stopPropagation();
                                    if (c.S53F02 == "E00T02F08") { // Amount
                                        if (tableEditingRow == null) {
                                            console.log("Add Product Call")
                                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                            } else {
                                                handleCreateRow()
                                            }
                                        } else {
                                            console.log("Edit Product Call")
                                            if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                                                GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                                            } else {
                                                handleEditSave()
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    );
                    break;
                default:
                    setInput = null;
                    break;
            }
            return setInput;
        }
    };

    useEffect(() => {
        let cols = [];
        if (T02ENT) {
            cols.push(
                columnHelper.accessor('CATCD', {
                    header: "Product Category",
                    id: "Product_Category",
                    size: 30,
                    Edit: ({ cell, row }) => {
                        let val = cell.getValue();
                        return <PLDataGrid
                            // autoFocus={true}
                            id={"ID_123"}
                            width={"500px"}
                            TmData={"T_M32C"}
                            // data={accList["T_M32C"]}
                            value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
                            setEdit={(e) => {
                                console.log("Product Category Obj=>", e);
                                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                                Promise.resolve(setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 }))
                                    .then(() => {
                                        pr?.current?.handlePopoverToggle();
                                    });
                            }}
                        />
                    },
                    Cell: ({ cell, row }) => {
                        let val = cell.getValue();
                        return T02Data[row.id]?.CATNM
                    },
                })
            )
            T02ENT.filter((h, i) => h?.S53F00 !== "H")?.map((c, i) => {
                cols.push(
                    columnHelper.accessor(c.S53F04, {
                        id: c.S53UID,
                        // header: c.S53F03,
                        // size: 150,

                        enableGlobalFilter: c.S53F07 == 0 ? false : true,
                        enableSorting: c.S53F07 == 0 ? false : true,
                        header: c.S53F07 == 0 ? "" : c.S53F03,
                        size: c.S53F07 == 0 ? 0 : c.S53F07,
                        // size: c.S53F07 == 0 ? 0 : 150,

                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(c.S53UID, val, c, cell?.id, i);
                        },
                        Cell: ({ cell, row }) => {
                            // console.log("cell.getValue()", cell.getValue())
                            let val = cell.getValue();
                            // console.log("T02Data[i]=>",T02Data[row.id]);
                            if (c.S53F02 == "E000T02F03") { //Product Name
                                return T02Data[row?.id]?.F03NM ?? T02Data[row?.id]?.PrdNM
                                // return "Test"
                            }
                            return c.S53F07 == 0 ? "" : val
                        },
                        //   Footer: () => <div>{c.S53F02 == "E000T02F03" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(c, i, "c")}</div>,
                        // ColumnVisibility : c?.S53F00 == "C" ? true : false
                    })
                );
            })
            setColumns(cols);
        }
    }, [T02ENT, T02, T02Data, GroupCatogaryProduct, selectedProductObj, T02Obj])

    // Update Row
    const handleEditSave = () => {
        console.log('edit T02Data', T02Data)
        setGroupCatogaryProduct({
            "Group": "",
            "Catogary": "",
            "Product": ""
        })
        // dispatch(BatchLogAddRemoveFun({ PageCode: "JAJA", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));
        let EditT02Data = [...T02Data];
        EditT02Data[tableEditingRow.index] = T02Obj;
        let nullFilter = EditT02Data?.filter((d) => d?.FIELD03 != "")
        setT02Data([...nullFilter, (obj.p0 == "E" ? T02?.[T02?.length - 1] : T02?.[0])]);

        if (obj.p0 == "E") {
            setT02Obj(T02?.[T02?.length - 1]);
        } else {
            setT02Obj(T02?.[0])
        }
        setRowSelection({ [T02Data?.length]: true })
        table.setEditingRow(null); //exit editing mode
        setTableEditingRow(null)
        setSelectedProductObj({})
    };

    //Add New Row
    const handleCreateRow = () => {
        console.log("handleCreateUser==>", T02Obj);
        setGroupCatogaryProduct({
            "Group": "",
            "Catogary": "",
            "Product": ""
        })
        // dispatch(BatchLogAddRemoveFun({ PageCode: "JAJA", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));

        let nullFilter = T02Data?.filter((d) => d?.FIELD03 != "")

        setT02Data([...nullFilter, (obj.p0 == "E" ? T02?.[T02?.length - 1] : T02?.[0])]);
        // console.log('T02Data handleCreateUser==>', T02Data)

        if (obj.p0 == "E") {
            setT02Obj(T02?.[T02?.length - 1]);
        } else {
            setT02Obj(T02?.[0])
        }
        table.setCreatingRow(null)
        setSelectedProductObj({})
    };

    const handleNullT02Obj = () => {
        if (obj.p0 == "E") {
            setT02Obj(T02?.[T02?.length - 1]);
        } else {
            setT02Obj(T02?.[0])
        }
        setTableEditingRow(null)
        setSelectedProductObj({})
    };

    const handleRowKeyDown = (e, row) => {
        // if (e?.key == "Enter" && tableEditingRow == null) {
        //   if (row?.id == 'mrt-row-create') {
        //     return
        //   } else {
        //     table.setEditingRow(row)
        //     setTableEditingRow(row)
        //     setT02Obj(row?.original)
        //   }
        // }

        const tabled = TableRef.current;
        const rows = tabled?.querySelectorAll('tr');
        const currentRowIndex = Array.from(rows).indexOf(e.target);
        console.log("TABKEY", e.key);
        switch (e?.key) {
            case 'ArrowDown':
                // e.preventDefault();
                if (currentRowIndex < rows.length - 1) {
                    e.preventDefault();
                    rows[currentRowIndex + 1].focus();
                    // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

                } else if (
                    table?.getPageCount() && table?.getPageCount() > 1 &&
                    pagination?.pageIndex < table?.getPageCount() - 1
                ) {
                    // Navigate to the next page
                    table.nextPage()
                    // setPagination((prev) => ({
                    //   ...prev,
                    //   pageIndex: prev.pageIndex + 1,
                    // }));
                    // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
                    // rows[0].focus();
                }

                break;
            case 'ArrowUp':

                if (currentRowIndex > 0) {
                    e.preventDefault();
                    rows[currentRowIndex - 1].focus();
                    // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
                } else if (pagination.pageIndex > 0) {
                    // Navigate to the previous page
                    table.previousPage()
                    // setPagination((prev) => ({
                    //   ...prev,
                    //   pageIndex: prev.pageIndex - 1,
                    // }));
                    // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
                    // rows[0].focus();
                }

                break;
            case "Enter":
                if (row?.id == 'mrt-row-create') {
                    return
                } else if (tableEditingRow) {
                    return
                } else {
                    table?.setEditingRow(row)
                    setTableEditingRow(row)
                    setT02Obj(row?.original)
                }
                break;
            case "Escape":
                setTableEditingRow(null)
                Promise.resolve()
                    .then(() => handleNullT02Obj())
                    .then(() => {
                        setTimeout(() => {
                            if (tableEditingRow == null) {
                                table.setCreatingRow(false)
                            } else {
                                table.setEditingRow(null)
                            }
                        }, 500)
                    })
                break;
            default:
                break;
        }
    }

    const DeleteRow = (row) => {

        console.log("DeleteRow", row);
        if (window.confirm('Confirm to Delete ?')) {
            let newArray = T02Data?.filter((d, i) => i != row?.index)
            setT02Data(newArray)
        }
    }

    const table = useMantineReactTable({
        columns,
        data: T02Data ?? [],
        mantineTableProps: { withColumnBorders: true },
        enableTopToolbar: false,
        enablePagination: true,
        enableStickyHeader: true,
        enableSorting: false,
        enableEditing: true,
        enableFilters: false,
        enableColumnActions: false,
        mantineTableBodyProps: {
            tabIndex: 0,
            ref: TableRef,
        },
        onCreatingRowSave: () => {
            if (
                // (T02Obj?.FIELD05 == "C" && T02Obj?.FIELD41 == "") || 
                T02Obj?.FIELD03 == "") {
                return GlobalClass.Notify(
                    "warning",
                    "Validation error",
                    "Please fill out all the required fields"
                );
            } else {
                handleCreateRow();
            }
        },
        onEditingRowSave: handleEditSave,
        onCreatingRowCancel: handleNullT02Obj,
        onEditingRowCancel: handleNullT02Obj,
        onPaginationChange: setPagination,
        mantineTableBodyRowProps: ({ row }) => ({
            // ref: TableContainer,
            tabIndex: 0,
            onKeyUp: (e) => handleRowKeyDown(e, row),
            // onKeyDown: (e) => handleRowCancel(e, row),
            onClick: () => {
                setRowSelection((prev) => {
                    if (!prev[row.id]) {
                        return { [row.id]: true };
                    }
                    return prev;
                });
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        initialState: {
            density: "0px",
            // pagination
        },
        state: {
            rowSelection,
            density: "0px",
            // pagination
        },
        editDisplayMode: "row",
        createDisplayMode: "row",
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon
                        disabled={T02Obj?.LVALID == "N" ? true : false}
                        onClick={() => {
                            dispatch(GetAccountList({
                                "id": "P_M21",
                                "name": "",
                                "type": "A",
                                "p0": "P_M21",
                                "p1": "P_M21",
                                "p2": "",
                                "p3": "",
                                "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
                                // "cWhere": ""
                            }))
                            table.setEditingRow(row);
                            // console.log('table.setEditingRow=>', row);
                            setT02Obj(row.original);
                            setTableEditingRow(row)
                        }}
                    >
                        <IconEdit size={"20px"} />
                    </ActionIcon>
                </Tooltip>
                {row?.original?.FIELD03 != "" &&
                    <Tooltip label="Delete">
                        <ActionIcon color="red"
                            // disabled={T02Obj?.LVALID == "N" ? true : false}
                            onClick={() => {
                                if (T02Obj?.LVALID == "N") {
                                    GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
                                } else {
                                    DeleteRow(row)
                                }
                            }}
                        >
                            <IconTrash size={'20px'} />
                        </ActionIcon>
                    </Tooltip>
                }
            </Flex>
        ),
    })

    const rowSum = T02Data.reduce((a, b) => {
        // if (b?.FIELD05 === "C") {
          a = a + (b?.FIELD08 || 0);
        // } else if (b?.FIELD05 === "D") {
        //   a = a - (b?.FIELD08 || 0);
        // }
        return a;
      }, 0)

    // Final Submit
  const handleSubmit = (e) => {
    // console.log('T41Obj==>', T41Obj)
    // console.log('T02Data==>', T02Data)
    if (obj.p0 == "E" && ((JSON.stringify(T02) === JSON.stringify(T02Data)) && (JSON.stringify(T41) === JSON.stringify(T41Obj)))) {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
          dispatch(ModalDelete(props.index));
      });
      return;
    }
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    let FilterT02Data = T02Data?.filter((t) => t?.FIELD03 != "")
    var JData = { T41: e ? e : { ...T41Obj, FIELD07: rowSum, FIELD06: rowSum }, T02: [...FilterT02Data] };
    console.log("jData",JData)

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode: obj.p0 == "E" ? obj.id : "LDLD",
      cSData: JSON.stringify(JData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode: obj.p0 == "E" ? obj.id : "LDLD",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == "SUCCESS") {
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            )
            .then(() => {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
                Promise.resolve(dispatch(DataSaveLoading(false)))
                  // .then(() => dispatch(ModalDelete(props.index)))
                  .then(() => {
                    if (obj.p0 == "A") {
                        dispatch(TransactionApi(obj))
                    } else {
                        dispatch(ModalDelete(props.index));
                    }
                  }).then(() => {
                    dispatch(
                      GetAccountList({
                        id: "20600002",
                        name: "Lead Entry",
                        p0: "MT",
                        p1: "TXNVOULISTLD",
                        p2: "TFORM0000001",
                        p3: "",
                        type: "A",
                        pagination: PaginationObj["20600002"],
                      })
                    );
                  })
            })
        } else {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            console.log('test'),
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)));
      });
    // console.log('data', data)
  };

    return (
        <>
            {
                hasError ? <>
                    <Text>{ErrorMsg?.message}</Text>
                    <Button onClick={() => {
                        if (obj) {
                           dispatch(TransactionApi(obj))
                        }
                    }}>Retry</Button>
                </> :
                    SaveLoading == true ?
                        GlobalClass.RenderLoader("dots")
                        :
                        <>
                            {
                                isLoading ? GlobalClass.RenderLoader("dots") :
                                    <div>
                                        <Modal
                                            opened={DrawerObj?.open}
                                            // onClose={close}
                                            withCloseButton={() => {

                                            }}
                                            fullScreen={false}
                                            closeButtonProps={{
                                                onClick: () => {
                                                    if (typeof DrawerObj?.onClickCloseButton == "function") {
                                                        DrawerObj?.onClickCloseButton();
                                                    }
                                                    else {
                                                        setDrawerObj(dra);
                                                    }
                                                },
                                            }}
                                            title={DrawerObj.title}
                                            size={DrawerObj.size}
                                            // centered={.Centered}
                                            closeOnClickOutside={true}
                                            position={DrawerObj?.position ?? 'bottom'}
                                            onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                        >
                                            {DrawerObj.body}
                                        </Modal>
                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Party A/C :</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLDataGrid
                                                        width={"500px"}
                                                        TmData={P_M02L}
                                                        value={{value:T41Obj?.FIELD04, label:T41Obj?.FIELD04NM}}
                                                        // dispexpr="FIELD61"
                                                        setEdit={(e) => {
                                                            console.log("datagrid", e)
                                                            setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM:e?.FIELD61 })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Text size={12} style={{ textAlign: "center" }}>Date :</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLDateBox
                                                        dispformat="DD/MM/YYYY"
                                                        setEdit={(e) => {
                                                            let year = e.toLocaleString("en-IN", {
                                                                year: "numeric",
                                                            });
                                                            let month = e.toLocaleString("en-IN", {
                                                                month: "2-digit",
                                                            });
                                                            let day = e.toLocaleString("en-IN", {
                                                                day: "2-digit",
                                                            });

                                                            let Date = year + month + day;

                                                            if (Date?.length == 8) {
                                                                setF02(true)
                                                                setT41Obj({ ...T41Obj, FIELD02: Date });
                                                            }
                                                        }}
                                                        defaultval={props?.obj?.p0 == "E" || f02 ? T41Obj?.FIELD02 : dateFormater(GetGeneral?.dYSDate)}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Voucher No. :</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLTextBox
                                                        value={T41Obj?.FIELD12}
                                                        setEdit={(e) => {
                                                            console.log("setEdit=>", e);
                                                            setT41Obj({ ...T41Obj, FIELD12: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Text size={12} style={{ textAlign: "center" }}>Type :</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLDataGrid
                                                        width={"500px"}
                                                        TmData={P_M17}
                                                        value={{value:T41Obj?.FIELD38, label:T41Obj?.FIELD38NM}}
                                                        setEdit={(e) => {
                                                            setT41Obj({...T41Obj, FIELD38:e?.FIELD01, FIELD38NM : e?.FIELD02})
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12}>
                                                <MantineReactTable table={table} />
                                            </Grid.Col>
                                            <Grid.Col span={12}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Narration</Text>
                                                </Grid.Col>
                                                <Grid.Col span={5}>
                                                    <PLTextarea
                                                        minRows={2}
                                                        value={T41Obj?.FIELD15}
                                                        onChange={(e) => {
                                                            // console.log(e)
                                                            setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            // console.log("Narration=>", e);
                                                            if (e.ctrlKey && e.key === "F1") {
                                                                //console.log("Narration call");

                                                                setDrawerObj({
                                                                    ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                                                        <PLNarration
                                                                            NarrationArray={NarrationData}
                                                                            selectedNarrationArray={
                                                                                SelectedNarrationData
                                                                            }
                                                                            Flag={SelectedNarrationData?.length > 0 ? false : true}
                                                                            // NarrationVal={T41Obj}
                                                                            setNarrationArray={(e) => {
                                                                                // setT41Obj(e)
                                                                                console.log("setNarrationArray", e);
                                                                                setNarrationData(e.NarrationData);
                                                                                setSelectedNarrationData(
                                                                                    e.selectedNarration
                                                                                );
                                                                            }}
                                                                            DrawerOBJ={DrawerObj}
                                                                            setDrawerOBJ={(e) => setDrawerObj(e)}
                                                                        />
                                                                    </>, open: true, size: "50%", position: "right"
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                            <Grid.Col span={12}>
                                                <Group style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <Button
                                                    onClick={() => {
                                                        handleSubmit()
                                                    }}
                                                    >OK</Button>
                                                    <Button
                                                        onClick={() => dispatch(ModalDelete(props.index))}
                                                    >Cancel</Button>
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </div>
                            }
                        </>
            }
        </>
    )
}

export default TransactionLeadEntryForm