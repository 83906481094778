import { Button, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { useDispatch, useSelector } from 'react-redux'
import { GetDataFreeze } from '../../utils/TransactionSlices/DataFreeze'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import { ModalDelete } from '../../utils/slices/ModalSlice'

const DataFreeze = (props) => {

    const { obj } = props
    console.log('DataFreeze obj=>>>>>', obj)

    const dispatch = useDispatch();
    const DataFreezeData = useSelector(store => store?.DataFreeze?.DataFreezeData);
    const isLoading = useSelector(store => store?.DataFreeze?.isLoading);
    const hasError = useSelector((state) => state?.DataFreeze?.hasError);
    const ErrorMsg = useSelector((state) => state?.DataFreeze?.ErrorMsg);
    const APIStatus = useSelector(store => store?.DataFreeze?.APIStatus);
    const { isLoading: isLoading1 } = useSelector((state) => state.AccountList);
    const GetGeneral = useSelector((state) => state.Language.General);

    const [state, setState] = useState();
    const [cType, setcType] = useState();
    const [minimunDate, setminimunDate] = useState();
    const [maximumDate, setmaximumDate] = useState();
    // const [sd, setSD] = useState();
    // const [ed, setED] = useState();

    // const sDate = localStorage.getItem("StartDate");
    // const eDate = localStorage.getItem("EndDate");

    useEffect(() => {
        dispatch(GetDataFreeze(obj))
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        console.log("DataFreezeData", DataFreezeData);
        setState({ ...DataFreezeData, cboVal: DataFreezeData?.cboVal ?? 'A' });

        // console.log("isLoading", isLoading);
        // console.log("APIStatus", APIStatus);
        // if (APIStatus === "SUCCESS") {
        //     // setState({ ...DataFreezeData, cboVal: 'A' });

        //     // if (DataFreezeData.StartDate == "" && DataFreezeData?.EndDate == "") {

        //     //     setState({ ...DataFreezeData, cboVal: 'A', StartDate: "", EndDate: "" });
        //     // }
        //     // else {
        //     // }
        // }

    }, [DataFreezeData])

    useEffect(() => {
        console.log("state", state);
    }, [state])

    useEffect(() => {
        // console.log("GetGeneral", GetGeneral?.dYSDate, GetGeneral?.dYEDate);
        var strtDate = GetGeneral?.dYSDate.split("T");
        var sDate = new Date(strtDate)
        var endDate = GetGeneral?.dYEDate.split("T");
        var eDate = new Date(endDate)
        setminimunDate(sDate)
        setmaximumDate(eDate)
        // var strtDate = GetGeneral?.dYSDate.split("T");
        // const sD1 = strtDate[0]?.slice(8, 10) + "-" + strtDate[0]?.slice(5, 7) + "-" + strtDate[0]?.slice(0, 4)
        // const sDate = GetGeneral?.dYSDate;
        // const endDate = GetGeneral?.dYEDate;
    }, [GetGeneral])

    // const seprate = (date) => {
    //     let year = date.toLocaleString("en-IN", { year: "numeric" });
    //     let month = date.toLocaleString("en-IN", { month: "2-digit" });
    //     let day = date.toLocaleString("en-IN", { day: "2-digit" });
    //     let date1 = year + month + day;
    //     // const date2 = date1?.slice(0, 2) + "-" + date1?.slice(2, 4) + "-" + date1?.slice(4, 8);
    //     return date1
    // }

    const postBtn = (cAction) => {

        if (state?.StartDate && state?.EndDate) {
            // dispatch(DataSaveLoading(true));
            // GlobalClass.Notify("info", toggle ? "Adding" : "Editing", "Please wait while we process your data");
            // const sDate = seprate(state?.StartDate)
            // const eDate = seprate(state?.EndDate)
            const data = { "StartDate": state?.StartDate, "EndDate": state?.EndDate, "cboVal": state?.cboVal, "cType": cType ?? "" }

            var data1 = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: cAction,
                cCode: "",
                cOPara: "",
                cSData: JSON.stringify(data),
            };

            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: cAction,
                cCode: "",
                cOPara: "",
            });

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data1),
            };

            // if (OBJ.p0 == "E" && JSON.stringify(data) === JSON.stringify(dataObj)) {
            //     GlobalClass.Notify("success", "Edited", "Edited successfully");
            //     Promise.resolve(dispatch(DataSaveLoading(false)))
            //         .then(() => dispatch(ModalDelete(props.index)));
            // }
            // else {

            fetch(GlobalClass.ApiUrl + GlobalClass.PostF02DataFreez + "?pa=" + param, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        // Promise.resolve(dispatch(DataSaveLoading(false)));
                        GlobalClass.Notify("success", "Success", "Posted successfully");
                        dispatch(ModalDelete(props.index))
                        // setState({...state,StartDate:"",EndDate:""})

                    }
                    else if (data.status === "FAIL") {
                        // Promise.resolve(dispatch(DataSaveLoading(false)));
                        GlobalClass.Notify("error", data?.status, data?.message);
                    }
                })
                .catch((e) => {
                    // Promise.resolve(dispatch(DataSaveLoading(false)));
                    GlobalClass.Notify("error", "Error", `${e?.message}`);
                });
            // } 
            // }
        }
        else {
            GlobalClass.Notify("warning", "Date is Empty or Invalid,Please Enter Valid Date");
        }
    }

    console.log("isLoading", isLoading, isLoading1);

    return (
        <>
            {
                hasError ?
                    <>
                        <Text>{ErrorMsg?.message}</Text>
                        <Button
                            onClick={() => {
                                if (obj) {
                                    dispatch(GetDataFreeze(obj))
                                }
                            }}
                        >
                            Retry
                        </Button>
                    </>
                    :
                    (isLoading || isLoading1?.[obj?.id]) ? GlobalClass.RenderLoader("dots") :
                        <Grid gutter={4}>
                            <Grid.Col span={12} style={{ padding: 0, display: "flex" }}>

                                <Grid.Col span={6}>
                                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                        <Grid.Col span={3}>
                                            <Text size={12}>Start Date</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            {
                                                state?.StartDate && state?.EndDate ?

                                                    <PLDateBox
                                                        defaultval={state?.StartDate}
                                                        dispformat="DD/MM/YYYY"
                                                        minDate={minimunDate}
                                                        maxDate={maximumDate}
                                                        setEdit={(e) => {
                                                            let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                            let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                            let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                            let date = year + month + day;
                                                            if (date?.length == 8) {
                                                                setState({ ...state, StartDate: date })
                                                            }
                                                            // setSD(date)
                                                        }}
                                                    />


                                                    :
                                                    <PLDateBox dispformat="DD/MM/YYYY"
                                                        minDate={minimunDate}
                                                        maxDate={maximumDate}
                                                        isVal={false}
                                                        placeholder="Enter Start Date"
                                                        setEdit={(e) => {
                                                            let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                            let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                            let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                            let date = year + month + day;
                                                            if (date?.length == 8) {
                                                                setState({ ...state, StartDate: date })
                                                            }
                                                        }}
                                                    />
                                            }

                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                        <Grid.Col span={3}>
                                            <Text size={12}>Voucher</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLComboBox value={state?.cboVal}
                                                data={state?.CBOVLN}
                                                dispexpr='DisplayMember'
                                                valexpr='ValueMember'
                                                setEdit={(e) => {
                                                    setState({ ...state, cboVal: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={6}>
                                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                        <Grid.Col span={3}>
                                            <Text size={12}>End Date</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            {
                                                state?.StartDate && state?.EndDate ?
                                                    <PLDateBox defaultval={state?.EndDate} dispformat="DD/MM/YYYY"
                                                        minDate={minimunDate}
                                                        maxDate={maximumDate}
                                                        setEdit={(e) => {
                                                            let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                            let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                            let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                            let date = year + month + day;
                                                            if (date?.length == 8) {
                                                                setState({ ...state, EndDate: date })
                                                            }
                                                            // setED(date)
                                                        }}
                                                    /> :
                                                    <PLDateBox dispformat="DD/MM/YYYY"
                                                        minDate={minimunDate}
                                                        maxDate={maximumDate}
                                                        isVal={false}
                                                        placeholder="Enter End Date"
                                                        setEdit={(e) => {
                                                            let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                            let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                            let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                            let date = year + month + day;
                                                            if (date?.length == 8) {
                                                                setState({ ...state, EndDate: date })
                                                            }
                                                        }}
                                                    />
                                            }

                                        </Grid.Col>
                                    </Grid.Col>
                                    {
                                        state?.cboVal === "S" &&
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <Grid.Col span={3}>
                                                <Text size={12}>Select Voucher</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLDataGrid isMulti={true}
                                                    value={state?.cType}
                                                    width={"500"}
                                                    dispexpr="S11F02"
                                                    valexpr="S11F01"
                                                    data={state?.DTS11}
                                                    TmData={state?.P_S11}
                                                    setEdit={(e) => {
                                                        let selectedRowPrint = e?.map((v) => v?.original?.S11F01).join();
                                                        setcType(selectedRowPrint);
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }
                                </Grid.Col>
                            </Grid.Col>

                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Group >
                                    <Button onClick={() => postBtn("A")}>OK</Button>
                                    <Button onClick={() => postBtn("C")}>Clear Date</Button>
                                </Group>
                            </Grid.Col>

                        </Grid>
            }
        </>
    )
}

export default DataFreeze   