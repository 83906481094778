import React, { forwardRef, useEffect, useState } from "react";
import { DateInput } from "@mantine/dates";
import Store from "../utils/store";
import { useSelector } from "react-redux";
import { Text, TextInput } from "@mantine/core";

const PLDateBox = forwardRef((props, ref) => { //Nairin Salot
    // console.log("date=>>", props);

    const [date, setDate] = useState();

    const [formattedValue, setFormattedValue] = useState('');

    const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend);
    const GetGeneral = useSelector((state) => state?.Language?.General)

    useEffect(() => {
        if(props?.defaultval){
            setFormattedValue(formatToDateInput(props?.defaultval));
        }else{
            setFormattedValue(formatToDateInput(props?.value)); 
        }
      }, [props?.defaultval, props?.value]);

      const formatToDateInput = (val) => {
        if (!val) return '';
    
        // Handle value1: "20251110"
        if (/^\d{8}$/.test(val)) {
          const year = val.slice(0, 4);
          const month = val.slice(4, 6);
          const day = val.slice(6, 8);
          return `${year}-${month}-${day}`;
        }
    
        // Handle value2: "Thu Jan 09 2025 05:30:00 GMT+0530 (India Standard Time)"
        const date = new Date(val);
        if (!isNaN(date.getTime())) {
            let year = date.toLocaleString("en-IN", { year: "numeric" });
            let month = date.toLocaleString("en-IN", {
              month: "2-digit",
            });
            let day = date.toLocaleString("en-IN", { day: "2-digit" });
            // let weekday = datee.toLocaleString("en-IN", { weekday: "long" });
            // let formatedStartDate = day + "/" + month + "/" + year;
            return `${year}-${month}-${day}`;
        }
    
        return '';
      };

    const handleInputType = (e) => {
        if (e == "time") {
            return props?.value
        } else if (e == "datetime-local") {
            return props?.value
        } else {
            // if (!props?.value || isNaN(props?.value?.getTime())) {
            //     return ""
            // }
            // if ((!isNaN(props?.defaultval?.getTime())) || (!isNaN(props?.value?.getTime()))) {
            //     return props?.defaultval ? (new Date(props?.defaultval?.slice(0, 4), props?.defaultval?.slice(4, 6), props?.defaultval?.slice(6, 8)).toISOString().split('T')[0]) : props?.value ? (props?.value?.toISOString().split('T')[0]) : new Date()
            // }

            return props?.defaultval ? (`${props?.defaultval?.slice(0, 4)}-${props?.defaultval?.slice(4, 6)}-${props?.defaultval?.slice(6, 8)}`) : props?.value ? (props?.value?.toISOString().split('T')[0]) : new Date()
        }
    }

    // console.log("props?.minDate ?? GetGeneral?.dYSDate", GetGeneral?.dYSDate);
    return (<>
        <TextInput
            popoverProps={{
                position: 'bottom', // Position the popover below the input
                withinPortal: true, // Optional: Prevent rendering in a portal
            }}
            size={'xs'}
            // withSeconds
            step="1" // for second
            ref={ref}
            type={props?.type ?? "date"}
            autoFocus={props?.autoFocus}
            style={{ width: props.width ? props.width : '', color: props?.color ? props?.color : '' }}
            id={props?.id??props?.name}
            name={props?.name}
            // {...props}
            // value={props?.defaultval ? (`${props?.defaultval?.slice(0, 4)}-${props?.defaultval?.slice(4, 6)}-${props?.defaultval?.slice(6, 8)}`) : props?.value ? props?.value : new Date()}
            // value={handleInputType(props?.type)}
            value={["time","datetime-local"].includes(props?.type) ? props?.value :formattedValue}
            locale="en-IN"
            // valueFormat={props?.dispformat}
            // defaultValue={props?.defaultValue}
            disabled={props?.disabled}
            onChange={(e) => {
                console.log("onChangeDate =>", [{
                    E: e?.target?.value,
                    defaultVal: props?.defaultval,
                    value: props?.value,
                    targetval: new Date(e?.target?.value)
                }]);
                if (props?.setEdit && typeof props?.setEdit == "function") {
                    if (props?.type == "time") {
                        props?.setEdit(e?.target?.value)
                    } else if (props?.type == "datetime-local") {
                        props?.setEdit(e?.target?.value)
                    } else {
                        const dateString = e.target.value;
                        setFormattedValue(dateString);
                        props?.setEdit(new Date(dateString));
                    }
                }
            }}
            onFocus={(e) => {
                if (props?.setOnFocus && typeof props?.setOnFocus == "function") {
                    props?.setOnFocus(e)
                }
            }}
            onBlur={(e) => {
                if (props?.onBlur && typeof props?.onBlur == "function") {
                    props?.onBlur(e)
                    const inputDate = new Date(e.target.value);
                    const minDate = new Date(formatToDateInput(props?.minDate ?? GetGeneral?.dYSDate));
                    const maxDate = new Date(formatToDateInput(props?.maxDate ?? GetGeneral?.dYEDate));
            
                    if (inputDate < minDate) {
                        e.target.value = formatToDateInput(props?.minDate ?? GetGeneral?.dYSDate); // Set to min date
                    } else if (inputDate > maxDate) {
                        e.target.value = formatToDateInput(props?.maxDate ?? GetGeneral?.dYEDate); // Set to max date
                    }
            
                    if (props?.setEdit && typeof props?.setEdit == "function") {
                        setFormattedValue(e.target.value);
                        props?.setEdit(new Date(e.target.value));
                }}
            }}
            onKeyDown={(e) => {
                if (props?.onKeyDown && typeof props?.onKeyDown == "function") {
                    props?.onKeyDown(e)
                }
            }}
            min={formatToDateInput(props?.minDate ?? GetGeneral?.dYSDate)}
            max={formatToDateInput(props?.maxDate ?? GetGeneral?.dYEDate)}
        />
        <Text style={{fontSize:"13px",color:"red"}} >{props?.placeholder}</Text>
        {/* <DateInput
            popoverProps={{
                position: 'bottom', // Position the popover below the input
                withinPortal: true, // Optional: Prevent rendering in a portal
            }}
            ref={ref} //Nairin Salot
            autoFocus={props?.autoFocus}
            style={{ zIndex: props?.zIndex ? props?.zIndex : 999 }}
            size="xs"
            onKeyDown={(e) => props?.onKeyDown && typeof props?.onKeyDown == "function" ? props?.onKeyDown(e) : null}
            label={props.label}
            height={5}
            id={props.name}
            valueFormat={props.dispformat}
            value={date} // value to defaultValue Change
            // value={props.value ? new Date(props.value).getTime() : new Date()}
            locale="en-IN"
            type={props.disptype}
            onBlur={props?.onBlur}
            display={props.disptype}
            // onBlur={()=>props.setDate("")}
            onChange={(e) => {
                if (props?.setEdit && typeof props?.setEdit == "function") {
                    props?.setEdit(e);
                }
            }}
            placeholder={props?.placeholder ?? "Date input"}
            //   minDate={new Date(2024, 0, 1)} // Minimum date: January 1, 2024
            // maxDate={new Date(2024, 11, 31)} // Maximum date: December 31, 2024
            minDate={props?.minDate ?? GetGeneral?.dYSDate} //Yasvi Patel ( kishanbhai told to commit this as of now )
            maxDate={props?.maxDate ?? GetGeneral?.dYEDate}
            disabled={props.disabled}
        /> */}
    </>);
}
)
export default PLDateBox;