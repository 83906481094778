import { modals } from '@mantine/modals';
import React from 'react'
import { ProductListForm } from './ProductListForm';
import Store from '../../utils/store';
import { ProductListDeleteAPi } from '../../utils/slices/ProductList';
import { Text } from '@mantine/core';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import { notifications } from '@mantine/notifications';
import GlobalClass from '../../utils/GlobalClass';
import { TabAdd } from '../../utils/slices/TabList';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import DataGrid from '../../components/DataGrid';

const M21 = (data) => {

}

export async function M21AED(data) {
    const OBJ = JSON.parse(data);
    console.log('Product Master M21AED =>', OBJ);

    if (OBJ.p0 === 'A') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => { },
                MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
                MAction: true,
                MSize: "xl",
                position: 'right',
                MBody: (i) => <ProductListForm OBJ={OBJ} index={i} />,
                MClose: true,
                tab: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        )
        //====================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    } else if (OBJ.id && OBJ.p0 === 'E') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => { },
                MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
                MAction: true,
                MSize: "xl",
                position: 'right',
                MBody: (i) => <ProductListForm OBJ={OBJ} index={i} />,
                MClose: true,
                tab: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        )
        //================================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    } else if (OBJ.id && OBJ.p0 === 'D') {
        const confirm = window.confirm("Are you sure you want to delete this row?");
        if (confirm) {
            Store.dispatch(ProductListDeleteAPi(OBJ))
        }
    } else if (OBJ.id && OBJ.p0 === 'R') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => { },
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "sm",
                position: 'right',
                MBody: (i) => <ProductListForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        )
    } else if (OBJ.p0 === 'L' && OBJ?.id) { //Ledger
        let obj;
        obj = {
            name: OBJ?.DataGridRow?.FIELD02,
            type: "A",
            id: OBJ?.id,
            p0: "PRODLEDGER",
            p1: "PRODLEDGER",
            p2: OBJ?.p1,
            OParam: "P" + "CODE~K~" + OBJ?.DataGridRow?.FIELD01
        }
        Store.dispatch(TabAdd(obj));
    } else if (OBJ.p0 === 'S' && OBJ?.id) { // Stock
        let oYear = Store?.getState()?.Language?.General?.oYear;
        if ((oYear?.OSC?._BATCHSTOC == "Y" && OBJ?.DataGridRow?.FIELD51 == "Y") || (oYear?.OSC?._LOCATIONW == "Y" && OBJ?.DataGridRow?.FIELD57 == "Y")) {
            Store.dispatch(
                ModalFunction({
                    onclose: (e) => { },
                    MTitle: <Text fw={700}>{OBJ?.text} For {OBJ?.DataGridRow?.FIELD02}</Text>,
                    MAction: true,
                    MSize: "xl",
                    position: 'right',
                    MBody: (i) => <>
                        <DataGrid
                            //   ValueEdit={(e) => {
                            //     console.log("ValueEdit", e);
                            // if(e?.FIELD01){
                            //     Store.dispatch(ModalDelete(i))
                            // }
                            //   }}
                            //   selectedData={{ [selectedRow?.id]: selectedRow?.id }}
                            selectionId={"FIELD01"}
                            setPopOver={() => { }}
                            cWhere={`T11.FIELD08 LIKE '%${OBJ?.id}%'`}
                            obj={{
                                name: "",
                                type: "A",
                                id: "P_T11",
                                p0: "P_T11",
                                p1: "P_T11",
                                p2: "TFORMSTK0001"
                            }}
                            //   data={UserName}
                            form={false}
                            viewOnly={true}
                            isMultiGroup={true}
                            groupSelection={true}
                            isButton={true}
                        />
                    </>,
                    MClose: true,
                    Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                    },
                })
            )
        } else {
            GlobalClass.Notify("info", "Info", "Current Stock :" + OBJ?.DataGridRow?.FIELD98)
        }
    }

}

export default M21;
