import { Alert, Grid, Skeleton } from '@mantine/core'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import ButtonPanel from '../../PlusComponent/ButtonPanel'
import { useDispatch, useSelector } from 'react-redux'
import { createColumnHelper } from '@tanstack/react-table'
import { GetEntList, GetM41UFList } from '../../utils/slices/CompanySetupSlice'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'
import Store from '../../utils/store'
import { IconAlertTriangle } from '@tabler/icons-react'
import GlobalClass from '../../utils/GlobalClass'
import { GetTMFormat } from '../../utils/slices/AccountListSlice'
import useWindowDimensions from '../../utils/UseWindowDimensions'

const UserFieldModal = (props) => {

  console.log("UserFieldModal props===>", props);


  const [rowSelection, setRowSelection] = useState({});
  const [PM41UFData, setPM41UFData] = useState([])
  const [S11Data, setS11Data] = useState([])
  const { height, width } = useWindowDimensions();

  // const M41UFListData = useSelector(state => state.CompanySetup?.M41UFListData);
  const { M41UFListData, GetEntListData, successMsg, hasError, ErrorMsg, EntisLoading } = useSelector(state => state.CompanySetup);

  const S11 = useSelector((state) => state.CompanySetup?.GetEntListData?.S11)
  const P_M41UF = useSelector((state) => state.CompanySetup?.M41UFListData?.P_M41UF);
  const P_M41UFData = useSelector((state) => state.CompanySetup?.M41UFListData?.P_M41UFData?.jData);
  const isLoading = useSelector((state) => state.CompanySetup?.isLoading);

  const { TMFormatData: tmList, isLoading: tmListIsLoading } = useSelector((state) => state.AccountList);

  const [cfmtid, setcfmtid] = useState("")
  const [columns, setColumns] = useState([]);
  const [grouping, setGrouping] = useState([]);

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  useEffect(() => {
    console.log("useEffectRun");
    Promise.resolve().then(() => { Promise.resolve(dispatch(GetM41UFList())).then(() => dispatch(GetEntList({ action: 'A' }))) });
  }, [])

  useEffect(() => {
    if (hasError) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message)
    }
  }, [hasError])

  function renderDynamic(col, val) {
    var ret = '';
    switch (col) {
      case "Placement":
        ret = (val == "H" ? "Header" : (val == "M" ? "Middle" : (val == "F" ? "Footer" : val)))
        break;
      case "Type":
        ret = (val == 'C' ? 'Character' : (val == 'N' ? 'Numeric' : (val == 'M' ? 'Memo' : (val == 'D' ? 'Date' : (val == 'I' ? 'Image' : (val == 'T' ? 'Time' : (val == 'E' ? 'Date Time' : (val == 'A' ? 'Attechment' : (val == 'H' ? 'Hyperlink' : val)))))))))
        break;
      case "Help":
        ret = val == "N" ? "No" :
          val == "Y" ? "Yes" : val
        break;
      default:
        ret = val
        break;
    }
    return ret;
  }

  const renderDynamicVal = (col, val) => {
    var ret = '';
    switch (col) {

      case "Type":
        ret = (val == 'C' ? 'Character' : (val == 'N' ? 'Numeric' : (val == 'M' ? 'Memo' : (val == 'D' ? 'Date' : (val == 'I' ? 'Image' : (val == 'T' ? 'Time' : (val == 'E' ? 'Date Time' : (val == 'A' ? 'Attechment' : (val == 'H' ? 'Hyperlink' : val)))))))))
        break;
      case "Help":
        ret = val == "N" ? "No" :
          val == "Y" ? "Yes" : val
        break;
      case "Status":
        ret = val == "E" ? "Enable" :
          val == "H" ? "Hidden" : val
        break;
      default:
        ret = val
        break;
    }
    return ret;
  }

  useEffect(() => {
    if (P_M41UFData) {
      setPM41UFData(P_M41UFData)
      // let arr=[]
      // P_M41UFData?.filter((u)=>u?.FIELD04 == "E").map((e)=>arr.push({[e?.FIELD03]:e?.FIELD18}))
      // console.log("UserFieldDateObject =>",arr)
    }
    if (S11) {
      setS11Data(S11)
    }
  }, [P_M41UFData, S11])

  useEffect(() => {
    if (P_M41UF) {
      console.log("tmList", tmList);
      dispatch(GetTMFormat({
        "id": P_M41UF.toString().split("~C~")[0],
        "name": "",
        "type": "A",
        "p0": P_M41UF.toString().split("~C~")[0],
        "p1": P_M41UF.toString().split("~C~")[0],
        "p2": P_M41UF.toString().split("~C~")[1],
        "p3": ""
      }))
    }
  }, [P_M41UF])




  useEffect(() => {
    // console.log('M41UFListData modal box=>', M41UFListData);
    if (isLoading == false && EntisLoading == false) {
      var cols = [];
      var grp = [];

      tmList[P_M41UF?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v.ColCap,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            GroupedCell: ({ cell }) => {
              let val = cell.getValue();
              return renderDynamic(v.ColCap, val);
            },
            Cell: ({ cell }) => {
              var val = cell.getValue();
              return renderDynamicVal(v.ColCap, val)

            }
            // Cell: ({ cell }) => {
            //   var val = cell.getValue();
            //   if (v.ColCap == "Type") {
            //     return <div>{(val == 'C' ? 'Character' : val == 'N' ? 'Numeric' : val == 'M' ? 'Memo' : val == 'D' ? 'Date' : val == 'I' ? 'Image' : val == 'T' ? 'Time' : val == 'E' ? 'Date Time' : val == 'A' ? 'Attechment' : val == 'H' ? 'Hyperlink' : val)}</div>
            //   }
            //   return val

            // }
          })
        )
      })
      if (tmList[P_M41UF?.toString().split("~C~")[0]] && tmList[P_M41UF?.toString().split("~C~")[0]]?.oDCFrmt && tmList[P_M41UF?.toString().split("~C~")[0]]?.oDCFrmt?.aDGrp && tmList[P_M41UF?.toString().split("~C~")[0]]?.oDCFrmt?.aDGrp?.length > 0) {
        P_M41UF.oDCFrmt?.aDGrp.map((g, i) => {
          cols.push(
            columnHelper.accessor(g.DispExpr, {
              header: g.ColCap,
              // enableHiding: true,
              enableColumnFilter: false,
              enableSorting: false,
              enableGlobalFilter: false,
            })
          )
          grp.push(g.DispExpr);
        })
        setGrouping(grp);
      } else {
        cols.push(
          columnHelper.accessor("FIELD32", {
            header: "Voucher Type",
            // enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            size: 150,
            minSize: 0,
            maxSize: 150,
            GroupedCell: ({ cell }) => <div>
              {/* {
                console.log("cell.getValue() =>", cell.getValue())
              } */}
              {S11Data[cell.getValue()]}
            </div>,
          })
        )
        cols.push(
          columnHelper.accessor("FIELD07", {
            header: "Placement",
            // enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            // size:50,
            // minSize:0,
            // maxSize:50,
            // GroupedCell: ({ cell }) => <div>{"123"}</div>,
          })
        )
        setGrouping(["FIELD32", "FIELD07"]);
      }
      setColumns(cols);
      // console.table("cols", cols);
      // console.table("rows", P_M41UFData);
    }
  }, [GetEntListData?.S11, M41UFListData, S11Data, tmList])

  console.log("Columns And Grouping =>", [{
    "columns": columns,
    "grouping": grouping
  }]);

  const simulateEnterKeyPress = () => {
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    if (rowElement) {
      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  };

  const table = useMantineReactTable({
    data: PM41UFData,
    columns,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableGrouping: true,
    // mantineToolbarAlertBannerBadgeProps:"none",
    positionToolbarAlertBanner: "none",
    enableFilters: true,
    enableGlobalFilter: true,
    enableColumnFilters: false,
    // enableRowOrdering:false,
    // enableColumnActions:false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: true,
    enableRowDragging: false,
    enableColumnDragging: false,
    mantineTableProps: { withColumnBorders: true },
    initialState: {
      //   grouping,
      density: '0px',
      expanded: true
    },
    state: {
      grouping,
      rowSelection
    },
    onGroupingChange: setGrouping,
    enableRowVirtualization: false,
    mantineTableContainerProps: {
      sx: {
        height: height * 0.6
      }
    },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    enableColumnActions: false,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      onDoubleClick: () => {
        Promise.resolve(
          setRowSelection((prev) => ({
            [row.id]: true,
          }))
        ).then(() => {
          simulateEnterKeyPress();
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: 'pointer' },
      height: 30
      // cellPadding: '0px'
    }),
  })

  console.log("GetEntListData===>", GetEntListData);
  console.log("M41UFListData==>", M41UFListData);
  console.log("S11============>", S11);
  console.log("tmList", tmList);

  return (<>
    {
      SaveLoading == true ? GlobalClass.RenderLoader('dots') :
        <>
          {
            (isLoading == true || EntisLoading == true || S11Data.length > 0 || tmListIsLoading == true) ? GlobalClass.RenderLoader('dots') : <Grid >
              <Grid.Col span={4}>
                <PLComboBox
                  // cmbid={v.F02F01}
                  value={tmList[P_M41UF?.toString().split("~C~")[0]]?.DTS62[0]["S62F02"]}
                  dispexpr="S62F04"
                  valexpr="S62F02"
                  data={tmList[P_M41UF?.toString().split("~C~")[0]]?.DTS62}
                  // copno={v.F02F08}
                  setEdit={(e) => { setcfmtid(e) }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ButtonPanel
                  //   table={TableRef}
                  data={tmList[P_M41UF?.toString().split("~C~")[0]]?.oS60?.DTHKey}
                  Skey={table?.getSelectedRowModel()?.flatRows[0]}
                  from={props?.obj?.id}
                />
              </Grid.Col>
            </Grid>
          }

          {/* USER Field ModalBox  */}
        </>
    }
  </>
  )
}

export default UserFieldModal