import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";




export const GetExpenseSetup = createAsyncThunk(
    "ExpenseSetup/GetExpenseSetup",
    async (obj) => {
      console.log(obj, " =>Obj");
      try {
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: obj?.p0 ? obj.p0 : "A",
          cCode:  "",
          cOPara: "",
          "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        });
        const response = await axios.get(
          GlobalClass.ApiUrl + GlobalClass.GetAutoTaxEnt + "?pa=" + param
        );
        return response.data?.data;
      } catch (error) {
        console.error(error);
        return { error };
      }
    }
  );

  const ExpenseSetupSlice = createSlice({
    name: "ExpenseSetup",
    initialState: {
      ExpenseSetupData: [],
      isLoading: false,
      hasError: false,
      ErrorMsg: {},
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetExpenseSetup.pending, (state, action) => {
          state.isLoading = true;
          state.hasError = false;
          state.ExpenseSetupData = [];
        })
        .addCase(GetExpenseSetup.fulfilled, (state, action) => {
          state.ExpenseSetupData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        })
        .addCase(GetExpenseSetup.rejected, (state, action) => {
          state.hasError = true;
          state.ErrorMsg = action.payload?.error
          state.isLoading = false;
        })
    },
  });

  export default ExpenseSetupSlice.reducer;