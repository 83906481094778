import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";

export const GetAddressBook = createAsyncThunk(
    "AddressBook/GetAddressBook",
    async (obj) => {
        console.log("obj in GetAddressBook", obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.cAction ?? "A",
                    "cCode": obj?.cCode ?? "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAddressBook + "?pa=" + param);
            console.log("GetAddressBook response=>>", response);
            return [response.data?.data,obj?.cCode];

        } catch (error) {
            return { error }
        }
    }

)

export const GetAddressBookDeleteAPI = createAsyncThunk(
    "AddressBook/GetAddressBookDeleteAPI",
    async (obj) => {
        console.log("obj in GetAddressBookDeleteAPI", obj);
        try {

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.cAction ?? "A",
                    "cCode": obj?.cCode ?? "",
                    "cOPara": "",
                    "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });

            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAddressBook + "?pa=" + param);
            console.log("GetAccountList response=>>", response);
            return response.data;

        } catch (error) {
            return { error }
        }
    }

)

const AddressBook = createSlice({
    name: "AddressBook",
    initialState: {
        AddressBookData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        isDeleted: false,
        data: [],
        format : ""
    },
    reducers: {
        setIsDeleted: (state, action) => {
            console.log("reducer", action.payload);
            state.isDeleted = action.payload
        },
        setoM02obj: (state, action) => {
            state.data = action.payload
        },
        setFormat : (state,action) =>{
            state.format = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAddressBook.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true;
            })
            .addCase(GetAddressBook.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.AddressBookData = action.payload[0];
                    if(!action.payload[1])
                    {
                       state.data = action.payload[0]?.oM02;
                    }
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetAddressBook.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(GetAddressBookDeleteAPI.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true;
            })
            .addCase(GetAddressBookDeleteAPI.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.isDeleted = true;
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetAddressBookDeleteAPI.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
})

export default AddressBook.reducer;
export const { setIsDeleted, setoM02obj,setFormat } = AddressBook.actions;

// export const { setIsDeleted } = AddressBook.actions;