import React, { useEffect, useRef, useState } from 'react';
import Header from './layout/header';
// import Footer from './layout/footer';
import Sidebar from './layout/sidebar';
import { AppShell, Grid, Modal, Navbar,Footer, List, Timeline, Affix, rem, Transition, ActionIcon, Tooltip } from '@mantine/core';
import TabView from './layout/TabView';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateSelected } from './utils/slices/TabList';
import { useDispatch, useSelector } from 'react-redux';
import { GetLanguageData } from './utils/slices/LanguageSlice';
import Store from './utils/store';
import GlobalClass from './utils/GlobalClass';
import { ModalBox } from './PlusComponent/ModalBox';
import Calculator from "awesome-react-calculator";
import { Dialog, Group, Button, TextInput, Text } from '@mantine/core';
import { useDisclosure, useHotkeys, useLocalStorage, useWindowScroll } from '@mantine/hooks';
import { IconCopy, IconFileInvoice, IconFilePlus, IconHistory, IconInfoCircle, IconNumber } from '@tabler/icons-react';
import { FooterBar } from './layout/footer';

function App() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const ModalAttr = useSelector((state:any)=>state.ModalSlice.ModalAttr)
  // console.log('ModalAttr=>',ModalAttr);
  
  
  
  const LanguageData = useSelector((state:any) => state.Language)
  // console.log("LanguageData==>", LanguageData);

  
  
const [opened, setOpened] = useState(false);
const [cal, calAction] = useDisclosure(false);
useHotkeys([
  ['F9', () => calAction.toggle()],
]);

useEffect(() => {
  // dispatch(GetLanguageData())
  if(cal){

  }
  // Store.dispatch(GetLanguageData())
}, [cal])
const [calHistory,setCalHistory] = useState([]);
function handleInput(input) {
  console.log(`${input.expression} is shown in the calculator, User clicked the ${input.key}`)
}
const bottomref = useRef(null);
function onResultChange(newResult) {
  console.log(newResult.result)
  console.log(`${newResult.expression} is validated as ${newResult.result} `)
  if(newResult.result > 0){
  setCalHistory([...calHistory,{expression:newResult.expression,result:newResult.result}]);
  }
  scrollToBottom()
}
const scrollToBottom = () => {
  const lastChildElement = bottomref.current?.lastElementChild;
  lastChildElement?.scrollIntoView({ behavior: 'smooth' });
};
const [isLoaded, setIsLoaded] = useLocalStorage({
    key: 'Loaded',
    defaultValue: false,
    getInitialValueInEffect: true,
  })
const [scroll, scrollTo] = useWindowScroll();
  return (
    <>

      <AppShell
        navbar={ <Sidebar opened={opened} drawerClose={()=>setOpened((o)=>!o)}/>}
        header={<Header opened={opened} setOpened={(e:any)=>setOpened(e)} links={[]} />}
        footer={
          isLoaded && <Affix position={{ bottom: rem(0), right: rem(0) }}>
            <FooterBar/>
           </Affix>
          
        }
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
          body:{backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]},
          root:{backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]}
        })}
      >
        <Dialog 
        opened={cal} 
        withCloseButton 
        onClose={calAction.close} 
        style={
          {
            height: '30rem',
            width: calHistory.length>0?'40rem':'20rem'
          }
        } 
        radius="md">
        <Text size="sm" mb="xs" weight={500}>
          Calculator
        </Text>
        <Group style={{flexDirection:'row'}}>
        {calHistory.length>0 && <Group align='flex-end'  style={{flex:1,flexDirection:'row'}}>
            <Timeline ref={bottomref} style={{height: '23rem',overflow:'auto',flex:1}} lineWidth={2}>
              {calHistory.map((e)=>(<Timeline.Item bullet={<IconHistory size={12} />} title={e.expression}>
                  <Group style={{flexDirection:'row'}}>
                    <Text color="dark">{e.result}</Text>
                </Group>
              </Timeline.Item>))}
              
            </Timeline>
            <Group style={{flexDirection:'row',flex:1,position:'absolute',bottom:"6px"}}>
              <Button 
              variant="outline" 
              color="gray" 
              radius="md" 
              size="xs" 
              compact 
              uppercase
              onClick={()=>setCalHistory([])}>
                Clear History
              </Button>
              <Tooltip
                label="History will be saved for current Session only, will be cleared once the tab is closed/reload"
                color="gray"
                position="right"
                withArrow
                arrowPosition="center"
              >
                <ActionIcon size="sm" variant="transparent">
                <IconInfoCircle/>
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>}
          <Group align="flex-end" style={{
            height: '25rem',
            flex:1,
          }}>
          <Calculator
          onNewInput={handleInput}
          onResultChange={onResultChange}
          />
          </Group>
         
        </Group>
      </Dialog>
        
        {isLoaded && <TabView navigate={navigate} />}
        <ModalBox/>
        {/* <Affix position={{ bottom: rem(20), right: rem(20) }}>
        <Transition transition="slide-up" mounted={true}>
          {(transitionStyles) => (
            <Button.Group
            style={transitionStyles}
            
            >
            <Button
              leftIcon={<IconFilePlus size="1rem" />}
              style={transitionStyles}
              variant='filled'
              color='blue'
              // onClick={() => s crollTo({ y: 0 })}
            >
              Sales Invoice
            </Button>
            <Button
              leftIcon={<IconFilePlus size="1rem" />}
              style={transitionStyles}
              variant='filled'
              color='dark'
              // onClick={() => s crollTo({ y: 0 })}
            >
              Purchase Invoice
            </Button>
            </Button.Group>
          )}
        </Transition>
      </Affix> */}
      </AppShell>

      {/* <Footer/> */}
    </>

  );
}

export default App;
