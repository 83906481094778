import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import Store from "../store";
import { ClearTabs } from "../slices/TabList";

export const VerifyUser = createAsyncThunk(
  "UserAuth/VerifyUser",
  async (obj) => {
    // console.log(obj, " =>Obj");
    // const user = sessionStorage.getItem("authUser");
    const user = localStorage.getItem("authUser")
    if (user) {
      return JSON.parse(user);
    }
    // GlobalClass.Notify(
    //     "info",
    //     "Verifying",
    //     "Please wait while we Verify Credentials...",
    //     true,
    //   );
    try {
      let param = "p1=" + obj.p1 + "&p2=" + obj.p2 + "&p3=" + obj.p3 + "&YrNo=" + localStorage.getItem("YrNo")?.replace(/[\\"]/g, '');
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetCorpDet + "?" + param);
      // console.log("GetUserList response=>>", response.data.data);
      if (response.data.status === "SUCCESS") {
        GlobalClass.Notify("success", "Login Success", "", false, 1000);
        // sessionStorage.setItem("authUser",JSON.stringify(response.data.data));
        localStorage.setItem("authUser", JSON.stringify(response.data.data))

        console.log("obj==>", obj);

        localStorage.setItem("corpid", obj.p1); //rathod kishan
        localStorage.setItem("cuser", obj.p2);
        localStorage.setItem("cpass", obj.p3);

        // gensetting.setcorpid(obj.p1);
        // gensetting.setcuser(obj.p2);
        // gensetting.getcpass(obj.p3);
        return response.data.data;
      }
      else {
        return { error: response.data.message }
      }
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const checkUser = createAsyncThunk(
  "UserAuth/checkUser",
  async (obj) => {
    // console.log(obj, " =>Obj");
    // const user = sessionStorage.getItem("authUser");
    const user = localStorage.getItem("authUser")
    if (user) {
      return JSON.parse(user);
    }
    else {
      return null
    }

  }
);
export const LogoutUser = createAsyncThunk(
  "UserAuth/LogoutUser",
  async (obj) => {

    try {
      // sessionStorage.removeItem("authUser");
      Store.dispatch(ClearTabs(""))
      sessionStorage.clear()
      localStorage.clear()
      GlobalClass.Notify("success", "Logout Success", "", true, 1000);
      return true;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);
// Footer WhatsApp Icon
export const checkLocalServer = createAsyncThunk(
  "UserAuth/checkLocalServer",
  async (obj) => {
    try {
      let resp = await fetch('http://localhost:3000/checkmob?custid=' + gensetting.getcorpid());;
      console.log("checkLocalServer_response", resp)
      if (resp.ok) {
        const data = await resp.json();
        console.log("data=>", data)
        return data
      } else {
        return GlobalClass.Notify("error", "Local server not available")
      }
    } catch (error) {
      // console.log("error",error)
      return GlobalClass.Notify("error", error.message);
    }
  }
);
export const HandleLogin = createAsyncThunk(
  'whatsApp/handleLogin',
  async () => {
    try {
      const response = await axios.get(`http://localhost:3000/check?custid=${gensetting.getcorpid()}`);
     return response.data
    } catch (error) {
      // GlobalClass.Notify('error', 'Failed to get QR code.', 'Please try again.');
      return GlobalClass.Notify("error", error.message)
    }
  }
);
export const handleLogout = createAsyncThunk(
  'whatsApp/handleLogout',
  async () => {
    try {
      await axios.get(`http://localhost:3000/logout?custid=${gensetting.getcorpid()}`);
      GlobalClass.Notify("warning", "Logged out successfully!");
      return true
    } catch (error) {
      GlobalClass.Notify('error', error.message);
    }
  }
);
const WhatsappSlice = createSlice({
  name: "UserAuth",
  initialState: {
    Data: null,
    termsAccepted: false,
    isLocalServerAvailable: null,
    checkingLocalServer:false,
    connecting:false,
    connectionStatus: 'Disconnected',
    loggedIn: false,
    phoneNumber: null,
    qrCodeData: null,
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    successMsg: ""
  },
  reducers: {
    TermsAcepted: (state, action) => {
      state.termsAccepted = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.Data = null;
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        state.Data = action.payload;
        // console.log("action.payload", action.payload);
        // console.log("state.AreaListData", state.AreaListData);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(checkUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(VerifyUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.Data = null;
      })
      .addCase(VerifyUser.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.Data = action.payload;
          state.isLoading = false;
          state.hasError = false;
          state.ErrorMsg = {};
        }
        else {
          state.Data = null;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error
        }
        // state.Data = action.payload;
        // // console.log("action.payload", action.payload);
        // // console.log("state.AreaListData", state.AreaListData);
        // state.isLoading = false;
        // state.hasError = false;
      })
      .addCase(VerifyUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(LogoutUser.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(LogoutUser.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.Data = null;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(LogoutUser.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(checkLocalServer.pending, (state) => {
        state.checkingLocalServer = true;
      })
      .addCase(checkLocalServer.fulfilled, (state, action) => {
        console.log("action.payload", action.payload)
        if (action.payload?.status === 'success') {
          state.isLocalServerAvailable = true;
        } else {
          state.isLocalServerAvailable = false;
        }
      })
      .addCase(checkLocalServer.rejected, (state) => {
        state.isLocalServerAvailable = false;
        // state.error = action.payload;
      })
      .addCase(HandleLogin.pending, (state) => {
        state.connecting = true
      })
      .addCase(HandleLogin.fulfilled, (state, action) => {
        if (action.payload.status === 'qr') {
          state.qrCodeData = action.payload.data;
          state.connectionStatus = 'Awaiting QR Code Scan';
        } else if (action.payload?.status === 'success') {
          state.loggedIn = true;
          state.connectionStatus = 'Connected';
          state.phoneNumber = action.payload.data;
        } else {
          state.connectionStatus = 'Disconnected';
        }
        state.connecting = false;
      })
      .addCase(HandleLogin.rejected, (state, action) => {
        state.connecting = false;
        state.connectionStatus = 'Disconnected';
      })
      .addCase(handleLogout.fulfilled, (state, action) => {
        state.connectionStatus = 'Disconnected';
        state.phoneNumber = null;
        state.loggedIn = false;
      })
  },
});
export const { TermsAcepted } = WhatsappSlice.actions
export default WhatsappSlice.reducer;
