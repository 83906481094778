// GETDEF undefined http:eplus.kishanlive.in/api/template/GetTMFormat?pa={"CorpID":"94.176.235.105","cU r":"SUPERVISOR","cPass":"a@123","CmpNo":"6002","cWhere":"","cRepId":"T_M01","cFmtID":"","StrtDt":"2021-04-01","EndDt":"2022-03-31"}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import { fetchDataFromDB, getDatafromDB, setupDB } from "../../IndexedDB/IndexedDB";
import { getFromIndexedDB, saveToIndexedDB } from "../../IndexedDB/dexieDB";
import Store from "../store";


export const GetAccountList = createAsyncThunk(
    "AccountList/GetAccountList",
    async (obj, thunkAPI) => {
        console.log("GetAccountList obj==>", obj);
        // const { cWhere} = obj;
        // let cWhere1 = "rajkot"
        // const query = `M01.FIELD17 LIKE '%${cWhere1}%' OR M01.FIELD02 LIKE '%${cWhere1}%'`
        // const query1 =  `M01.FIELD02 LIKE '%${cWhere1}%'`

        let startDate, endDate;
        const state = thunkAPI.getState();
        const StrtEndDateAry = state.AccountList.StrtEndDateAry;
        const currentDate = state?.AccountList?.currentDate
        // console.log("StrtEndDateAry", StrtEndDateAry);


        // const productName = state.TSalesSlices.productName; //KishanBhai  // Used in Test Certificate Yasvi Patel
        // const qry = `T11.FIELD08 LIKE '%${productName}%'`; //KishanBhai   // Used in Test Certificate Yasvi Patel
        // const pageID = state.GSTEntryPageID.pageID; //Nairin Salot
        // const qry2 = `(M01.FIELD07 NOT LIKE '%TX%' AND M01.FIELD07 NOT LIKE '%PR%' AND M01.FIELD07 NOT LIKE '%CS%' AND M01.FIELD07 NOT LIKE '%BK%' AND M01.FIELD07 NOT LIKE '%PV%') OR (M01.FIELD07 LIKE '%TX%' AND (M01.FIELD22 LIKE '%S%' AND M01.FIELD22 LIKE '%T%'))`;
        // //  OR (M01.FIELD07 LIKE '%{"TX"}%') AND (M01.FIELD22 LIKE '%{"S,T"}%')`;
        // const qry3 = `M51.FIELD08 LIKE '%G%'`; // Nairin Salot
        const seprate = (e) => {
            var paraArr = e.split("T")[0];
            var paraArr1 = paraArr.replaceAll("-", "");
            return paraArr1;
        };

        if (obj?.StrtDt && obj?.EndDt) {
            startDate = seprate(obj?.StrtDt)
            endDate = seprate(obj?.EndDt)
        }
        else if (StrtEndDateAry) {
            for (const key in StrtEndDateAry) {
                if (key === obj.id) {
                    let object = StrtEndDateAry[key];
                    startDate = object.StrtDt;
                    endDate = object.EndDt;
                }
            }
        }

        if (!currentDate[obj?.id]) { //Yasvi Patel
            Store?.dispatch(
                getCurrentDate({
                    "date": { From: obj?.StrtDt, To: obj?.EndDt },
                    "id": obj?.id,
                    "flag": false
                }));
        }

        if (!obj?.p1) {
            return ["", obj?.id, "", startDate, endDate, obj?.cWhere];
        }

        try {

            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cWhere: obj?.cWhere ?? "",
                cRepId: obj?.p1,
                cFmtID: obj?.p2 ?? "",
                StrtDt: startDate ?? "",
                EndDt: endDate ?? "",
                "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            };
            if (obj?.iscWhereRequired == false) {
                param = {
                    CorpID: gensetting.getcorpid(),
                    cUser: gensetting.getcuser(),
                    cPass: gensetting.getcpass(),
                    CmpNo: gensetting.getcmpno(),
                    cRepId: obj?.p1,
                    cFmtID: obj?.p2 ?? "",
                    StrtDt: startDate ?? "",
                    EndDt: endDate ?? "",
                    "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                }
            }//Vara Pooja
            if (obj?.pagination && false) {
                // Aakash Kothari
                param = {
                    ...param,
                    nRecords: obj?.pagination ? obj?.pagination?.pageSize : null,
                    nPage: obj?.pagination ? obj?.pagination?.pageIndex + 1 : null,
                };
            }
            if (obj?.OParam) {
                // Aakash Kothari
                param = {
                    ...param,
                    OParam: obj?.OParam,
                };
            }
            if (obj?.CoPara) {
                param = {
                    ...param,
                    cOPara: obj?.CoPara,
                };
            }

            // const localData = await getFromIndexedDB(obj.p1,obj?.cWhere ?? "");
            // if(localData && localData.length > 0 ){
            //     return [localData, obj?.id, "", startDate, endDate, obj?.cWhere];
            // }
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + JSON.stringify(param));
            // console.log("GetAccountList response=>>", response.data?.data);
            // const live = response.data?.data?.jData; // Assume API returns city data array
            // await saveToIndexedDB(obj.p1,live);
            return [response.data?.data?.jData, obj?.id, response.data?.data?.TotRec, startDate, endDate, obj?.cWhere, response.data?.data];
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
);
export const GetTMFormat = createAsyncThunk(
    "AccountList/GetTMFormat",
    async (obj, thunkAPI) => {

        console.log("GetTMFormat obj", obj);
        var StrtDt, EndDt;

        const seprate = (e) => {
            // console.log("seprate e", e);
            var paraArr = e.split('T');
            var paraArr1 = paraArr[0].replaceAll("-", "")
            return paraArr1
        }

        if (obj?.StrtDt && obj?.EndDt) {
            StrtDt = await seprate(obj?.StrtDt)
            EndDt = await seprate(obj?.EndDt)
        }
        if (!obj?.p1) {
            return ["", obj?.id, "", StrtDt, EndDt, obj?.cWhere];
        }
        // console.log("StrtDt", StrtDt);
        // console.log("endDate", EndDt);

        try {
            const result = await getDatafromDB("S60", obj)

            if (result?.S62JSON && (!["P_M21","P_M01"].includes(obj?.p1))) {
                const data = JSON.parse(result?.S62JSON);
                // console.log("data",data)
                console.log("GetTMFormat response indexDB", result);
                return [data, obj.id]
            }
            else {
                let localversionold = localStorage.getItem("P_version_old");
                let localversionnew = localStorage.getItem("P_version_new");
                // if (obj?.p1.includes("P_M21") && localversionold && localversionnew && localversionold == localversionnew) {
                //     let resp = localStorage.getItem("P_M21_" + (obj?.p2 ?? "TFORM0000001"));
                //     if (resp) {
                //         return [JSON.parse(resp), obj.id];
                //     }
                // }
                if (localversionold && localversionnew && localversionold == localversionnew) {
                    if (obj?.p1.includes("P_M21")) {
                        let resp = localStorage.getItem("P_M21_" + (obj?.p2 ?? "TFORM0000001"));
                        if (resp) {
                            return [JSON.parse(resp), obj.id];
                        }
                    } else if (obj?.p1.includes("P_M01")) {
                        let resp = localStorage.getItem("P_M01_" + (obj?.p2 ?? "TFORM0000001"));
                        if (resp) {
                            return [JSON.parse(resp), obj.id];
                        }
                    }
                }
                let param =
                    JSON.stringify({
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cWhere": obj?.p3,
                        "cRepId": obj?.p1,
                        "cFmtID": obj?.p2 ?? "TFORM0000001",
                        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                        // "StrtDt": StrtDt ?? obj?.StrtDt?.replaceAll("-", "") ?? "20210401",
                        // "EndDt": EndDt ?? obj?.EndDt?.replaceAll("-", "") ?? "20220331",
                    });

                const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param);
                console.log("  response=>>", response.data);
                if (obj?.p1.includes("P_M21")) {
                    localStorage.setItem("P_M21_" + (obj?.p2 ?? "TFORM0000001"), JSON.stringify(response.data.data));
                    localStorage.setItem("P_version_old", localversionnew);
                }else if(obj?.p1.includes("P_M01")){
                    localStorage.setItem("P_M01_" + (obj?.p2 ?? "TFORM0000001"), JSON.stringify(response.data.data));
                    localStorage.setItem("P_version_old", localversionnew);
                }
                return [response.data.data, obj.id];
            }
            // }

        } catch (error) {
            console.error(error);
            return { error }

        }
    }
);
export const GetAllForamt = createAsyncThunk(
    "AccountList/GetAllForamt",
    async (obj) => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAllForamt + "?pa=" + param);
            console.log("GetAllForamt response=>>", response);
            response && await setupDB(response.data.data)

            if (response?.data?.data !== null) {
                return response?.data.data;
            }
            else if (response?.data?.status == "FAIL") {
                return { error: response?.data?.message }
            }
            else {
                return { error: "Error" }
            }

        } catch (error) {
            console.error(error);
            return { error: "Network Error!!" }
        }
    }

);
export const GetAllForamtVersion = createAsyncThunk(
    "AccountList/GetAllForamtVersion",
    async (obj) => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAllForamtVersion + "?pa=" + param);
            console.log("GetAllForamtVersion response=>>", response.data);

            if (response?.data?.data !== null) {
                return response?.data.data;
            }
            else if (response?.data?.status == "FAIL") {
                return { error: response?.data?.message }
            }
            else {
                return { error: "Error" }
            }
        } catch (error) {
            console.error(error);
            return { error: "Network Error!!" }
        }
    }
);
const AccountListSlice = createSlice({
    name: 'AccountList',
    initialState: {
        TMFormatData: [],
        AccountListData: [],
        SaveAccountListArr: {}, // rathod kishan
        StrtEndDateAry: [],
        rowCount: 0,
        isRefetching: false,
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        groupEdited: false,
        pageID: '',
        isGetAllFrmtApiLoading: true,
        GetAllForamtVersionData: [],
        SearchInputValue: '',
        currentDate: {}, //Yasvi Patel
        flag: false, //Yasvi Patel
        sortingOrderField: "", //Yasvi Patel
        sortingArrangeField: "", //Yasvi Patel
        sortingOrderField: "", //Yasvi Patel
        sortingArrangeField: "", //Yasvi Patel
        rangeBtnState: true,
        isTmFormatAPICalling: false,
        cFooter: [],
    },
    reducers: {
        getGroupEdited: (state, action) => {
            // console.log("action.payload", action.payload);
            state.groupEdited = action.payload;
        },
        getCurrentDate: (state, action) => { // Yasvi Patel
            state.currentDate = { ...state.currentDate, [action?.payload?.id]: action?.payload?.date }
            // state.currentDate?.[action.payload.id]=action.payload?.date;
            state.flag = { ...state.flag, [action?.payload?.id]: action?.payload?.flag }
        },
        getSortingField: (state, action) => { //Yasvi Patel
            state.sortingOrderField = { ...state.sortingOrderField, [action?.payload?.id]: action?.payload?.order }
            state.sortingArrangeField = { ...state.sortingArrangeField, [action?.payload?.id]: action?.payload?.arrange }
        },
        SaveAccountListData: (state, action) => {
            state.SaveAccountListArr = action.payload
        },
        SetAccountListData: (state, action) => { // rathod kishan
            console.log("SetAccountListData =>", action);
            state.AccountListData = action.payload
        },
        getRangeBtnState: (state, action) => { //Yasvi Patel
            state.rangeBtnState = { ...state?.rangeBtnState, [action.payload.id]: action?.payload?.rangeBtnState }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAccountList.pending, (state, action) => {
                // console.log("GetAccountList action.payload==>", action?.meta?.arg?.id);
                // console.log("GetAccountList action.payload==>",action);


                state.hasError = false;
                state.isLoading = { ...state.isLoading, [action?.meta?.arg?.id]: false }; // Yasvi patel (as instructed by Kishanbhai)
                state.isRefetching = false;
                // if (state.AccountListData.length <= 0) {
                if (state.AccountListData[action?.meta?.arg?.id]) {
                    state.isRefetching = { ...state.isRefetching, [action?.meta?.arg?.id]: true }

                }
                else {
                    state.isLoading = { ...state.isLoading, [action?.meta?.arg?.id]: true };
                }
                // }
                // else {

                // }

            })
            .addCase(GetAccountList.fulfilled, (state, action) => {
                // console.log("GetAccountList fulfilled", action);
                // console.log("state.AccountListData",state.AccountListData);
                // console.log("isRefetching",state?.isRefetching);
                // console.log("action.payload[2]==>",{[action.payload[1]]:action.payload[2]})
                if (!action.payload?.error) {
                    state.SearchInputValue = action?.payload?.[5];
                    // console.log("SearchInputValue",state?.SearchInputValue);

                    if (action.payload[6]) {
                        // console.log("action.payload[6]",action.payload[6])
                        state.cFooter = action.payload[6];
                    } else {
                        state.cFooter = []
                    }


                    state.AccountListData = { ...state.AccountListData, [action.payload[1]]: action.payload[0] };
                    state.StrtEndDateAry = { ...state.StrtEndDateAry, [action.payload[1]]: { StrtDt: action.payload[3], EndDt: action.payload[4] } }
                    // state.AccountListData = action.payload;
                    state.rowCount = { ...state.rowCount, [action.payload[1]]: action.payload[2] }; // rathod kishan
                    // console.log("state.AccountListData", state.AccountListData);
                    // console.log("state.StrtEndDateAry", state.StrtEndDateAry);
                    state.isLoading = { ...state.isLoading, [action?.meta?.arg?.id]: false }; // Yasvi patel (as instructed by Kishanbhai)
                    // console.log("isLoading",state?.isLoading)

                    state.isRefetching = false;
                    state.hasError = false;
                    if (!action?.payload?.[5]) {
                        state.SearchInputValue = "";
                    }

                }
                else {
                    state.AccountListData = { ...state.AccountListData, [action.payload[1]]: action.payload[0] };
                    state.isLoading = { ...state.isLoading, [action?.meta?.arg?.id]: false }; // Yasvi patel (as instructed by Kishanbhai)
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }

                // state.OneProductData = {};
            })
            .addCase(GetAccountList.rejected, (state, action) => {

                state.hasError = true
                state.isLoading = { ...state.isLoading, [action?.meta?.arg?.id]: false }; // Yasvi patel (as instructed by Kishanbhai)
            })
            .addCase(GetTMFormat.pending, (state, action) => {
                // console.log("action", action);
                // if (action?.meta?.arg?.isTmFormatNeeded) {
                //     state.isTmFormatAPICalling = true
                // }
                // else {
                // }
                // state.isLoading = true;  //Yasvi Patel ( as instructed by sir)
                state.hasError = false;
            })
            .addCase(GetTMFormat.fulfilled, (state, action) => {
                console.log("GetTMFormat fulfilled", action.payload);
                // console.log("fulfilled", action.payload[0]?.oDCFrmt?.aDCol.map(item => item));

                if (!action?.payload?.error) {
                    // if(action?.payload[2])
                    // {
                    //     state.isTmFormatAPICalling = false
                    // }
                    state.TMFormatData = { ...state.TMFormatData, [action.payload[1]]: action.payload[0] }
                    // state.TMFormatData = action.payload;
                    // console.log("state.TMFormatData", state.TMFormatData);
                    // state.isLoading = false; //Yasvi Patel ( as instructed by sir)
                    state.hasError = false;
                } else {
                    if (state.AccountListData[action.payload[1]] && state.AccountListData[action.payload[1]].length > 0) {
                        // state.isLoading = false; //Yasvi Patel ( as instructed by sir)
                    }
                    else {

                    }

                    state.hasError = true;
                    state.ErrorMsg = action?.payload?.error
                }
                // state.OneProductData = {};
            })
            .addCase(GetTMFormat.rejected, (state, action) => {
                state.hasError = true
                // state.isLoading = false; //Yasvi Patel ( as instructed by sir)
            })
            .addCase(GetAllForamt.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetAllForamt.fulfilled, (state, action) => {
                // console.log("action.payload", action.payload);
                if (!action.payload?.error) {
                    state.isLoading = false;
                    state.hasError = false;
                    state.isGetAllFrmtApiLoading = false;
                } else {
                    state.hasError = true;
                    state.isLoading = false;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetAllForamt.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetAllForamtVersion.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetAllForamtVersion.fulfilled, (state, action) => {
                // console.log("GetAllForamtVersion fulfilled", action.payload);
                if (!action?.payload?.error) {
                    state.GetAllForamtVersionData = action.payload
                    state.isLoading = false;
                    state.hasError = false;
                    // state.isGetAllFrmtApiLoading = false;
                } else {
                    // if (state.AccountListData[action.payload[1]] && state.AccountListData[action.payload[1]].length > 0) {
                    //     state.isLoading = false;
                    // }
                    // else {

                    // }

                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetAllForamtVersion.rejected, (state, action) => {
                // console.log("GetAllForamtVersion rejected", action.payload);
                state.hasError = true
                state.isLoading = false;
            })
    }
})
export const { getGroupEdited, getCurrentDate, getSortingField, getRangeBtnState, SetAccountListData, SaveAccountListData } = AccountListSlice.actions;
export default AccountListSlice.reducer;


