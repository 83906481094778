import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { DataSaveLoading } from "./DataSaveLoadingSlice";

// get companySetup Data
export const GetCMPSetup = createAsyncThunk(
  "CompanySetup/GetCMPSetup",
  async (obj) => {
    console.log("CompanySetup/GetCMPSetup===>", obj);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: obj.cID ? obj.cID : "CCCC",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetCMPSetup + "?pa=" + param
      );
      // console.log("GetCMPSetup response=>>", response.data);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
var DataCache = {};

export const ComboBoxUrl = createAsyncThunk(
  "CompanySetup/ComboBoxUrl",
  async (copno) => {
    // console.log("copno", copno);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: copno ?? "1",
      });
      if (!DataCache[copno]) {
        const response = await axios.get(
          GlobalClass.ApiUrl + GlobalClass.GetCop + "?pa=" + param
        );
        DataCache = { ...DataCache, [copno]: response.data.data };
        // DataCache[copno]=response.data.data;
      }
      // console.log("ComboBoxUrl response=>>", response.data);
      return DataCache;
    } catch (error) {
      console.error(error);
    }
  }
);

// get GetYearDateUrl Data
export const GetYearDateUrl = createAsyncThunk(
  "CompanySetup/GetYearDateUrl",
  async (obj) => {
    try {
      let param =
        "p1=" +
        gensetting.getcorpid() +
        "&p2=" +
        gensetting.getcuser() +
        "&p3=" +
        gensetting.getcpass() +
        "&p4=" +
        gensetting.getcmpno() + "&YrNo=" + localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')

      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetYearDet + "?" + param
      );

      if (response?.data?.data !== null) {
        return response?.data.data;
      }
      else if (response?.data?.status == "FAIL") {
        return { error: response?.data?.message }
      }
      else {
        return { error: "Error" }
      } // Vara Pooja

    } catch (error) {
      console.error(error);
      return { error: "Network Error!!" }  // Vara Pooja
    }
  }
);

export const GetM41UFList = createAsyncThunk(
  "CompanySetup/GetM41UFList",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara: "",
        // "cRepId": "",
        // "cFmtID": "",
        StrtDt: "2021-04-01",
        EndDt: "2022-03-31",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      // {"CorpID": "94.176.235.105",  "cUser": "SUPERVISOR",  "cPass": "a@123",  "CmpNo": "6002",  "cAction": "A",  "cCode": "","cOPara":""}
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.M41UFList + "?pa=" + param
      );
      // console.log("GetCMPSetup response=>>", response.data);
      // if (response.data.status === "SUCCESS") {
      //   GlobalClass.Notify(
      //       "success","Added",
      //          "Added successfully"
      //     );
      //   return response.data.data;
      // } else {
      //   GlobalClass.Notify(
      //       "error",
      //       response.data?.status,
      //       response.data?.message
      //     );
      //   return { error: response.data.message };
      // }
      return response.data.data;
    } catch (e) {
      GlobalClass.Notify(
        "error",
        "Error",
        `${e?.message}`
      );
      return { e };
    }
  }
);

export const GetEntList = createAsyncThunk(
  "CompanySetup/GetEntList",
  async (obj) => {
    // console.log('CompanySetup/GetEntList obj=>', obj);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj.action ? obj.action : "A",
        cCode: obj.code ? obj.code : "",
        cOPara: "",
        // "StrtDt": "2021-04-01",
        // "EndDt":  "2022-03-31"
      });
      // {"CorpID": "94.176.235.105",  "cUser": "SUPERVISOR",  "cPass": "a@123",  "CmpNo": "6002",  "cAction": "A",  "cCode": "","cOPara":""}
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetEnt + "?pa=" + param
      );
      // console.log("GetCMPSetup response=>>", response.data);
      if (response.data.status === "SUCCESS") {
        return response.data.data;
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        return { error: response?.data?.message }
      }
      // return response.data.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);

      return { e };
    }
  }
);

export const PostM41UFEnt = createAsyncThunk(
  "Product/PostM41UFEnt",
  async (obj) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj.action ? obj.action : "A",
        cCode: obj.code ? obj.code : "",
        cOPara: "",
      });

      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.PostM41UFEnt + "?pa=" + param,
        obj
      );
      console.warn("PostM41UFEnt", response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteUserData = createAsyncThunk(
  "CompanySetup/deleteUserData",
  async (obj) => {
    console.log("CompanySetup/deleteUserData obj=>", obj);
    GlobalClass.Notify(
      "info",
      obj?.action == "A" ? "Adding" : obj?.action == "E" ? "Editing" : "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj.action ? obj.action : "A",
        cCode: obj.code ? obj.code : "",
        cOPara: "",
        // "StrtDt": "2021-04-01",
        // "EndDt":  "2022-03-31"
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetEnt + "?pa=" + param
      );
      console.log("CompanySetup/deleteUserData response=>>", response.data);
      if (response.data.status === "SUCCESS") {
        Promise.resolve(Store.dispatch(GetM41UFList())).then(() =>
          GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);

      console.error(e);
    }
  }
);

export const PostM41UFEntStatus = createAsyncThunk(
  "CompanySetup/PostM41UFEntStatus",
  async (obj) => {
    try {
      console.log("CompanySetup/PostM41UFEntStatus=>", obj);
      let param = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.action ? obj?.action : "N",
        cCode: obj?.code ? obj?.code : "",
        cOPara: obj?.HEStatus ? obj?.HEStatus : "H",
      };

      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.PostM41UFEnt, param);
      console.log("PostM41UFEntStatus", response?.data);
      if (response.data.status === "SUCCESS") {
        Promise.resolve(Store.dispatch(GetM41UFList())).then(() =>
          GlobalClass.Notify("success", "Status", "Status Change successfully")).then(() => Store.dispatch(DataSaveLoading(false)))
      } else {
        Promise.resolve(GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        )).then(() => Store.dispatch(DataSaveLoading(false)))
      }
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const CompanySetupSlice = createSlice({
  name: "CompanySetup",
  initialState: {
    CorporateData: [],
    CompanySetupData: [],
    ComboBoxUrlData: [],
    GetYearDateData: [],
    M41UFListData: [],
    GetEntListData: [],
    isLoadingCmp: false,
    isLoading: false,
    isLoadingCMP: false,
    EntisLoading: false,
    hasError: false,
    ErrorMsg: "",
    // deleteUser:{},
    successMsg: "",
    TableObjArray: [],
  },
  reducers: {
    SetTableObjArray: (state, action) => {
      state.TableObjArray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCMPSetup.pending, (state, action) => {
        state.CompanySetupData = []
        state.isLoadingCmp = true;
        state.hasError = false;
      })
      .addCase(GetCMPSetup.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.CompanySetupData = action.payload;
          state.isLoadingCmp = false;
          state.hasError = false;
        } else {
          // state.CompanySetupData = action.payload;
          state.isLoadingCmp = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetCMPSetup.rejected, (state, action) => {
        state.hasError = true;
        state.CompanySetupData = []
        state.isLoadingCmp = false;
      })
      .addCase(ComboBoxUrl.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(ComboBoxUrl.fulfilled, (state, action) => {
        // console.log("ComboBoxUrlData", action.payload);
        state.ComboBoxUrlData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(ComboBoxUrl.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetYearDateUrl.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetYearDateUrl.fulfilled, (state, action) => {
        // console.log("action.payload", action?.payload);

        if (action?.payload && !action.payload?.error) {
          // console.log("fulfilled");
          state.GetYearDateData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          // console.log("rejected");
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error
        }  // Vara Pooja

      })
      .addCase(GetYearDateUrl.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetM41UFList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetM41UFList.fulfilled, (state, action) => {
        // console.log('M41UFListData action.payload=>', action.payload);
        if (!action?.payload?.error) {
          state.M41UFListData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.M41UFListData = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetM41UFList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetEntList.pending, (state, action) => {
        state.EntisLoading = true;
        state.hasError = false;
      })
      .addCase(GetEntList.fulfilled, (state, action) => {
        // console.log('GetEntListData action.payload=>', action.payload);
        if (!action.payload.error) {
          state.GetEntListData = action.payload;
          state.EntisLoading = false;
          state.hasError = false;
          state.ErrorMsg = "";
        } else {
          state.GetEntListData = [];
          state.EntisLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetEntList.rejected, (state, action) => {
        state.hasError = true;
        state.EntisLoading = false;
      })
      .addCase(PostM41UFEnt.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(PostM41UFEnt.fulfilled, (state, action) => {
        console.log("PostM41UFEnt", action.payload);
        state.M41UFListData.push(action.payload);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(PostM41UFEnt.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(deleteUserData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(deleteUserData.fulfilled, (state, action) => {
        console.log("GetEntListData delete==>", action.payload);
        // state.deleteUser = action.payload
        state.successMsg = action?.payload?.status;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(deleteUserData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const { SetTableObjArray } = CompanySetupSlice.actions;

export default CompanySetupSlice.reducer;
