import { Alert, Button, Center, Drawer, Grid, Group, Modal, Popover, Skeleton, Text, Textarea } from '@mantine/core';
import React, { useEffect, useRef, useState, useCallback } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserFieldObj, TransactionApi } from '../../utils/TransactionSlices/Transaction';
import gensetting from '../../utils/gensetting';
import GlobalClass from '../../utils/GlobalClass';
import { modals } from '@mantine/modals';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import CBUserField from './CBUserField';
import DataGrid from '../../components/DataGrid';
import Store from '../../utils/store';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import PLNarration from '../../PlusComponent/PLNarration';
import ExpenseEntryForm from '../../Transaction/SaleEntry/ExpenseEntryForm';
import { GetExpenseData, GetInvTypeDropDown } from '../../utils/TransactionSlices/TSalesSlices';
import PLBillToBill from '../../PlusComponent/PLBillToBill';
import { GetAccountListForPrint } from '../../utils/PrintSlice/PrintSlice';
import FormatListTable from '../../Transaction/SMSEmailWpBtn/FormatListTable';
import PLPrint from '../Print/PLPrint';
import { MidCalc } from '../../Transaction/SaleEntry/AssignItemDefaultFun';
import { TabRemove } from '../../utils/slices/TabList';

// let PageCode;
let stringOfNames;
// var isAPICalling = false;

export default function TransactionForm(props) {

    const { OBJ, index } = props;
    console.log(OBJ, "TransactionForm props");

    const weekday = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
    const myComboBox = document.getElementById('#myComboBox');

    const inputRef = useRef();
    const formRef = useRef(null);
    const userFieldRef = useRef(null);
    const invTypeRef = useRef(null);
    const dateRef = useRef(null);
    const numberRef = useRef(null);
    const numberInputRef = useRef(null);
    const oppAccRef = useRef(null);
    // const partyAmtRef = useRef(null);


    const dispatch = useDispatch();
    const TransactionData = useSelector(store => store?.Transaction?.TransactionData);
    const isLoading = useSelector(store => store?.Transaction?.isLoading);
    const UserField = useSelector(store => store?.Transaction?.UserField);
    const hasError = useSelector((state) => state?.Transaction?.hasError);
    const ErrorMsg = useSelector((state) => state?.Transaction?.ErrorMsg);
    const { isLoading: isLoading1 } = useSelector((state) => state.PrintList);


    const accListSliceFlag = useSelector(store => store?.Transaction?.flag);
    const invTypeData = useSelector(store => store?.Transaction?.invTypeData);

    const isUserFieldLoading = useSelector(store => store?.Transaction?.isUserFieldLoading)

    const ExpenseData = useSelector((store) => store?.TSalesSlices?.ExpenseData)
    const SaveLoading = useSelector(store => store?.DataSaveLoadingSlice?.SaveLoading);
    const General = useSelector(store => store?.Language?.General);
    const printData = useSelector((state) => state?.PrintList?.PrintData[OBJ?.id]);
    const {
        AccountListData: accList,
        TMFormatData: tmList,
        isLoading: AccNameisLoading,
    } = useSelector((state) => state.AccountList);

    const InvTypeDropDown = useSelector((state) => state?.TSalesSlices?.InvTypeDropDown);
    const { oYear } = useSelector((state) => state?.Language?.General);
    const footerDate = useSelector((state) => state?.Language?.footerDate);
    let OS11 = TransactionData?.OS11
    // let OSV = TransactionData?.OSV
    const dtM14DT = TransactionData?.dtM14;
    const dtM51DT = TransactionData?.dtM51;
    const dtM52DT = TransactionData?.dtM52;
    const dtM46DT = TransactionData?.dtM46;
    const T41Obj = TransactionData?.T41

    const [state, setState] = useState();
    const [T41, setT41] = useState();
    const [dataObj, setdataObj] = useState();
    const [UFT41H, setUFT41H] = useState(null);
    const [bankCashBalance, setBankCashBalance] = useState();
    const [oppAccountBalance, setOppAccountBalance] = useState();
    const [isBalanceSet, setIsBalanceSet] = useState(false)
    const [hasFocused, setHasFocused] = useState("");
    const [day, setDay] = useState();
    // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // const [lEditVou, setlEditVou] = useState();
    const [DB, setDB] = useState("");
    const [defaultDate, setDefaultDate] = useState();
    const [cCode, setcCode] = useState("");
    const [selectAccount, setselectAccount] = useState("")
    const [T05DataArray, setT05DataArray] = useState([]);
    const [oppAccountSelectedValue, setOppAccountSelectedValue] = useState();
    const [PageCode, setPageCode] = useState("")
    const [T41Flag, setT41Flag] = useState(false)
    const [field, setField] = useState(false)
    const [accListData, setAccListData] = useState([])
    const [cVType, setcVType] = useState()

    const [isBillToBill, setIsBillToBill] = useState(false);
    const [M45IDT, setM45IDT] = useState([]);
    const [M45I, setM45I] = useState("")

    const [temp, setTemp] = useState(false)
    const [flag, setflag] = useState(true);

    const [OSV, setOSV] = useState([]);
    const [BillNoStatus, setBillNoStatus] = useState(false)

    const [NumberInput, setNumberInput] = useState({
        NumberVal: "",
        TextInput: false,
        DataGridInput: true
    })
    const [NarrationData, setNarrationData] = useState([]);
    const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [f02, setF02] = useState(false);

    const obj1 = {
        id: OBJ?.id,
        // name : "Account List",
        // type: "A",
        // p0: "MT",
        p1: "P_M01",
        p2: "",
        // p3: ""
    }

    const map = new Map();

    // useEffect(() => {
    //   console.log("ExpenseData",ExpenseData);
    // },[ExpenseData])

    useEffect(() => {
        dispatch(TransactionApi(OBJ)).then(() => {
            dispatch(GetAccountListForPrint(obj1))
            setHasFocused("yes")
        });
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        setAccListData(printData)
    }, [printData])

    useEffect(() => {
        console.log("T41", T41?.FIELD07);
        if (accListData && OBJ.p0 == "E") {
            let bankCash = accListData.find(item => item.FIELD01 == T41?.FIELD05)
            console.log("bankCash", bankCash);
            setselectAccount(bankCash?.FIELD07)
            let oppAccount = accListData.find(item => item.FIELD01 == T41?.FIELD04)
            console.log("oppAccount", oppAccount);
            setOppAccountSelectedValue(oppAccount?.FIELD07)
        }
    }, [accListData, T41])

    useEffect(() => {
        let date = f02 || props?.obj?.p0 == "E" ? T41?.FIELD02 : dateFormatter2(footerDate);
        // console.log(date, "group?.FIELD02");
        if (date) {
            const year = parseInt(date.substr(0, 4));
            const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
            const day = parseInt(date.substr(6, 2));
            const dateObject = new Date(year, month, day);
            // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
            const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
            // console.log("dayOfWeek", dayOfWeek);
            setDay(dayOfWeek?.slice(0, 3));
        }
    }, [T41?.FIELD02, footerDate]);

    // useEffect(() => {
    //     if (NumberInput.NumberVal.length <= 0) {
    //         setNumberInput({ ...NumberInput, DataGridInput: true, TextInput: false })
    //     }
    // }, [NumberInput.NumberVal])

    useEffect(() => {
        if (SelectedNarrationData?.length > 0) {
            const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
            stringOfNames = stringifyArray?.join(" ");
            // console.log("SelectedNarrationData", SelectedNarrationData);
            // console.log("stringOfNames", stringOfNames);
        }
        setT41({ ...T41, FIELD15: stringOfNames });
        // setT41Obj({...T41Obj,FIELD15:})
    }, [stringOfNames, SelectedNarrationData]);

    useEffect(() => {

        if (OBJ.p0 == "A") {

            if (OBJ.page === "04740462") {
                setcCode("BPBP")
                setselectAccount("BK")
                // setT41PVT("BP")
            }
            else if (OBJ.page === "04800462") {
                setcCode("CPCP")
                setselectAccount("CS")
                // setT41PVT("CP")
            }
            else if (OBJ.page === "04770462") {
                setcCode("BRBR")
                setselectAccount("BK")
                // setT41PVT("BR")
            }
            else if (OBJ.page === "04830462") {
                setcCode("CRCR")
                setselectAccount("CS")
                // setT41PVT("CR")
            }
            else {
                setselectAccount("BK")
                // setT41PVT("BC")
            }
        }

    }, [OBJ.page]);

    useEffect(() => {
        console.log(TransactionData, "TransactionData");
        // console.log("General", General, OBJ);
        setState(TransactionData);
        setUFT41H(TransactionData?.UFT41H);
        if (f02 && OBJ.p0 === "A") {
            console.log("dataObj", dataObj);
            setT41(dataObj)
        }
        else {
            setT41(TransactionData?.T41)
        }
        setdataObj(TransactionData?.T41)
        let date = dateFormatter()
        setDefaultDate(date)
        setOSV(TransactionData?.OSV)

        // let Result = TransactionData?.M45IDT?.filter(item => item.FIELD20 !== "3" && item.FIELD20 !== "4")
        // setM45IDT(Result)
        setM45I(TransactionData?.M45I)

    }, [TransactionData])

    useEffect(() => {
        if (invTypeData && OBJ.page === "04860462") {
            // console.log("invTypeData", invTypeData);
            let Result = invTypeData[0]?.filter(item => item.FIELD20 !== "3" && item.FIELD20 !== "4")
            // console.log("invTypeData result", Result);
            setM45IDT(Result)
            setM45I(invTypeData[1])
        }
    }, [invTypeData])

    useEffect(() => {
        if (state?.T05) {
            setT05DataArray(state?.T05);
        }
    }, [state?.T05]);

    useEffect(() => {
        // Inv type che a contra ma nthi to bank/cash ane receipt/Payment mujab api call krvani ane amathi levanu
        if (!accListSliceFlag && OBJ.page === "04860462") {
            switch (true) {
                case (selectAccount === "BK" && T41?.FIELD16 === "R"):
                    dispatch(TransactionApi({ ...OBJ, page: "04770462", isContraPage: true }))
                    break;
                case (selectAccount === "BK" && T41?.FIELD16 === "P"):
                    dispatch(TransactionApi({ ...OBJ, page: "04740462", isContraPage: true }))
                    break;
                case (selectAccount === "CS" && T41?.FIELD16 === "R"):
                    dispatch(TransactionApi({ ...OBJ, page: "04830462", isContraPage: true }))
                    break;
                case (selectAccount === "CS" && T41?.FIELD16 === "P"):
                    dispatch(TransactionApi({ ...OBJ, page: "04800462", isContraPage: true }))
                    break;
                default:
                    break;
            }

            if (T41) {
                setT41({ ...T41, FIELD13: "" })
                setM45I("")
                setM45IDT([])
            }
        }
    }, [temp, accListSliceFlag])

    // console.log("cVType",cVType);

    useEffect(() => {
        // console.log("PageCode selectAccount", PageCode)
        console.log("pagecode selectAccount", selectAccount)
        console.log("pagecode oppAccountSelectedValue?.FIELD07", oppAccountSelectedValue)
        switch (true) {
            case ((oppAccountSelectedValue?.FIELD07 === "CS" && selectAccount === "BK") || (selectAccount === "CS" && oppAccountSelectedValue?.FIELD07 === "BK")):
                setPageCode("BCBC")
                // PageCode = "BCBC";
                break;
            case (selectAccount === "BK" && T41?.FIELD16 === "R"):
                setPageCode("BRBR")
                setcVType("BR")

                //   PageCode = "BRBR"
                //   setT41PVT("BR")
                break;
            case (selectAccount === "BK" && T41?.FIELD16 === "P"):
                setPageCode("BPBP")
                setcVType("BP")

                //   PageCode = "BPBP"
                //   setT41PVT("BP")
                break;
            case (selectAccount === "CS" && T41?.FIELD16 === "R"):
                setPageCode("CRCR")
                setcVType("CR")

                //   PageCode = "CRCR" 
                //   setT41PVT("CR")
                break;
            case (selectAccount === "CS" && T41?.FIELD16 === "P"):
                setPageCode("CPCP")
                setcVType("CP")

                //   PageCode = "CPCP"
                //   setT41PVT("CP")
                break;
            default:
                break;
        }

        if (selectAccount && T41?.FIELD16 && OBJ.page === "04860462") {
            setTemp(!temp)
        }

    }, [selectAccount, T41?.FIELD16, oppAccountSelectedValue])

    useEffect(() => {
        console.log("PageCode", PageCode);
        if (PageCode && flag) {
            setflag(false)
        }
        else if (!flag) {
            let cCode1 = PageCode.slice(0, 2)
            Promise.resolve(dispatch(GetUserFieldObj(cCode1)))
        }
    }, [PageCode])

    useEffect(() => {
        if (oppAccountSelectedValue && UFT41H) {
            UFT41H?.forEach((item) => {
                if (item?.S13F14 == "PLPOPUP") {
                    const key = seperate(item.S13F09)[1]; // Key
                    const value = T41?.[item.S13F02]; // Value
                    console.log(map, "map");
                    if (map.has(key)) {
                        // If it exists, add the value to the set/array of values for that key
                        map.get(key).add(value); // Use a Set to ensure uniqueness of values
                    } else {
                        map.set(key, new Set([value]));
                    }
                }
            });

            if (map?.size > 0) {
                map.forEach((value, key) => {
                    const dyValue = Array.from(value).length
                        ? "'" + Array.from(value).join("', '") + "'"
                        : "";
                    setTimeout(() => {
                        dyValue && Promise.resolve(dispatch(
                            GetAccountList({
                                id: key,
                                name: "",
                                type: "A",
                                p0: key,
                                p1: key,
                                p2: "",
                                p3: "",
                                cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                            })
                        )).then(() => {
                            dispatch(GetTMFormat({
                                "id": key,
                                "name": "",
                                "type": "A",
                                "p0": key,
                                "p1": key,
                                "p2": "TFORM0000001",
                                "p3": ""
                            }))
                        })
                    }, [500]);
                });
            }
        }
    }, [oppAccountSelectedValue]);

    useEffect(() => {
        // console.log("useEffect oppAccountSelectedValue", oppAccountSelectedValue, "field", field);
        // console.log("useEffect UserField", UserField, "UFT41H", UFT41H);


        if (oppAccountSelectedValue && field) {

            // alert("oppAccountSelectedValue")
            // if (oppAccountSelectedValue && UserField?.UFTbl?.length > 0) {

            if (UserField?.UFTbl?.length > 0) {
                setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>User Field</Text>,
                    body: (
                        <>
                            {
                                isUserFieldLoading ? GlobalClass.RenderLoader('dots') :
                                    <CBUserField
                                        UFT41H={UserField?.UFTbl} T41={T41} setT41={setT41} OBJ={OBJ}
                                        DrawerOBJ={DrawerObj}
                                        setDrawerOBJ={(e) => {
                                            setDrawerObj(e);
                                        }}
                                        setFocus={() => {
                                            const partyAmtRef = document.getElementById("partyAmtRef")
                                            partyAmtRef && partyAmtRef.focus()
                                            // console.log("partyAmtRef UFTbl", partyAmtRef);
                                        }}
                                    />
                            }

                        </>
                    ),
                    open: true,
                    size: "sm",
                    position: "right",
                });
            }
            else if (UFT41H?.length > 0) {
                setDrawerObj({
                    ...dra,
                    title: <Text fw={700}>User Field</Text>,
                    body: (
                        <>
                            {
                                isUserFieldLoading ? GlobalClass.RenderLoader('dots') :
                                    <CBUserField
                                        UFT41H={UFT41H} T41={T41} setT41={setT41} OBJ={OBJ}
                                        DrawerOBJ={DrawerObj}
                                        setDrawerOBJ={(e) => {
                                            setDrawerObj(e);
                                        }}
                                        setFocus={() => {
                                            // console.log("partyAmtRef UFT41H",partyAmtRef);
                                            const partyAmtRef = document.getElementById("partyAmtRef")
                                            partyAmtRef && partyAmtRef?.focus()
                                        }}
                                    />
                            }

                        </>
                    ),
                    open: true,
                    size: "sm",
                    position: "right",
                });

            }
            // }
            // else if (oppAccountSelectedValue && UFT41H?.length > 0) {
            //     setDrawerObj({
            //         ...dra,
            //         title: <Text fw={700}>User Field</Text>,
            //         body: (
            //             <>
            //                 {
            //                     // T41?.FIELD04 &&
            //                     <CBUserField
            //                         UFT41H={UFT41H} T41={T41} setT41={setT41} OBJ={OBJ}
            //                         DrawerOBJ={DrawerObj}
            //                         setDrawerOBJ={(e) => {
            //                             setDrawerObj(e);
            //                         }}
            //                     />
            //                 }

            //             </>
            //         ),
            //         open: true,
            //         size: "sm",
            //         position: "right",
            //     });
            // }

        }

    }, [oppAccountSelectedValue, UserField])

    // useEffect(() => {
    //     // console.log("UserField",UserField); 

    //     // if (!isUserFieldLoading) {

    //     //     if (oppAccountSelectedValue && UserField?.UFTbl?.length > 0) {
    //     //         setDrawerObj({
    //     //             ...dra,
    //     //             title: <Text fw={700}>User Field</Text>,
    //     //             body: (
    //     //                 <>
    //     //                     {
    //     //                         // T41?.FIELD04 &&
    //     //                         <CBUserField
    //     //                             UFT41H={UserField?.UFTbl} T41={T41} setT41={setT41} OBJ={OBJ}
    //     //                             DrawerOBJ={DrawerObj}
    //     //                             setDrawerOBJ={(e) => {
    //     //                                 setDrawerObj(e);
    //     //                             }}
    //     //                         />
    //     //                     }

    //     //                 </>
    //     //             ),
    //     //             open: true,
    //     //             size: "sm",
    //     //             position: "right",
    //     //         });
    //     //     }
    //     //     else if (oppAccountSelectedValue && UFT41H?.length > 0) {
    //     //         setDrawerObj({
    //     //             ...dra,
    //     //             title: <Text fw={700}>User Field</Text>,
    //     //             body: (
    //     //                 <>
    //     //                     {
    //     //                         // T41?.FIELD04 &&
    //     //                         <CBUserField
    //     //                             UFT41H={UFT41H} T41={T41} setT41={setT41} OBJ={OBJ}
    //     //                             DrawerOBJ={DrawerObj}
    //     //                             setDrawerOBJ={(e) => {
    //     //                                 setDrawerObj(e);
    //     //                             }}
    //     //                         />
    //     //                     }

    //     //                 </>
    //     //             ),
    //     //             open: true,
    //     //             size: "sm",
    //     //             position: "right",
    //     //         });
    //     //     }
    //     // }


    // }, [UserField, oppAccountSelectedValue])

    useEffect(() => {
        if (OBJ.p0 === "E" && T41 && !isBalanceSet) {
            // console.log("T41?.M01BAL", T41?.M01BAL);
            // console.log("T41?.M01OPPBAL", T41?.M01OPPBAL);
            setBankCashBalance(T41?.M01BAL)
            setOppAccountBalance(T41?.M01OPPBAL)
            setIsBalanceSet(true)
        }
    }, [T41])

    useEffect(() => {
        console.log("state", state?.M01BA)
        console.log("state", state?.M01OA)

        // console.log("T41", T41);

        // setlEditVou(state?.lEditVou)

        if (hasFocused == "yes") {

            if (OBJ.p0 == "E" && TransactionData?.lEditVou == true) {
                inputRef.current.handlePopoverToggle();
            }
            else if (OBJ.p0 == "A") {
                inputRef.current.handlePopoverToggle();
            }
            setHasFocused("no");
        }

        // let balance = state?.M01BADT?.jData?.filter((item) => {
        //     return item?.FIELD01 === T41?.FIELD05;
        // }).map(item => item.FIELD99).join();
        // setBankCashBalance(balance);

        // let balance1 = state?.M01OADT?.jData?.filter((item) => {
        //     return item?.FIELD01 === T41?.FIELD04;
        // }).map(item => item.FIELD99).join();
        // setOppAccountBalance(balance1);

    }, [state, hasFocused, T41]);

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const seperate1 = (e) => {
        var paraArr = e.split("_");
        // console.log("paraArr=>", paraArr);
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const costCenter = () => {
        const MyInputBox = document.querySelector('#MyInputBox');
        if (MyInputBox) {
            MyInputBox.focus();
        }
    }

    const negBalPopupOkBtn = () => {
        setDrawerObj({ ...DrawerObj, open: false })
    }

    const handleClose = () => {
        Promise.resolve(dispatch(DataSaveLoading(false)))

        if (OBJ.p0 == "A") {
            dispatch(TransactionApi(OBJ)).then(() => setHasFocused("yes"))
            // setF02(true)

        }
        else {
            if (props?.index.includes("modalForm1")) {
                dispatch(TabRemove(props?.index))
            } else {
                dispatch(ModalDelete(props.index))
            }
        }

        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
        switch (OBJ.page) {
            case "04740462":
                dispatch(GetAccountList({
                    id: "04740462",
                    name: "Bank Payment",
                    p0: "MT",
                    p1: "T41VLISTBPBP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04740462"],
                }))
                break;
            case "04800462":
                dispatch(GetAccountList({
                    id: "04800462",
                    name: "Cash Payment",
                    p0: "MT",
                    p1: "T41VLISTCPCP",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04800462"]

                }))
                break;
            case "04770462":
                dispatch(GetAccountList({
                    id: "04770462",
                    name: "Bank Receipt",
                    p0: "MT",
                    p1: "T41VLISTBRBR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04770462"]

                }))
                break;
            case "04830462":
                dispatch(GetAccountList({
                    id: "04830462",
                    name: "Cash Receipt",
                    p0: "MT",
                    p1: "T41VLISTCRCR",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04830462"]

                }))
                break;
            case "04860462":
                dispatch(GetAccountList({
                    id: "04860462",
                    name: "Contra",
                    p0: "MT",
                    p1: "T41VLISTBCBC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["04860462"]
                }))
                break;
            default:
                break;
        }
    }


    const EmailFun = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Email Format</Text>,
            onClickCloseButton: () => {
                console.log("onClickCloseButton");
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>Whatsapp Format</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>SMS Format</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            body: (
                                                <>
                                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }

                                })
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>SMS Format</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            body: (
                                                <>
                                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }

                                })
                            },
                            body: (
                                <>
                                    <FormatListTable obj={{ ...OBJ, p0: "W", text: "WhatsApp" }} index={props.index} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        console.log("else");
                        handleClose()
                    }
                })
            },
            onClose: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>Whatsapp Format</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>SMS Format</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            body: (
                                                <>
                                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }

                                })
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>SMS Format</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                            },
                                                            body: (
                                                                <>
                                                                    <PLPrint data={OBJ} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            body: (
                                                <>
                                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }

                                })
                            },
                            body: (
                                <>
                                    <FormatListTable obj={{ ...OBJ, p0: "W", text: "WhatsApp" }} index={props.index} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        handleClose()
                    }
                })
            },
            body: (
                <>
                    <FormatListTable obj={{ ...OBJ, p0: "E", text: "EMail" }} index={props.index} />
                </>
            ),
            open: true,
            size: "sm",
            position: "right",
        })
    }

    const WhatsappFun = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Whatsapp Format</Text>,
            onClickCloseButton: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>SMS Format</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>Print Vocuher</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            body: (
                                                <>
                                                    <PLPrint data={OBJ} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }
                                })
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>Print Vocuher</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            body: (
                                                <>
                                                    <PLPrint data={OBJ} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }
                                })
                            },
                            body: (
                                <>
                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        handleClose()
                    }

                })
            },
            onClose: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>SMS Format</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>Print Vocuher</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            body: (
                                                <>
                                                    <PLPrint data={OBJ} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }
                                })
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>Print Vocuher</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                            },
                                            body: (
                                                <>
                                                    <PLPrint data={OBJ} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    }
                                    else {
                                        handleClose()
                                    }
                                })
                            },
                            body: (
                                <>
                                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        handleClose()
                    }

                })
            },
            body: (
                <>
                    <FormatListTable obj={{ ...OBJ, p0: "W", text: "WhatsApp" }} index={props.index} />
                </>
            ),
            open: true,
            size: "sm",
            position: "right",
        })
    }

    const SMSFun = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>SMS Format</Text>,
            onClickCloseButton: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>Print Vocuher</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                            },
                            body: (
                                <>
                                    <PLPrint data={OBJ} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        handleClose()
                    }
                })
            },
            onClose: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                        setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>Print Vocuher</Text>,
                            onClickCloseButton: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                            },
                            onClose: () => {
                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                            },
                            body: (
                                <>
                                    <PLPrint data={OBJ} />
                                </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                        })
                    }
                    else {
                        handleClose()
                    }
                })
            },
            body: (
                <>
                    <FormatListTable obj={{ ...OBJ, p0: "S", text: "SMS" }} index={props.index} />
                </>
            ),
            open: true,
            size: "sm",
            position: "right",
        })
    }

    const PrintFun = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Print Vocuher</Text>,
            onClickCloseButton: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
            },
            onClose: () => {
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
            },
            body: (
                <>
                    <PLPrint data={OBJ} />
                </>
            ),
            open: true,
            size: "sm",
            position: "right",
        })
    }

    const okBtn = () => {

        if (T41?.FIELD04) {
            dispatch(DataSaveLoading(true))
            GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

            const T41Data = { "T41": T41, "T05": T05DataArray }


            // console.log("T05DataArray", T05DataArray);

            var data = {
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": OBJ.p0,
                "cCode": OBJ?.id ? OBJ?.id : PageCode ? PageCode : "BCBC",
                "cOPara": "",
                "cSData": JSON.stringify(T41Data)
            };

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ?.id ? OBJ?.id : PageCode ? PageCode : "BCBC",
                    "cOPara": "",
                });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            if (OBJ.p0 == "E" && (JSON.stringify(T41) === JSON.stringify(dataObj))) {

                Promise.resolve(dispatch(DataSaveLoading(false))).then(() => {
                    // dispatch(ModalDelete(props.index))
                    if (props?.index.includes("modalForm1")) {
                        dispatch(TabRemove(props?.index))
                    } else {
                        dispatch(ModalDelete(props.index))
                    }

                })
                GlobalClass.Notify("success", "Edited", "Item is successfully Edited")
                dispatch(GetInvTypeDropDown(null))

                // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                // switch (OBJ.page) {
                //     case "04740462":
                //         dispatch(GetAccountList({
                //             id: "04740462",
                //             name: "Bank Payment",
                //             p0: "MT",
                //             p1: "T41VLISTBPBP",
                //             p2: "",
                //             p3: "",
                //             type: "A",
                //             pagination: PaginationObj["04740462"],
                //         }))
                //         break;
                //     case "04800462":
                //         dispatch(GetAccountList({
                //             id: "04800462",
                //             name: "Cash Payment",
                //             p0: "MT",
                //             p1: "T41VLISTCPCP",
                //             p2: "",
                //             p3: "",
                //             type: "A",
                //             pagination: PaginationObj["04800462"]

                //         }))
                //         break;
                //     case "04770462":
                //         dispatch(GetAccountList({
                //             id: "04770462",
                //             name: "Bank Receipt",
                //             p0: "MT",
                //             p1: "T41VLISTBRBR",
                //             p2: "",
                //             p3: "",
                //             type: "A",
                //             pagination: PaginationObj["04770462"]

                //         }))
                //         break;
                //     case "04830462":
                //         dispatch(GetAccountList({
                //             id: "04830462",
                //             name: "Cash Receipt",
                //             p0: "MT",
                //             p1: "T41VLISTCRCR",
                //             p2: "",
                //             p3: "",
                //             type: "A",
                //             pagination: PaginationObj["04830462"]

                //         }))
                //         break;
                //     case "04860462":
                //         dispatch(GetAccountList({
                //             id: "04860462",
                //             name: "Contra",
                //             p0: "MT",
                //             p1: "T41VLISTBCBC",
                //             p2: "",
                //             p3: "",
                //             type: "A",
                //             pagination: PaginationObj["04860462"]
                //         }))
                //         break;
                //     default:
                //         break;
                // }
            }
            else {

                fetch(GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param, requestOptions)
                    .then(response => response.json())
                    .then((data) => {
                        if (data.status === 'SUCCESS') {
                            dispatch(DataSaveLoading(false))
                            OBJ.p0 === "A" ? GlobalClass.Notify("success", "Added", "Item is successfully added") : GlobalClass.Notify("success", "Edited", "Item is successfully Edited")

                            setdataObj({ ...T41, FIELD05: T41?.FIELD05, FIELD05NM: T41?.FIELD05NM, FIELD16: T41?.FIELD16, FIELD04: T41?.FIELD04, FIELD04NM: T41?.FIELD04NM, FIELD13: T41?.FIELD13, M45F02: T41?.M45F02 })

                            if (OSV?._ONLINEMAL == "Y" || OSV?._ONLINEMAL == "L") {
                                return EmailFun()
                            } else if (OSV?._ONLINEWAP == "Y" || OSV?._ONLINEWAP == "L") {
                                return WhatsappFun()
                            } else if (OSV?._ONLINESMS == "Y" || OSV?._ONLINESMS == "L") {
                                return SMSFun()
                            } else if (OSV?._ONLINEVOU == "Y" || OSV?._ONLINEVOU == "D") {
                                return PrintFun()
                            } else {
                                return handleClose()
                            }

                            if (OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L") {
                                Promise.resolve(dispatch(DataSaveLoading(false)))
                                    .then(() => {
                                        setDrawerObj({
                                            ...dra,
                                            title: <Text fw={700}>Email Format</Text>,
                                            onClickCloseButton: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Whatsapp Format</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                                                        setDrawerObj({
                                                                            ...dra,
                                                                            title: <Text fw={700}>SMS Format</Text>,
                                                                            onClickCloseButton: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            onClose: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            body: (
                                                                                <>
                                                                                    <FormatListTable obj={{ ...OBJ, p0: "S" }} index={props.index} />
                                                                                </>
                                                                            ),
                                                                            open: true,
                                                                            size: "sm",
                                                                            position: "right",
                                                                        })
                                                                    }
                                                                    else {
                                                                        handleClose()
                                                                    }

                                                                })
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                                                        setDrawerObj({
                                                                            ...dra,
                                                                            title: <Text fw={700}>SMS Format</Text>,
                                                                            onClickCloseButton: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            onClose: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            body: (
                                                                                <>
                                                                                    <FormatListTable obj={{ ...OBJ, p0: "S" }} index={props.index} />
                                                                                </>
                                                                            ),
                                                                            open: true,
                                                                            size: "sm",
                                                                            position: "right",
                                                                        })
                                                                    }
                                                                    else {
                                                                        handleClose()
                                                                    }

                                                                })
                                                            },
                                                            body: (
                                                                <>
                                                                    <FormatListTable obj={{ ...OBJ, p0: "W" }} index={props.index} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            onClose: () => {
                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                    if (OSV._ONLINEWAP == "Y" || OSV._ONLINEWAP == "L") {
                                                        setDrawerObj({
                                                            ...dra,
                                                            title: <Text fw={700}>Whatsapp Format</Text>,
                                                            onClickCloseButton: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                                                        setDrawerObj({
                                                                            ...dra,
                                                                            title: <Text fw={700}>SMS Format</Text>,
                                                                            onClickCloseButton: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            onClose: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            body: (
                                                                                <>
                                                                                    <FormatListTable obj={{ ...OBJ, p0: "S" }} index={props.index} />
                                                                                </>
                                                                            ),
                                                                            open: true,
                                                                            size: "sm",
                                                                            position: "right",
                                                                        })
                                                                    }
                                                                    else {
                                                                        handleClose()
                                                                    }

                                                                })
                                                            },
                                                            onClose: () => {
                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                    if (OSV._ONLINESMS == "Y" || OSV._ONLINESMS == "L") {
                                                                        setDrawerObj({
                                                                            ...dra,
                                                                            title: <Text fw={700}>SMS Format</Text>,
                                                                            onClickCloseButton: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            onClose: () => {
                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
                                                                                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                                                                                        setDrawerObj({
                                                                                            ...dra,
                                                                                            title: <Text fw={700}>Print Vocuher</Text>,
                                                                                            onClickCloseButton: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            onClose: () => {
                                                                                                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => handleClose())
                                                                                            },
                                                                                            body: (
                                                                                                <>
                                                                                                    <PLPrint data={OBJ} />
                                                                                                </>
                                                                                            ),
                                                                                            open: true,
                                                                                            size: "sm",
                                                                                            position: "right",
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        handleClose()
                                                                                    }
                                                                                })
                                                                            },
                                                                            body: (
                                                                                <>
                                                                                    <FormatListTable obj={{ ...OBJ, p0: "S" }} index={props.index} />
                                                                                </>
                                                                            ),
                                                                            open: true,
                                                                            size: "sm",
                                                                            position: "right",
                                                                        })
                                                                    }
                                                                    else {
                                                                        handleClose()
                                                                    }

                                                                })
                                                            },
                                                            body: (
                                                                <>
                                                                    <FormatListTable obj={{ ...OBJ, p0: "W" }} index={props.index} />
                                                                </>
                                                            ),
                                                            open: true,
                                                            size: "sm",
                                                            position: "right",
                                                        })
                                                    }
                                                    else {
                                                        handleClose()
                                                    }
                                                })
                                            },
                                            body: (
                                                <>
                                                    <FormatListTable obj={{ ...OBJ, p0: "E" }} index={props.index} />
                                                </>
                                            ),
                                            open: true,
                                            size: "sm",
                                            position: "right",
                                        })
                                    })
                            }
                            // else {
                            //     handleClose()
                            // }

                        }
                        else if (data.status === 'FAIL') {
                            Promise.resolve(dispatch(DataSaveLoading(false)))
                            GlobalClass.Notify("error", data?.status, data?.message);
                        }
                    })
                    .catch((e) => {
                        Promise.resolve(dispatch(DataSaveLoading(false)))
                        GlobalClass.Notify("error", "Error", `${e?.message}`);
                    });
            }

        }
        else {
            GlobalClass.Notify("warning", "Opposite Account is not selected")
        }
    }

    const dateFormatter = () => {
        // let date = new Date(dYWDate)
        let date = new Date()
        let year = date.toLocaleString('en-IN', { year: 'numeric' })
        let month = date.toLocaleString('en-IN', { month: '2-digit' })
        let day1 = date.toLocaleString('en-IN', { day: '2-digit' })
        let startDate = year + month + day1;
        return startDate
    }

    function dateFormatter2(date) {
        let year = date.toLocaleString("en-IN", { year: "numeric" });
        let month = date.toLocaleString("en-IN", { month: "2-digit" });
        let day = date.toLocaleString("en-IN", { day: "2-digit" });
        let date1 = year + month + day;
        return date1;
    }

    // useEffect(() => {
    //     console.log("DrawerObj", DrawerObj);
    // }, [DrawerObj])

    const BilltoBillBtn = () => {
        setDrawerObj({
            ...dra, title: <Text fw={700}>Bill to Bill</Text>,
            // onClickCloseButton: () => setDrawerObj({ ...DrawerObj, open: false }),
            body: <>
                <PLBillToBill
                    T41ObjSend={T41}
                    T05Format={state?.T05ENT}
                    T05UserField={state?.UFT05H}
                    T05Data={T05DataArray}
                    setT05Data={(e) => setT05DataArray(e)}
                />
            </>, open: true, size: "50%", position: "right"
        })
    }

    let ExpenseObj = {
        OS11,
        OSV,
        oYear,
        M01SP: ExpenseData?.M01SP,
        M01SPDT: ExpenseData?.M01SPDT,
        dtM14DT,
        dtM51DT,
        dtM52DT,
        dtM46DT,
        dtExp: ExpenseData?.dtExp,
        dtExpDef: ExpenseData?.dtExpDef,
        dtExp_I: ExpenseData?.dtExp_I,
        dtExpDef_I: ExpenseData?.dtExpDef_I,
        InvTypeDropDown,
        // ItemCode: selectedProductObj?.FIELD03,
        // selectedProductObj,
        // T02Obj: { ...T02Obj, VorC: T41Obj?.FIELD45 },
        // T02Data
        T41Obj,
    }

    useEffect(() => {

        const formElement = formRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }
        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef]);

    useEffect(() => {

        const formElement = userFieldRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
        }
        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
            }
        };
    }, [userFieldRef]);

    return (
        <div ref={formRef}>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: (e) => {
                        if (typeof DrawerObj?.onClickCloseButton == "function") {
                            DrawerObj?.onClickCloseButton();
                        }
                        else {
                            setDrawerObj(dra);
                        }
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>
            {
                hasError ?
                    <>
                        <Text>{ErrorMsg?.message}</Text>
                        <Button
                            onClick={() => {
                                if (OBJ) {
                                    dispatch(TransactionApi(OBJ)).then(() => {
                                        dispatch(GetAccountListForPrint(obj1))
                                        setHasFocused("yes")
                                    });
                                }
                            }}
                        >
                            Retry
                        </Button>
                    </>
                    :
                    (isLoading || SaveLoading ) ? GlobalClass.RenderLoader("dots") :
                        <div>
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>Bank/Cash</Text>
                                        </Grid.Col>
                                        <Grid.Col span={7}>
                                            <PLDataGrid width={500} height={"28px"} ref={inputRef} value={{ value: T41?.FIELD05, label: T41?.FIELD05NM }}
                                                TmData={state?.M01BA}
                                                // data={state?.M01BADT?.jData}
                                                setEdit={(e) => {
                                                    // console.log("e", e);
                                                    e && setT41({ ...T41, FIELD05: e.FIELD01, FIELD05NM: e.FIELD02 })
                                                    setBankCashBalance(e?.FIELD99)
                                                    setselectAccount(e.FIELD07)
                                                    setField(false)
                                                    // setState((prevState) => ({
                                                    //     ...prevState,
                                                    //     T41: {
                                                    //         ...prevState?.T41,
                                                    //         FIELD05: e.FIELD01
                                                    //     }
                                                    // }))
                                                    myComboBox && myComboBox.focus()
                                                }}

                                            />
                                            {/*Data Table*/}
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={2}>
                                            <Text style={{ fontSize: 12 }}>Inv Type</Text>
                                        </Grid.Col>
                                        <Grid.Col span={7}>
                                            {
                                                (T41?.lInvType === true) ?
                                                    // && M45IDT?.length > 0
                                                    <PLDataGrid ref={invTypeRef} width={500} height={"28px"} value={{ value: T41?.FIELD13, label: T41?.M45F02 }}
                                                        TmData={M45I}
                                                        cWhere={`M45.FIELD21 = '${cVType}' AND M45.FIELD32 = 'T' AND M45.FIELD12 = 0 AND M45.FIELD09 = 'E' AND  M45.FIELD20 != '3' AND M45.FIELD20 != '4' `}
                                                        // cWhere={`M45.FIELD21 LIKE '%${cVType}%' AND M45.FIELD32 LIKE '%T%' AND M45.FIELD12 LIKE '%0%' AND M45.FIELD09 LIKE '%E%'`}
                                                        // data={M45IDT}
                                                        setEdit={(e) => {
                                                            // Promise.resolve(setState((prevState) => ({
                                                            //     ...prevState,
                                                            //     T41: {
                                                            //         ...prevState.T41,
                                                            //         FIELD13: e.FIELD01
                                                            //     }
                                                            // })))
                                                            Promise.resolve(setT41({ ...T41, FIELD13: e.FIELD01, M45F02: e.FIELD02 }))
                                                                .then(() => dispatch(GetInvTypeDropDown(e)))
                                                                .then(() => dispatch(GetExpenseData({ action: "A", code: cCode, invCode: e.FIELD01 })))
                                                                .then(() => dateRef?.current?.focus())
                                                        }}
                                                        onKeyDown={(e) => {
                                                            // if (e.key == "Enter" || e.key == "Tab") {
                                                            // console.log("dateRef", dateRef);
                                                            // }
                                                            // GlobalClass.formNextInput(e, formRef)
                                                        }}
                                                        handleClose={() => { }} />
                                                    : <PLTextBox disabled />
                                            }
                                        </Grid.Col>
                                    </Grid.Col>

                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}></Grid.Col>
                                        <Grid.Col span={8} style={{ display: 'flex' }} py={0}>
                                            <Text style={{ fontSize: 12 }}>Balance</Text>
                                            <Text style={{ marginLeft: 60, fontSize: 12 }}>
                                                {
                                                    bankCashBalance !== null ?
                                                        (() => {
                                                            const balanceStr = bankCashBalance?.toString();
                                                            const hasSuffix = / CR| DB$/.test(balanceStr);
                                                            const suffix = bankCashBalance > 0 ? " CR" : " DB";
                                                            return hasSuffix ? balanceStr ? balanceStr : "" : balanceStr && suffix && `${balanceStr}${suffix}`;
                                                        })()
                                                        : null
                                                }
                                                {/* {bankCashBalance ? bankCashBalance + (OBJ.p0 = "E"  ? "" : (bankCashBalance > 0 ? " CR" : " DB")) : null } */}

                                            </Text>
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={2} py={0}>
                                            <Text style={{ fontSize: 12 }}>Date</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4} py={0}>
                                            <PLDateBox ref={dateRef} height={"28px"}
                                                defaultval={f02 || props?.obj?.p0 == "E" ? T41?.FIELD02 : dateFormatter2(footerDate)}
                                                dispformat="DD/MM/YYYY"
                                                setEdit={(e) => {
                                                    // console.log("e", e);
                                                    let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                    let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                    let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                    let startDate = year + month + day;

                                                    if (startDate?.length == 8) {
                                                        setF02(true);
                                                        setT41({ ...T41, FIELD02: startDate })
                                                    }
                                                    // setState((prevState) => ({
                                                    //     ...prevState,
                                                    //     T41: {
                                                    //         ...prevState.T41,
                                                    //         FIELD02: startDate
                                                    //     }
                                                    // }))
                                                    setDay(e.getDay())
                                                    // console.log("numberInputRef i", numberInputRef);
                                                    // console.log("numberRef", numberRef);
                                                    if (numberRef?.current) {
                                                        numberRef?.current?.handlePopoverToggle()
                                                    }
                                                    else {
                                                        // console.log("numberInputRef", numberInputRef);
                                                        // console.log("numberRef",numberRef);
                                                        // numberInputRef?.current?.focus()
                                                        const numberInputRef = document.getElementById('numberInputRef');
                                                        numberInputRef && numberInputRef?.focus()
                                                    }

                                                }} />
                                        </Grid.Col>
                                        <Grid.Col span={4} py={0}>
                                            <Text style={{ marginLeft: 30, fontSize: 12 }}>
                                                {day}
                                            </Text>
                                        </Grid.Col>
                                    </Grid.Col>

                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4} py={0}>
                                            <Text style={{ fontSize: 12 }}>Rcpt/Pytm</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4} py={0}>
                                            <PLComboBox cmbid="#myComboBox" value={T41?.FIELD16}
                                                data={state?.COP99}
                                                dispexpr="DisplayMember"
                                                valexpr="ValueMember"
                                                setEdit={(e) => {
                                                    setT41({ ...T41, FIELD16: e })
                                                    setField(false)
                                                    // setState(prevState => ({
                                                    //     ...prevState,
                                                    //     T41: {
                                                    //         ...prevState.T41,
                                                    //         FIELD16: e
                                                    //     }
                                                    // }))
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key == "Enter" || e.key == "Tab") {
                                                        invTypeRef?.current?.handlePopoverToggle();
                                                    }
                                                    // GlobalClass.formNextInput(e, formRef)
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={2}>
                                            <Text style={{ fontSize: 12 }}>Number</Text>
                                        </Grid.Col>

                                        <Grid.Col span={10}>
                                            {
                                                state?.OSV?._VOUNUMBER == "Y" ?
                                                    (
                                                        OBJ.p0 == "E" ? (
                                                            <PLTextBox id="numberInputRef"
                                                                onKeyDown={(e) => {
                                                                    GlobalClass.formNextInput(e, formRef);
                                                                }}
                                                                setText={(e) => {
                                                                    if (e?.target?.value?.length > 0) {
                                                                        if (accList[OBJ?.page] && accList[OBJ?.page].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                                                            console.log("Duplicate Value log call");
                                                                            GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                            setBillNoStatus(true)
                                                                        } else {
                                                                            setBillNoStatus(false)
                                                                        }
                                                                    } else {
                                                                        setBillNoStatus(false)
                                                                    }
                                                                }}
                                                                value={T41?.FIELD12}
                                                                setEdit={(e) => {
                                                                    setT41({ ...T41, T92F00: "", FIELD12: e.target.value });
                                                                }}
                                                            />
                                                        ) :
                                                            (!state?.T41?.T92F00) ? (
                                                                <div style={{ position: 'relative' }}>
                                                                    {NumberInput?.DataGridInput == true && (
                                                                        <PLDataGrid ref={numberRef} height={"28px"}
                                                                            width={"500px"}
                                                                            position={"unset"}
                                                                            valexpr={"FIELD00"}
                                                                            value={NumberInput?.NumberVal}
                                                                            TmData={state?.T92}
                                                                            // data={state?.T92DT}
                                                                            onKeyDown={(e) => {
                                                                                GlobalClass.formNextInput(e, formRef);
                                                                            }}
                                                                            setEdit={(e) => {
                                                                                // console.log("Oppaccount", e);
                                                                                Promise.resolve(setT41({ ...T41, T92F00: e?.FIELD00, FIELD12: "" })).then(() => {
                                                                                    if (e) {
                                                                                        let letr = e.FIELD02
                                                                                        // console.log("num=>", num);
                                                                                        let num = parseInt(e.FIELD03) + 1
                                                                                        setNumberInput({ ...NumberInput, DataGridInput: false, TextInput: true, NumberVal: letr + num, });
                                                                                    }
                                                                                }).then(() => oppAccRef?.current?.handlePopoverToggle())

                                                                            }}

                                                                        />
                                                                    )}
                                                                    {NumberInput?.TextInput == true && (
                                                                        <PLTextBox id="numberInputRef"
                                                                            value={NumberInput?.NumberVal}
                                                                            setText={(e) => {
                                                                                // console.log("bill number setText 2", [{
                                                                                //   "e": e,
                                                                                //   "value": e?.target?.value
                                                                                // }]);
                                                                                if (e?.target?.value?.length > 0) {
                                                                                    if (accList[OBJ?.page] && accList[OBJ?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                                        console.log("Duplicate Value log call");
                                                                                        GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                                        setBillNoStatus(true)
                                                                                    } else {
                                                                                        setBillNoStatus(false)
                                                                                    }
                                                                                } else {
                                                                                    setBillNoStatus(false)
                                                                                }
                                                                                if (NumberInput?.NumberVal.length <= 0) {
                                                                                    setNumberInput({ ...NumberInput, DataGridInput: true, TextInput: false })
                                                                                }
                                                                            }}
                                                                            setEdit={(e) => {
                                                                                setNumberInput({ ...NumberInput, NumberVal: e.target.value });
                                                                                setT41({ ...T41, T92F00: "", FIELD12: e.target.value });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <PLTextBox id="numberInputRef"
                                                                    value={T41?.FIELD12}
                                                                    setText={(e) => {
                                                                        // console.log("bill number setText 1", [{
                                                                        //   "e": e,
                                                                        //   "value": e?.target?.value
                                                                        // }]);
                                                                        if (e?.target?.value?.length > 0) {
                                                                            if (accList[OBJ?.page] && accList[OBJ?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                                                console.log("Duplicate Value log call");
                                                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                                                setBillNoStatus(true)
                                                                            } else {
                                                                                setBillNoStatus(false)
                                                                            }
                                                                        } else {
                                                                            setBillNoStatus(false)
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        GlobalClass.formNextInput(e, formRef);
                                                                    }}
                                                                    setEdit={(e) => {
                                                                        //  setNumberInput({ ...NumberInput, NumberVal: e.target.value });
                                                                        setT41({ ...T41, T92F00: "", FIELD12: e.target.value });
                                                                    }}
                                                                />
                                                            )
                                                    ) :
                                                    (<PLTextBox disabled />)
                                            }
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                            </Grid>

                            <hr style={{ marginTop: 40 }} />

                            <Grid>

                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>Opp.A/c</Text>
                                        </Grid.Col>
                                        <Grid.Col span={7} style={{ paddingTop: '25px' }}>
                                            {/* <Grid.Col span={12} > */}
                                            <PLDataGrid ref={oppAccRef} width={500} height={"28px"} value={{ value: T41?.FIELD04, label: T41?.FIELD04NM }}
                                                TmData={state?.M01OA}
                                                // data={state?.M01OADT?.jData}
                                                setEdit={(e) => {
                                                    console.log("oppo acc e", e);
                                                    setT41({ ...T41, FIELD04: e.FIELD01, FIELD04NM: e.FIELD02 })
                                                    setOppAccountBalance(e?.FIELD99)
                                                    setOppAccountSelectedValue(e)
                                                    setField(true)
                                                    if (e.FIELD08 == "2") {
                                                        setIsBillToBill(true)
                                                    }
                                                    else {
                                                        setIsBillToBill(false)
                                                    }

                                                    // setState((prevState) => ({
                                                    //     ...prevState,
                                                    //     T41: {
                                                    //         ...prevState.T41,
                                                    //         FIELD04: e.FIELD01
                                                    //     }
                                                    // }))
                                                    // handleOppAcSelection(e)
                                                    // functionTwo(e)

                                                }}
                                                handleClose={() => { }}
                                            />
                                            {/* </Grid.Col> */}

                                        </Grid.Col>
                                        <Grid.Col>
                                            <Text style={{ fontSize: 12 }}>INR-₹<br />@</Text>
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid.Col span={4} ml={90}>
                                            <Text style={{ fontSize: 12 }}>Party Amount ₹</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLNumberBox id="partyAmtRef" value={T41?.FIELD07}
                                                decimalSeparator={true}
                                                precision={2}
                                                setEdit={(e) => {
                                                    setT41({ ...T41, FIELD07: e })
                                                    // setState((prevState) => ({
                                                    //     ...prevState,
                                                    //     T41: {
                                                    //         ...prevState.T41,
                                                    //         FIELD07: e
                                                    //     }
                                                    // }))
                                                }}

                                                onKeyDown={(e) => {
                                                    if (e.key == "Tab" || e.key == "Enter") {

                                                        const oldAmt = OBJ?.p0 === "E" ? T41?.FIELD08 : 0

                                                        let oM01 = accListData?.find(item => item?.FIELD01 == T41?.FIELD04)

                                                        if (TransactionData?.OSV?._NGBALWARN != "N" && oM01?.FIELD06 != "G000016A") {
                                                            let nBal = (oM01?.FIELD99 + (OBJ?.p0 === "E" ? oldAmt * -1 : 0)) - T41?.FIELD07 * (T41?.FIELD16 == "P" ? -1 : 1)
                                                            // console.log("nBal", nBal)
                                                            if (nBal > 0) {
                                                                let message = `Negative Balance Of ${oM01?.FIELD02?.trim()}` + nBal;
                                                                // message && setMessage(message)
                                                                // console.log("message", message);
                                                                setDrawerObj({
                                                                    ...dra,
                                                                    title: <Text fw={700}>Warning</Text>,
                                                                    body: (
                                                                        <>
                                                                            {
                                                                                message &&
                                                                                <div>
                                                                                    <Text style={{ fontSize: 14 }}>{message}</Text>
                                                                                    <Center><Button onClick={negBalPopupOkBtn}>Ok</Button></Center>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    ),
                                                                    open: true,
                                                                    size: "md",
                                                                    position: "right",
                                                                });
                                                            }

                                                        }

                                                    }

                                                }}
                                                setNumber={() => {

                                                    const oldAmt = OBJ?.p0 === "E" ? T41?.FIELD08 : 0

                                                    let oM01 = accListData?.find(item => item?.FIELD01 == T41?.FIELD04)

                                                    if (TransactionData?.OSV?._NGBALWARN != "N" && oM01?.FIELD06 != "G000016A") {
                                                        let nBal = (oM01?.FIELD99 + (OBJ?.p0 === "E" ? oldAmt * -1 : 0)) - T41?.FIELD07 * (T41?.FIELD16 == "P" ? -1 : 1)
                                                        // console.log("nBal", nBal)
                                                        if (nBal > 0) {
                                                            let message = `Negative Balance Of ${oM01?.FIELD02?.trim()}` + nBal;
                                                            // message && setMessage(message)
                                                            // console.log("message", message);
                                                            setDrawerObj({
                                                                ...dra,
                                                                title: <Text fw={700}>Warning</Text>,
                                                                body: (
                                                                    <>
                                                                        {
                                                                            message &&
                                                                            <div>
                                                                                <Text style={{ fontSize: 14 }}>{message}</Text>
                                                                                <Center><Button onClick={negBalPopupOkBtn}>Ok</Button></Center>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ),
                                                                open: true,
                                                                size: "md",
                                                                position: "right",
                                                            });
                                                        }

                                                    }

                                                    setDB(T41?.FIELD07)
                                                    // console.log("onblur ExpenseData?.dtExp",ExpenseData?.dtExp);
                                                    // console.log("onblur T41?.FIELD07",T41?.FIELD07);

                                                    if (!ExpenseData?.dtExp && T41?.FIELD07) {
                                                        // console.log("onblur",T41?.FIELD07);
                                                        setT41({ ...T41, FIELD06: T41?.FIELD07 })
                                                    }
                                                }}
                                            />
                                        </Grid.Col >
                                        <Grid.Col span={2}><Text style={{ fontSize: 12 }}>{T41?.FIELD16 == "R" ? "CR" : "DB"}</Text></Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                    <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}></Grid.Col>
                                        <Grid.Col span={8} style={{ display: 'flex' }}>
                                            <Text style={{ fontSize: 12 }}>Balance</Text>
                                            <Text style={{ fontSize: 12, marginLeft: '10px' }}>
                                                {
                                                    oppAccountBalance !== null ?
                                                        (() => {
                                                            const balanceStr = oppAccountBalance?.toString();
                                                            const hasSuffix = / CR| DB$/.test(balanceStr);
                                                            const suffix = oppAccountBalance > 0 ? " CR" : " DB";
                                                            return hasSuffix ? balanceStr ? balanceStr : "" : balanceStr && suffix && `${balanceStr}${suffix}`;
                                                        })()
                                                        : null
                                                }
                                                {/* {
                                                
                                                oppAccountBalance ? oppAccountBalance +( OBJ.p0 = "E" ? "" :( oppAccountBalance > 0 ? " CR": " DB")): null
                                            } */}
                                            </Text>
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                    {ExpenseData?.dtExp &&
                                        <ExpenseEntryForm
                                            // ProductObj={selectedProductObj}
                                            isExpense={false}
                                            expenseArr={ExpenseData?.dtExp}
                                            // expenseArrDef={ExpenseData?.dtExpDef}
                                            // M01PData={state?.M01BADT?.jData}
                                            // M01Format={state?.M01BA}//Not Needed
                                            valueSaveObj={T41}
                                            TableExpenseValue={T41?.FIELD07}
                                            // TableExpenseArr={T02Data}//Not Needed
                                            onChange={(e) => {
                                                // console.log("ONCHANGEe", e);
                                                // setT41(e)
                                                // setFooterExpenseObj(e)
                                                // setExpenseRowSum(e.FIELD07)
                                                // console.log("TableExpenseEntry=>", e);
                                                // if (e?.ObjT41?.FIELD06) {
                                                // setT41(e?.ObjT41)          
                                                new Promise((resolve) => {
                                                    let dt = MidCalc({ ...ExpenseObj, T41Obj: e?.ObjT41 })
                                                    resolve(dt)
                                                }).then((res) => {
                                                    setT41(res?.T41Object)
                                                })
                                                // } // if expense is there 
                                                // else {
                                                //     setT41({ ...T41, FIELD06: e?.ObjT41?.FIELD07 })
                                                // }

                                                setT41Flag(true)
                                            }}
                                            DrawerOBJ={DrawerObj}
                                            setDrawerOBJ={(e) => {
                                                // console.log("setDrawerOBJ");
                                                setDrawerObj(e)
                                            }
                                            }
                                        />
                                    }
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: 'flex', marginTop: 80 }}>
                                    <Grid.Col span={6}>
                                        {/* <Button variant='light' onClick={BilltoBillBtn}>Bill To Bill</Button> */}
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex' }} >
                                        <Grid.Col span={4} ml={90}>
                                            <Text style={{ fontSize: 12 }}>Bank Amount ₹</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            {/* <PLTextBox value={DB}
                                            // setEdit={(e) => {
                                            //     setState((prevState) => ({
                                            //         ...prevState,
                                            //         T41: {
                                            //             ...prevState.T41,
                                            //             FIELD06: e.target.value
                                            //         }
                                            //     }))
                                            // }}
                                            disabled
                                        /> */}
                                            <PLNumberBox decimalSeparator={true}
                                                precision={2} value={T41?.FIELD06} disabled={true} />
                                        </Grid.Col>
                                        <Grid.Col span={2}><Text style={{ fontSize: 12 }}>{T41?.FIELD16 == "P" ? "CR" : "DB"}</Text></Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                            </Grid>

                            <hr />

                            <Grid>

                                <Grid.Col span={6}>
                                    {
                                        (OBJ.page == "04740462" || OBJ.page == "04860462") && state?.OSV?.CHQNMBP == "Y" &&
                                        <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                            <Grid.Col span={4} py={4}><Text style={{ fontSize: 12 }}>Cheque Name</Text></Grid.Col>
                                            <Grid.Col span={8} py={4}>
                                                <PLTextBox value={T41?.FIELD53}
                                                    setEdit={(e) => {
                                                        setT41({ ...T41, FIELD53: e.target.value })

                                                        // setState((prevState) => ({
                                                        //     ...prevState,
                                                        //     T41: {
                                                        //         ...prevState.T41,
                                                        //         FIELD53: e.target.value
                                                        //     }
                                                        // }))
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }

                                    <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                        <Grid.Col span={4} py={4}><Text style={{ fontSize: 12 }}>{(OBJ.page === "04740462" || OBJ.page === "04770462") ? "chq/DD No" : "Doc.No."}</Text></Grid.Col>
                                        <Grid.Col span={5} py={4}>
                                            {
                                                state?.OSV?._DOCNODATE && state?.OSV?._DOCNODATE == "N" ?
                                                    <PLTextBox disabled /> :
                                                    <PLTextBox id='MyInputBox' value={T41?.FIELD10}
                                                        setEdit={(e) => {
                                                            setT41({ ...T41, FIELD10: e.target.value })
                                                            // setState((prevState) => ({
                                                            //     ...prevState,
                                                            //     T41: {
                                                            //         ...prevState.T41,
                                                            //         FIELD10: e.target.value
                                                            //     }
                                                            // }))
                                                        }}
                                                    />
                                            }
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                        <Grid.Col span={4} py={4}><Text style={{ fontSize: 12 }}>{(OBJ.page === "04740462" || OBJ.page === "04770462") ? "chq/DD Date" : "Doc. Date"}</Text></Grid.Col>
                                        <Grid.Col span={4} py={4}>
                                            {
                                                state?.OSV?._DOCNODATE && state?.OSV?._DOCNODATE == "N" ?
                                                    <PLDateBox dispformat="DD/MM/YYYY" disabled height={"28px"} />
                                                    : (T41?.FIELD11?.trim()) ?
                                                        <PLDateBox height={"28px"}
                                                            defaultval={T41?.FIELD11}
                                                            dispformat="DD/MM/YYYY"
                                                            setEdit={(e) => {
                                                                // console.log("DATE", e);
                                                                let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                                let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                                let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                                let startDate = year + month + day;

                                                                if (startDate?.length == 8) {
                                                                    setT41({ ...T41, FIELD11: startDate })
                                                                }
                                                                // setState((prevState) => ({
                                                                //     ...prevState,
                                                                //     T41: {
                                                                //         ...prevState.T41,
                                                                //         FIELD11: startDate
                                                                //     }
                                                                // }))
                                                            }}
                                                        />
                                                        :
                                                        (<PLDateBox height={"28px"}
                                                            isVal={false}
                                                            dispformat="DD/MM/YYYY"
                                                            setEdit={(e) => {
                                                                // console.log("DATE", e);
                                                                let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                                                let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                                                let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                                                let startDate = year + month + day;
                                                                setT41({ ...T41, FIELD11: startDate })
                                                                // setState((prevState) => ({
                                                                //     ...prevState,
                                                                //     T41: {
                                                                //         ...prevState.T41,
                                                                //         FIELD11: startDate
                                                                //     }
                                                                // }))
                                                            }}
                                                        />)

                                            }
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Button onClick={costCenter} m={30} raduius="xs" size='xs'><Text style={{ fontSize: 12 }}>Cost Center</Text></Button>
                                        {isBillToBill && <Button onClick={BilltoBillBtn} variant='light' raduius="xs" size='sm'><Text style={{ fontSize: 12 }}>Bill To Bill</Text></Button>}
                                    </Grid.Col>

                                    <Grid.Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Button m={30} raduius="xs" size='xs'> <Text style={{ fontSize: 12 }}>Check Balance</Text></Button>
                                        <Button raduius="xs" size='xs'><Text style={{ fontSize: 12 }}>Check Statement</Text></Button>
                                    </Grid.Col>

                                </Grid.Col>

                                <Grid.Col span={6}>
                                    {
                                        (OBJ.page == "04740462" || OBJ.page == "04860462") && state?.OSV?.CHQRETURN == "Y" &&
                                        <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                            <Grid.Col span={4} py={4}><Text style={{ fontSize: 12 }}>Cheque Return</Text></Grid.Col>
                                            <Grid.Col span={6} py={4}>
                                                <PLComboBox value={T41?.FIELD33}
                                                    data={state?.COP01}
                                                    dispexpr="DisplayMember"
                                                    valexpr="ValueMember"
                                                    setEdit={(e) => {
                                                        setT41({ ...T41, FIELD33: e })
                                                        // setState(prevState => ({
                                                        //     ...prevState,
                                                        //     T41: {
                                                        //         ...prevState.T41,
                                                        //         FIELD33: e
                                                        //     }
                                                        // }))
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    }

                                    <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                        <Grid.Col span={12} py={4} ><Text style={{ fontSize: 12 }}>Narration</Text></Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={8}>
                                        {/* <PLTextBox value={state?.T41?.FIELD15} 
                                        setEdit={(e) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                T41: {
                                                    ...prevState.T41,
                                                    FIELD15: e.target.value
                                                }
                                            }))
                                        }}
                                    /> */}
                                        <Textarea value={T41?.FIELD15}
                                            onChange={(e) => {
                                                setT41({ ...T41, FIELD15: e.target.value })
                                                // setState((prevState) => ({
                                                //     ...prevState,
                                                //     T41: {
                                                //         ...prevState.T41,
                                                //         FIELD15: e.target.value
                                                //     }
                                                // }))
                                            }} onKeyDown={(e) => {
                                                // console.log("Narration=>", e);
                                                if (e.ctrlKey && e.key === "F1") {
                                                    //   console.log("Narration call");

                                                    setDrawerObj({
                                                        ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                                            <PLNarration
                                                                NarrationArray={NarrationData}
                                                                selectedNarrationArray={
                                                                    SelectedNarrationData
                                                                }
                                                                // NarrationVal={T41Obj}
                                                                setNarrationArray={(e) => {
                                                                    // setT41Obj(e)
                                                                    // console.log("setNarrationArray", e);
                                                                    setNarrationData(e.NarrationData);
                                                                    setSelectedNarrationData(
                                                                        e.selectedNarration
                                                                    );
                                                                }}
                                                                DrawerOBJ={DrawerObj}
                                                                setDrawerOBJ={(e) => setDrawerObj(e)}
                                                            />
                                                        </>, open: true, size: "50%", position: "right"
                                                    })

                                                    // modals.open({
                                                    //   title: "Naration",
                                                    //   size: "50%",
                                                    //   zIndex: 301,
                                                    //   children: (
                                                    //     <>
                                                    //       <PLNarration
                                                    //         NarrationArray={NarrationData}
                                                    //         selectedNarrationArray={
                                                    //           SelectedNarrationData
                                                    //         }
                                                    //         // NarrationVal={T41Obj}
                                                    //         setNarrationArray={(e) => {
                                                    //           // setT41Obj(e)
                                                    //           console.log("setNarrationArray", e);
                                                    //           setNarrationData(e.NarrationData);
                                                    //           setSelectedNarrationData(
                                                    //             e.selectedNarration
                                                    //           );
                                                    //         }}
                                                    //       />
                                                    //     </>
                                                    //   ),
                                                    // });
                                                }
                                            }}
                                        // onKeyDown={(e) => {
                                        //     // console.log("Narration=>", e);
                                        //     if (e.ctrlKey && e.key === "F1") {
                                        //         //console.log("Narration call");
                                        //         // modals.open({
                                        //         //   title: "Naration",
                                        //         //   size: "50%",
                                        //         //   zIndex: 301,
                                        //         //   children: (
                                        //         //     <>
                                        //         //       <PLNarration
                                        //         //         // NarrationVal={T41Obj}
                                        //         //         // NarrationValObj={(e) => {
                                        //         //         //     setT41Obj(e)
                                        //         //         // }}
                                        //         //         NarrationArray={NarrationData}
                                        //         //         selectedNarrationArray={SelectedNarrationData}
                                        //         //         // NarrationVal={T41Obj}
                                        //         //         setNarrationArray={(e) => {
                                        //         //           // setT41Obj(e)
                                        //         //           // console.log("setNarrationArray", e);
                                        //         //           setNarrationData(e.NarrationData);
                                        //         //           setSelectedNarrationData(e.selectedNarration);
                                        //         //         }}
                                        //         //       />
                                        //         //     </>
                                        //         //   ),
                                        //         // });
                                        //         setDrawerObj({
                                        //             ...dra,
                                        //             title: <Text fw={700}>Naration</Text>,
                                        //             body: (
                                        //                 <>
                                        //                     <PLNarration
                                        //                         // NarrationVal={T41Obj}
                                        //                         // NarrationValObj={(e) => {
                                        //                         //     setT41Obj(e)
                                        //                         // }}
                                        //                         NarrationArray={NarrationData}
                                        //                         selectedNarrationArray={SelectedNarrationData}
                                        //                         // NarrationVal={T41Obj}
                                        //                         setNarrationArray={(e) => {
                                        //                             // setT41Obj(e)
                                        //                             // console.log("setNarrationArray", e);
                                        //                             setNarrationData(e.NarrationData);
                                        //                             setSelectedNarrationData(e.selectedNarration);
                                        //                         }}
                                        //                         DrawerOBJ={DrawerObj}
                                        //                         setDrawerOBJ={(e) => {
                                        //                             setDrawerObj(e);
                                        //                         }}
                                        //                     />
                                        //                 </>
                                        //             ),
                                        //             open: true,
                                        //             size: "sm",
                                        //             position: "right",
                                        //         });
                                        //     }
                                        // }}


                                        />
                                    </Grid.Col>

                                    <Grid.Col span={12} style={{ display: 'flex' }}>
                                        <Grid.Col span={3}><Button raduius="xs" size='xs' onClick={okBtn} fullWidth>
                                            <Text style={{ fontSize: 12 }}>
                                                Ok</Text>
                                        </Button></Grid.Col>
                                        <Grid.Col span={3}><Button raduius="xs" size='xs' fullWidth><Text style={{ fontSize: 12 }}>Print</Text></Button></Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>

                            </Grid>
                            {
                                TransactionData?.lEditVou == false && <>{GlobalClass.Alert("info", props.index)}</>
                            }
                        </div>
            }
        </div>
    )

}





















