import { useEffect } from "react";
import Store from "../../utils/store";
import { TabAdd } from "../../utils/slices/TabList";

export function SLED(d) {
    const data = JSON.parse(d);
    console.log("SLED data=>", [{
        "data":data,
        'data.p0[0]':data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01':data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);
    // console.log("data", data.DataGridRow.FIELD01);

    let obj;
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: data.p0,
        p1: data.p0,
        p2: "TFORM0000001",
        OParam: data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01,
        StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
        EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    }
    // console.log("SLED data 2=>",obj);
    // switch (data?.p0) {
       
    //     case "PRODLEDGER":
    //         obj = {...obj,OParam: "PCODE~K~" + data.DataGridRow.FIELD01}
    //         break;
    //     case "ASLEDGER":
    //         obj = {
    //             ...obj,
    //             OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         }
    //         break;
    //     default:
    //         obj = {}
    //         //  {
    //         //     name: data.DataGridRow.FIELD02,
    //         //     type: "A",
    //         //     id: data.id,
    //         //     p0: data.p0,
    //         //     p1: data.p0,
    //         //     p2: "TFORM0000001",
    //         //     OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         // }
    //         break;
    // }
    // const OBJ = JSON.parse(data);
    Store.dispatch(TabAdd(obj));
}

export function TM_ZINOUT(d){
    const data = JSON.parse(d);
    console.log("TM_ZINOUT data=>", [{
        "data":data,
        'data.p0[0]':data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01':data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);

    let obj;
    let p0 = data.id.charAt(0).toLowerCase().includes("p")?'PRODLEDGER':"ASLEDGER";
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: p0,
        p1: p0,
        p2: "TFORM0000001",
        OParam: (data.id.charAt(0).toLowerCase().includes("p")?"P": "A")+"CODE~K~" + data.DataGridRow.FIELD01,
        StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
        EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    }

    Store.dispatch(TabAdd(obj));

}

export function PLED_MAIN(d) {
    const data = JSON.parse(d);
    console.log("PLED_MAIN data=>", [{
        "data":data,
        'data.p0[0]':data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01':data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);
    // console.log("data", data.DataGridRow.FIELD01);

    let obj;
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: 'PRODLEDGER',
        p1: 'PRODLEDGER',
        p2: "TFORM0000001",
        OParam:  "PCODE~K~" + data.DataGridRow.FIELD01,
        StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
        EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    }
    // obj = {
    //     name: data.DataGridRow.FIELD02,
    //     type: "A",
    //     id: data.id,
    //     p0: data?.BtnPnlObj?.p0,
    //     p1: data?.BtnPnlObj?.p1,
    //     p2: data?.BtnPnlObj?.p2,
    //     OParam: data?.BtnPnlObj?.p0 + "CODE~K~" + data.id,
    //     StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
    //     EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    // }
    // console.log("SLED data 2=>",obj);
    // switch (data?.p0) {
       
    //     case "PRODLEDGER":
    //         obj = {...obj,OParam: "PCODE~K~" + data.DataGridRow.FIELD01}
    //         break;
    //     case "ASLEDGER":
    //         obj = {
    //             ...obj,
    //             OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         }
    //         break;
    //     default:
    //         obj = {}
    //         //  {
    //         //     name: data.DataGridRow.FIELD02,
    //         //     type: "A",
    //         //     id: data.id,
    //         //     p0: data.p0,
    //         //     p1: data.p0,
    //         //     p2: "TFORM0000001",
    //         //     OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         // }
    //         break;
    // }
    // const OBJ = JSON.parse(data);
    Store.dispatch(TabAdd(obj));
}

