import React, { useState } from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Loader, Text } from "@mantine/core";
import BillFormChallanModal from "./BillFormChallanModal";
import { GetNullTransactionData, TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import { GetInvTypeDropDown } from "../../../utils/TransactionSlices/TSalesSlices";
import { RefreshIOrderPopUp, RefreshIssuePopUp } from "../../../utils/TransactionSlices/PendingIssueSlice";

export function VouEntAct(props) {
  console.log(props, "VouEntAct--");

  const OBJ = JSON.parse(props);

  if (OBJ?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetInvTypeDropDown(null)))
            .then(() => Store.dispatch(RefreshIssuePopUp({})))
            .then(() => Store.dispatch(RefreshIOrderPopUp({})))
            .then(() => Store.dispatch(GetNullTransactionData()))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        tab:OBJ.page.startsWith("P_")?false:true,
        MSize: "100%",
        MBody: (i) => (
          <BillFormChallanModal
            obj={OBJ}
            index={i}
            No="Voucher No"
            date="Voucher Date"
          />

        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    );
  } else if (OBJ?.id && OBJ?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetInvTypeDropDown(null)))
            .then(() => Store.dispatch(RefreshIssuePopUp({})))
            .then(() => Store.dispatch(RefreshIOrderPopUp({})))
            .then(() => Store.dispatch(GetNullTransactionData()))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "100%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => <BillFormChallanModal obj={OBJ} index={i} No="Voucher No"
          date="Voucher Date" />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Store.dispatch(TransactionDeleteApi(OBJ));
    }
  } else if (OBJ.p0 === "U" && OBJ?.id) {
    console.log(OBJ?.id, "data?.id")

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "100%",
        // position:'right',
        MBody: (i) => <BillFormChallanModal obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
        onClickCloseButton: () => {

          Promise.all([
            Store.dispatch(RefreshIssuePopUp({})),
            Store.dispatch(RefreshIOrderPopUp({})),
          ]).then(() => Store.dispatch(GetNullTransactionData()));
        }
      })
    );
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
      Store.dispatch(TransactionDeleteApi(OBJ))
    }
  }
}
