import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const ProductPopUpDetail = (props) => {
    const { obj, value, setEditSend } = props
    console.log('ProductPopUpDetail_props=>>', props)
    const ProductDetailData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    // console.log('ProductDetailData=>>', ProductDetailData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const PD_PLUSM21DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.PD_PLUSM21DT)
    const PD_PLUSM21 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.PD_PLUSM21)
    // console.log('PD_PLUSM21DT=>>', PD_PLUSM21DT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    function extractFields(input) {
        let parts = input.split('||');
        // console.log("parts",parts)
        const lastElements = parts.map(item => item.split('##').pop());
        // console.log("lastElements",lastElements)

        PD_PLUSM21DT?.map((a) => {
            lastElements?.map((m , i) => {
                if (a["S34F02"].includes(m)) {
                    lastElements.splice(i, 1);
                    lastElements.push(a?.S34F02);
                }

            })
        })
        // console.log("lastElements", lastElements.join(','))
        return lastElements.join(',')
    }

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    ProductDetailData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        TmData={PD_PLUSM21}
                        data={PD_PLUSM21DT}
                        valexpr={"S34F02"}
                        dispexpr={"S34F03E"}
                        isMulti={true}
                        value={extractFields(value)}
                        setEdit={(e) => {
                            console.log('e===>', e)
                            //    setEditSend(e?.S34F00)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m, i) => {
                                    // val.push(m.original)
                                    const sequence = i + 1;
                                    const field = m?.original?.S34F03;
                                    const number = m?.original?.S34F04;
                                    const S34F02 = m?.original?.S34F02;

                                    const formattedString = `${sequence}##${field}##${number}##${S34F02}`;
                                    val.push(formattedString)
                                })
                                // console.log('val=>', val);
                                const result = val.join('||').replaceAll(" ", "");
                                console.log("result", result)
                                setEditSend(result)
                            }else {
                                setEditSend("")
                            }
                        }}
                    />
            }
        </div>
    )
}

export default ProductPopUpDetail