import { Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { createColumnHelper } from "@tanstack/react-table";
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { GetAccountList, GetTMFormat } from "../../utils/slices/AccountListSlice";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountListForPrint } from "../../utils/PrintSlice/PrintSlice";
import GlobalClass from "../../utils/GlobalClass";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import DataGrid from "../../components/DataGrid";
import PLModal from "./PLModal";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { GetFilterData, getFilterStatus, getFiltersName } from "../../utils/FilterSlice/FilterSlice";
import { GetReportDesignerData } from "../../utils/PrintSlice/ReportDesignerSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDateBox from "../../PlusComponent/PLDateBox";

let globalid = "";
let P1Value = "";
let pageid = "";
let separator = ".";
export const PLFilter = (props) => {


    console.log("PLFilter", props);
    const { ary, OBJ } = props;
    console.log("OBJ", OBJ)
    console.log("props ary", ary)

    const dispatch = useDispatch();
    // const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const { isLoading, hasError, ErrorMsg, Data } = useSelector(
        (state) => state?.FilterSlice
    );
    //   console.log("reportData",Data)
    const filterData = useSelector((state) => state.FilterSlice?.Data)
    console.log("filterData", filterData)
    const CBOF = useSelector((state) => state.FilterSlice?.Data?.CBOF)
    const CBOFFV = useSelector((state) => state.FilterSlice?.Data?.CBOFFV)
    const CBOND = useSelector((state) => state.FilterSlice?.Data?.CBOND)
    const CBOOT = useSelector((state) => state.FilterSlice?.Data?.CBOOT)
    const CBOP = useSelector((state) => state.FilterSlice?.Data?.CBOP)
    const M47DATA = useSelector((state) => state.FilterSlice?.Data?.M47DATA)
    const M47list = useSelector((state) => state.FilterSlice?.Data?.M47list)
    const S96 = useSelector((state) => state.FilterSlice?.Data?.S96)
    const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
    const AccountListData = useSelector(store => store?.PrintList?.PrintData);
    // const isLoading = useSelector(store => store?.PrintList?.isLoading);
    const FiltersList = useSelector(store => store?.FilterSlice?.FiltersName)

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [defaultOperatorVal, setDefaultOperatorVal] = useState([])
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [PLDataGridSelectedValues, setPLDataGridSelectedValues] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const [ruleID, setruleID] = useState();
    const [queryState, setQueryState] = useState({});
    const [condition, setCondition] = useState("and");
    const [selectedObj, setSelectedObj] = useState(null)
    const [groupFields, setGroupFields] = useState([]);
    // const [operators, setOperators] = useState([])
    const [opeartorVal, setOperatorVal] = useState(null)
    const [typeVal, setTypeVal] = useState(null)
    const [M47Data, setM47Data] = useState([])
    const [M47Obj, setM47Obj] = useState({})
    console.log("M47Obj", M47Obj)

    // const filter = S96?.filter((v) => v.field06 == "C" && (v.field07 == "2" || v.field07 == "3"))
    // const filter = S96?.filter((v) => v.field07 == "P")
    // console.log("filter", filter)

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        if (OBJ) {
            dispatch(GetFilterData(OBJ))
        }
    }, [OBJ])

    useEffect(() => {
        if (M47DATA) {
            setM47Data(M47DATA)
            setM47Obj(M47DATA[M47DATA?.length - 1])
        }
    }, [M47DATA])

    function selectedOperators(selected) {
        let val = [];
        if (selected) {
            if (selected?.field06 === "N" || selected?.field06 === "D") {
                CBOND?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
            } else if (selected?.field06 === "C" && ["2", "3"].includes(selected?.field07)) {
                CBOF?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
            } else if (selected?.field07 === "P") {
                CBOP?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
            } else {
                CBOOT?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
            }
        } else {
            CBOOT?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
        }
        // console.log("operator_val",val)
        return val
    }
    // useEffect(() => {
    //     let val = [];
    //     if (selectedObj) {
    //         if (selectedObj?.field06 === "N" || selectedObj?.field06 === "D") {
    //             CBOND?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
    //         } else if (selectedObj?.field06 === "C" && ["2", "3"].includes(selectedObj?.field07)) {
    //             CBOF?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
    //         } else if (selectedObj?.field07 === "P") {
    //             CBOP?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
    //         } else {
    //             CBOOT?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
    //         }
    //     } else {
    //         CBOOT?.map(v => val.push({ key: v.DisplayMember, value: v.ValueMember }));
    //     }
    //     setOperators(val);
    // }, [selectedObj, CBOND, CBOF, CBOP, CBOOT]);

    const dropdownTemplate = () => {
        console.log("dropdownTemplate")
        return (
            <>
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={6}>
                            <PLComboBox
                                data={CBOFFV}
                                // size="xl"
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                setEdit={(e) => {
                                    console.log("combobox value", e)
                                    // setM47Obj({ ...M47Obj, FIELD99: e })
                                    setTypeVal(e)
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            {
                                selectedObj?.field07 == "P" && selectedObj?.field06 == "C" &&
                                <PLDataGrid
                                    // position="target"
                                    // pid={P1Value}
                                    // value={M47Obj?.FIELD23}
                                    pid={selectedObj?.FIELD08}
                                    value={M47Obj?.FIELD23}
                                    TmData={selectedObj?.FIELD08}
                                    isMulti={true}
                                    width="500px"
                                    setEdit={(e) => {
                                        console.log("datagrid", e)
                                        let selectedRow = "";
                                        let selectedName = ""
                                        if (e?.length > 0) {
                                            selectedRow = e?.map((item) => item?.original?.FIELD01).join(",");
                                            selectedName = e?.map((item) => item?.original?.FIELD02).join(",");
                                        }
                                        setM47Obj({ ...M47Obj, FIELD23: selectedRow, FIELD17: selectedName })
                                    }}
                                />
                            }
                            {
                                selectedObj?.field06 == "C" && ["2", "3"].includes(selectedObj?.field07) &&
                                <PLComboBox
                                    copno={selectedObj?.FIELD08}
                                    dispexpr="DisplayMember"
                                    valexpr="ValueMember"
                                    setEdit={(e) => {
                                        console.log("comboval", e)
                                        setM47Obj({ ...M47Obj, FIELD17: e })
                                    }}
                                />
                            }
                            {
                                selectedObj?.field06 == "C" && selectedObj?.field07 !== "P" &&
                                <PLTextBox
                                    setEdit={(e) => {
                                        setM47Obj({ ...M47Obj, FIELD17: e.target.value })
                                    }}
                                />
                            }
                            {
                                selectedObj?.field06 == "N" &&
                                <PLNumberBox
                                    setEdit={(e) => {
                                        setM47Obj({ ...M47Obj, FIELD17: e })
                                    }}
                                />
                            }
                            {
                                selectedObj?.field06 == "D" &&
                                <PLDateBox
                                    setEdit={(e) => {
                                        let year = e.toLocaleString("en-IN", {
                                            year: "numeric",
                                        });
                                        let month = e.toLocaleString("en-IN", {
                                            month: "2-digit",
                                        });
                                        let day = e.toLocaleString("en-IN", {
                                            day: "2-digit",
                                        });

                                        let StringDate = year + month + day;
                                        if (StringDate?.length == 8) {
                                            setM47Obj({ ...M47Obj, FIELD17: StringDate })
                                        }
                                    }}
                                />
                            }
                        </Grid.Col>
                    </Grid.Col>
                </Grid>
            </>
        )
    }

    const groupAndTransformData = (data) => {

        // console.log("selectedObj", selectedObj)
        let operators = selectedOperators(selectedObj);
        console.log("operator", operators)
        let groupedData = data?.reduce((acc, item) => {
            const groupName = item.field12;
            const groupId = item.field02;

            let group = acc.find(g => g.field === groupName);

            if (!group) {
                group = {
                    field: groupName,
                    label: groupName,
                    columns: [],
                };
                acc.push(group);
            }

            group.columns.push({
                ...item,
                field: groupId,
                label: item.field03,
                operators: operators,
                template: dropdownTemplate,
            });

            return acc;
        }, []);
        console.log("groupedData", groupedData);
        return groupedData
    };

    useEffect(() => {
        const grouped = groupAndTransformData(S96);
        console.log("grouped", grouped);
        setGroupedData(grouped);
    }, [selectedObj, S96]);

    const handleRuleChange = (args) => {
        console.log("handleRuleChange", args);

        const { type, groupID, field, value, operator, condition, ruleID, childGroupID } = args;

        const selectedObject = S96?.find(item => item?.field02 == value);

        if (selectedObject) {
            setSelectedObj(selectedObject);
        }


        switch (type) {
            // case "insertRule":
            //     // Adding Condition
            //     setQueryState(prevState => ({
            //         ...prevState,
            //         [ruleID]: {
            //             field: undefined,
            //             operator: "Contains",
            //             value: undefined,
            //         },

            //     }));
            //     break;
            // case "insertGroup":
            //     // Adding Group

            //     setQueryState(prevState => ({
            //         ...prevState,
            //         [ruleID]: {
            //             field: undefined,
            //             operator: "Contains",
            //             value: undefined,
            //         },
            //     }));
            //     break;

            // case "deleteRule":
            //     // Removing Condition
            //     setQueryState(prevState => {
            //         const newState = { ...prevState };
            //         if (newState[ruleID]) {
            //             delete newState[ruleID];
            //         }
            //         return newState;
            //     });
            //     setPLDataGridSelectedValues(prevState => {
            //         const newState = { ...prevState };
            //         if (newState[ruleID]) {
            //             delete newState[ruleID]
            //         }
            //         return newState;
            //     })

            //     break;
            // case "deleteGroup":
            //     // Removing Group
            //     setQueryState(prevState => {
            //         const newState = { ...prevState };
            //         if (newState[ruleID]) {
            //             delete newState[ruleID];
            //         }
            //         return newState;
            //     });
            //     break;
            // case "field":
            //     setQueryState(prevState => ({
            //         ...prevState,
            //         [ruleID]: {
            //             ...prevState?.[ruleID],
            //             field: value
            //         }
            //     }));
            //     break;
            // case "value":
            //     setQueryState(prevState => ({
            //         ...prevState,
            //         [ruleID]: {
            //             ...prevState?.[ruleID],
            //             value: value
            //         }
            //     }));
            //     break;
            case "operator":
                setOperatorVal(value)
                // setQueryState(prevState => ({
                //     ...prevState,
                //     [ruleID]: {
                //         ...prevState?.[ruleID],
                //         operator: value
                //     }
                // }));
                break;
            // case "condition":
            //     setQueryState(prevState => ({
            //         ...prevState,
            //         [`${groupID}_condition`]: value
            //     }));
            //     break;
            default:
                break;
        }

    };

    const SubmitFilter = (obj) => {
        console.log("SubmitFilter obj", obj)
        // console.log("M47Obj in function",M47Obj)
        dispatch(DataSaveLoading(true));
        const filterData = M47DATA?.filter((v) => v.FIELD01 !== null)

        var JData = { M47DATA: [...filterData, obj?.FormObj] }
        console.log("JData", JData)
    }

    const saveBtn = () => {
        let newObj = {};
        let updatedM47Obj = {
            ...M47Obj,
            FIELD01: selectedObj?.field01,
            FIELD04: selectedObj?.field05,
            FIELD05: opeartorVal,
            FIELD06: selectedObj?.field06,
            FIELD07: "",
            FIELD08: "(",
            FIELD09: ")",
            FIELD10: selectedObj?.field02,
            FIELD90: selectedObj?.field02,
            FIELD99: typeVal,
            FIELD11: 0,
            FIELD15: 0
        }

        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Save Filter</Text>,
            body: (
                <>
                    <Grid>
                        <Grid.Col span={12} style={{ display: 'flex' }}>
                            <Grid.Col span={4}>
                                <Text size={12}>Filter Name</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    setEdit={(e) => {
                                        // setFilterName(e.target.value)
                                        newObj = { "FormName": e.target.value, "FormObj": updatedM47Obj }
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={() => SubmitFilter(newObj)} >Ok</Button>
                        </Grid.Col>
                    </Grid>
                </>
            ),
            open: true,
            size: "md",
            position: "right",
        });
    }

    const transformDataForQueryBuilder = (data) => {
        // const queryData = {
        //     condition: data.group0_condition,
        //     rules: Object.keys(data)
        //         .filter(key => key.includes('_rule'))
        //         .map(key => {
        //             const ruleId = key.split('_')[1];
        //             const ruleData = data[key];
        //             return {
        //                 label: ruleData.field,
        //                 field: ruleData.field,
        //                 type: 'string', // You may need to adjust this based on your data types
        //                 operator: ruleData.operator,
        //                 value: ruleData.value
        //             };
        //         })
        // };
        // return queryData;


        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };

        // let currentGroup = null;

        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {
        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };

        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });

        // return queryData;

        // const queryData = {
        //     condition: "and", // Default condition
        //     rules: []
        // };

        // let currentGroup = null;

        // Object.keys(data).forEach(key => {
        //     if (key.includes('_rule')) {


        //         console.log("queryData IF",queryData);

        //         const ruleId = key.split('_')[1];
        //         const ruleData = data[key];
        //         const ruleObj = {
        //             label: ruleData.field,
        //             field: ruleData.field,
        //             type: 'string', // You may need to adjust this based on your data types
        //             operator: ruleData.operator,
        //             value: ruleData.value
        //         };

        //         if (currentGroup) {
        //             // If a rule belongs to a group, add it to the group's rules
        //             currentGroup.rules.push(ruleObj);
        //         } else {
        //             // If a rule doesn't belong to any group, add it directly to queryData
        //             queryData.rules.push(ruleObj);
        //         }
        //     } else if (key.endsWith('_condition')) {
        //         // Set the condition for the current group
        //         console.log("queryData ELSE",queryData);
        //         const groupId = key.split('_')[0];
        //         if (queryData.rules.length > 0 && groupId in queryData.rules[queryData.rules.length - 1]) {
        //             // If there are rules in the queryData and the current group matches the last rule
        //             queryData.rules[queryData.rules.length - 1].condition = data[key];
        //         } else {
        //             // If the condition belongs to a new group
        //             currentGroup = {
        //                 condition: data[key],
        //                 rules: []
        //             };
        //             queryData.rules.push(currentGroup);
        //         }
        //     }
        // });

        // return queryData;


        const queryData = {
            condition: "and", // Default condition
            rules: []
        };

        let currentGroup = null;

        Object.keys(data).forEach(key => {
            if (key.includes('_rule')) {
                const ruleId = key.split('_')[1];
                const ruleData = data[key];
                const ruleObj = {
                    label: ruleData.field,
                    field: ruleData.field,
                    type: 'string', // You may need to adjust this based on your data types
                    operator: ruleData.operator,
                    value: ruleData.value
                };

                if (currentGroup) {
                    // If a rule belongs to a group, add it to the group's rules
                    currentGroup.rules.push(ruleObj);
                } else {
                    // If a rule doesn't belong to any group, add it directly to queryData
                    queryData.rules.push(ruleObj);
                }
            } else if (key.endsWith('_condition')) {
                // Set the condition for the current group
                const groupId = key.split('_')[0];
                if (currentGroup) {
                    // If there's already a current group, join it with the previous group based on the condition
                    const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
                    queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
                }
                currentGroup = {
                    condition: data[key],
                    rules: []
                };
            }
        });

        // If there's a current group, add it to the queryData
        if (currentGroup) {
            const joinCondition = currentGroup.condition === 'or' ? 'or' : 'and';
            queryData.rules.push({ condition: joinCondition, rules: currentGroup.rules });
        }

        return queryData;

    };

    const queryData = ary && transformDataForQueryBuilder(ary);
    // console.log(queryData , "queryData");

    return (
        <>
            {
                hasError ? <>
                    <Text>{ErrorMsg?.message}</Text>
                    <Button onClick={() => {
                        if (OBJ) {
                            dispatch(GetFilterData(OBJ))
                        }
                    }}>Retry</Button>
                </> :
                    <>
                        {
                            isLoading ? GlobalClass.RenderLoader("dots") :
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        // onClose={close}
                                        withCloseButton={() => { }}
                                        fullScreen={false} //
                                        // overlayProps={{ opacity: 0.5, blur: 4 }}
                                        closeButtonProps={{
                                            onClick: () => {
                                                setDrawerObj(dra);
                                            },
                                        }}
                                        title={DrawerObj.title}
                                        size={DrawerObj.size}
                                        // centered={.Centered}
                                        closeOnClickOutside={true}
                                        // overlayProps={m.Overlay}
                                        position={DrawerObj?.position ?? "bottom"}
                                        onClose={
                                            typeof DrawerObj?.onclose == "function"
                                                ? DrawerObj?.onclose
                                                : () => {
                                                    setDrawerObj(dra);
                                                }
                                        }
                                    // scrollAreaComponent={ScrollArea.Autosize}
                                    >
                                        {DrawerObj.body}
                                    </Modal>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {
                                                // ary ?  // For Edit Case
                                                //     (
                                                //         <QueryBuilderComponent width="100%"
                                                //             // dataSource={columnData}
                                                //             // columns={columnData??[]}
                                                //             columns={groupFields ?? []}
                                                //             rule={queryData}
                                                //             // ruleChange={handleRuleChange}
                                                //             change={handleRuleChange}
                                                //             // rule={[]}
                                                //             separator="."
                                                //             fieldMode="DropdownTree"
                                                //         >

                                                //         </QueryBuilderComponent>
                                                //     )
                                                //     :
                                                (

                                                    <QueryBuilderComponent
                                                        width="100%"
                                                        // dataSource={groupFields}
                                                        columns={groupedData ?? []}
                                                        change={handleRuleChange}
                                                        // change={(e)=>console.log("handleRuleChange =>",e)}

                                                        rule={[]}
                                                        // separator="."
                                                        fieldMode="DropdownTree"
                                                    />
                                                )}
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                            <Group>
                                                <Button onClick={saveBtn}>Save</Button>
                                                <Button>Run</Button>
                                                <Button>Delete</Button>
                                                <Button>Copy</Button>
                                            </Group>
                                        </Grid.Col>

                                    </Grid>
                                </>
                        }
                    </>
            }
        </>
    )

}


export function TM_EXPORT(data) {
    const OBJ = JSON.parse(data);
    console.log("OBJ", OBJ);

    const state = Store.getState();
    const FiltersName = state?.FilterSlice?.FiltersName;
    const isFilterApplied = state?.FilterSlice?.isFilterApplied;

    let isAnySavedFilter = FiltersName && Object.keys(FiltersName).length
    //  console.log("isAnySavedFilter",isAnySavedFilter);


    if (isFilterApplied) {
        // Store.dispatch((GetAccountListForPrint({...OBJ,pagination:{pageIndex: 0,pageSize: 25}})))
        Store.dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: { pageIndex: 0, pageSize: 25 } }))
        Store.dispatch((getFilterStatus(false)))
    }
    else {
        if (isAnySavedFilter === 0) {
            Store.dispatch(
                ModalFunction({
                    onclose: (e) => { },
                    MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                    MAction: true,
                    MSize: "85%",
                    position: 'bottom',
                    MBody: (i) => <PLFilter OBJ={OBJ} index={i} />,
                    MClose: true,
                    Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                    },
                })
            );
        }
        else {
            Store.dispatch(
                ModalFunction({
                    onclose: () => { },
                    MTitle: <Text fw={700}>Select Filter to Apply</Text>,
                    MAction: true,
                    MSize: "xl",
                    // MHeight :100,
                    MBody: (i) => <PLModal OBJ={OBJ} index={i} />,
                    MClose: true,
                    Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                    }
                })
            );
        }
    }



}
