import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const BillSeries = (props) => {
    const { obj, value, setEditSend } = props
    const BillSeriesData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_T92 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_T92)
    const P_T92DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_T92DT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                BillSeriesData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        TmData={P_T92}
                        data={P_T92DT?.jData}
                        setEdit={(e) => {
                            setEditSend(e.FIELD02)
                        }}
                        value={value}
                    />
            }
        </div>
    )
}

export default BillSeries