import '../global.js'
/* eslint-disable */
import React, { useEffect } from 'react';
import '../App.css';
//Report designer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reportdesigner.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import { Alert } from '@mantine/core';
import { GetAddCmpEnt } from '../utils/slices/UserListSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import GlobalClass from '../utils/GlobalClass.jsx';
var designerStyle = {
  'height': '700px',
  'width': '100%'
};

const A4_WIDTH = 595.28;  // Width of A4 in points (pt) in portrait
const A4_HEIGHT = 841.89; // Height of A4 in points (pt) in portrait
const LANDSCAPE_A4_WIDTH = A4_HEIGHT;
const LANDSCAPE_A4_HEIGHT = A4_WIDTH;
const MIN_FONT_SIZE = 8; // Minimum font size before switching to landscape
const MARGIN_TOP = 36 * 2; // 0.5 inch in points
const MARGIN_BOTTOM = 36 * 2; // 0.5 inch in points
const MARGIN_LEFT = 36 * 2; // 0.5 inch in points
const MARGIN_RIGHT = 36 * 2; // 0.5 inch in points

function ReportViewer(props) {
  { console.log("rv props", props) }
  const { AdvanceFormData } = props;
  if (!props.data || !props.title || !props.aDcol) {
    console.log(props);
    return <Alert>Please Provide Necessary Data</Alert>
  }
  var viewerStyle = { 'height': '700px', 'width': '100%' };
  var ReportTitle = props.title;
  var ReportDataSet = ReportTitle.replaceAll(" ", "");
  var reportData = [{
    value: props.data,
    name: ReportDataSet
  }];
  var aDCol = [...props.aDcol?.filter((a)=>a.ColWidth >0 && a.ColCap != ''), ...props?.aDGrp?.filter((a)=>a.ColWidth >0 && a.ColCap != '')];
  var aDGrp = [...props?.aDGrp?.filter((a)=>a.ColWidth >0 && a.ColCap != '')];

  // if(aDGrp){
  //   aDCol.push(aDGrp);
  // }

  let CmpNumber = localStorage.getItem("cmp")
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.UserList);
  const { M00 } = useSelector((state) => state?.UserList?.AddCmpEnt);
  //FIELD02 = Company name
  //FIELD91 = Header 1
  //FIELD92 = Header 2
  //FIELD93 = Footer 1
  //FIELD94 = Footer 2

  // useEffect(() => {
  //   if (CmpNumber != "") {
  //     dispatch(GetAddCmpEnt({ cmpNo: CmpNumber, action: "E" }))
  //   }
  // }, [CmpNumber])

  console.log("M00==>", M00);

  function calculateOptimalColumnWidth(numColumns, pageWidth) {
    return pageWidth / numColumns;
  }

  function getPageDimensions(isLandscape) {
    const width = isLandscape ? LANDSCAPE_A4_HEIGHT : A4_WIDTH;
    const height = isLandscape ? LANDSCAPE_A4_WIDTH : A4_HEIGHT;
    const printableWidth = width - MARGIN_LEFT - MARGIN_RIGHT;
    const printableHeight = height - MARGIN_TOP - MARGIN_BOTTOM;
    return { printableWidth, printableHeight };
  }
  function adjustColumnAndFontSizes(numColumns, initialFontSize, isLandscape = false) {
    let currentFontSize = initialFontSize;
    let { printableWidth } = getPageDimensions(isLandscape);
    let currentWidth = calculateOptimalColumnWidth(numColumns, printableWidth);
    console.log("currentFontSize",currentFontSize);
    while (currentFontSize > MIN_FONT_SIZE) {
      if (currentWidth * numColumns <= printableWidth) {
        break;  // The current setup fits within the printable area
      }
      // Reduce font size and re-calculate column width
      currentFontSize -= 1;
      currentWidth = calculateOptimalColumnWidth(numColumns, printableWidth);
    }

    // Check if landscape mode is needed
    if (currentWidth * numColumns > printableWidth && !isLandscape) {
      isLandscape = true;
      printableWidth = getPageDimensions(isLandscape).printableWidth;
      currentWidth = calculateOptimalColumnWidth(numColumns, printableWidth);
    }
    console.log("currentFontSize after",currentFontSize);
    return { currentWidth, currentFontSize, isLandscape, printableWidth };
  }

  function charLengthToWidthPt(e) {
    // Conversion factor from pixels to points
    const numColumns = aDCol.length; // Example: 3 columns
    const initialFontSize = 9; //9pt = 12px

    let results = adjustColumnAndFontSizes(numColumns, initialFontSize);
    console.log("results=>", results)

    //   currentFontSize: 12
    // currentWidth: 150.42666666666665
    // isLandscape: false
    // printableWidth: 451.28

    switch (e) {
      case "CLM":
        return results.currentWidth;
        break;
      case "FNT":
        return results.currentFontSize;
        break;
      default:
        return results.currentWidth;
        break;
    }

    // return results.currentWidth;

    // const pxToPt = 72 / 96;
    // Calculate width in pixels
    // const widthPx = charLength * avgCharWidthPx;

    // Convert width to points
    // const widthPt = widthPx * pxToPt;
  }

  function renderTablixColumns() {
    return aDCol.map((e, i) => {
      return `<TablixColumn>
        <Width>${charLengthToWidthPt("CLM")}pt</Width>
      </TablixColumn>`
    })
  }
  function renderTablixHeader() {
    return aDCol.map((e, i) => {
      return `<TablixCell>
        <CellContents>
          <Textbox Name="TextBox2">
            <Left>0in</Left>
            <Top>0in</Top>
            <Height>18pt</Height>
            <Width>${charLengthToWidthPt("CLM")}pt</Width>
            <Style>
              <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
              <PaddingLeft>2pt</PaddingLeft>
              <PaddingRight>2pt</PaddingRight>
              <PaddingTop>2pt</PaddingTop>
              <PaddingBottom>2pt</PaddingBottom>
              <Border>
                <Width>0.99975pt</Width>
                <Color>LightGrey</Color>
                <Style>Solid</Style>
              </Border>
            </Style>
            <CanGrow>true</CanGrow>
            <KeepTogether>true</KeepTogether>
            <Paragraphs>
              <Paragraph>
                <TextRuns>
                  <TextRun>
                    <Value>${e.ColCap}</Value>
                    <Style>
                      <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                      <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                      <FontWeight>Bold</FontWeight>
                      <Color>black</Color>
                    </Style>
                  </TextRun>
                </TextRuns>
                <Style>
                  <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                  <TextAlign>Center</TextAlign>
                </Style>
              </Paragraph>
            </Paragraphs>
          </Textbox>
          <ColSpan>1</ColSpan>
          <RowSpan>1</RowSpan>
        </CellContents>
      </TablixCell>`
    })
  }
  function renderValueCell() {
    return aDCol.map((e, i) => {
      return `<TablixCell>
        <CellContents>
          <Textbox Name="TextBox11">
            <Left>0in</Left>
            <Top>0in</Top>
            <Height>18pt</Height>
            <Width>${charLengthToWidthPt("CLM")}pt</Width>
            <Style>
              <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
              <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
              <PaddingLeft>2pt</PaddingLeft>
              <PaddingRight>2pt</PaddingRight>
              <PaddingTop>2pt</PaddingTop>
              <PaddingBottom>2pt</PaddingBottom>
              <Border>
                <Color>LightGrey</Color>
                <Style>Solid</Style>
              </Border>
            </Style>
            <CanGrow>true</CanGrow>
            <KeepTogether>true</KeepTogether>
            <Paragraphs>
              <Paragraph>
                <TextRuns>
                  <TextRun>
                    <Value />
                    <Style>
                      <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                      <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                    </Style>
                  </TextRun>
                </TextRuns>
                <Style>
                  <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                </Style>
              </Paragraph>
            </Paragraphs>
          </Textbox>
          <ColSpan>1</ColSpan>
          <RowSpan>1</RowSpan>
        </CellContents>
      </TablixCell>`
    })
  }
  function renderFieldValueCell() {
    return aDCol.map((e, i) => {
      return `<TablixCell>
        <CellContents>
          <Textbox Name="TextBox5">
            <Left>0in</Left>
            <Top>0in</Top>
            <Height>18pt</Height>
            <Width>${charLengthToWidthPt("CLM")}pt</Width>
            <Style>
              <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
              <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
              <PaddingLeft>2pt</PaddingLeft>
              <PaddingRight>2pt</PaddingRight>
              <PaddingTop>2pt</PaddingTop>
              <PaddingBottom>2pt</PaddingBottom>
              <Border>
                <Color>LightGrey</Color>
                <Style>Solid</Style>
              </Border>
            </Style>
            <CanGrow>true</CanGrow>
            <KeepTogether>true</KeepTogether>
            <Paragraphs>
              <Paragraph>
                <TextRuns>
                  <TextRun>
                    <Value>=Fields!${e.DispExpr}.Value</Value>
                    <Style>
                      <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                      <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                    </Style>
                  </TextRun>
                </TextRuns>
                <Style>
                  <FontFamily>${e.FontName ? e.FontName : "Arial"}</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                </Style>
              </Paragraph>
            </Paragraphs>
          </Textbox>
          <ColSpan>1</ColSpan>
          <RowSpan>1</RowSpan>
        </CellContents>
      </TablixCell>`
    })
  }
  function renderGrouping() {
    if (aDGrp) {
      return aDGrp.map((e, i) => {
        return `<Group Name="${e.DispExpr}1">
                    <GroupExpressions>
                      <GroupExpression>=Fields!${e.DispExpr}.Value</GroupExpression>
                    </GroupExpressions>
                  </Group>`
      })
    }
  }
  function renderGroupingHeader() {
    return aDGrp?.map((e) => {
      return `<TablixHeader>
        <Size>71.8125pt</Size>
        <CellContents>
          <Textbox Name="TextBox8">
            <Left>0in</Left>
            <Top>0in</Top>
            <Height>18pt</Height>
            <Width>71.8125pt</Width>
            <Style>
              <FontFamily>Arial</FontFamily>
              <PaddingLeft>2pt</PaddingLeft>
              <PaddingRight>2pt</PaddingRight>
              <PaddingTop>2pt</PaddingTop>
              <PaddingBottom>2pt</PaddingBottom>
              <Border>
                <Width>0.99975pt</Width>
                <Color>LightGrey</Color>
                <Style>Solid</Style>
              </Border>
            </Style>
            <CanGrow>true</CanGrow>
            <KeepTogether>true</KeepTogether>
            <Paragraphs>
              <Paragraph>
                <TextRuns>
                  <TextRun>
                    <Value>${e.ColCap}</Value>
                    <Style>
                      <FontFamily>Arial</FontFamily>
                      <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                      <FontWeight>Bold</FontWeight>
                      <Color>black</Color>
                    </Style>
                  </TextRun>
                </TextRuns>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <TextAlign>Center</TextAlign>
                </Style>
              </Paragraph>
            </Paragraphs>
          </Textbox>
        </CellContents>
      </TablixHeader>`
    })
  }

  function ShowHeader() {
    return M00?.FIELD91 != "" || M00?.FIELD92 != "" ? ` <PageHeader>
    <Style>
      <FontFamily>Arial</FontFamily>
      <Border>
        <Style>None</Style>
      </Border>
    </Style>
    <Height>63pt</Height>
    <PrintOnFirstPage>true</PrintOnFirstPage>
    <PrintOnLastPage>true</PrintOnLastPage>
    <ReportItems>
      <Textbox Name="TextBox2">
        <Left>6pt</Left>
        <Top>7.5pt</Top>
        <Height>19.5pt</Height>
        <Width>453pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <PaddingLeft>2pt</PaddingLeft>
          <PaddingRight>2pt</PaddingRight>
          <PaddingTop>2pt</PaddingTop>
          <PaddingBottom>2pt</PaddingBottom>
          <Border>
            <Style>None</Style>
          </Border>
        </Style>
        <CanGrow>true</CanGrow>
        <KeepTogether>true</KeepTogether>
        <Paragraphs>
          <Paragraph>
            <TextRuns>
              <TextRun>
                <Value>${M00?.FIELD02}</Value>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                  <FontWeight>ExtraBold</FontWeight>
                  <Color>Black</Color>
                </Style>
              </TextRun>
            </TextRuns>
            <Style>
              <FontFamily>Arial</FontFamily>
              <TextAlign>Center</TextAlign>
            </Style>
          </Paragraph>
        </Paragraphs>
      </Textbox>
      <Textbox Name="TextBox5">
        <Left>6pt</Left>
        <Top>26.25pt</Top>
        <Height>15pt</Height>
        <Width>453pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <PaddingLeft>2pt</PaddingLeft>
          <PaddingRight>2pt</PaddingRight>
          <PaddingTop>2pt</PaddingTop>
          <PaddingBottom>2pt</PaddingBottom>
          <Border>
            <Style>None</Style>
          </Border>
        </Style>
        <CanGrow>true</CanGrow>
        <KeepTogether>true</KeepTogether>
        <Paragraphs>
          <Paragraph>
            <TextRuns>
              <TextRun>
                <Value>${M00?.FIELD91}</Value>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                  <FontWeight>Bold</FontWeight>
                  <Color>#4d4d4d</Color>
                </Style>
              </TextRun>
            </TextRuns>
            <Style>
              <FontFamily>Arial</FontFamily>
            </Style>
          </Paragraph>
        </Paragraphs>
      </Textbox>
      <Textbox Name="TextBox6">
        <Left>6pt</Left>
        <Top>40.5pt</Top>
        <Height>15pt</Height>
        <Width>453pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <PaddingLeft>2pt</PaddingLeft>
          <PaddingRight>2pt</PaddingRight>
          <PaddingTop>2pt</PaddingTop>
          <PaddingBottom>2pt</PaddingBottom>
          <Border>
            <Style>None</Style>
          </Border>
        </Style>
        <CanGrow>true</CanGrow>
        <KeepTogether>true</KeepTogether>
        <Paragraphs>
          <Paragraph>
            <TextRuns>
              <TextRun>
                <Value>${M00?.FIELD92}</Value>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                  <FontWeight>Bold</FontWeight>
                  <Color>#4d4d4d</Color>
                </Style>
              </TextRun>
            </TextRuns>
            <Style>
              <FontFamily>Arial</FontFamily>
            </Style>
          </Paragraph>
        </Paragraphs>
      </Textbox>
      <Line Name="Line2">
        <Left>0pt</Left>
        <Top>59.25pt</Top>
        <Height>0pt</Height>
        <Width>462pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <Border>
            <Width>2.25pt</Width>
            <Style>Solid</Style>
          </Border>
        </Style>
      </Line>
    </ReportItems>
  </PageHeader>`: null

  }

  function ShowFooter() {
    return M00?.FIELD93 != "" || M00?.FIELD94 != "" ? `<PageFooter>
    <Style>
      <FontFamily>Arial</FontFamily>
      <Border>
        <Style>None</Style>
      </Border>
    </Style>
    <Height>290.25pt</Height>
    <PrintOnFirstPage>true</PrintOnFirstPage>
    <PrintOnLastPage>true</PrintOnLastPage>
    <ReportItems>
      <Line Name="Line3">
        <Left>0pt</Left>
        <Top>6pt</Top>
        <Height>0pt</Height>
        <Width>461.25pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <Border>
            <Width>2.25pt</Width>
            <Style>Solid</Style>
          </Border>
        </Style>
      </Line>
      <Textbox Name="TextBox14">
        <Left>5.25pt</Left>
        <Top>15.75pt</Top>
        <Height>15.75pt</Height>
        <Width>453.75pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <PaddingLeft>2pt</PaddingLeft>
          <PaddingRight>2pt</PaddingRight>
          <PaddingTop>2pt</PaddingTop>
          <PaddingBottom>2pt</PaddingBottom>
          <Border>
            <Style>None</Style>
          </Border>
        </Style>
        <CanGrow>true</CanGrow>
        <KeepTogether>true</KeepTogether>
        <Paragraphs>
          <Paragraph>
            <TextRuns>
              <TextRun>
                <Value>${M00?.FIELD93}</Value>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                  <FontWeight>Bold</FontWeight>
                  <Color>#4d4d4d</Color>
                </Style>
              </TextRun>
            </TextRuns>
            <Style>
              <FontFamily>Arial</FontFamily>
            </Style>
          </Paragraph>
        </Paragraphs>
      </Textbox>
      <Textbox Name="TextBox15">
        <Left>5.25pt</Left>
        <Top>30.75pt</Top>
        <Height>15pt</Height>
        <Width>453.75pt</Width>
        <Style>
          <FontFamily>Arial</FontFamily>
          <PaddingLeft>2pt</PaddingLeft>
          <PaddingRight>2pt</PaddingRight>
          <PaddingTop>2pt</PaddingTop>
          <PaddingBottom>2pt</PaddingBottom>
          <Border>
            <Style>None</Style>
          </Border>
        </Style>
        <CanGrow>true</CanGrow>
        <KeepTogether>true</KeepTogether>
        <Paragraphs>
          <Paragraph>
            <TextRuns>
              <TextRun>
                <Value>${M00?.FIELD94}</Value>
                <Style>
                  <FontFamily>Arial</FontFamily>
                  <FontSize>${charLengthToWidthPt("FNT")}pt</FontSize>
                  <FontWeight>Bold</FontWeight>
                  <Color>#4d4d4d</Color>
                </Style>
              </TextRun>
            </TextRuns>
            <Style>
              <FontFamily>Arial</FontFamily>
            </Style>
          </Paragraph>
        </Paragraphs>
      </Textbox>
    </ReportItems>
  </PageFooter>` : null
  }

  var xml = `<?xml version="1.0"?>
      <Report xmlns:df="http://schemas.microsoft.com/sqlserver/reporting/2016/01/reportdefinition/defaultfontfamily" xmlns:rd="http://schemas.microsoft.com/SQLServer/reporting/reportdesigner" xmlns="http://schemas.microsoft.com/sqlserver/reporting/2016/01/reportdefinition">
        <ReportSections>
          <ReportSection>
            <Body>
              <Style>
                <FontFamily>Arial</FontFamily>
                <Border>
                  <Style>None</Style>
                </Border>
              </Style>
              <ReportItems>
                <Tablix Name="Tablix1">
                  <Left>0pt</Left>
                  <Top>45pt</Top>
                  <Height>54pt</Height>
                  <Width>540pt</Width>
                  <Style>
                    <FontFamily>Arial</FontFamily>
                    <Border>
                      <Style>None</Style>
                    </Border>
                  </Style>
                  <DataSetName>${ReportDataSet}</DataSetName>
                  <TablixBody>
                    <TablixColumns>
                      ${renderTablixColumns()}
                    </TablixColumns>
                    <TablixRows>
                      <TablixRow>
                        <Height>18pt</Height>
                        <TablixCells>
                          ${renderTablixHeader()}
                        </TablixCells>
                      </TablixRow>
                      <TablixRow>
                        <Height>18pt</Height>
                        <TablixCells>
                          ${renderValueCell()}
                        </TablixCells>
                      </TablixRow>
                      <TablixRow>
                        <Height>18pt</Height>
                        <TablixCells>
                          ${renderFieldValueCell()}
                        </TablixCells>
                      </TablixRow>
                    </TablixRows>
                  </TablixBody>
                  <TablixColumnHierarchy>
                    <TablixMembers>
                    ${aDCol?.map((e) => (`<TablixMember />`))}
                    </TablixMembers>
                  </TablixColumnHierarchy>
                  <TablixRowHierarchy>
                    <TablixMembers>
                      <TablixMember>
                      ${renderGroupingHeader()}
                        <TablixMembers>
                          <TablixMember />
                        </TablixMembers>
                        <KeepWithGroup>After</KeepWithGroup>
                      </TablixMember>
                      <TablixMember>
                      ${renderGrouping()}
                     
                      ${aDGrp?.map((e) => {
    return `<TablixHeader>
                        <Size>71.8125pt</Size>
                        <CellContents>
                          <Textbox Name="${e.DispExpr}11">
                            <Left>0in</Left>
                            <Top>0in</Top>
                            <Height>18pt</Height>
                            <Width>71.8125pt</Width>
                            <Style>
                              <FontFamily>Arial</FontFamily>
                              <PaddingLeft>2pt</PaddingLeft>
                              <PaddingRight>2pt</PaddingRight>
                              <PaddingTop>2pt</PaddingTop>
                              <PaddingBottom>2pt</PaddingBottom>
                              <Border>
                                <Color>LightGrey</Color>
                                <Style>Solid</Style>
                              </Border>
                            </Style>
                            <CanGrow>true</CanGrow>
                            <KeepTogether>true</KeepTogether>
                            <Paragraphs>
                              <Paragraph>
                                <TextRuns>
                                  <TextRun>
                                    <Value>=Fields!${e.DispExpr}.Value</Value>
                                    <Style>
                                      <FontFamily>Arial</FontFamily>
                                    </Style>
                                  </TextRun>
                                </TextRuns>
                                <Style>
                                  <FontFamily>Arial</FontFamily>
                                </Style>
                              </Paragraph>
                            </Paragraphs>
                          </Textbox>
                        </CellContents>
                      </TablixHeader>`
  })}
                        <TablixMembers>
                          <TablixMember>
                            <KeepWithGroup>After</KeepWithGroup>
                          </TablixMember>
                          <TablixMember>
                            <Group Name="Details" />
                          </TablixMember>
                        </TablixMembers>
                      </TablixMember>
                    </TablixMembers>
                  </TablixRowHierarchy>
                </Tablix>
              </ReportItems>
              <Height>3.125in</Height>
            </Body>
            <Width>540pt</Width>
            <Page>
           ${ShowHeader()}
        ${ShowFooter()}
              <LeftMargin>1in</LeftMargin>
              <RightMargin>1in</RightMargin>
              <TopMargin>1in</TopMargin>
              <BottomMargin>1in</BottomMargin>
              <Style>
                <FontFamily>Arial</FontFamily>
                
                <Border>
                  <Style>None</Style>
                </Border>
              </Style>
            </Page>
          </ReportSection>
        </ReportSections>
        <AutoRefresh>0</AutoRefresh>
        <DataSources>
          <DataSource Name="DataSource1">
            <ConnectionProperties>
              <DataProvider />
              <ConnectString>/* Local Connection */</ConnectString>
            </ConnectionProperties>
            <rd:ImpersonateUser>false</rd:ImpersonateUser>
          </DataSource>
        </DataSources>
        <DataSets>
          <DataSet Name="${ReportDataSet}">
            <Fields>
            ${aDCol.map((e, i) => (`<Field Name="${e.DispExpr}">
            <DataField>${e.DispExpr}</DataField>
            <rd:TypeName>System.String</rd:TypeName>
          </Field>`))}
            </Fields>
            <Query>
              <DataSourceName>DataSource1</DataSourceName>
              <CommandType>Text</CommandType>
              <CommandText>/* Local Query */</CommandText>
            </Query>
            <rd:DataSetInfo>
              <rd:DataSetName>DataSource1</rd:DataSetName>
              <rd:ObjectDataSourceType>JSONData</rd:ObjectDataSourceType>
              <rd:TableName>${ReportDataSet}</rd:TableName>
            </rd:DataSetInfo>
          </DataSet>
        </DataSets>
        <ReportParametersLayout>
          <GridLayoutDefinition>
            <NumberOfColumns>${aDCol.length}</NumberOfColumns>
            <NumberOfRows>2</NumberOfRows>
          </GridLayoutDefinition>
        </ReportParametersLayout>
        <rd:ReportUnitType>Inch</rd:ReportUnitType>
        <rd:PageUnit>Px</rd:PageUnit>
        <df:DefaultFontFamily>Segoe UI</df:DefaultFontFamily>
      </Report>`

  // console.log("ej.ReportViewer.PaperSize.A4==>", ej.ReportViewer.PaperSize.A4, "==>",`#`+ ej.ReportViewer.PaperSize[AdvanceFormData.PaperSize]);

  console.log("AdvanceFormData=>", AdvanceFormData)

  return (
    <div style={viewerStyle}>
      <BoldReportViewerComponent
        id="reportviewer-container"
        reportServiceUrl={GlobalClass.ApiUrl+'ReportViewer'}
        processingMode={"Local"}
        reportPath={xml}
        dataSources={reportData}
        pageSettings={{ paperSize: ej.ReportViewer.PaperSize[AdvanceFormData.PaperSize] }}
        isResponsive={true}
        printMode={true}
        // toolbarRenderMode={ej.ReportViewer?.ToolbarRenderMode?.Classic}
      >
      </BoldReportViewerComponent>
    </div>
  );
}

export default ReportViewer;