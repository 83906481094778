import React, { useEffect, useRef, useState } from "react";
import { NumberInput } from "@mantine/core";

export default function PLNumberBox(props) {
  const [numValue, setNumValue] = useState(0);
  const NumberInputRef = useRef(null)

  function setformat(e) {
    let nnumformat;
    switch (e) {
      // case "Q1":
      //     nnumformat = appContext.appsetting.oYear._DEC1;
      //     break;
      // case "Q2":
      //     nnumformat = appContext.appsetting.oYear._DEC2;
      //     break;
      // case "Q3":
      //     nnumformat = appContext.appsetting.oYear._DEC3;
      //     break;
      // case "Q4":
      //     nnumformat = appContext.appsetting.oYear._DEC4;
      //     break;
      // case "RT":
      //     nnumformat = appContext.appsetting.oYear._RATE;
      //     break;
      // case "AM":
      //     nnumformat = appContext.appsetting.oYear._AMT;
      //     break;
      // case "MC":
      //     nnumformat = appContext.appsetting.oYear._MDEC;
      //     break;
      case "P2":
        nnumformat = 6.2;
        break;
      case "P3":
        nnumformat = 7.3;
        break;
      case "P4":
        nnumformat = 8.4;
        break;
      case "DD":
        nnumformat = 3;
        break;
      default:
        nnumformat = 5;
        break;
    }
    if (nnumformat > 0) {
      var precision = parseInt(new Number(nnumformat).toPrecision(2));
      nnumformat = new Number(nnumformat);
      var dec = Math.round((nnumformat - precision + Number.EPSILON) * 10);
      var nstr = "#";
      var dstr = "0";
      return nstr.repeat(precision) + "." + dstr.repeat(dec);
    }
    return "";
  }

  // function getmin(props) {
  //     if (props.lrange) {
  //         return props.nlrange;
  //     }
  //     if (props.lnonegative) {
  //         return 0;
  //     }
  //     if (props.lnonzero) {
  //         return 1;
  //     }
  //     return undefined
  // }

  // name = { v.F02F01 }
  // value = { v[c.S53F04]}
  // ntypeofentry = { v.F02F31 }
  // setNumberBox = {(e)=> setNumberBox(e)}
  function setNum() {
    if (props?.setNumber && typeof props?.setNumber == "function") {
      props?.setNumber();
    }
  }
  useEffect(() => {
    setNumValue(parseFloat(props.value) || 0);
  }, [props.value]);
  return (
    <NumberInput
      ref={NumberInputRef}
      styles={{ input: { textAlign: props?.textAlign ? 'left' : 'end' } }} // rathod kishan
      // onFocus={(e) => typeof props.onFocus == "function" ? props?.onFocus(e) : null}
      {...props}
      onKeyUp={(e) => typeof props.onKeyUp == "function" ? props.onKeyUp(e) : null}
      onFocus={(e)=>{
        if(props?.onFocus && typeof props?.onFocus == "function"){
          props?.onFocus(e)
        }
        NumberInputRef?.current?.select()
      }}
      // onKeyUp={(e)=>{
      //   if(e.key == 'enter'){

      //   }
      // }}
      // defaultValue={0}
      maxLength={props?.nmaxlength}
      type="number"
      size={"xs"}
      label={props.label}
      min={props?.min ?? ""}      // Yasvi Patel
      max={props?.max ?? ""}      // Yasvi Patel
      disabled={props?.disabled}
      id={props?.id ?? props?.name}
      name={props.name}
      defaultValue={parseFloat(props.defaultval)}
      value={numValue}
      hideControls={true}  //Yasvi Patel ( to remove side arrows for lowing or uping number )
      format={setformat(props.ntypeofentry)}
      decimalSeparator={props?.decimalSeparator == true ? "." : ""}
      precision={
        props?.decimalSeparator == true
          ? props?.precision
            ? props?.precision
            : "2"
          : ""
      }
      onBlur={() => setNum()}
      onChange={(e) => {

        setNumValue(parseFloat(e)); //Yasvi Patel (because when we remove default zeroes from NumberBox and enter text it converts it to "" and while POST this is causing an error)
        if (props.setEdit) {
          props.setEdit(parseFloat(e));
        }
      }}
      autoFocus={props?.autoFocus}
      onKeyDown={(e) => typeof props.onKeyDown == "function" ? props.onKeyDown(e) : null}

    // min={getmin(this.props)}
    // max={this.props.lrange ? this.props.nurange : undefined}
    />
  );
}
