import { Alert, Button, Center, Grid, Group, Paper, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { GetVoucherFormatData } from "../../utils/PrintSlice/ReportDesignerSlice";
import Store from "../../utils/store";
import AccountListSlice, { GetAccountList } from "../../utils/slices/AccountListSlice";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import GlobalClass from "../../utils/GlobalClass";
import DataGrid from "../../components/DataGrid";
import { IconInfoCircle } from "@tabler/icons-react";

export default function VoucherFmtForm(props) {

  console.log("VoucherFmtForm =>", props)

  const [PrintMode, setPrintMode] = useState([
    { "DisplayMember": "Windows Mode", "ValueMember": "W" },
    { "DisplayMember": "DOS Mode", "ValueMember": "D" }
  ])

  const [VouFormatObj, setVouFormatObj] = useState({
    "ReportType": "",
    "Type": "",
    "ReportFor": "",
    "LabelType": ""
  })
  const [ReportTypeObj, setReportTypeObj] = useState({})

  const { height } = useWindowDimensions();

  const dispatch = useDispatch()

  const VoucherFormatFormData = useSelector((state) => state?.ReportDesigner?.VoucherFormatFormData)
  const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

  const { C50, S11, VF1, VF2, VF3 } = useSelector((state) => state?.ReportDesigner?.VoucherFormatFormData)

  const { VouFormatisLoading, VouFormathasError, VouFormatErrorMsg } = useSelector((state) => state?.ReportDesigner)



  let columns = [
    {
      accessor: "Description", // Convert index to string
      header: "Description",
      Cell: ({ row }) => {
        // console.log(row.original, "row.orginal");
        // return (
        //   <Button onClick={() => generateBill(row?.original)}>
        //     Generate Bill
        //   </Button>
        // );
      },

      maxSize: 100,
    },
  ];

  useEffect(() => {
    dispatch(GetVoucherFormatData())
  }, [])

  useEffect(() => {
    if (VoucherFormatFormData) {
      console.log("VoucherFormatFormData =>", VoucherFormatFormData)
    }
  }, [VoucherFormatFormData])

  // useEffect(() => {
  //   if (S11) {
  //     console.log("VoucherFormatFormData =>", VoucherFormatFormData);
  //     dispatch(GetAccountList({
  //       "id": seperate(S11)[0],
  //       "name": "",
  //       "type": "A",
  //       "p0": seperate(S11)[0],
  //       "p1": seperate(S11)[0],
  //       "p2": seperate(S11)[1],
  //       "p3": "",
  //       // "cWhere": seperate(S11)[2],
  //     }))
  //   }
  // }, [S11])

  // useEffect(() => {
  //   if (S11 && accList[seperate(S11)[0]]) {
  //     console.log("VoucherFormatFormData s11", accList[seperate(S11)[0]]);
  //   }
  // }, [S11, accList])

  const seperate = (e) => {
    var paraArr = e?.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr?.length == 1) {
      paraArr[1] = paraArr[0];
    }
    // console.log("VoucherFormatFormData s11", paraArr);
    return paraArr;
  };

  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    enableTableHead: false, //Hide Column
    enableBottomToolbar: false, //pagination remove
    mantineTableProps: { withColumnBorders: true },
    initialState: {
      density: "xs",
      expanded: true,
    },

    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => { },
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 100,
    }),
  });
  return (<>
    {
      VouFormatisLoading ? GlobalClass.RenderLoader("dots") :
        <Grid gutter={2}>
          <Grid.Col span={12}>
            <Grid.Col span={12} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>Report Type</Text>
              </Grid.Col>
              <Grid.Col span={10}>
                {/* <PLComboBox /> */}
                <PLDataGrid
                  value={VouFormatObj?.ReportType}
                  valexpr={"S11F01"}
                  
                  dispexpr={"S11F02"}
                  TmData={"P_S11~C~TFORM0000001~C~S11F00=S11F01 AND S11FTY LIKE '%V%'"}
                  setEdit={(e) => {
                    new Promise((resolve) => {
                      setVouFormatObj({ ...VouFormatObj, ReportType: e?.S11F02 })
                      resolve()
                    }).then(() => {
                      dispatch(GetAccountList({
                        name: "P_C50",
                        type: "A",
                        id: "P_C50",
                        p0: "P_C50",
                        p1: "P_C50",
                        p2: "TFORM0000001",
                        cWhere: `C50.FIELD02 LIKE '${e?.S11S92ID ?? ""}'`
                      }))
                    })

                    console.log("ReportType", e);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex" }}>
            {/* <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Print Mode</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={PrintMode}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                />
              </Grid.Col>
            </Grid.Col> */}

            <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  value={VouFormatObj?.Type}
                  data={VF1}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    // console.log(e);
                    setVouFormatObj({ ...VouFormatObj, Type: e })
                  }}
                />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Report For</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  value={VouFormatObj?.ReportFor}
                  data={VF2}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    setVouFormatObj({ ...VouFormatObj, ReportFor: e })
                  }}
                />
              </Grid.Col>
            </Grid.Col>

          </Grid.Col>

          <Grid.Col span={12} style={{ display: "flex" }} >


            <Grid.Col span={6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Label Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  value={VouFormatObj?.LabelType}
                  data={VF3}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    setVouFormatObj({ ...VouFormatObj, LabelType: e })
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          {/* <Grid.Col span={12}> */}
          <Grid.Col span={12} style={{ padding: "5px 0px" }}>
            {/* <MantineReactTable table={table} /> */}

            {VouFormatObj?.ReportType && <DataGrid
              // ValueEdit={(e) => {
              //   console.log("ValueEdit", e);
              //   if(e != selectedRow){
              //   setSelectedRow(e);
              //   }
              //   // setSelected(e?.FIELD00);
              // }}
              // selectedData={{[selectedRow?.FIELD01]:selectedRow?.FIELD01}}
              selectionId={"FIELD01"}
              setPopOver={() => { }}
              cWhere={`C50.FIELD02 LIKE '%${ReportTypeObj?.S11S92ID ?? ""}%'`}
              obj={{
                name: seperate(C50)?.[0],
                type: "A",
                id: seperate(C50)?.[0],
                p0: seperate(C50)?.[0],
                p1: seperate(C50)?.[0],
                p2: seperate(C50)?.[1],
                // cWhere:`C50.FIELD02 LIKE '%${ReportTypeObj?.S11S92ID ?? ""}%'`
              }}
              // data={UserName}
              isFormatDropdown={false}
              isRefresh={false}
              form={true}
              isButton={false}
              size={0.3}
              
            // btnName="Print"
            />
            }
            {/* <Alert variant="light" color="blue" title="Not Available" icon={<IconInfoCircle />}>
            Format Not Available!!!
        </Alert> */}
          </Grid.Col>
          <Grid.Col span={12}>
            <Center mt={5}>
              <Group spacing={"xs"}>
                <Button
                  size="xs"
                  variant="light"
                  compact
                  onClick={()=>console.log("VouFormatObj =>",VouFormatObj)}
                >Submit</Button>

                {/* <Button size="xs" variant="light" compact>New</Button>
                <Button size="xs" variant="light" compact>Edit</Button>
                <Button size="xs" variant="light" compact>Copy</Button>
                <Button size="xs" variant="light" compact>import</Button>
                <Button size="xs" variant="light" compact>Cancel</Button> */}
              </Group>
            </Center>
          </Grid.Col>
        </Grid>
    }
  </>)
}
