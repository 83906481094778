import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const ProductPopUpType = (props) => {
    const { obj, value, setEditSend } = props
    const ProductPopTypeData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    // const P_APPOPTYPE = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPE)
    // const P_APPOPTYPEDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPEDT)

    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    function extractFields(input) {
        let parts = input.split("||")
        // console.log("parts", parts)
        let result = parts.map((p) => {
            let fields = p.split("#");
            // console.log("fields",fields)
            let firstField = fields[1]; 
            let secondField = fields[2];
            // console.log("fierstField",firstField)
            // console.log("secondField",secondField)
            if (/^[A-Z]$/.test(firstField)) {
                return `'${firstField}'+${secondField}`;
            } else {
                return firstField; 
            }
        })
        console.log("extractFields_result",result)
        return result.join(",")
    }


    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    ProductPopTypeData &&
                    <PLDataGrid
                        // width={"500px"}
                        dropdownPosition={true}
                        TmData={ProductPopTypeData?.P_APPOPTYPE}
                        data={ProductPopTypeData?.P_APPOPTYPEDT}
                        valexpr={"S16F04"}
                        dispexpr={"S16F35"}
                        value={extractFields(value)}
                        isMulti={true}
                        setEdit={(e) => {
                            // console.log('e==>>',e)
                            // setEditSend(e?.S16F03)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m, i) => {
                                    // val.push(m.original)
                                    const S16F02 = m?.original?.S16F02;
                                    const S16F04 = m?.original?.S16F04;
                                    const S16F05 = m?.original?.S16F05;
                                    const S16F35 = m?.original?.S16F35
                                    const extractVal = S16F04.split("+")
                                    console.log("extractVal", extractVal)
                                    const charVal = extractVal[0].replace(/^'|'$/g, '')
                                    const fieldVal = extractVal[1]
                                    let formattedString = ""
                                    if (charVal == undefined) {
                                        formattedString = `${S16F02}#${fieldVal}#${S16F05}#${"FIELD02"}#${S16F35}`;
                                    } else if (fieldVal == undefined) {
                                        formattedString = `${S16F02}#${charVal}#${S16F05}#${"FIELD02"}#${S16F35}`;
                                    } else {
                                        formattedString = `${S16F02}#${charVal}#${fieldVal}#${S16F05}#${"FIELD02"}#${S16F35}`;
                                    }
                                    val.push(formattedString)
                                })
                                const result = val.join('||').replaceAll(" ", "");
                                console.log("result", result)
                                setEditSend(result)
                            } else {
                                setEditSend("")
                            }
                        }}
                    />
            }
        </div>
    )
}

export default ProductPopUpType