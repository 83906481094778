import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { Box, Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import FormatForm from "./FormatForm";
import Store from "../../utils/store";
import {
  GetVouchNum,
  GetVouchNumData,
} from "../../utils/slices/VoucherNumSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import DataGrid from "../../components/DataGrid";
import { ModalFunction } from "../../utils/slices/ModalSlice";

export default function FormatListTable(props) {
  const { obj, index } = props;
  const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  // const [activeBtn, setActiveBtn] = useState(true);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState("");
  const [listObj, setListobj] = useState(null);
  let pageformat = "";
  const columnHelper = createColumnHelper();
  // console.log(obj, "hhbh-");
  const p0 = obj?.p0;
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: () => {},
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const {
    hasError,
    ErrorMsg,
    VoucherNumTemp: tmFrmt,
    isLoading: tmIsloadong,
    VoucherNumdata: genData,
  } = useSelector((state) => state?.VoucherNum);

  // const {isLoading ,hasError,ErrorMsg,Data } = useSelector((state) => state?.ReportDesigner);

  // console.log(genData, "genData", tmFrmt, ErrorMsg);

  // const tabData = genData?.jData;
  useEffect(() => {
    if (!tmIsloadong && tmFrmt && tmFrmt?.oDCFrmt && tmFrmt?.oDCFrmt?.aDCol) {
      var cols = [];
      tmFrmt?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v.ColCap,
            enableHiding: true,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });

      setColumns(cols);
    }
  }, [tmFrmt]);
  function EditFormatForm(row) {
    // console.log(obj?.text, "OnNewBtn", row?.original);
    let newObj = {
      p0: "E",
      id: row?.original?.FIELD01,
      text: obj?.text,
    };
    // console.log(newObj, "newObj-");
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>Edit</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => <FormatForm obj={newObj} tabObj={obj} index={i} />,
        MClose: true,
        onclose: () => {},
        closeOnClickOutside: true,
      })
    );
    setRowSelection(false);
  }
  function handleRowDblClk(row, cell) {
    EditFormatForm(row);
  }
  const table = useMantineReactTable({
    data: genData?.jData ?? [],
    columns,
    mantineTableProps:{withColumnBorders:true},
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    initialState: {
      density: "xs",
      expanded: true,
    },
    state: {
      rowSelection,
      globalFilter: search,
    },

    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {
        handleRowDblClk(row, cell);
      },
      onClick: () => {
        // setActiveBtn(false);
        // console.log(row?.original, "row?.original?.FIELD00=");
        setEditData(row?.original?.FIELD01);
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
      },
      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
      height: 30,
    }),
  });

  useEffect(() => {
    if (hasError && !tmIsloadong) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);
  useEffect(() => {
    const fetchData = async () => {
      if (p0 == "S") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_SMS" }));
        await Store.dispatch(GetVouchNum({ RepId: "P_SMS" }));
      } else if (p0 == "E") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_EMAIL" }));
        await Store.dispatch(GetVouchNum({ RepId: "P_EMAIL" }));
      } else if (p0 == "W") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_WAPP" }));
        await Store.dispatch(GetVouchNum({ RepId: "P_WAPP" }));
      }
    };
    fetchData();
  }, []);

  return (
    <>
     
      {tmIsloadong ? (
        GlobalClass.RenderLoader("dots")
      ) : ( <>
          <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            } else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj?.title}
        size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
        <Box>
          <Grid>
            {/* {!SaveLoading ? ( */}
            <>
              <Grid.Col span={12}>
                <PLTextBox
                  placeholder="Search here..."
                  value={search}
                  setEdit={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
                {/* <DataGrid
                  ValueEdit={(e) => {
                    console.log("ValueEdit", e);
                    // setSelected(e?.FIELD00);
                  }}
                  setPopOver={() => {}}
                  obj={obj}
                  form={false}
                  isButton={true}
                  data={tabData}
                  // btnName="Print"
                /> */}
              </Grid.Col>
            </>
          </Grid>
        </Box>
        <ButtonPanel
        // tableRef={TableRef}
        data={tmFrmt?.oS60?.DTHKey}
        Skey={table.getSelectedRowModel().flatRows[0]}
        from={obj?.id}
        btnPnlObj={obj}
      />
        </>
      )}
      
    </>
  );
}
