import { Button, Center, Grid, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLComboBox from "./PLComboBox";
import PLTextBox from "./PLTextBox";
import PLDataGrid from "./PLDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { TransactionApi } from "../utils/TransactionSlices/Transaction";
import {
  GetAccountList,
  getRangeBtnState,
} from "../utils/slices/AccountListSlice";
import { ModalDelete } from "../utils/slices/ModalSlice";
import GlobalClass from "../utils/GlobalClass";

const PLRange = (props) => {
  // console.log("props here",props)
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector(
    (state) => state.Transaction?.TransactionData?.T92DT
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const currentDate = useSelector((state) => state?.AccountList?.currentDate);
  const rangeBtnState = useSelector(
    (state) => state?.AccountList?.rangeBtnState
  );
  const isLoading = useSelector((state) => state?.Transaction?.isLoading);
  const hasError = useSelector((state) => state?.Transaction?.hasError);
  const ErrorMsg = useSelector((state) => state?.Transaction?.ErrorMsg);
  const flagDate = useSelector((state) => state?.AccountList?.flag);
  const GetGeneral = useSelector((state) => state.Language.General);
  const date1 = new Date();
  // console.log("props in range", props);
  // console.log("currentDate", currentDate);
  // console.log(
  //   "accList=>",
  //   accList[props?.obj?.page],
  //   "tmList=>",
  //   tmList[props?.obj?.page],
  //   "AccNameisLoading=>",
  //   AccNameisLoading[props?.obj?.page]
  // );

  const [date, setDate] = useState({});
  // const [notField02,setNotField02]=useState();
  const [prefix, setPrefix] = useState();
  const [prefix1, setPrefix1] = useState("");
  const [bnFrom, setBnFrom] = useState("");
  const [bnTo, setBnTo] = useState("");
  const [currentDate1, setCurrentDate1] = useState({});
  const [StrtDt, setStrtDt] = useState();
  const [EndDt, setEndDt] = useState();
  const formRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("rangeBtnState", rangeBtnState);
    if (rangeBtnState == true || rangeBtnState?.[props?.obj?.page] == true) {
      dispatch(TransactionApi({ ...props.obj, p0: "A" }));
    }
  }, []);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  useEffect(() => {
    // console.log("currentDate",currentDate)
    setCurrentDate1(currentDate);
  }, [currentDate]);

  useEffect(() => {
    // console.log("rangeBtnState?.[props?.obj?.page]?.rangeBtnState",rangeBtnState?.[props?.obj?.page])
    // console.log(
    //   "rangeBtnState?.[props?.obj?.page]==false h",
    //   rangeBtnState?.[props?.obj?.page]
    // );

    // console.log(
    //   "rangeBtnState?.[props?.obj?.page]==false && bnFrom== && bnTo=",
    //   rangeBtnState?.[props?.obj?.page] == false && !bnFrom && !bnTo
    // );
    if (rangeBtnState?.[props?.obj?.page] == false && !bnFrom && !bnTo) {
      // console.log("inside here");
      Promise.resolve(dispatch(ModalDelete(props.index)))
        .then(() => {
          dispatch(
            getRangeBtnState({ id: [props.obj.page], rangeBtnState: true })
          );
        })
        .then(() => {
          dispatch(
            GetAccountList({
              ...props?.obj?.BtnPnlObj,
              StrtDt: dateFormater2(currentDate?.[props?.obj?.page]?.From),
              EndDt: dateFormater2(currentDate?.[props?.obj?.page]?.To),
            })
          );
        });
    }
  }, [rangeBtnState]);

  // function modalDeleteFun () {
  //   dispatch
  // }
  // program to check leap year
  // function checkLeapYear(year) {
  //   //three conditions to find out the leap year
  //   if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // let lom = "";
  // let m = parseInt(date1.getMonth() + 1);
  // switch (m.toString()) {
  //   case "01":
  //     lom = 31;
  //     break;
  //   case "02":
  //     lom = checkLeapYear(date1.getFullYear()) ? 29 : 28;
  //     break;
  //   case "03":
  //     lom = 31;
  //     break;
  //   case "04":
  //     lom = 30;
  //     break;
  //   case "05":
  //     lom = 31;
  //     break;
  //   case "06":
  //     lom = 30;
  //     break;
  //   case "07":
  //     lom = 31;
  //     break;
  //   case "08":
  //     lom = 31;
  //     break;
  //   case "09":
  //     lom = 30;
  //     break;
  //   case "10":
  //     lom = 31;
  //     break;
  //   case "11":
  //     lom = 30;
  //     break;
  //   case "12":
  //     lom = 31;
  //     break;
  //   default:
  //     break;
  // }

  // const dateFormater = (date, temp) => {
  //   let result;
  //   if (temp === 0) {
  //     var paraArr = date?.split("T");
  //     var paraArr1 = new Date(paraArr);
  //     result = paraArr1;
  //   } else if (temp === 1) {
  //     let year = date.toLocaleString("en-IN", { year: "numeric" });
  //     let month = date.toLocaleString("en-IN", { month: "2-digit" });
  //     let day = date.toLocaleString("en-IN", { day: "2-digit" });
  //     let date1 = year + month + day;
  //     const date2 =
  //       date1?.slice(0, 4) +
  //       "-" +
  //       date1?.slice(4, 6) +
  //       "-" +
  //       date1?.slice(6, 8);
  //     result = date2;
  //   }
  //   return result;
  // };

  const dateFormater2 = (inputDate) => {
    // Yasvi Patel
    const date = new Date(inputDate);
    // console.log("date inside", inputDate);
    // Extract components
    const year = date.getUTCFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    // Get the timezone offset
    const timezoneOffset = -date.getTimezoneOffset(); // in minutes
    const offsetHours = String(
      Math.floor(Math.abs(timezoneOffset) / 60)
    ).padStart(2, "0");
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(
      2,
      "0"
    );
    const timezoneString =
      (timezoneOffset >= 0 ? "+" : "-") + offsetHours + ":" + offsetMinutes;

    // Construct the final output
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00${timezoneString}`;

    return formattedDate;
  };

  const handleOkButton = () => {
    // console.log("currentDate1", currentDate1);
    Promise.resolve(
      bnFrom?.trim() !== "" &&
        bnTo?.trim() !== "" &&
        (prefix1?.trim() !== "" || prefix) &&
        dispatch(
          GetAccountList({
            ...props?.obj?.BtnPnlObj,
            StrtDt: dateFormater2(currentDate?.[props?.obj?.page]?.From),
            EndDt: dateFormater2(currentDate?.[props?.obj?.page]?.To),
            // cWhere: `T41.FIELD12 BETWEEN ${ bnFrom >= 1 && bnFrom <=9 ? prefix1 + bnFrom?.toString().padStart(2, 0) :prefix1 + bnFrom} AND ${
            //   bnTo >= 1 && bnFrom <=9 ? prefix1+bnTo?.toString().padStart(2, 0) : prefix1+bnTo
            // }`,
            // cWhere: `TRIM(T41.FIELD12)='${bnFrom?.trim() >= 1 && bnFrom ?.trim() <=9 ?prefix1?.trim() + bnFrom?.trim()?.toString().padStart(2, 0) :prefix1?.trim() + bnFrom?.trim()}' OR TRIM(T41.FIELD12)='${bnTo?.trim() >= 1 && bnFrom?.trim() <=9 ?prefix1?.trim()+bnTo?.trim()?.toString().padStart(2, 0) :prefix1?.trim()+bnTo?.trim()}'`
           // cWhere: `TRIM(T41.FIELD12) Between '${
            //   bnFrom?.trim() >= 1 && bnFrom?.trim() <= 9
            //     ?   prefix1?.trim() + bnFrom?.trim()?.toString().padStart(2, 0)
            //     :  prefix1?.trim() + bnFrom?.trim() 
            // }' AND '${
            //   bnTo?.trim() >= 1 && bnFrom?.trim() <= 9
            //     ?  prefix1?.trim() + bnTo?.trim()?.toString().padStart(2, 0)
            //     :  prefix1?.trim() + bnTo?.trim() 
            // }'`,
            cWhere:`LEFT(LTRIM(T41VNO), 5) = '${prefix1?.trim()}' AND CAST(SUBSTRING(T41VNO, 6, 11) AS INT) BETWEEN '${
              bnFrom?.trim() >= 1 && bnFrom?.trim() <= 9
                ? bnFrom?.trim()?.toString().padStart(2, 0)
                :  bnFrom?.trim() 
            }' AND '${
              bnTo?.trim() >= 1 && bnFrom?.trim() <= 9
                ? bnTo?.trim()?.toString().padStart(2, 0)
                :  bnTo?.trim() 
            }'`
            
         
          })
        )
    )
      .then(() => {
        bnFrom?.trim() !== "" && bnTo?.trim() !== "" && (prefix1?.trim() !== "" || prefix)
          ? dispatch(
              getRangeBtnState({ id: [props.obj.page], rangeBtnState: false })
            )
          : dispatch(
              getRangeBtnState({ id: [props.obj.page], rangeBtnState: true })
            );
      })
      .then(() => {
        dispatch(ModalDelete(props.index));
      });
  };

  return hasError ? (
    <>
      <Text>{ErrorMsg?.message}</Text>
      <Button
        onClick={() => {
          if (props?.obj) {
            if (
              rangeBtnState == true ||
              rangeBtnState?.[props?.obj?.page] == true
            ) {
              dispatch(TransactionApi({ ...props.obj, p0: "A" }));
            }
          }
        }}
      >
        Retry
      </Button>
    </>
  ) : isLoading ? (
    GlobalClass?.RenderLoader("dots")
  ) : (
    <div ref={formRef}>
      <Grid gutter={4}>
        <Grid.Col span={12}>
          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12, margin: 0 }}>Select Prefix</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLDataGrid
                width={"500px"}
                position={"unset"}
                value={prefix1}
                TmData={T92}
                // data={T92DT}
                dispexpr={"FIELD02"}
                valexpr={"FIELD00"}
                setEdit={(e) => {
                  Promise.resolve(
                    // e?.FIELD02
                    //   ? setPrefix(e.FIELD02 + parseInt(e.FIELD03) + 1)
                    //   : """
                    // setPrefix1(e?.FIELD02)
                    e?.FIELD02!=="" ? setPrefix1(e.FIELD02) : setPrefix(e?.FIELD03)

                  )
                  // .then(()=>{
                  //  // e?.FIELD02!=="" ? setPrefix1(e.FIELD02) : setPrefix(e?.FIELD03)
                  // })
                  .then(() => {
                    let ele = document.getElementById("bnf");
                    if (ele) {
                      ele?.focus();
                    }
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          {/* <Grid.Col
            span={6}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          > */}
          <Grid.Col span={2}>
            <Text style={{ fontSize: 12 }}>Bill No. From</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox
              id={"bnf"}
              value={bnFrom}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              setEdit={(e) => {
                // console.log("e here", e.target.value);
                setBnFrom(e.target.value);
                // if (e.target.value >= 1 && e.target.value <= 9) {
                //   setBnFrom1(e.target.value.toString().padStart(2, 0));
                // }else{
                //   setBnFrom1(e?.target?.value);
                // }
              }}
            />
          </Grid.Col>
          {/* </Grid.Col> */}
          {/* <Grid.Col
            span={6}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          > */}
          <Grid.Col span={2}>
            <Text style={{ fontSize: 12, textAlign: "center" }}>To</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox
              value={bnTo}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, formRef);
              }}
              setEdit={(e) => {
                // console.log("e here", e.target.value.toString().padStart(2, 0));
                setBnTo(e.target.value);
                // if (e.target.value >= 1 && e.target.value <= 9) {
                //   setBnTo(e.target.value.toString().padStart(2, 0));
                // }
              }}
            />
          </Grid.Col>
        </Grid.Col>
        {/* </Grid.Col>  */}
        <Grid.Col span={12}>
          <Center>
            <Button
              style={{ width: "130px" }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                  handleOkButton();
                }
              }}
              onClick={handleOkButton}
            >
              Ok
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default PLRange;
