import React, { useEffect, useRef, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import DataGrid from '../../components/DataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { ProcessMasterData } from '../../utils/slices/ProcessMasterSlice'
import { ActionIcon, Button, Flex, Grid, Group, Text, Tooltip } from '@mantine/core'
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import { modals } from '@mantine/modals'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { notifications } from '@mantine/notifications'
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
import { ProcessMasterTable } from './ProcessMasterTable'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import Store from '../../utils/store'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'

export const ProcessMaster = (props) => {
    // console.log('ProcessMaster props=>', props);
    const { obj } = props;
    const formRef = useRef(null);
    const [M73Grd, setM73Grd] = useState(null)
    const [M73, setM73] = useState([])
    // const [newObj, setNewObj] = useState({
    //     "FIELD01": null,
    //     "FIELD02": null,
    //     "FIELD03": null,
    //     "FIELD04": null,
    //     "FIELD05": null,
    //     "FIELD06": null,
    //     "FIELD07": null,
    //     "FIELD08": null,
    //     "FIELD09": null,
    //     "FIELD26": null,
    //     "FIELD27": null,
    //     "FIELD00": null,
    //     "FLDUNQ": null,
    //     "FLDAED": null,
    //     "FLDBRC": null,
    //     "EGKEYID": 1
    // })
    // const [M71, setM71] = useState(null);
    const [M71Obj, setM71Obj] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD02_01": "",
        "FIELD02_02": "",
        "FIELD02_03": "",
        "FIELD03": "",
        "FIELD04": 0.0000,
        "FIELD05": 0.0,
        "FIELD06": "",
        "FLDUNQ": 4,
        "FLDAED": "",
        "FLDBRC": ""
    })

    const ProcessMData = useSelector((state) => state.ProcessMaster.ProcessMData)
    const { isLoading, hasError, ErrorMsg } = useSelector((state) => state.ProcessMaster)
    const SaveLoading = useSelector(state => state?.DataSaveLoadingSlice?.SaveLoading)

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)


    const dispatch = useDispatch();

    useEffect(() => {
        if (hasError && !isLoading) {
            console.log("ProcessMaster/ProcessMasterData ErrorMsg =>", ErrorMsg);
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message)
        }

    }, [isLoading, hasError, ErrorMsg])

    useEffect(() => {
        if (obj?.p0 == 'E') {
            dispatch(ProcessMasterData({ action: obj?.p0, code: obj?.id }))
            // Promise.resolve(dispatch(ProcessMasterData({ action: obj?.p0, code:obj?.id }))).then(()=>{
            //     Promise.resolve(dispatch(GetAccountList({
            //         "id": "T_M32C",
            //         "name": "",
            //         "type": "A",
            //         "p0": "T_M32C",
            //         "p1": "T_M32C",
            //         "p2": "",
            //         "p3": ""
            //       }))).then(() => dispatch(GetTMFormat({
            //         "id": "T_M32C",
            //         "name": "",
            //         "type": "A",
            //         "p0": "T_M32C",
            //         "p1": "T_M32C",
            //         "p2": "",
            //         "p3": ""
            //       })))
            // })
        } else {
            dispatch(ProcessMasterData({ action: obj?.p0, code: '' }))
            // Promise.resolve(dispatch(ProcessMasterData({ action: obj?.p0, code:'' }))).then(()=>{
            //     Promise.resolve(dispatch(GetAccountList({
            //         "id": "T_M32C",
            //         "name": "",
            //         "type": "A",
            //         "p0": "T_M32C",
            //         "p1": "T_M32C",
            //         "p2": "",
            //         "p3": ""
            //       }))).then(() => dispatch(GetTMFormat({
            //         "id": "T_M32C",
            //         "name": "",
            //         "type": "A",
            //         "p0": "T_M32C",
            //         "p1": "T_M32C",
            //         "p2": "",
            //         "p3": ""
            //       })))
            // })
        }
    }, [obj])

    useEffect(() => {
        if (ProcessMData && !isLoading) {
            // setM73(ProcessMData?.M73)
            // setM73Grd(ProcessMData?.M73Grd)
            setM71Obj(ProcessMData?.M71)
        }
    }, [ProcessMData])

    useEffect(() => {
        const formElement = formRef.current;
        console.log("formRef", formRef);

        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef])

    const postM71M73 = () => {


        dispatch(DataSaveLoading(true))
        GlobalClass.Notify(
            "info",
            obj.p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
        );

        let M73Arr = M73?.filter((m) => (m?.FIELD04 != "" && m?.FIELD04 != null))

        let PostObj = { M71: M71Obj, M73: [...M73Arr] }
        // console.log("postM71M73====>", PostObj);

        var data = {
            "CorpID": gensetting.getcorpid(),
            "cUser": gensetting.getcuser(),
            "cPass": gensetting.getcpass(),
            "CmpNo": gensetting.getcmpno(),
            "cAction": obj.p0,
            "cCode": "",
            "cOPara": "",
            "cSData": JSON.stringify(PostObj)
        };

        let param =
            JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": obj.p0,
                "cCode": "",
                "cOPara": "",
            });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch(GlobalClass.ApiUrl + GlobalClass.PostM71Ent + "?pa=" + param, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status == 'SUCCESS') {
                    let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

                    Promise.resolve(
                        GlobalClass.Notify(
                            "success",
                            obj.p0 == "A" ? "Added" : "Edited",
                            obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                        )).then(() => {
                            if (obj?.p0 == "A") {
                                dispatch(ProcessMasterData({ action: obj?.p0, code: '' }))
                            } else {
                                dispatch(ModalDelete(props.index))
                            }
                        }).then(() => dispatch(DataSaveLoading(false))).then(() => dispatch(GetAccountList({
                            'id': '01980001',
                            'name': 'Process Master',
                            'p0': 'MT',
                            'p1': 'T_M71',
                            'p2': "",
                            'p3': "",
                            'type': "A",
                            'pagination': PaginationObj['01980001']
                        })))
                } else if (data.status == 'FAIL') {
                    Promise.resolve(GlobalClass.Notify("error", data?.status, data?.message)).then(() => dispatch(DataSaveLoading(false)))
                }
            }).catch((e) => Promise.resolve(GlobalClass.Notify("error", "Error", `${e.message}`)).then(() => dispatch(DataSaveLoading(false))))
    }

    return (<>
        {
            hasError ? <>
                <Grid>
                    <Grid.Col span={12}>
                        <Text>{ErrorMsg.message}</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            onClick={() => {
                                if (obj?.p0 == 'E') {
                                    dispatch(ProcessMasterData({ action: obj?.p0, code: obj?.id }))
                                } else {
                                    dispatch(ProcessMasterData({ action: obj?.p0, code: '' }))
                                }
                            }}
                        >Retry</Button>
                    </Grid.Col>
                </Grid>
            </> :
                <div ref={formRef}>
                    {
                        (SaveLoading == true || isLoading == true) ? GlobalClass.RenderLoader('dots') :
                            // isLoading == true ? GlobalClass.RenderLoader('dots') :
                            <>
                                <Grid style={{ margin: "10px 0px" }} ref={formRef}>
                                    <Grid.Col span={12} style={{ padding: 0, display: "flex" }}>
                                        <Grid.Col span={6} style={{ padding: 0, display: "flex" }}>
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>
                                                    Process Name
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLTextBox
                                                    autoFocus={true}
                                                    // disabled={obj.p0 == 'A' ? false : true}
                                                    value={M71Obj?.FIELD02} // FIELD02
                                                    setEdit={(e) => {
                                                        // console.log(e)
                                                        setM71Obj({ ...M71Obj, FIELD02: e.target.value })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                        <Grid.Col span={6} style={{ padding: 0, display: "flex" }}>
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12 }}>
                                                    Formula Quality
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLNumberBox // FIELD04
                                                    decimalSeparator={true}
                                                    precision={3}
                                                    value={M71Obj?.FIELD04}
                                                    setEdit={(e) => {
                                                        // console.log(e)
                                                        setM71Obj({ ...M71Obj, FIELD04: e })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid.Col>

                                    <Grid.Col span={12}>
                                        <ProcessMasterTable
                                            // M71Obj={M71Obj}
                                            obj={obj}
                                            // M73={M73}
                                            // M73Grd={M73Grd}
                                            getM73={(e) => {
                                                setM73(e)
                                            }}
                                        />
                                    </Grid.Col>

                                    <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Group spacing={'xs'} position="center" display={'flex'} style={{ marginTop: 10, width: '100%' }}>
                                            <Button
                                                style={{ height: "30px", width: "100px" }}
                                                onClick={() => postM71M73()}
                                            >Ok</Button>
                                        </Group>
                                    </Grid.Col>
                                </Grid>
                            </>
                    }
                </div>
        }
    </>)
}
