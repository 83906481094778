import React from "react";
import StockTransferForm from "./StockTransferForm";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import {
  GetNullTransactionData,
  TransactionDeleteApi,
} from "../../../utils/TransactionSlices/Transaction";
import PLRange from "../../../PlusComponent/PLRange";
// import FormatListTable from '../../SMSEmailWpBtn/FormatListTable';

export function VouEntAct(props) {
  const OBJ = JSON.parse(props);
  // console.log("props VouEntAct===============>", data.p0);

  if (OBJ?.p0 == "A") {
    // console.log(data?.p0 , "data?.p0 ")
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "98%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => <StockTransferForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ?.id && OBJ?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => <StockTransferForm obj={OBJ} index={i} />,
        MClose: true,
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Store.dispatch(TransactionDeleteApi(OBJ));
    }
  } else if (OBJ.p0 === "U" && OBJ?.id) {
    console.log(OBJ?.id, "data?.id");

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        // position:'right',
        MBody: (i) => <StockTransferForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(OBJ))
    }
  }else if(OBJ.p0=="R"){ //Range Button
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        MBody: (i) => <PLRange obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
}
