import { Button, Grid, Modal, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import { saveAs } from "file-saver";
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import { useDispatch, useSelector } from 'react-redux'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { createColumnHelper } from '@tanstack/react-table'

const BackUpForm = (props) => {
    const { obj } = props

    const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
    const {
        AccountListData: accList,
        TMFormatData: tmListData,
        isLoading: AccNameisLoading,
    } = useSelector((state) => state.AccountList);
    const [objSave, setObjSave] = useState({})
    const [value, setValue] = useState({
        email: [],
        mobile: [],
        supportEmail: ""
    })
    const [backupVal, setBackupVal] = useState("D")
    const [backupLoading, setIsBackupLoading] = useState(false)
    const [cOParaVal, setcOParaVal] = useState("")
    let dra = {
        title: "",
        size: "md",
        open: false,
        onClickCloseBtn: () => { },
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [modalClosed, setModalClosed] = useState();
    let column = [];
    let backupData = [
        {
            DisplayMember: "Download File",
            ValueMember: "D"
        },
        {
            DisplayMember: "E-Mail",
            ValueMember: "E"
        },
        {
            DisplayMember: "WhatsApp",
            ValueMember: "W"
        },
        {
            DisplayMember: "Send for Support",
            ValueMember: "S"
        }
    ]

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper();

    useEffect(() => {
        let CmpListarr = localStorage.getItem("authUser")
        setObjSave(JSON.parse(CmpListarr))
    }, [])

    useEffect(() => {
        setData(accList?.P_M02);
    }, [accList?.P_M02]);

    useEffect(() => {
        if (backupVal == "S") {
            setValue({ ...value, supportEmail: "support@aakashinfo.com" })
        }
    }, [backupVal])

    useEffect(() => {
        column?.push(
            columnHelper.accessor("FIELD61", {
                header: "Party Name",
                size: 100,
                enableEditing: false,
            })
        );
        column?.push(
            columnHelper.accessor("FIELD05", {
                header: "City",
                size: 100,
                enableEditing: false,
            })
        );
        if (backupVal == "E") {
            column?.push(
                columnHelper.accessor("FIELD43", {
                    header: "email id",
                    size: 100,
                    enableEditing: false,
                })
            );
        } else if (backupVal == "W") {
            column?.push(
                columnHelper.accessor("FIELD35", {
                    header: "Mobile",
                    size: 100,
                    enableEditing: false,
                })
            );
        }

        setColumns(column);
    }, [data, backupVal]);

    let companyName = objSave?.cmpList?.filter((a) => a?.cmpNo == localStorage.getItem("cmp"))
    // console.log("companyName",companyName)

    const bookDataCalled = () => {
        // setbookDataCalledState(true);
        dispatch(
            GetAccountList({
                id: "P_M02",
                name: "",
                p0: "P_M02",
                p1: "P_M02",
                p2: "",
                p3: "",
                type: "A",
            })
        );
        setDrawerObj({
            ...DrawerObj, title: <Text fw={700}>Address Book</Text>, open: true,
            size: "70%",
            position: "right",
        });
    };

    useEffect(() => {
        if (modalClosed == true) {
            let arr = {
                email: [],
                mobile: []
            }
            if (Object.keys(rowSelection)?.length > 0) {
                Array.from(Object.keys(rowSelection)).map((item) => {
                    arr?.email?.push(accList?.P_M02[item]?.FIELD43);
                    arr?.mobile?.push(accList?.P_M02[item]?.FIELD35);
                    setValue({ ...value, email: arr?.email, mobile: arr?.mobile })
                })
            } else {
                setValue({ ...value, email: [], mobile: [] });
            }
        }
    }, [modalClosed, rowSelection]);

    const handleFile = (e, cOPara) => {
        setIsBackupLoading(true)
        console.log("handleFile", [{ "backupVal": e, "cOPara": cOPara }])
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cOPara: cOPara
        }
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cOPara: cOPara
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.DownloadFile + "?pa=" + param,
            requestOptions
        )
            .then(async (response) => {
                if (response.ok) {
                    let data = await response.blob()
                    console.log("response.blob()", data)
                    return data;
                } else {
                    GlobalClass.Notify("error", response?.status, response.message)
                }
            })
            .then((blob) => {
                setIsBackupLoading(false)
                let path = cOPara
                const filename = path.split('\\').pop();
                console.log("filename", filename)
                saveAs(blob, filename);
            })
            .catch((error) => {
                console.log("catch_error", error)
                setIsBackupLoading(false)
                GlobalClass.Notify("error", "Error", error.message);
            });
    }

    const handleSubmitBackup = () => {
        setIsBackupLoading(true)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
        }
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno()
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.BackupDB + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data)
                if (data.status == "SUCCESS") {
                    setIsBackupLoading(false)
                    // setcOParaVal(data?.message)
                    handleFile(backupVal, data?.message)
                } else {
                    setIsBackupLoading(false);
                    GlobalClass.Notify("error", data?.status, data?.message)
                }
            })
            .catch((error) => {
                setIsBackupLoading(false);
                GlobalClass.Notify("error", "Error", error.message);
            });
        // if (cOParaVal && backupVal == "D") {
        //   return handleFile(backupVal, cOParaVal)
        // }
    }

    const table = useMantineReactTable({
        columns,
        data: data ? data : [],
        mantineTableContainerProps: {
            sx: { maxHeight: "350px", minHeight: "300px" },
        },
        enableTopToolbar: false,
        enablePagination: true,
        enableRowSelection: true,
        enableMultiRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            sx: { cursor: "pointer" },
        }),
        state: { pagination: pagination, showLoadingOverlay: AccNameisLoading?.P_M02, rowSelection: rowSelection },
        initialState: {
            density: "0px",
            pagination: pagination
        }
    });

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={true}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: (e) => {
                        setModalClosed(true);
                        if (typeof DrawerObj?.onClickCloseButton == "function") {
                            DrawerObj?.onClickCloseButton();
                        } else {
                            setDrawerObj(dra);
                        }
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }

            // scrollAreaComponent={ScrollArea.Autosize}
            >
                <MantineReactTable table={table} />
            </Modal>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Company Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            disabled
                            value={companyName?.length > 0 ? companyName[0]?.cmpName : ""}
                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Backup</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLComboBox
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            data={backupData}
                            value={backupVal}
                            setEdit={(e) => {
                                // console.log("combo value", e)
                                setBackupVal(e)
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {backupVal == "D" ? "" :
                            <PLTextBox
                                disabled={backupVal == "S" ? true : false}
                                value={backupVal == "E" ? value.email : backupVal == "W" ? value.mobile : value.supportEmail}
                                setEdit={(e) => {
                                    backupVal == "E" ?
                                        setValue({ ...value, email: e.target.value }) :
                                        setValue({ ...value, mobile: e.target.value })
                                }}
                            />
                        }
                    </Grid.Col>
                    <Grid.Col span={2}>
                        {
                            (backupVal == "E" || backupVal == "W") &&
                            <Button
                                compact
                                onClick={bookDataCalled}
                            >
                                <Text size={12}> {backupVal == "E" ? "eMail Book" : "Phone Book"}</Text>
                            </Button>
                        }
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        onClick={() => backupVal == "D" && handleSubmitBackup()}
                        loading={backupLoading}
                    >OK</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default BackUpForm