import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { modals } from "@mantine/modals";
import GlobalClass from "../../../utils/GlobalClass";
import { useSelector } from "react-redux";
import { GetTMFormat } from "../../../utils/slices/AccountListSlice";
import Store from "../../../utils/store";

const PendingIssue = (props) => {
  const [columns, setColumns] = useState([]);
  const [t02Jobj, setT02Jobj] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  // console.log(props, "PendingIssueCom", t02Jobj);
  const { setDrawerOBJ, DrawerOBJ, data, setEmpty } = props;
  const columnHelper = createColumnHelper(); 

  const { JWListData, hasError, isLoading, errorMsg } = useSelector(
    (state) => state?.GetPendingIssue
  );
  const { TMFormatData: tmList } = useSelector((state) => state.AccountList);

  const T02JDT = JWListData?.T02JDT ?? [];
  const T02J = JWListData?.T02J ?? "";
  const OrdCol = JWListData?.OrdCol ?? "";
  console.log(JWListData, "props--");
  useEffect(() => {
    console.log(errorMsg, "error2")
   if(hasError){
    Promise.resolve(GlobalClass.Notify("error", "Error: " + errorMsg.code, errorMsg.message))
    .then(()=>{
      setDrawerOBJ({ ...DrawerOBJ, open: false })
    });
   }
  }, [hasError, errorMsg])
  
  useEffect(() => {
    if (T02J) {
      console.log("tmList", tmList);
      Store.dispatch(
        GetTMFormat({
          id: T02J.toString().split("~C~")[0],
          name: "",
          type: "A",
          p0: T02J.toString().split("~C~")[0],
          p1: T02J.toString().split("~C~")[0],
          p2: T02J.toString().split("~C~")[1],
          p3: "",
        })
      );
    }
  }, [T02J, JWListData]);

  const P_JORECPI = tmList[T02J.toString().split("~C~")[0]];
  useEffect(() => {
    if (
      P_JORECPI !== undefined &&
      T02J !== undefined &&
      P_JORECPI instanceof Object
    ) {
      var cols = [];
      // tmList[M45.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?
      P_JORECPI?.oDCFrmt?.aDCol?.map((v, i) => {
        // console.log(v?.DispExpr, "DispExpr5", v?.ColCap, v?.lSearchCol)
        cols.push(
          columnHelper.accessor(v?.DispExpr, {
            header: v?.ColCap,
            enableHiding: true,
            enableColumnFilter: v?.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: "auto",
            id: v?.ColCap,

            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });
      setColumns(cols);
    }
  }, [T02J, P_JORECPI, JWListData]);
  const table = useMantineReactTable({
    data: T02JDT ? T02JDT : [],
    columns: columns,
    mantineTableProps:{withColumnBorders:true},
    enableRowSelection: true,
    enableStickyHeader:true,
    enableMultiRowSelection: true,
    getRowId: (row) => row?.id,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
  });

  // useEffect(() => {
  //   if ((T02JDT?.length == 0 && !isLoading) || hasError ) {
  //     // console.log(T02JDT, "T02JDT--", typeof setDrawerOBJ )
  //     // if (typeof setDrawerOBJ == "function" ) {
  //       console.log(T02JDT, "T02JDT--", DrawerOBJ)
  //       setEmpty(true)
  //       // setDrawerOBJ({ ...DrawerOBJ, open: false });
  //     // }
  //   }
  // }, [T02JDT, isLoading, hasError])
  // useEffect(() => {
  //   if ( hasError ) {
  //     // console.log(T02JDT, "T02JDT--", typeof setDrawerOBJ )
  //     // if (typeof setDrawerOBJ == "function" ) {
  //       console.log(T02JDT, "T02JDT--", DrawerOBJ)
  //       setDrawerOBJ({ ...DrawerOBJ, open: false });
  //     // }
  //   }
  // }, [ hasError])
  

  function SavePendingIssueData() {
    // console.log(typeof setDrawerOBJ == "function", "typeof setDrawerObj == ", setDrawerObj);

   
      let newArray = [];
      Promise.resolve(
        T02JDT?.map((n, i) => {
          if (i in rowSelection) {
            newArray.push(n);
          }
          setT02Jobj(newArray);
          props?.setData(newArray);
        })
      ).then(() => 
      //  typeof setDrawerOBJ == "function"
          // ?
           setDrawerOBJ({ ...DrawerOBJ, open: false })
          // : ""
      );


    // modals.closeAll();
  }
  // console.log(T02JDT, "rowSelection--", t02Jobj);

  // useEffect(() => {
  //   if(t02Jobj?.length > 0){

  //   }
  // }, [t02Jobj]);
  console.log(isLoading, "isLoading", T02J, tmList);
  return (
    <>

{hasError ? <>
          <Text>{errorMsg?.message}</Text>
          <Button onClick={() => {
            if (OrdCol) {
              Store.dispatch(
                GetTMFormat({
                  id: OrdCol.toString().split("~C~")[0],
                  name: "",
                  type: "A",
                  p0: OrdCol.toString().split("~C~")[0],
                  p1: OrdCol.toString().split("~C~")[0],
                  p2: OrdCol.toString().split("~C~")[1],
                  p3: "",
                })
              );
            }
          }}>Retry</Button>
        </>
   :   P_JORECPI == undefined || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <MantineReactTable table={table} />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group spacing={"xs"} position="center">
              <Button
                size="sm"
                variant="filled"
                compact
                onClick={() => SavePendingIssueData()}
              >
                OK
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};
export default PendingIssue;
