import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetYearDateUrl } from "../utils/slices/CompanySetupSlice";
import PLComboBox from "../PlusComponent/PLComboBox";
import { Alert, Button, Text } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import Store from "../utils/store";
import { ModalFunction } from "../utils/slices/ModalSlice";
import { modals } from "@mantine/modals";
import { YearAndDateTable } from "../FunctionsCall/YearAndDate/YearAndDateTable";
// import { Main_ChangeYear } from './BindFunWithPara';

export default function YearAndDate(props) {
  // console.log('YearAndDate props',props);

  const GetYearDateData = useSelector(
    (state) => state.CompanySetup?.GetYearDateData?.YearList
  );
  // const GetYearDateShow = useSelector((state) => state.CompanySetup?.GetYearDateData);

  const GetGeneral = useSelector((state) => state?.Language?.General);
  const YearAndDateSend = useSelector(
    (state) => state.UserList.YearAndDateSend
  );
  const { hasError, ErrorMsg } = useSelector((state) => state.Language);

  const [dateAndYear, setDateAndYear] = useState(null);
  const [OBJ, setOBJ] = useState({
    id: "02070001",
    name: "Change Year",
    p0: "",
    type: "A",
  });
  
  useEffect(() => {
    if (hasError) {
      Store.dispatch(
        ModalFunction({
          onclose: () => {
            Store.dispatch(
              ModalFunction({
                MTitle: "",
                MAction: false,
                MSize: "md",
                MBody: () => "",
                MClose: false,
              })
            );
          },
          MTitle: null,
          MAction: true,
          MSize: "md",
          MBody: () => (
            <Alert
              variant="outline"
              color="red"
              radius="md"
              title={"Error: " + ErrorMsg.code}
              icon={<IconAlertTriangle />}
            >
              {ErrorMsg.message}
            </Alert>
          ),
          MClose: false,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
          Style: {
            backgroundColor: "red",
          },
        })
      );
    }
  }, [hasError]);

  useEffect(() => {
    // console.log("YearAndDateSend",YearAndDateSend);
    // console.log("GetYearDateData",GetYearDateData);

    // if (YearAndDateSend != null) {
    //   setDateAndYear(YearAndDateSend)
    // } else {
    //   setDateAndYear(GetYearDateData[0])
    // }
    // console.log("GetGeneral?.dYEDate?.slice(8,9) ",GetGeneral?.dYEDate?.slice(8, 10) );
    // console.log("date here",GetGeneral?.dYEDate?.slice(0,4) +
    // GetGeneral?.dYEDate?.slice(5,7) +
    // GetGeneral?.dYEDate?.slice(8,10))
    // console.log("date hrer s", GetGeneral?.dYSDate?.slice(0,4) +
    // GetGeneral?.dYSDate?.slice(5,7) +
    // GetGeneral?.dYSDate?.slice(8,10))
    setDateAndYear({
      YearNo: localStorage?.getItem("YrNo"),
      endDate:
      GetGeneral?.dYEDate?.slice(0,4) +
    GetGeneral?.dYEDate?.slice(5,7) +
    GetGeneral?.dYEDate?.slice(8,10),
      strtDate:
      GetGeneral?.dYSDate?.slice(0,4) +
    GetGeneral?.dYSDate?.slice(5,7) +
    GetGeneral?.dYSDate?.slice(8,10)
    });

    // else{
    //   setDateAndYear(YearAndDateSend)
    // }
  }, [GetYearDateData, YearAndDateSend]);

  // console.log('YearAndDateSend==>', YearAndDateSend);
  // console.log('GetGeneral==>', GetGeneral);
  // console.log('dateAndYear==>', dateAndYear);

  return (
    <>
      <Button
        variant="outline"
        color="gray"
        size="xs"
        compact
        onClick={() => {
          return modals.open({
            title: <Text fw={700}>{OBJ.name}</Text>,
            size: "md",
            centered: true,
            withCloseButton: true,
            closeOnClickOutside: false,
            closeOnEscape: false,
            children: <YearAndDateTable OBJ={OBJ} />,
          });
        }}
      >
        {
        //  dateAndYear?.strtDate.slice(6, 8)  + "/" + dateAndYear?.strtDate.slice(4, 6) + "/" + dateAndYear?.strtDate.slice(0, 4) + " - " + dateAndYear?.endDate.slice(6, 8) + "/" + dateAndYear?.endDate.slice(4, 6) + "/" + dateAndYear?.endDate.slice(0, 4)
        dateAndYear?.strtDate.slice(0, 4) + " - " + dateAndYear?.endDate.slice(0, 4)
        }
        {/* test */}
      </Button>

      {/* {!GetYearDateData.isLoading && <PLComboBox
        data={
          GetYearDateData.isLoading ?
            [] :
            GetYearDateData?.GetYearDateData?.YearList?.map(
              (e) => (
                {
                  ...e,
                  label: e.strtDate.slice(0, 4) + "/" + e.strtDate.slice(4, 6) + "/" + e.strtDate.slice(6, 8) + " - " + e.endDate.slice(0, 4) + "/" + e.endDate.slice(4, 6) + "/" + e.endDate.slice(6, 8), value: parseInt(e.YearNo)
                }
              )
            )
        }
        value={GetGeneral?.oYear?.nYrNo}
        dispexpr={"label"}
        valexpr={"value"}
        setEdit={(e) => {
          console.log("e", e);
        }}
      />} */}
    </>
  );
}
