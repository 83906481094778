import GSTMasterForm from "./GSTMasterForm";
import Store from "../../utils/store";
import { modals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { DeleteGSTMasterGroup } from "../../utils/slices/GSTMasterSlice";
import { ModalFunction } from "../../utils/slices/ModalSlice";
// import { DeleteAccData } from "../../utils/slices/AccountGroupSlice";

export function M51AED(data, Skey) {
  const OBJ = JSON.parse(data);
  // console.log("MSAED", OBJ);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "md",
        // position:"right",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <GSTMasterForm OBJ={OBJ} index={i}  />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )

    //================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <GSTMasterForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        // position:"right",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <GSTMasterForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
    //========================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <GSTMasterForm OBJ={OBJ} />,
    // });
  }
  else if (OBJ.p0 === "D" && !OBJ.id == "") {
    if (window.confirm("Are you sure to delete selected data?")) {
      Store.dispatch(DeleteGSTMasterGroup({ cCode: OBJ.id, cAction: OBJ.p0, cId: OBJ.page }));
    } else {
      return null;
    }
  }
}
