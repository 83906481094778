import React from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Loader, Text } from "@mantine/core";
import { GetNullTransactionData, TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import JournalEntryForm from "./JournalEntryForm";
import PLRange from "../../../PlusComponent/PLRange";

export function VouEntAct(props) {

  const OBJ = JSON.parse(props);
  console.log(OBJ, "VouEntAct--");

  if (OBJ?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "98%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => <JournalEntryForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  }
     else if (OBJ?.id && OBJ?.p0 == "E") {
      Store.dispatch(
        ModalFunction({
          onclose: () => {},
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "98%",
          tab:OBJ.page.startsWith("P_")?false:true,
          MBody: (i) => <JournalEntryForm obj={OBJ} index={i} />,
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
          onClickCloseButton: () => {
            Promise.resolve(Store.dispatch(GetNullTransactionData()));
          },
        })
      );
    }
    else if (OBJ.p0 === "D" && OBJ.id) {
      if (window.confirm("Confirm to Delete ?")) {
        Store.dispatch(TransactionDeleteApi(OBJ));
      }
    } else if (OBJ.p0 === "U" && OBJ?.id) {
      console.log(OBJ?.id, "OBJ?.id")
  
      // UserField
      Store.dispatch(
        ModalFunction({
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "50%",
          // position:'right',
          MBody: (i) => <JournalEntryForm obj={OBJ} index={i} />,
          MClose: true,
          onclose: () => {},
          onClickCloseButton: () => {
            Promise.resolve(Store.dispatch(GetNullTransactionData()));
          },
        })
      );
    }
    else if(OBJ.p0=="R"){ //Range Button
      Store.dispatch(
        ModalFunction({
          onclose: () => {},
          MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
          MAction: true,
          MSize: "98%",
          position:"right",
          MBody: (i) => <PLRange obj={OBJ} index={i} />,
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
        })
      );
    }
}
