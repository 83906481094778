import { modals } from '@mantine/modals';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFinYear, GetUserList, SetYearAndDateData } from '../../utils/slices/UserListSlice';
import { ClearTabs } from '../../utils/slices/TabList';
import { GetLanguageData } from '../../utils/slices/LanguageSlice';
import { getCurrentDate, getSortingField } from '../../utils/slices/AccountListSlice';
import { Button, Group } from '@mantine/core';
import GlobalClass from '../../utils/GlobalClass';
export const YearAndDateTable = (props) => {

  const { obj, index } = props

  console.log('YearAndDateTable props=>', props);

  const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);
  const apiSuccess = useSelector((state) => state?.Language.apiSuccess);
  const { DelFinRes, DelFinYearhasError, DelFinYearErrorMsg, DelFinYearisLoading } = useSelector((state) => state?.UserList)
  // const GetGeneral = useSelector((state) => state.Language.General);

  const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);

  const dispatch = useDispatch();

  // const columnHelper = createColumnHelper();

  console.log('GetYearDateData=>', GetYearDateData);


  useEffect(() => {
    var cols = [];
    if (GetYearDateData && GetYearDateData.length > 0) {
      Object.keys(GetYearDateData[0]).map((key) => {
        cols.push({
          accessorKey: key,
          header: obj?.id == "12961281" ? (key == "YearNo" ? "No." : key == "strtDate" ? "From Date" : key == "endDate" ? "To Date" : "") : (key == "strtDate" ? "From" : key == "endDate" ? "To Date" : ""),
          Cell: ({ row, cell }) => {
            let val = cell.getValue();
            let newVal;
            if (key == "strtDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return newVal
            } else if (key == "endDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return newVal
            } else if (key == "YearNo") {
              newVal = parseInt(val)
              return newVal
            }
            return val

          }
        })
      })
    }
    setColumns(cols);
    console.log('GetYearDateData columns=>', cols);
  }, [GetYearDateData])

  useEffect(() => {
    if (selectedRowData != null) {
      console.log("selectedRowData",selectedRowData)
      Promise.resolve(dispatch(SetYearAndDateData(selectedRowData))).then(() => {
        localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
      })
        .then(() => {
          window.location.reload();
        })

      dispatch(GetLanguageData({"YrNo":selectedRowData?.YearNo?.slice(0,2)}));
    };
  }, [selectedRowData])

  useEffect(() => {
    if (DelFinYearhasError && DelFinRes && DelFinYearErrorMsg) {

      // DelFinRes, DelFinYearhasError, DelFinYearErrorMsg, DelFinYearisLoading

      console.log('DelFinReshasErrorErrorMsgisLoading =>', [{
        "DelFinRes": DelFinRes,
        "DelFinYearhasError": DelFinYearhasError,
        "DelFinYearErrorMsg": DelFinYearErrorMsg,
        "DelFinYearisLoading": DelFinYearisLoading
      }]);
      GlobalClass.Notify("error", "Error: " + DelFinRes, DelFinYearErrorMsg);
    }

  }, [DelFinRes, DelFinYearhasError, DelFinYearErrorMsg])


  // useEffect(()=>{
  //   if(apiSuccess==true){
  //     window.location.reload();
  //   }
  // },[apiSuccess])

  // console.log('GetGeneral=>',GetGeneral);

  const SendSelectedRow = (e) => {
    GetYearDateData.map((d, i) => {
      if (i == e) {
        setSelectedRowData(d)
      }
    })
  }

  const tabled = useMantineReactTable({
    data: GetYearDateData ?? [],
    columns,
    // enableRowSelection: false, //enable some features
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableMultiRowSelection: false,
    mantineTableProps: { withColumnBorders: true },
    initialState: {
      columnVisibility: { YearNo: obj?.id == "12961281" }
    },
    state: {
      density: "10px",
      rowSelection
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
      },
      onDoubleClick: () => {
        console.log("row", row);
        if(obj?.id != "12961281"){
        if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
          // modals.closeAll();
          // setCmpLoading(false);
          // cmpAction.close();
        } else {
          sessionStorage.setItem("year", row.getValue("YearNo"));
          // setSelected(row.getValue("YearNo"));
          // dispatch(SetCmpNumber(row.getValue("YearNo")))
          // setCmpLoading(true);
          // modals.closeAll();
          dispatch(ClearTabs(''))
        }

        Promise.resolve(SendSelectedRow(row.id)).then(() => {
          modals.closeAll()
        })
      }
        // setRowSelection((prev) => ({
        //   [row.id]: !prev[row.id],
        // }));
        setRowSelection((prev) => ({
          [row?.id]: true,
        }))
      },
      selected: rowSelection[row.id],
      sx: { cursor: 'pointer' },
      // height: 30
    })
  })

  const handleDeleteYear = () => {
    let GetSelectedRow = tabled.getSelectedRowModel().flatRows[0].original;
    console.log("GetSelectedRow =>", GetSelectedRow);
    dispatch(DeleteFinYear({
      Type: parseInt(GetSelectedRow?.YearNo),
      From: GetSelectedRow?.strtDate,
      To: GetSelectedRow?.endDate
    }))

  }

  // console.log('rowSelection==>', rowSelection);
  // console.log('selectedRowData==>', selectedRowData)

  return (<>
    {
      GetYearDateData && GetYearDateData?.length > 0 &&
      <MantineReactTable table={tabled} />
    }

    <Group pt={5} spacing={"xs"} position="center">
      {
        obj?.id == "12961281" && <Button
          style={{ height: "30px", width: "100px" }}
          size="sm"
          onClick={() => {
            if (tabled.getSelectedRowModel().flatRows?.length > 0) {
              handleDeleteYear()
            }
          }}
        >
          Delete
        </Button>
      }
    </Group>
  </>)
}

