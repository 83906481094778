import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const DefaultInvType = (props) => {
    const { obj, value, setEditSend } = props
    const InvTypeData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_M45I = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M45I)
    const P_M45IDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M45IDT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    InvTypeData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr="FIELD15"
                        valexpr = "FIELD01"
                        TmData={P_M45I}
                        data={P_M45IDT?.jData}
                        value={value}
                        setEdit={(e) => {
                            setEditSend(e.FIELD01)
                        }}
                    />
            }
        </div>
    )
}

export default DefaultInvType