import React, { useState } from 'react';
import {
  Button,
  Progress,
  FileInput,
  Card,
  Group,
  Text,
  Badge,
  Box,
  Center,
  Notification,
  Stack,
  Alert,
  Divider,
} from '@mantine/core';
import { IconUpload, IconFileZip, IconCheck, IconAlertTriangle, IconInfoCircle, IconRestore } from '@tabler/icons-react';
import axios from 'axios';
import gensetting from '../../utils/gensetting';
import GlobalClass from '../../utils/GlobalClass';
import PLTextBox from '../../PlusComponent/PLTextBox';

const ChunkedFileUploader = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [importStatus, setImportStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isImporting,setisImporting] = useState(false);
  const [company, setCompany] = useState('');
  const CHUNK_SIZE = 1024 * 1024; // 1 MB
  console.log("selected Company",company);
  
const handleReset = () =>{
    setFile(null);
    setUploadProgress(0);
    setImportStatus('');
    setIsUploading(false);
    setisImporting(false);
}
  const handleFileUpload = async () => {
    if (!file) {
      // Notification.show({ title: 'Error', message: 'No file selected', color: 'red' });
      GlobalClass.Notify("error","Error","No file selected")
      return;
    }
    else if(!company){
      GlobalClass.Notify("error","Error","Please Enter Company Number")
      return
    }

    setIsUploading(true);
    setUploadProgress(0);
    let filePath = '';
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    try {
      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min(file.size, start + CHUNK_SIZE);
        const fileChunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('file', fileChunk);
        // formData.append('fileName', file.name);
        // formData.append('chunkIndex', chunkIndex);
        // formData.append('totalChunks', totalChunks);

        const response = await axios.post(
          `https://kishanlive.in/Eplus/api/Utility/UploadChunk/File/Upload?fileName=${file.name}&chunkIndex=${chunkIndex}&totalChunks=${totalChunks}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        
        
        if(response.data.filePath){
          filePath = response.data.filePath;
        }
        
        setUploadProgress(((chunkIndex + 1) / totalChunks) * 100);
      }

      // Import Data After Upload
      if(filePath){
        await importData(filePath);
      }
      else{
        GlobalClass.Notify("error","Error","Error in Uploading File, Try Again");
      }
      
    } catch (error) {
      GlobalClass.Notify("error","Error",error.message)
      // Notification.show({ title: 'Error', message: error.message, color: 'red' });
    } finally {
      setIsUploading(false);
    }
  };

  const importData = async (filePath) => {
    try {
        setisImporting(true);
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: company,
        cAction: "",
        cCode: "",
        cOPara: filePath
      });
      const importResponse = await axios.post(`https://kishanlive.in/Eplus/api/Utility/ImportVfpData`, param,{
        headers: {
          'Content-Type': 'application/json', // Ensures the server interprets the payload as JSON
        },
      });

      setImportStatus(importResponse.data.message || 'Import successful');
      // Notification.show({ title: 'Success', message: 'Import completed successfully', color: 'green' });
      importResponse.data.message?.toLowerCase().includes('success') ?GlobalClass.Notify("success","Success","Import completed successfully"):GlobalClass.Notify("error","Error",importResponse.data.message);      
    } catch (error) {
      setImportStatus(error.message);
      GlobalClass.Notify("error","Error",error.message)
      // Notification.show({ title: 'Error', message: error.message, color: 'red' });
    }
    finally{
        setisImporting(false)
    }
  };

  

  return (
    <Center style={{ minWidth: '100vh', padding: '2rem', background: '#f8f9fa' }}>
      <Stack spacing="lg" align="center" style={{ width: '100%', maxWidth: '600px' }}>
        <Card shadow="md" radius="md" padding="xl" style={{ width: '100%' }} withBorder>
          <Stack spacing="md">
            
            <Alert
              icon={<IconInfoCircle size={16} />}
              title="Important Note"
              color="blue"
              variant="filled"
            >
              Currently, we only support the import of **Plus Accounting Software** data in `.zip` format. Please ensure the file is properly prepared before uploading.
            </Alert>
            {!importStatus &&<Box
              style={{
                border: '2px dashed #CED4DA',
                borderRadius: '8px',
                padding: '2rem',
                textAlign: 'center',
                backgroundColor: file ? '#f1f3f5' : '#f8f9fa',
                transition: 'background-color 0.3s',
              }}
              onDrop={(e) => {
                e.preventDefault();
                const droppedFile = e.dataTransfer.files[0];
                if (droppedFile && droppedFile.type === 'application/zip') setFile(droppedFile);
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              {file ? (
                <Group position="center" spacing="xs">
                  <IconFileZip size={32} />
                  <Text weight={500}>{file.name}</Text>
                  <Badge color="blue" size="sm">
                    {Math.round(file.size / 1024 / 1024)} MB
                  </Badge>
                </Group>
              ) : (
                <Text color="dimmed">Drag and drop your ZIP file here or use the file selector below</Text>
              )}
            </Box>}

            {!importStatus && <FileInput
              label="Select ZIP File"
              placeholder="Choose a ZIP file"
              value={file}
              onChange={setFile}
              accept=".zip"
              icon={<IconUpload size={18} />}
              
            />}
            {!importStatus &&<PLTextBox label={"New Company Number"} required value={company} setEdit={(e)=>setCompany(e)} fullWidth />}
           {file && !importStatus && <Button
              onClick={handleFileUpload}
            //   disabled={!file || isUploading || isImporting}
              loading={isUploading || isImporting}
              fullWidth
              size="lg"
              color="indigo"
              radius="md"
            >
              {isUploading?"Uploading File...":(isImporting?"Importing Data Please Wait...":"Upload and Import")}
            </Button>}

            {uploadProgress > 0 && !importStatus && (
              <Progress
                value={uploadProgress}
                label={`${Math.round(uploadProgress)}%`}
                color={uploadProgress === 100 ? 'teal' : 'blue'}
                striped
                animate
                radius="xl" size="xl"
                style={{ marginTop: '1rem' }}
              />
            )}

            {importStatus && (
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Group position="apart">
                  <Text weight={500} style={{ color: importStatus?.toLowerCase().includes('success') ? 'green' : 'red' }}>
                    {importStatus}
                  </Text>
                  {importStatus?.toLowerCase().includes('success') ? (
                    <IconCheck color="green" />
                  ) : (
                    <IconAlertTriangle color="red" />
                  )}
                </Group>
              </Card>
            )}
            {importStatus && <Button
              onClick={handleReset}
              fullWidth
              size="lg"
              color="gray"
              radius="md"
              compact
              leftIcon={<IconRestore/>}
            >
              Reset
            </Button>}
          </Stack>
        </Card>
      </Stack>
    </Center>
  );
};

export default ChunkedFileUploader;
