// GETDEF undefined http:eplus.kishanlive.in/api/template/GetTMFormat?pa={"CorpID":"94.176.235.105","cUser":"SUPERVISOR","cPass":"a@123","CmpNo":"6002","cWhere":"","cRepId":"T_M01","cFmtID":"","StrtDt":"2021-04-01","EndDt":"2022-03-31"}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

// get all Product List
export const GetAccountListForPrint = createAsyncThunk(
  "AccountListForPrint/GetAccountListForPrint",
  async (obj, thunkAPI) => {
    console.log("GetAccountListForPrint obj==>", obj);
    let startDate, endDate;
    const state = thunkAPI.getState();
    const StrtEndDateAry = state.AccountList.StrtEndDateAry;
    // console.log("StrtEndDateAry", StrtEndDateAry);

    const seprate = (e) => {
      var paraArr = e.split("T")[0];
      var paraArr1 = paraArr.replaceAll("-", "");
      return paraArr1;
    };
    if (obj?.StrtDt && obj?.EndDt) {
      startDate = seprate(obj?.StrtDt);
      endDate = seprate(obj?.EndDt);
    } else if (StrtEndDateAry) {
      for (const key in StrtEndDateAry) {
        if (key === obj.id) {
          let object = StrtEndDateAry[key];
          // console.log("object", object);
          startDate = object.StrtDt;
          endDate = object.EndDt;
          // console.log("object", startDate, endDate);
        }
      }
    }

    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cWhere: obj?.cWhere ?? "",
        cRepId: obj?.p1 ?? "",
        cFmtID: obj?.p2 ?? "",
        StrtDt: startDate ?? "",
        EndDt: endDate ?? "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param
      );
      // console.log("GetAccountList response=>>", response);
      return [
        response.data?.data?.jData,
        obj?.id,
        response.data?.data?.TotRec,
        startDate,
        endDate,
      ];
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
);
export const GetTMFormat = createAsyncThunk(
  "AccountListForPrint/GetTMFormat",
  async (obj) => {
    const seprate = (e) => {
      var paraArr = e.split("T");
      var paraArr1 = paraArr[0].replaceAll("-", "");
      return paraArr1;
    };
    const StrtDt = await seprate(obj?.StrtDt);
    const EndDt = await seprate(obj?.EndDt);
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cWhere: "",
        cRepId: obj?.p1 ?? "",
        cFmtID: obj?.p2 ?? "",
        StrtDt: StrtDt ?? obj?.StrtDt?.replaceAll("-", "") ?? "",
        EndDt: EndDt ?? obj?.EndDt?.replaceAll("-", "") ?? "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param
      );
      // console.log("GetAccountList response=>>", response.data);
      return [response.data.data, obj.id];
    } catch (error) {
      // console.error(error);
      // alert(error)
      return { error };
    }
  }
);

export const GetPrintEntData = createAsyncThunk(
  "PrintList/GetPrintEntData",
  async (obj) => {
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "",
        "cCode": "",
        "cOPara": ""
      })

      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetAdvanceEnt + "?pa=" + param);
      return response?.data?.data
    } catch (error) {
      return { error };
    }

  }
)



const PrintListSlice = createSlice({
  name: "PrintList",
  initialState: {
    TMFormatData: [],
    PrintData: [],
    StrtEndDateAry: [],
    rowCount: 0,
    isRefetching: false,
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    pageID: "",
    PrintEntData: [],
    PrintEntDataisLoading:false
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAccountListForPrint.pending, (state, action) => {
        state.hasError = false;
        if (state.PrintData.length <= 0) {
          state.isLoading = true;
        } else {
          state.isRefetching = true;
        }
      })
      .addCase(GetAccountListForPrint.fulfilled, (state, action) => {
        // console.log("GetAccountListForPrint fulfilled", action.payload);
        if (!action.payload?.error) {
          state.PrintData = {
            ...state.PrintData,
            [action.payload[1]]: action.payload[0],
          };
          state.StrtEndDateAry = {
            ...state.StrtEndDateAry,
            [action.payload[1]]: {
              StrtDt: action.payload[3],
              EndDt: action.payload[4],
            },
          };
          // state.PrintData = action.payload;
          state.rowCount = action.payload[2];
          // console.log("state.PrintData", state.PrintData);
          // console.log("state.StrtEndDateAry", state.StrtEndDateAry);
          state.isLoading = false;
          state.isRefetching = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.isRefetching = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }

        // state.OneProductData = {};
      })
      .addCase(GetAccountListForPrint.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetTMFormat.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetTMFormat.fulfilled, (state, action) => {
        // console.log("fulfilled", action.payload);
        // console.log("fulfilled", action.payload[0]?.oDCFrmt?.aDCol.map(item => item));

        if (!action.payload?.error) {
          state.TMFormatData = {
            ...state.TMFormatData,
            [action.payload[1]]: action.payload[0],
          };
          // state.TMFormatData = action.payload;
          // console.log("state.TMFormatData", state.TMFormatData);
          state.isLoading = false;
          state.hasError = false;
        } else {
          if (
            state.PrintData[action.payload[1]] &&
            state.PrintData[action.payload[1]].length > 0
          ) {
            state.isLoading = false;
          } else {
          }

          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
        // state.OneProductData = {};
      })
      .addCase(GetTMFormat.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetPrintEntData.pending, (state, action) => {
        state.PrintEntDataisLoading = true;
        state.hasError = false;
      })
      .addCase(GetPrintEntData.fulfilled, (state, action) => {
        state.PrintEntData = action.payload;
        state.PrintEntDataisLoading = false;
        state.hasError = false;
      })
      .addCase(GetPrintEntData.rejected, (state, action) => {
        state.hasError = true;
        state.PrintEntDataisLoading = false;
      })
  },
});
export const { getGroupEdited } = PrintListSlice.actions;
export default PrintListSlice.reducer;