import { Button, Grid, Group, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../../utils/store";
import { GetTMFormat } from "../../../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../../utils/GlobalClass";
import useWindowDimensions from "../../../utils/UseWindowDimensions";

export default function PendingOrder(props) {
  const { setDrawerOBJ, DrawerOBJ, data, setEmpty } = props;
  const [rowSelection, setRowSelection] = useState({});
  const [columns, setColumns] = useState([]);
  const [t02Jobj, setT02Jobj] = useState([]);
  const {
    JWOrderData,
    hasErrorOder: hasError,
    isLoadingOrder: isLoading,
    errorMsgOrder: error,
  } = useSelector((state) => state?.GetPendingIssue);
  const { TMFormatData: tmList, hasError: Error, ErrorMsg: ErrorMsg } = useSelector((state) => state.AccountList);
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch()

  const dtOrd = JWOrderData?.dtOrd ?? [];
  const OrdCol = JWOrderData?.OrdCol ?? "";
  const columnHelper = createColumnHelper();
  console.log(JWOrderData, "JWOrderData");

  useEffect(() => {
    if (Error) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [Error]);

  useEffect(() => {
    if (OrdCol) {
      console.log("tmList", tmList);
      dispatch(
        GetTMFormat({
          id: OrdCol.toString().split("~C~")[0],
          name: "",
          type: "A",
          p0: OrdCol.toString().split("~C~")[0],
          p1: OrdCol.toString().split("~C~")[0],
          p2: OrdCol.toString().split("~C~")[1],
          p3: "",
        })
      );
    }
  }, [OrdCol, JWOrderData]);
  const P_JORECPO = tmList[OrdCol.toString().split("~C~")[0]];
  console.log("P_JORECPO", P_JORECPO)
  useEffect(() => {
    if (
      P_JORECPO !== undefined &&
      OrdCol !== undefined &&
      P_JORECPO instanceof Object
    ) {
      var cols = [];
      // tmList[M45.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?
      P_JORECPO?.oDCFrmt?.aDCol?.map((v, i) => {
        console.log([{ "P_JORECPO": v, "P_JORECPO_index": i }])
        cols.push(
          columnHelper.accessor(v?.DispExpr, {
            header: v?.ColCap,
            enableHiding: true,
            enableColumnFilter: v?.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: "auto",
            id: v?.ColCap,

            Cell: ({ cell, renderedCellValue }) => {
              console.log("renderedCellValue", renderedCellValue)
              var val = cell.getValue();
              console.log("val", val)
              return rendercolcell(i == 0 ? renderedCellValue : cell.getValue(), v);
            },
          })
        );
      });
      setColumns(cols);
    }
  }, [OrdCol, P_JORECPO, JWOrderData]);

  const rendercolcell = (data, v) => {
    console.log("EPLUS", v);
    console.log('data===>>', data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v?.ColCap == "Date") {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt = dtxt.toString()  //Vara Pooja
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      // return dtxt
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt += " CR";
        } else {
          dtxt += " DB";
        }
      }
    }

    if (v.DispExpr == "FIELD06-FIELD50") {
      dtOrd?.map((d, i) => {
        dtxt = d.FIELD06 - d.FIELD50
      })
    }

    if (v.DispExpr == "FIELD21-FIELD52") {
      dtOrd?.map((d, i) => {
        dtxt = d.FIELD21 - d.FIELD52
      })
    }

    // if(v.ColCap == "Doc No") {
    //   dtOrd?.map((d, i) => {
    //     dtxt = d.T41F10
    //   })
    // }

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : 12,
          fontFamily: v.FontName ? v.FontName : "Verdana",
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };
  function SavePendingOrderData() {
    let newArray = [];


    Promise.resolve(
      // setT02Jobj(newArray);
      dtOrd?.map((n, i) => {
        if (i in rowSelection) {
          newArray.push(n);
        }
        props?.setData(newArray)
      })

    ).then(() =>
      typeof setDrawerOBJ == "function"
        ? setDrawerOBJ({ ...DrawerOBJ, open: false })
        : ""
    );
  }
  useEffect(() => {
    console.log(error, "error2");
    if (hasError) {
      GlobalClass.Notify("error", "Error: " + error.code, error.message);
    }
  }, [hasError, error]);
  // useEffect(() => {
  //   if ((dtOrd?.length == 0 && !isLoading) ) {
  //     console.log(dtOrd, "T02JDT--", typeof setDrawerOBJ )
  //     if (typeof setDrawerOBJ == "function" ) {
  //       console.log(dtOrd, "T02JDT--");
  //       // setEmpty(true)
  //       setDrawerOBJ({ ...DrawerOBJ, open: false });
  //     }
  //   }
  // }, [dtOrd, isLoading])
  const table = useMantineReactTable({
    data: dtOrd ?? [],
    columns: columns,
    mantineTableProps: { withColumnBorders: true },
    positionToolbarAlertBanner: "bottom",
    state: {
      rowSelection
    },
    initialState: {
      density: "xs"
    },
    // enableTopToolbar:false,
    enableHiding: false,
    enableFilters: false,
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableFilters: true,
    enableDensityToggle: false,
    enableColumnActions: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    // enableRowSelection: true,
    // enableMultiRowSelection: true,
    getRowId: (row) => row?.id,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      sx: { cursor: "pointer" }
    })
  });

  return (
    <>
      {
        Error ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (OrdCol) {
              dispatch(
                GetTMFormat({
                  id: OrdCol.toString().split("~C~")[0],
                  name: "",
                  type: "A",
                  p0: OrdCol.toString().split("~C~")[0],
                  p1: OrdCol.toString().split("~C~")[0],
                  p2: OrdCol.toString().split("~C~")[1],
                  p3: "",
                })
              );
            }
          }}>Retry</Button>
        </> :
          <>
            {P_JORECPO == undefined || isLoading ? (
              GlobalClass.RenderLoader("dots")
            ) : (
              <Grid gutter={4}>
                <Grid.Col span={12}>
                  <MantineReactTable table={table} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Group spacing={"xs"} position="center">
                    <Button
                      // size="xs"
                      // variant="filled"
                      // compact
                      onClick={() => SavePendingOrderData()}
                    >
                      OK
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            )}
          </>
      }
    </>
  );
}
