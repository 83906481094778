import React from 'react'

const EquationsFunctions = () => {
    return (
        <div>EquationsFunctions</div>
    )
}

export default EquationsFunctions

export const EquationFunction = (c, T02Obj, T41Obj, T02UF, Obj) => {

    console.log("EquationFunction parameter =>", [{
        "c": c,
        "T02Obj": T02Obj,
        "T41Obj": T41Obj,
        "T02UF": T02UF,
        "PageObj": Obj
    }]);

    let EquationObj = {};
    if (T02UF && T02UF?.length > 0) {
        console.log("c?.S53F02 " + c?.S53F02 + " =>", c);
        let val = T02Obj[c?.S53F04];
        switch (c.S53F03) {
            case "Amount":
                if ("02210213,02250213,02280213,02430213,02530249,02590249,02670249,02460213".includes(Obj?.page)) {
                    //"Sales Invoice","Sales Return","Sales Challan","Sales Order","Purchase Invoice","Purchase Return","Purchase Challan","Quotation"

                    val = eval("(T02Obj.UGRO ? (T02Obj.UGRO-(T02Obj.UGRO*T02Obj.UDIS/100.000000))*(T02Obj.FIELD33/T02Obj.UPE) : (T02Obj.UMUR-(T02Obj.UMUR*T02Obj.UDIS/100.000000))*(T02Obj.FIELD33/T02Obj.UPE))");
                    // console.log("EQEVAL=> Amount", val);
                    EquationObj = { ...T02Obj, [c?.S53F04]: parseFloat(val?.toFixed(2)) };
                } else if ("02760249".includes(Obj?.page)) {
                    //"Purchase Order"
                    //ura == qty1/qty2
                    val = eval(`('Qty1'.includes(T41Obj.URA) ? 
                                (T02Obj.UGRO ? 
                                    (T02Obj.UGRO - (T02Obj.UGRO * T02Obj.UDIS / 100.000000)) * (T02Obj.FIELD33 / T02Obj.UPE) : 
                                    (T02Obj.UMUR - (T02Obj.UMUR * T02Obj.UDIS / 100.000000)) * (T02Obj.FIELD33 / T02Obj.UPE)) 
                                : (T02Obj.UGRO ? 
                                    (T02Obj.UGRO - (T02Obj.UGRO * T02Obj.UDIS / 100.000000)) * (T02Obj.FIELD21 / T02Obj.UPE) : 
                                    (T02Obj.UMUR - (T02Obj.UMUR * T02Obj.UDIS / 100.000000)) * (T02Obj.FIELD21 / T02Obj.UPE)))`)



                    console.log("EquationFunction parameter PurchaseOrder", [{
                        "T41Obj.URA": T41Obj?.URA,
                        "T02Obj.FIELD21": T02Obj?.FIELD21,
                        'val': val
                    }]);

                    EquationObj = { ...T02Obj, [c?.S53F04]: parseFloat(val?.toFixed(2)) };

                } else {
                    EquationObj = { ...T02Obj }
                }

                break;
            case "Rate":
                if ("02210213,02250213,02280213,02430213,02530249,02760249,02460213".includes(Obj?.page)) {
                    //"Sales Invoice","Sales Return","Sales Challan","Sales Order","Purchase Invoice","Purchase Orde","Quotation"

                    val = eval("(T02Obj.UGRO ? (T02Obj.UGRO-(T02Obj.UGRO*T02Obj.UDIS/100.000000)) : (T02Obj.UMUR-(T02Obj.UMUR*T02Obj.UDIS/100.000000)))");

                    console.log("EQEVAL=> Rate", [{
                        "T02Obj": T02Obj,
                        "val": val
                    }]);

                    EquationObj = { ...T02Obj, [c?.S53F04]: parseFloat(val?.toFixed(2)) };

                    console.log("EQEVAL=> Rate EquationObj", EquationObj);

                } else if ("02590249,02670249".includes(Obj?.page)) {
                    //Purchase Return,Purchase Challan

                    val = eval("(T02Obj.UGRO ? ((T02Obj.UGRO - (T02Obj.UGRO * T02Obj.UDIS/100.000000)) * (T02Obj.FIELD33 / T02Obj.UPE)) : ((T02Obj.UMUR - (T02Obj.UMUR * T02Obj.UDIS/100.000000)) * (T02Obj.FIELD33 / T02Obj.UPE)))")

                    EquationObj = { ...T02Obj, [c?.S53F04]: parseFloat(val?.toFixed(2)) };

                } else {
                    EquationObj = { ...T02Obj }
                }
                break;

            default:
                EquationObj = { ...T02Obj };
                break;
        }
        return EquationObj
    } else {
        return { ...T02Obj }
    }

}

export const UFEquationFunction = (c, T02Obj, T41Obj, T02UF, Obj, nRate) => {
    //c : User Field Object
    //T02UF : T02 All UserField
    //Obj : Page Object

    console.log("EquationFunction parameter =>", [{
        "c": c,
        "T02Obj": T02Obj,
        "T41Obj": T41Obj,
        "T02UF": T02UF,
        "PageObj": Obj,
        "nRate": nRate
    }]);

    let EquationObj = {};
    if (T02UF && T02UF?.length > 0) {
        console.log("c?.S53F02 " + c?.S53F02 + " =>", c);
        let val = T02Obj[c?.S53F04];
        switch (c.S13F07) {
            case "Gross Rate":
                if ("02210213".includes(Obj?.page)) {
                    val = eval("('02340213'.includes(Obj?.id) ? T02Obj.UGRO  : ((T02Obj.UGRO ? T02Obj.UGRO : nRate)))");



                    EquationObj = { ...T02Obj, [c?.S53F04]: val };
                } else {
                    EquationObj = { ...T02Obj }
                }
                break;
            default:
                EquationObj = { ...T02Obj };
                break;
        }
        return EquationObj
    }

}

export const ReverseCalCulation = (c, val, t02, RevQty2) => {
    console.log("ReverseCalCulation para =>", [{
        "c": c,
        "val": val,
        "t02": t02,
        "RevQty2":RevQty2
    }]);
    // S53F02": "E00T02F21","S53F04": "FIELD21","S53F03": "Qty-2",
    //"S53F02": "E00T02F22","S53F04": "FIELD22","S53F03": "Conversion",
    // "S53F02": "E00T02F06","S53F04": "FIELD33","S53F03": "Qty",
    let CalVal = { ...t02 };
    let cal = 0;

    switch (c?.S53F02) {
        case "E00T02F21": // Qty-2
        case "E00FIELD21":
            cal = t02?.FIELD21 * t02?.FIELD22;
            CalVal = { ...t02, FIELD33: parseFloat(cal.toFixed(2)) }
            break;
        case "E00T02F22": //Conversion
        case "E00FIELD22":
            cal = t02?.FIELD21 * t02?.FIELD22;
            CalVal = { ...t02, FIELD33: parseFloat(cal.toFixed(2)) }
            break;
        case "E00T02F06": // Qty
        case "E00FIELD33":
            if (RevQty2 == "Y") {
                cal = t02?.FIELD33 / t02?.FIELD22;
                CalVal = { ...t02, FIELD21: parseFloat(cal.toFixed(2)) }
            } else {
                CalVal = { ...t02 }
            }
            break;
        default:
            CalVal = { ...t02 }
            break;
    }
    return CalVal
}





