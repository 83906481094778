import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const AccountPopUpType = (props) => {
    const { obj, value, setEditSend } = props
    console.log("value", value)
    const AccPopUpTypeData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    console.log('AccPopUpTypeData', AccPopUpTypeData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_APPOPTYPE = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPE)
    const P_APPOPTYPEDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPEDT)
    const dispatch = useDispatch()

    function extractFields(inputString) {
        let parts = inputString.split('||');

        let processedParts = parts.map(part => {

            let splitPart = part.split('##');

            // Remove the first and last elements
            splitPart.shift();
            splitPart.pop();

            return splitPart;
        });
        //   console.log("processedParts",processedParts.join(","))
        return processedParts.join(",")
    }

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    AccPopUpTypeData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr={"S16F35"}
                        valexpr={"S16F20"}
                        TmData={P_APPOPTYPE}
                        data={P_APPOPTYPEDT}
                        isMulti={true}
                        setEdit={(e) => {
                            // setEditSend(e.S16F03)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    // val.push(m.original)
                                    const S16F02 = m.original.S16F02
                                    const S16F20 = m.original.S16F20
                                    const S16F05 = m.original.S16F05

                                    const formatVal = `${S16F02}##${S16F20}##${S16F05}`
                                    val.push(formatVal)
                                })
                                const result = val.join('||')
                                console.log("result", result)
                                setEditSend(result)
                            } else {
                                setEditSend("")
                            }
                        }}
                        value={extractFields(value)}
                    />
            }
        </div>
    )
}

export default AccountPopUpType