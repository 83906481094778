import React from 'react'
import Store from '../../../utils/store';
import CreditDebitNoteForm from './CreditDebitNoteForm';
import { ModalFunction } from '../../../utils/slices/ModalSlice';
import { Text } from '@mantine/core';
import GlobalClass from '../../../utils/GlobalClass';
import { TransactionDeleteApi } from '../../../utils/TransactionSlices/Transaction';
import { GetExpenseDataNull, GetInvTypeDropDown, GetPreFillTransactionFormData } from '../../../utils/TransactionSlices/TSalesSlices';
import EInvoice from '../../EInvoiceAndEWayBill/EInvoice';
import EWayBill from '../../EInvoiceAndEWayBill/EWayBill';

let PreFillFormData = {
  CashDebit: "",
  PartyAc: "",
  PartyAcName:"",
  DeliveryAt: "",
  DeliveryAtName:"",
  InvoiceType: "",
  BillDate: ""
}

export function VouEntAct(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log('Credit/Debit Note Object===>', OBJ);
  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
          Store.dispatch(GetPreFillTransactionFormData(PreFillFormData))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <CreditDebitNoteForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === "E" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        
        MSize: "98%",
        position:"right",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <CreditDebitNoteForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === "D" && OBJ.id) {
    if (window.confirm('Confirm to Delete ?')) {
     
       Store.dispatch(TransactionDeleteApi(OBJ))
    }
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    // if (window.confirm('Confirm to Audit ?')) {
    //     Store.dispatch(TransactionDeleteApi(OBJ))
    // }
    GlobalClass.Confirm(
        "red",
        "Are you Sure to Audit This voucher?",
        "",
        () => {
            Store.dispatch(TransactionDeleteApi(OBJ))
        },
        () => { })
} else if (OBJ.p0 === 'U' && OBJ?.id) { // UserField
    Store.dispatch(ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        // position:'right',
        MBody: (i) => (<CreditDebitNoteForm obj={OBJ} index={i} />),
        MClose: true,
        onclose: () => { },
        onClickCloseButton: () => {
            Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetExpenseDataNull([])))
        }
    }))
} else if (OBJ.id && OBJ.p0 === "I") { // E-invoice
  Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "md",
      // position:'right',
      MBody: (i) => (<EInvoice obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {

      }
  }))
} else if (OBJ.id && (OBJ.p0 === "G" || OBJ.p0 === "W")) { // E-way bill
  Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "xl",
      // position:'right',
      MBody: (i) => (
          <EWayBill obj={OBJ} index={i} />
      ),
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {

      }
  }))
} else {
    return null
  }
}