import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserMasterEntry } from "../../../utils/slices/UserMasterEntrySlice";
import { Button, Grid, Group, Text } from "@mantine/core";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import GlobalClass from "../../../utils/GlobalClass";
import gensetting from "../../../utils/gensetting";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import { GetAccountList } from "../../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import Store from "../../../utils/store";

const UserMasterEntryForm = (props) => {
  const dispatch = useDispatch();
  const userFieldRef = useRef(null);
  const [UMEDataS13, setUMEDataS13] = useState([]);
  const [UMEDataoMsc, setUMEDataoMsc] = useState([]);
  const UserMasterEntryData = useSelector(
    (state) => state.UserMasterEntry?.UserMasterEntryData
  );
  const isLoading = useSelector((state) => state?.UserMasterEntry?.isLoading);
  const hasError = useSelector((state) => state?.UserMasterEntry?.hasError);
  const ErrorMsg = useSelector((state) => state?.UserMasterEntry?.ErrorMsg);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );


  useEffect(() => {
    dispatch(GetUserMasterEntry(props?.OBJ));
  }, []);

  useEffect(() => {
    // console.log("UserMasterEntryData",UserMasterEntryData);
    setUMEDataS13(UserMasterEntryData?.S13);
    setUMEDataoMsc(UserMasterEntryData?.oMsc);
  }, [UserMasterEntryData]);

  useEffect(() => {
    
    const formElement = userFieldRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
      }
    };
  }, [userFieldRef]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);


  const UserFieldRender = () => {
    let InputField;
    return UMEDataS13?.map((ele,index) => {
      switch (ele?.S13F14) {
        case "NUMBOX":
          InputField = (
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col span={4} style={{ fontSize: 12 }}>
                {ele?.S13F07}
              </Grid.Col>
              <Grid.Col span={8}>
                <PLNumberBox
                  autoFocus = {index == 0 ? true : false}
                  onKeyDown={(e)=>{
                    GlobalClass.formNextInput(e,userFieldRef);
                  }}
                  value={UMEDataoMsc?.[ele?.S13F02]}
                  setEdit={(e) => {
                    setUMEDataoMsc({ ...UMEDataoMsc, [ele?.S13F02]: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          );

          break;
        case "TEXTBOX":
          InputField = (
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col span={4} style={{ fontSize: 12 }}>
                {ele?.S13F07}
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                autoFocus = {index == 0 ? true : false}
                onKeyDown={(e)=>{
                  GlobalClass.formNextInput(e,userFieldRef);
                }}
                  value={UMEDataoMsc?.[ele?.S13F02]}
                  nmaxlength={ele?.S13F04}
                  setEdit={(e) => {
                    setUMEDataoMsc({
                      ...UMEDataoMsc,
                      [ele?.S13F02]: e.target.value,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          );
          break;
        case "PLPOPUP":
          InputField = (
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col span={4} style={{ fontSize: 12 }}>
                {ele?.S13F07}
              </Grid.Col>
              <Grid.Col span={8}>
                <PLDataGrid
                autoFocus = {index == 0 ? true : false}
                onKeyDown={(e)=>{
                  GlobalClass.formNextInput(e,userFieldRef);
                }}
                  value={UMEDataoMsc?.[ele?.S13F02]}
                  setEdit={(e) => {
                    setUMEDataoMsc({
                      ...UMEDataoMsc,
                      [ele?.S13F02]: e?.FIELD01,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          );
          break;
        case "COMBOBOX":
          InputField = (
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col span={4} style={{ fontSize: 12 }}>
                {ele?.S13F07}
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                autoFocus = {index == 0 ? true : false}
                onKeyDown={(e)=>{
                  GlobalClass.formNextInput(e,userFieldRef);
                }}
                  value={UMEDataoMsc?.[ele?.S13F02]}
                  setEdit={(e) => {
                    setUMEDataoMsc({ ...UMEDataoMsc, [ele?.S13F02]: e?.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          );
          break;
        default:
          InputField = (
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
              <Grid.Col span={4} style={{ fontSize: 12 }}>
                {ele?.S13F07}
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                autoFocus = {index == 0 ? true : false}
                onKeyDown={(e)=>{
                  GlobalClass.formNextInput(e,userFieldRef);
                }}
                  value={UMEDataoMsc?.[ele?.S13F02]}
                  setEdit={(e) => {
                    setUMEDataoMsc({
                      ...UMEDataoMsc,
                      [ele?.S13F02]: e.target.value,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          );
          break;
      }
      return InputField;
    });
  };

  const handleOkButton = () => {
    dispatch(DataSaveLoading(true));

    GlobalClass.Notify(
      "info",
      props?.OBJ.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0 ? props?.OBJ.p0 : "A",
      cCode: props?.OBJ?.p0 == "A" ? "" : props?.OBJ?.id,
      cOPara: props?.OBJ?.BtnPnlObj?.p1?.split("_")[1],
      cSData: JSON.stringify(UMEDataoMsc),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0 ? props?.OBJ.p0 : "A",
      cCode: props?.OBJ?.p0 == "A" ? "" : props?.OBJ?.id,
      cOPara: props?.OBJ?.BtnPnlObj?.p1?.split("_")[1],
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostUserMasterEntry + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          GlobalClass.Notify(
            "success",
            props?.OBJ.p0 == "A" ? "Added" : "Edited",
            props?.OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
          );
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => dispatch(ModalDelete(props.index)))
            .then(() =>
              dispatch(
                GetAccountList({
                  id: props?.OBJ?.BtnPnlObj?.id,
                  name: props?.OBJ?.BtnPnlObj?.name,
                  p0: props?.OBJ?.BtnPnlObj?.p0,
                  p1: props?.OBJ?.BtnPnlObj?.p1,
                  p2: props?.OBJ?.BtnPnlObj?.p2,
                  p3: props?.OBJ?.BtnPnlObj?.p3,
                  type: props?.OBJ?.BtnPnlObj?.type,
                  pagination: PaginationObj[props?.OBJ?.BtnPnlObj?.id],
                })
              )
            );
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading(false));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading(false));
      });
  };

  return (
    <>
      {hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (props?.OBJ) {
              dispatch(GetUserMasterEntry(props?.OBJ));
            }
          }}>Retry</Button>
        </>  : isLoading || SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        UMEDataS13?.length > 0 ?
       <div ref={userFieldRef}><Grid gutter={4}>
       {UserFieldRender()}
       <Grid.Col span={12}>
          <Group spacing={"xs"} position="center">
           <Button
           onKeyDown={(e)=>{
            if(e.key =="Enter"){
              e.preventDefault();
              handleOkButton();
            }
          }}
            onClick={() => handleOkButton()}>Ok</Button>
         </Group> 
         
       </Grid.Col>
     </Grid> </div> : "There is no user field present"
      )}
    </>
  );
};

export default UserMasterEntryForm;
