import React from "react";
import Store from "../../utils/store";
import { Text } from "@mantine/core";
// import { notifications } from "@mantine/notifications";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
// import OpeningStockEditForm from "./OpeningStockEditForm";
import OpeningStockAddForm from "./OpeningStockForm";
// import { GetOpeningStockEnt } from "../../utils/TransactionSlices/openingStockEntSlice";

// import { GetOpeningStockTM } from "../../utils/TransactionSlices/openingStockSliceTM";
// import { GetOpeningStockGen } from "../../utils/TransactionSlices/openingStockSliceGen";
// import { setToggleButton } from "../../utils/TransactionSlices/openingStockEntSlice";
// import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import {
  GetNullTransactionData,
  TransactionDeleteApi,
} from "../../utils/TransactionSlices/Transaction";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";
import {
  GetExpenseDataNull,
  GetInvTypeDropDown,
  GetPendingOrderDataNull,
  GetPendingQuotationDataNull,
  GetSingleOrderRowNull,
  GetSingleSelectedOrderRowNull,
} from "../../utils/TransactionSlices/TSalesSlices";
import PLRange from "../../PlusComponent/PLRange";
import {
  GetAccountList,
  getRangeBtnState,
} from "../../utils/slices/AccountListSlice";

export function VouEntAct(props) {
  const OBJ = JSON.parse(props);
  if (OBJ.id && OBJ?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        // position: "right",
        MBody: (i) => <OpeningStockAddForm obj={OBJ} index={i} />,
        MClose: true,
        tab:OBJ.page.startsWith("P_")?false:true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,  
        MAction: true,
        MSize: "98%",
        fullSize:false,
        // position: "right",
        MBody: (i) => <OpeningStockAddForm obj={OBJ} index={i} />,
        // MBody: (i) => <OpeningStockForm/>,
        onClickCloseButton: () => {},
        MClose: true,
        tab:OBJ.page.startsWith("P_")?false:true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ?.id && OBJ?.p0 == "D") {
    if (window.confirm("Are you sure to delete selected data?")) {
      Store.dispatch(TransactionDeleteApi(OBJ));
    } else {
      return null;
    }
  } else if (OBJ.p0 === "T" && OBJ?.id) {
    //Audit
    if (window.confirm("Confirm to Audit ?")) {
      Store.dispatch(TransactionDeleteApi(OBJ));
    }
  } else if (OBJ.id && OBJ.p0 === "P") {
    // Print
    // eval('TM_PRINTVF('+OBJ+')')
    TM_PRINTVF(props);
  } else if (OBJ.p0 === "U" && OBJ?.id) {
    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        // position:'right',
        MBody: (i) => <OpeningStockAddForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          Store.dispatch(ModalDelete());
        },
      })
    );
  } else if (OBJ.p0 == "R") { //Range Button
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>Enter Voucher No. Range</Text>,
        MAction: true,
        MSize: "lg",
        position: "right",
        MBody: (i) => <PLRange obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
}
