import { Text } from "@mantine/core";
import EntryTypeForm from "../../SetupComponent/EntryType/EntryTypeForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { modals } from "@mantine/modals";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { notifications } from "@mantine/notifications";
import {
  GetVouSetupEnableDisable,
  GetVoucherSetup,
  GetVoucherSetupEnt,
} from "../../utils/slices/VoucherSetupSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

export function CallInvType(data) {
  const OBJ = JSON.parse(data);
  console.log("OBJJ CallInvType==>", OBJ);

  if (OBJ.p0 == "A") { //Add
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => (
          <>
            <EntryTypeForm index={i} obj={OBJ} />
          </>
        ),
        MClose: true,
        //   MCentered:true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 == "E" && (OBJ.id || OBJ.M45UID)) { // Edit
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => (
          <>
            <EntryTypeForm index={i} obj={OBJ} />
          </>
        ),
        MClose: true,
        //   MCentered:true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 == "D" && (OBJ.id || OBJ.M45UID)) { // Delete
    // Promise.resolve()
    let newArray = [];
    const M45DT = Store.getState().VoucherSetup?.VoucherSetupData?.M45DT;
    const ActionPara = Store.getState().VoucherSetup?.ApiActionPara;
    console.log("ActionPara===========>", ActionPara);
    modals.openConfirmModal({
      title: "Are you sure you want to delete this user?",
      zIndex: 401,
      children: (
        <Text>
          Are you sure you want to delete? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        GlobalClass.Notify(
          "info",
          "Deleting",
          "Please wait while we process your data"
        );
        if (OBJ.id !== "") {
          newArray = M45DT.filter((a) => {
            return a.FIELD01 !== OBJ.id;
          });
        } else {
          newArray = M45DT.filter((a) => {
            return a.M45UID !== OBJ.M45UID;
          });
        }

        console.log("newArray===>", newArray);

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: ActionPara?.action,
          cCode: "",
          cOPara: ActionPara?.para,
          cSData: JSON.stringify(newArray),
        };
        // console.log("DeleteData", data);
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: ActionPara?.action,
          cCode: "",
          cOPara: ActionPara?.para,
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM45Ent + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              Promise.resolve(
                GlobalClass.Notify("success", "Deleted", "Deleted successfully")
              ).then(() =>
                Store.dispatch(
                  GetVoucherSetup({
                    action: ActionPara?.action,
                    para: ActionPara?.para,
                  })
                )
              );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
          });
      },
    });
  } else if (OBJ.p0 == "N" && (OBJ.id || OBJ.M45UID)) { // Expense Enable/Disable
    const ActionPara = Store.getState().VoucherSetup?.ApiActionPara;
    console.log("ActionPara===========>", [{
      "ActionPara": ActionPara,
      "OBJ": OBJ
    }]);

    if (window.confirm('Confirm to Status Change ?')) {
      Promise.resolve(GlobalClass.Notify("info", "Info: " + "Status Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(GetVouSetupEnableDisable({ ActionPara: ActionPara, obj: OBJ })))
      // .then(()=>Store.dispatch(DataSaveLoading(true)))
      // .then(() => )
    }
  }
}
