import { useDisclosure } from '@mantine/hooks';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetMenuList, SetCmpNumber } from '../../utils/slices/UserListSlice';
import { modals } from '@mantine/modals';
import { ClearTabs } from '../../utils/slices/TabList';
import { GetYearDateUrl } from '../../utils/slices/CompanySetupSlice';
import { GetLanguageData } from '../../utils/slices/LanguageSlice';
import Localize from '../../utils/rc';
import { Grid, Header, Loader, Progress } from '@mantine/core';

export const ChangeCompany = () => {

  let GetLanguage = new Localize();

  const [columns, setColumns] = useState([])
  const [selectedCmp, setSelectedCmp] = useState({ cmpNo: "", cmpName: "" });
  const [cmpLoading, setCmpLoading] = useState(false);
  const [companyModal, cmpAction] = useDisclosure(false);

  const langisLoading = useSelector((state) => state.Language?.isLoading);
  const LangData = useSelector((state) => state.Language?.LanguageData);
  const yrisLoading = useSelector((state) => state.CompanySetup?.isLoading);
  const [LanguageData, setLanguageData] = useState([]);
  const [progressBar, setProgressBar] = useState(0);




  const { isLoading, UserListData, isMenuLoading, CmpNumber, hasError: UserListHasError, ErrorMsg: UserListError } = useSelector((state) => state.UserList);

  const dispatch = useDispatch();



  useEffect(() => {
    if (UserListData != '' && UserListData?.cmpList) {
      let col = [];
      Object.keys(UserListData?.cmpList[0]).map((key) => {
        col.push({
          accessorKey: key,
          header: key == "cmpNo" ? "Company No." : "Company Name",
          enableHiding: false,
          enableColumnFilter: false,
          enableSorting: false,
          enableGlobalFilter: false,

        })
      })
      setColumns(col)
      setSelected();
    }

  }, [UserListData?.cmpList])

  const setSelected = (id = "") => {
    if (localStorage.getItem("cmp")) {
      setCmpLoading(false);
      cmpAction.open();
      var data = UserListData.cmpList.filter((e) => e["cmpNo"] == localStorage.getItem("cmp") ? e : "");
      setSelectedCmp(data[0]);
      dispatch(SetCmpNumber(localStorage.getItem("cmp")))
      // console.log("selected", data);

    } 

  }

  const table = useMantineReactTable({
    data: UserListData?.cmpList ? UserListData?.cmpList : [],
    columns,
    enableTopToolbar: false,
    initialState: {
      density: 'xs',

    },
    enableRowVirtualization: true,
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableGrouping: false,
    enableDensityToggle: false,
    enableColumnActions: false,
    mantineTableProps:{withColumnBorders:true},
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (localStorage.getItem("cmp") == row.getValue("cmpNo")) {
          modals.closeAll();
          // cmpAction.close();
          // setCmpLoading(false);
        } else {
          localStorage.setItem("cmp", row.getValue("cmpNo"));
          setSelected(row.getValue("cmpNo"));
          dispatch(SetCmpNumber(row.getValue("cmpNo")))
          setCmpLoading(true);
          // modals.closeAll();
          dispatch(ClearTabs(''))
          window.location.reload()
                    
        }

      },
      sx: { cursor: 'pointer' },
    }),
  });

  // useEffect(() => {
  //   if (cmpLoading) {
  //     dispatch(GetLanguageData())
  //   }
  // }, [cmpLoading])

  // useEffect(() => {
  //   if (cmpLoading && !langisLoading) {
  //     var i = 0;
  //     var intv = setInterval(() => {
  //       if (i < 33) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         dispatch(GetYearDateUrl())
  //       }
  //     }, 50)
  //   }
  // }, [langisLoading])

  // useEffect(() => {
  //   if (cmpLoading && !yrisLoading) {
  //     var i = 33;
  //     var intv = setInterval(() => {
  //       if (i < 66) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         dispatch(GetMenuList());
  //       }
  //     }, 50)
  //   }
  // }, [yrisLoading])

  // useEffect(() => {
  //   if (cmpLoading && !isMenuLoading) {
  //     var i = 66;
  //     var intv = setInterval(() => {
  //       if (i < 100) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         setCmpLoading(false)
  //         setProgressBar(0);
  //         setLanguageData(GetLanguage.GetLanguageList());
  //         // cmpAction.close();
  //         modals.closeAll()
         
  //       }
  //     }, 50)
  //   }
  // }, [isMenuLoading])
  return (<>
    {/* {
      cmpLoading == true ?
        <Grid gutter={4}>
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>Loading Company Data...{progressBar}%</Grid.Col>
          <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}><Loader /></Grid.Col>
          <Grid.Col span={12}>
            <Progress aria-label={progressBar + "%"} size={"lg"} radius="md" value={progressBar} striped animate />
          </Grid.Col>
        </Grid>
        : */}
    {/* // } */}
        <MantineReactTable table={table} />
  </>)
}
