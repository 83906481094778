import { Button, Checkbox, Grid, Group, Paper, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../../PlusComponent/PLComboBox'

const FontModal = (props) => {
    // console.log('props', props)
    const { waterMarkObjSend, setWaterMarkObjSend, setDrawerObjSend, DrawerObjSend } = props
    // console.log('waterMarkObjSend', waterMarkObjSend)
    // console.log('setWaterMarkObjSend', setWaterMarkObjSend)
    // For Fonts Family
    const [fontFamily, setFontFamily] = useState([])
    const [FontSize, setFontSize] = useState([])
    const [fontData, setFontData] = useState({
        FIELD01: "",
        FIELD02: 30
    })
    const [fonts, setFonts] = useState({
        FontSize: 0,
        FontFamily: "",
        FontWeight: "",
        FontStyle: ""
    })
    // console.log('fonts', fonts)
    const [boldChecked, setBoldChecked] = useState(false)
    const [italicChecked, setItalicChecked] = useState(false)
    const [selectedVal, setSelectedVal] = useState({ value: 'Verdana', label: 'Verdana' })
    const [fontSelected, setFontSelected] = useState({ label: 30, value: 30 })
    const [isHover, setIsHover] = useState('')
    const [ObjSave, setObjSave] = useState({})
    const [val, setVal] = useState(null)

    useEffect(() => {
        console.log("waterMarkObjSend", waterMarkObjSend)
        console.log('val', val)
        console.log('selectedVal', selectedVal)
        console.log('fontSelected', fontSelected)
    }, [waterMarkObjSend, val, fontSelected, selectedVal])

    useEffect(() => {
        if (waterMarkObjSend?.WMF12 !== "") {
            const values = waterMarkObjSend?.WMF12.split(',');
            setSelectedVal({ value: values[0], label: values[0] });
            setFontSelected({ value: values[1], label: values[1] });
            setBoldChecked(values.includes("B"));
            setItalicChecked(values.includes("I"));
            console.log('values',values)
        }
    }, [waterMarkObjSend]);

    // const saperate = () => {
    //     if(waterMarkObjSend?.WMF12 !== "") {
    //         values = waterMarkObjSend?.WMF12.split(',')
    //     }
    // }

    const fontCheck = new Set([
        // Windows 10
        'Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic',
        // macOS
        'American Typewriter', 'Andale Mono', 'Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Avenir', 'Avenir Next', 'Avenir Next Condensed', 'Baskerville', 'Big Caslon', 'Bodoni 72', 'Bodoni 72 Oldstyle', 'Bodoni 72 Smallcaps', 'Bradley Hand', 'Brush Script MT', 'Chalkboard', 'Chalkboard SE', 'Chalkduster', 'Charter', 'Cochin', 'Comic Sans MS', 'Copperplate', 'Courier', 'Courier New', 'Didot', 'DIN Alternate', 'DIN Condensed', 'Futura', 'Geneva', 'Georgia', 'Gill Sans', 'Helvetica', 'Helvetica Neue', 'Herculanum', 'Hoefler Text', 'Impact', 'Lucida Grande', 'Luminari', 'Marker Felt', 'Menlo', 'Microsoft Sans Serif', 'Monaco', 'Noteworthy', 'Optima', 'Palatino', 'Papyrus', 'Phosphate', 'Rockwell', 'Savoye LET', 'SignPainter', 'Skia', 'Snell Roundhand', 'Tahoma', 'Times', 'Times New Roman', 'Trattatello', 'Trebuchet MS', 'Verdana', 'Zapfino',
    ].sort());

    const FontFunction = async () => {

        await document.fonts.ready;

        const fontAvailable = new Set();

        for (const font of fontCheck.values()) {
            if (document.fonts.check(`12px "${font}"`)) {
                fontAvailable.add(font);
            }
        }
        // console.log('Available Fonts:', [...fontAvailable.values()]);
        return [...fontAvailable.values()]
    }

    useEffect(() => {
        FontFunction().then((e) => {
            let newArr = [];
            e.map((a, i) => {
                newArr.push({ value: a, label: a })
            })
            // console.log("newArr", newArr);
            setFontFamily(newArr)
        })
    }, [])

    let fontSize = [];
    const FontSizeFunction = () => {
        for (let i = 4; i <= 127; i++) {
            fontSize.push(i)
        }
        // console.log('fontsize==>', fontSize);
    }

    useEffect(() => {
        FontSizeFunction()
        if (fontSize.length > 0) {
            let newFontArr = []
            fontSize.map((a, i) => {
                newFontArr.push({ label: a, value: a })
            })
            // console.log('newFontArr=.>', newFontArr)
            setFontSize(newFontArr)
        }
    }, [])

    useEffect(() => {
        if (fontData.FIELD01 !== "" || fontData.FIELD02 !== 0 || boldChecked == true || italicChecked == true) {
            setFonts({ ...fonts, FontFamily: fontData.FIELD01, FontSize: fontData.FIELD02, FontWeight: boldChecked == true ? 'bold' : '', FontStyle: italicChecked == true ? "italic" : '' })
        }
    }, [fontData.FIELD01, fontData.FIELD02, boldChecked, italicChecked])

    const ModalCloseFun = () => {
        setDrawerObjSend({ ...DrawerObjSend, open: false })
    }

    const GetFontFamilyObj = (e) => {
        console.log('e-===>', e)
        setSelectedVal(e)
    }
    const GetFontSizeObj = (e) => {
        setFontSelected(e)
    }

    useEffect(() => {
        let val = []
        if (fonts.FontFamily !== "" || fonts.FontSize !== 0 || boldChecked === true || italicChecked === true) {
            val.push(
                fonts.FontFamily || "Verdana"
            )
            val.push(fonts.FontSize.toString());
            if (boldChecked) {
                val.push("B");
            }
            if (italicChecked) {
                val.push("I");
            }
            return setVal(val.join(','))
        }
    }, [fonts, boldChecked, italicChecked])

    const SaveFontData = () => {
        Promise.resolve(setWaterMarkObjSend({ ...waterMarkObjSend, WMF12: val }))
            .then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
    }

    // for font value save

    // Verdana,30,B

    // fontname,fontsize,type(B/I)

    // B- BOLD

    // I- Italic

    useEffect(() => {
        let CmpListarr = localStorage.getItem("authUser")
        setObjSave(JSON.parse(CmpListarr))
    }, [])
    // console.table("ObjSave==>", ObjSave?.cmpList);

    let companyName = ObjSave?.cmpList?.filter((a) => a?.cmpNo == localStorage.getItem("cmp"))

    // console.log("companyName==>",companyName);
    return (
        <>
            <Paper shadow="md" radius="md" p="xs" withBorder>
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={7}>
                            <Text size={12} fw={500} my={5} style={{ textAlign: 'center' }}>Font Name</Text>
                            <Grid.Col span={12} style={{ height: "200px", border: "1px solid gray", overflow: "hidden", overflowY: "scroll", cursor: "pointer" }}>
                                {
                                    fontFamily.map((f) => {
                                        return <Text size={12}
                                            onClick={() => {
                                                setFontData({ ...fontData, FIELD01: f.label })
                                                GetFontFamilyObj(f)
                                            }}
                                            onMouseEnter={(e) => {
                                                setIsHover(e.target.innerText)
                                            }}
                                            onMouseLeave={() => {
                                                setIsHover("")
                                            }}
                                            style={{
                                                textAlign: 'center',
                                                color: selectedVal?.value == f?.value ? "white" :
                                                    isHover == f?.value ? "#228be6" : "black",
                                                backgroundColor:
                                                    (isHover == selectedVal?.value) && (isHover == f?.value) ? "#228be6" :
                                                        isHover == f?.value ? "rgba(231, 245, 255, 1)" :
                                                            selectedVal?.value == f?.value ? "#228be6" :
                                                                isHover == f?.value && selectedVal?.value == f?.value ? "#228be6" :
                                                                    ""
                                            }}
                                        >
                                            {f.label}
                                        </Text>
                                    })
                                }
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={5}>
                            <Text size={12} fw={500} my={5} style={{ textAlign: 'center' }}>Font Size</Text>
                            <Grid.Col span={12} style={{ height: "200px", border: "1px solid gray", overflow: "hidden", overflowY: "scroll", cursor: "pointer" }}>
                                {
                                    FontSize.map((f) => {
                                        return <Text size={12}
                                            onClick={() => {
                                                setFontData({ ...fontData, FIELD02: f.label })
                                                GetFontSizeObj(f)
                                            }}
                                            onMouseEnter={(e) => {
                                                setIsHover(e.target.innerText)
                                            }}
                                            onMouseLeave={() => {
                                                setIsHover("")
                                            }}
                                            style={{
                                                textAlign: 'center',
                                                color: fontSelected?.value == f?.value ? "white" :
                                                    isHover == f?.value ? "#228be6" : "black",
                                                backgroundColor:
                                                    (isHover == fontSelected?.value) && (isHover == f?.value) ? "#228be6" :
                                                        isHover == f?.value ? "rgba(231, 245, 255, 1)" :
                                                            fontSelected?.value == f?.value ? "#228be6" :
                                                                isHover == f?.value && fontSelected?.value == f?.value ? "#228be6" :
                                                                    ""
                                            }}
                                        >
                                            {f.label}
                                        </Text>
                                    })
                                }
                            </Grid.Col>
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={2}>
                            <Text size={12} fw={500} my={5}>Font Style:</Text>
                        </Grid.Col>
                        <Grid.Col span={10} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Checkbox
                                size="xs"
                                checked={boldChecked}
                                onChange={(e) => {
                                    console.log('bold e===>', e)
                                    setBoldChecked(e.currentTarget.checked)
                                }}
                            />
                            <Text mx={5} size={12}>Bold</Text>
                            <Checkbox
                                size="xs"
                                checked={italicChecked}
                                onChange={(e) => {
                                    setItalicChecked(e.currentTarget.checked)
                                }}
                            />
                            <Text mx={5} size={12}>Italic</Text>
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Paper shadow="sm" radius="xs" p="xs" withBorder>
                            <div style={{ overflow: 'hidden', minHeight: "20vh", maxHeight: '20vh', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                <Text style={{
                                    // display:"flex",
                                    // wordWrap:"",
                                    // wordBreak:"none",
                                    whiteSpace: "nowrap",
                                    // flexWrap:"nowrap",
                                    fontFamily: fonts.FontFamily == "" ? selectedVal.label : `${fonts.FontFamily}`,
                                    fontSize: fonts.FontSize == 0 ? fontSelected.label : `${fonts.FontSize}px`,
                                    fontWeight: `${fonts.FontWeight}`,
                                    fontStyle: `${fonts.FontStyle}`,

                                }}>
                                    {companyName?.length > 0 ? companyName[0]?.cmpName : ""}
                                </Text>
                            </div>
                        </Paper>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Text size={12}>This font style is imitated for the display. The closest matching style will be used for printing.</Text>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Group>
                                <Button size='xs'
                                    onClick={() => SaveFontData()}
                                >
                                    OK
                                </Button>
                                <Button size='xs' onClick={() => ModalCloseFun()}>Cancel</Button>
                            </Group>
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

            </Paper>
        </>
    )
}

export default FontModal