import React from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import GlobalClass from "../../../utils/GlobalClass";
import JobWorkOutORBForm from "../RIOJobOutForm/JobWorkOutORBForm";
import { GetNullTransactionData, TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import {
  RefreshIOrderPopUp,
  RefreshIssuePopUp,
} from "../../../utils/TransactionSlices/PendingIssueSlice";
import PLRange from "../../../PlusComponent/PLRange";

export function VouEntAct(props) {
  console.log(props, "JOBWORKOut--");
  const OBJ = JSON.parse(props);

  if (OBJ?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "100%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <JobWorkOutORBForm
            obj={OBJ}
            index={i}
            No={OBJ?.page == "20400399" ? "Order No" : "Voucher No"}
            date={OBJ?.page == "20400399" ? "Order Date" : "Voucher Date"}
          />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([
            Store.dispatch(RefreshIssuePopUp({})),
            Store.dispatch(RefreshIOrderPopUp({})),
          ]).then(()=>Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ?.id && OBJ?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "100%",
        tab:OBJ.page.startsWith("P_")?false:true,
        MBody: (i) => (
          <JobWorkOutORBForm
            obj={OBJ}
            index={i}
            No={OBJ?.page == "20400399" ? "Order No" : "Voucher No"}
            date={OBJ?.page == "20400399" ? "Order Date" : "Voucher Date"}
          />
        ),

        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([
            Store.dispatch(RefreshIssuePopUp({})),
            Store.dispatch(RefreshIOrderPopUp({})),
          ]).then(()=>Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Store.dispatch(TransactionDeleteApi(OBJ));
    }
  } else if (OBJ.p0 === "U" && OBJ?.id) {
    console.log(OBJ?.id, "data?.id")

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "100%",
        // position:'right',
        MBody: (i) => <JobWorkOutORBForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          
          Promise.all([
            Store.dispatch(RefreshIssuePopUp({})),
            Store.dispatch(RefreshIOrderPopUp({})),
          ]).then(()=>Store.dispatch(GetNullTransactionData()));        }
      })
    );
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    if (window.confirm('Confirm to Audit ?')) {
        Store.dispatch(TransactionDeleteApi(OBJ))
    }
}else if(OBJ.p0=="R"){ //Range Button
  Store.dispatch(
    ModalFunction({
      onclose: () => {},
      MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
      MAction: true,
      MSize: "98%",
      position:"right",
      MBody: (i) => <PLRange obj={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}
}
