import { useEffect, useRef, useState } from "react";
import {
  createStyles,
  rem,
  Group,
  Button,
  Grid,
  Box,
  Text,
  Card,
  Paper,
  MantineProvider,
  useMantineTheme,
  Kbd,
  MediaQuery,
  Tooltip,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList, GetTMFormat, SaveAccountListData, getCurrentDate, getRangeBtnState } from "../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";
import useOnScreen from "../utils/useOnScreen";
import PLComboBox from "../PlusComponent/PLComboBox";
import ButtoPanel from "../PlusComponent/ButtonPanel";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import * as XLSX from 'xlsx';
// import {
//   IconAlertTriangle,
//   IconArrowBadgeDownFilled,
//   IconArrowBadgeRight,
//   IconArrowBadgeRightFilled,
//   IconChevronLeft,
//   IconChevronRight,
//   IconX,
// } from "@tabler/icons-react";
// import { IconArrowBadgeDown } from "@tabler/icons-react";
// import ButtoPanel from "../PlusComponent/ButtonPanel";
// import { modals } from "@mantine/modals";
// import LocalizedStrings from "react-localization";

import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React from "react";
import { useHotkeys, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import PLTextBox from "../PlusComponent/PLTextBox";
import GlobalClass from "../utils/GlobalClass";
import PLNumberBox from "../PlusComponent/PLNumberBox";
import { ProcessMasterData } from "../utils/slices/ProcessMasterSlice";
import { notifications } from "@mantine/notifications";
import {
  GetButtonPannel,
  GetButtonPannelList,
  GetPaginationObj,
  GetReportIdArray,
} from "../utils/slices/DataSaveLoadingSlice";
import PLDateBox from "../PlusComponent/PLDateBox";
import { IconFileDownload, IconRefresh, IconRefreshDot, IconSearch } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import Store from "../utils/store";
import { ModalFunction } from "../utils/slices/ModalSlice";
import ReportViewer from "./ReportViewer";
import useWindowDimensions from "../utils/UseWindowDimensions";
import PLFormat from "../FunctionsCall/AccountLedger/PLFormat";
import { GetAccountListForPrint } from "../utils/PrintSlice/PrintSlice";
import { getFilterStatus } from "../utils/FilterSlice/FilterSlice";
import ContextMenu from "./ContextMenu";
const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[2]
        }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

function DataGrid(props) {
  // console.log("DataGrid Props==>", props);
  // console.log("datagriddl props", props);
  const globalTheme = useMantineTheme();
  const { height, width } = useWindowDimensions();
  const { tag, obj, form, id, cWhere,isFormatDropdown,isRefresh } = props;
  // console.log("cWhere", obj);
  const date1 = new Date(); //Yasvi Patel
  const [focusset, setfocusset] = useState(false);
  // console.log("obj of dg",obj)
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const disRef = useRef();
  const dispatch = useDispatch();
  const TableRef = useRef();
  const childRef = useRef();
  useEffect(() => {
    const elements = document.querySelectorAll('button[aria-label="Show/Hide search"]');
    elements.forEach((el) => (el.style.display = "none"));
  }, []);
  // const TableDiv = useRef(null)
  // const [manualPagination, setManualPagination] = useState(true);
  // const AccountListData = useSelector((state) => state.AccountList?.AccountListData[obj?.id] ?? []);
  // const TMFormatData = useSelector((state) => state.AccountList?.TMFormatData[obj?.id] ?? []);
  const {
    hasError,
    ErrorMsg,
    isLoading,
    AccountListData: accList,
    TMFormatData: tmList,
    cFooter,
    rowCount,
    isRefetching,
  } = useSelector((state) => state.AccountList);
  // const PaginationObj = useSelector(state => state?.DataSaveLoadingSlice?.PaginationObj)

  const [acTM, setacTM] = useState({
    AccountListData: [],
    TMFormatData: [],
  });
  // console.log('TMFormatData', TMFormatData);
  const [cfmtid, setcfmtid] = useState("");
  const [columns, setColumns] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [rowSelection, setRowSelection] = useState({}); //{ 0: true }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [sessionSetting, setSessionSetting] = useLocalStorage({
    key: 'session_setting',
    defaultValue: {
      Primary_Color: 'blue',
      Font_Family: 'Arial',
      Font_Size: 12,
      Font_XS: 10,
      Font_SM: 12,
      Font_MD: 14,
      Font_LG: 16,
      Font_XL: 20
    },
    getInitialValueInEffect: true,
  })

  const ReportIdArray = useSelector((state) => state.DataSaveLoadingSlice.ReportIdArray);

  const InputRef = useRef();

  const TransactionReportIdArray = useSelector((store) => store.DataSaveLoadingSlice.TransactionReportIdArray);
  const GetGeneral = useSelector((state) => state.Language.General);
  const SearchInputValue = useSelector((store) => store?.AccountList?.SearchInputValue);
  const currentDate = useSelector((state) => state?.AccountList?.currentDate); // Yasvi Patel ( for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
  const flagDate = useSelector((state) => state?.AccountList?.flag); // Yasvi Patel
  const isFilterApplied = useSelector(store => store?.FilterSlice?.isFilterApplied) // Filter is applied or not 
  const sortingOrderField = useSelector((store)=>store?.AccountList?.sortingOrderField); // Yasvi Patel
  const sortingArrangeField = useSelector((store)=>store?.AccountList?.sortingArrangeField); // Yasvi Patel
const SaveAccountListArr =  useSelector((store)=>store?.AccountList.SaveAccountListArr); //Rathod kishan
const BtnStatus = useSelector((store)=>store?.DataSaveLoadingSlice.ButtonPannelSave); //rathod kishan
const BtnStatusList = useSelector((store)=>store?.DataSaveLoadingSlice?.ButtonPannelList); //rathod kishan

  // const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);
  // const YearAndDateSend = useSelector((state) => state.UserList.YearAndDateSend);
  // const [dateAndYear, setDateAndYear] = useState();
  // const [isDataGridCalled, setisDataGridCalled] = useState();
  const media = useMediaQuery('(min-width: 62em)');
  const [date, setDate] = useState({});
  const [minimunDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();
  const [searchColumns, setSearchColumns] = useState();
  const [StrtDt, setStrtDt] = useState();
  const [EndDt, setEndDt] = useState();
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState();
  const [flag, setFlag] = useState(false);
  const [currentDate1, setcurrentDate1] = useState({}); // Yasvi Patel
  const [flagDate1, setFlagDate1] = useState({}); // Yasvi Patel
  // const [dDate,setDDate] = useState({});
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [date2,setDate2]=useState({});
  const [flagOfReport,setFlagOfReport]=useState(false);
  const [flagOfReport1,setFlagOfReport1]=useState(false);
  // const AccountListData = useSelector(store => store?.PrintList?.PrintData);

  // useEffect(() => {
  //   // AccountListData: accList,
  //   // TMFormatData: tmList,
  //   if (accList && tmList) {
  //     console.log("GenData and Tmformat =>", [{
  //       "accList": accList,
  //       "tmList": tmList
  //     }]);
  //   }
  // }, [accList, tmList])

  // useEffect(() => {
  //   const nextInput = document.getElementById(`${obj.id}Grid`)

  //   if (nextInput) {
  //     nextInput.focus()
  //   }
  // }, []);
  //Rathod kishan selected row (+) and (-)
  // useEffect(() => {

  //   document.getElementById("TableDiV").addEventListener("keydown", (e) => {
  //       console.log("addEventListener=>",e);

  //       var r = tabled.getSelectedRowModel().flatRows
  //       let pagerows = [];
  //       pagerows = [...Object.keys(tabled.getPaginationRowModel().rowsById)];
  //       if (e.key == "ArrowDown") {
  //         // console.log("onKeyDown=>", r[0]?.id +"===="+(pagerows?.length));
  //         // console.log("TableRef",  TableRef.current)
  //         if (r && r.length > 0) {
  //           e.preventDefault()
  //           if (r[0]?.id == (pagerows?.length - 1)) {
  //             // if(tabled.getCanNextPage()){

  //             tabled.nextPage();
  //             setRowSelection({ [0]: true });
  //             disRef.current.children[0].scrollIntoView({ behavior: "smooth", block: "start" })
  //             // }
  //           }
  //           else {
  //             setRowSelection({ [parseInt(r[0]?.id) + 1]: true })
  //             TableRef.current.children[parseInt(r[0]?.id)].scrollIntoView({ behavior: "smooth", })
  //           }
  //         }
  //       } else if (e.key == "ArrowUp") {
  //         if (r && r.length > 0) {
  //           e.preventDefault()
  //           if (r[0]?.id == 0) {
  //             // if(tabled.getCanPreviousPage()){
  //             tabled.previousPage();
  //             setRowSelection({ [pagerows?.length - 1]: true });
  //             TableRef.current.children[pagerows?.length - 1].scrollIntoView({ behavior: "smooth", block: "end" })
  //             // }
  //           }
  //           else {
  //             setRowSelection({ [parseInt(r[0]?.id) - 1]: true })
  //             if ((parseInt(r[0]?.id) - 2) >= 0) {
  //               TableRef.current.children[parseInt(r[0]?.id) - 2]?.scrollIntoView({ behavior: "smooth", })
  //             }
  //             else {
  //               disRef.current.children[0].scrollIntoView({ behavior: "smooth", block: "start" })
  //             }

  //           }

  //         }
  //       }else if (e.key == "ArrowRight") {
  //         // if(tabled.getCanNextPage()){
  //         e.preventDefault()
  //         tabled.nextPage();
  //         setRowSelection({ [0]: true });
  //         disRef.current.children[0].scrollIntoView({ behavior: "smooth", block: "start" })
  //         // }
  //       }else if (e.key == "ArrowLeft") {
  //         // if(tabled.getCanPreviousPage()){
  //         e.preventDefault()
  //         tabled.previousPage();
  //         setRowSelection({ [pagerows?.length - 1]: true });
  //         TableRef.current.children[pagerows?.length - 1].scrollIntoView({ behavior: "smooth", block: "end" })
  //         // }
  //       }
  //     })

  // }, [])

  // Report Menu
  // useEffect(() => {
  //   if (ReportIdArray.includes(obj.id)) {
  //     setManualPagination(false);
  //   } else {
  //     setManualPagination(true);
  //   }
  // }, [ReportIdArray, obj.id]);

  //SEARCH


  //TEMP SEARCH
  // useEffect(() => {
  //   if (aSrcDic) {
  //     let objValueAry = Object.values(aSrcDic);
  //     let columnArray = [];
  //     objValueAry.filter((item) => {
  //       acTM?.TMFormatData?.oDCFrmt?.aDCol?.map((ele, index) => {
  //         // Search on first column
  //         // if (index === 0 && ele.DispExpr === item.cFldID) {
  //         if (ele.DispExpr === item.cFldID) {
  //           columnArray.push(item.FileID + "." + ele.SearchFld);
  //         }
  //       });
  //     });
  //     setSearchColumns(columnArray);
  //     // console.log("objValueAry", objValueAry);
  //     // console.log("acTM?.TMFormatData?.oDCFrmt?.aDCol", acTM?.TMFormatData?.oDCFrmt?.aDCol);
  //     console.log("columnArray", columnArray);
  //     // console.log(aSrcDic,"aSrcDic");
  //   }
  // }, [aSrcDic]);//Vara Pooja
  const dateFormater2 = (inputDate) => { // Yasvi Patel
    const date = new Date(inputDate);

    // Extract components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    // Get the timezone offset
    const timezoneOffset = -date.getTimezoneOffset(); // in minutes
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
    const timezoneString = (timezoneOffset >= 0 ? '+' : '-') + offsetHours + ':' + offsetMinutes;

    // Construct the final output
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00${timezoneString}`;

    return formattedDate;
  }

  const dateFormater = (date, temp) => {
    let result;
    if (temp === 0) {
      var paraArr = date?.split("T");
      var paraArr1 = new Date(paraArr);
      result = paraArr1;

    } else if (temp === 1) {
      let year = date.toLocaleString("en-IN", { year: "numeric" });
      let month = date.toLocaleString("en-IN", { month: "2-digit" });
      let day = date.toLocaleString("en-IN", { day: "2-digit" });
      let date1 = year + month + day;
      const date2 =
        date1?.slice(0, 4) +
        "-" +
        date1?.slice(4, 6) +
        "-" +
        date1?.slice(6, 8);
      result = date1;
    }
    return result;
  };//Vara Pooja
  // program to check leap year
  function checkLeapYear(year) {

    //three conditions to find out the leap year
    if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
      return true;
    } else {
      return false;
    }
  }

  let lom = "";
  let m = String(date1?.getMonth()+1).padStart(2, '0');
  switch (m.toString()) {
    case "01":
      lom = 31;
      break;
    case "02":
      lom = checkLeapYear(date1.getFullYear()) ? 29 : 28;
      break;
    case "03":
      lom = 31;
      break;
    case "04":
      lom = 30;
      break;
    case "05":
      lom = 31;
      break;
    case "06":
      lom = 30;
      break;
    case "07":
      lom = 31;
      break;
    case "08":
      lom = 31;
      break;
    case "09":
      lom = 30;
      break;
    case "10":
      lom = 31;
      break;
    case "11":
      lom = 30;
      break;
    case "12":
      lom = 31;
      break;
    default:
      break;
  }


  useEffect(() => {
    setcurrentDate1(currentDate);
    setFlagDate1(flagDate);
  }, [currentDate, flagDate]); //Yasvi Patel

  // useEffect(() => {
  //   // console.log("GetGeneral", GetGeneral?.dYEDate, GetGeneral?.dYEDate);
  //   const strtDate = date1.getFullYear()
  //       + GetGeneral?.dYSDate.substr(4,1)+parseInt(date1.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+"01"+GetGeneral?.dYSDate.substr(10);
  //    console.log("strtDate",strtDate);
  //   //     const currentdateNewFrom = currentDate[obj.id]?.From?.getFullYear()
  //   // + GetGeneral?.dYSDate.substr(4,1)+parseInt(currentDate[obj.id]?.From?.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+currentDate[obj.id]?.From?.getDate()+GetGeneral?.dYSDate.substr(10);
  //   // console.log("currentdateNew",currentdateNewFrom);

  //     const endDate = date1.getFullYear()
  //       + GetGeneral?.dYSDate.substr(4,1)+parseInt(date1.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+lom+GetGeneral?.dYSDate.substr(10);   
  //       // const currentdateNewTo = currentDate[obj.id]?.From?.getFullYear()
  //       // + GetGeneral?.dYSDate.substr(4,1)+parseInt(currentDate[obj.id]?.To?.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+currentDate[obj.id]?.To?.getDate()+GetGeneral?.dYSDate.substr(10);;
  //       // console.log("currentdateTo",currentdateNewTo);


  //       if(flagDate[obj?.id]==false)  {

  //     Store.dispatch(getCurrentDate({"date":{From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0)},"id": obj?.id,"flag":false}))};

  // }, [GetGeneral]);//Vara Pooja

  useEffect(() => {
    // console.log("GetGeneral", GetGeneral?.dYEDate, GetGeneral?.dYEDate);
    const strtDate1 = GetGeneral?.dYSDate;
    const endDate1 = GetGeneral?.dYEDate;
    //  if(tmList[obj?.id]){
      // setFlagOfReport1(true);
      let flag = false;
      if(new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime()){
        flag=true;
      }
     
      const strtDate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth()+1).padStart(2, '0')+  "-" + "01"+GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01')
      const endDate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth()+1).padStart(2, '0') + "-" + lom + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate
        setDate({ From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0) })
    // }
    // }
    // console.log("flagDate",flagDate1)
    if (flagDate1[obj?.id]==false || flagDate1=={} || flagDate1==false || flagDate1==undefined) {
      if(tmList[obj.id]?.oDTR?.DTType=="S"){
        Store.dispatch(getCurrentDate({ "date": { From: dateFormater(date2?.From, 0), To: dateFormater(date2?.To, 0) }, "id": obj?.id, "flag": false }))

      }else if(tmList[obj.id]?.oDTR?.DTType=="R"){
      Store.dispatch(getCurrentDate({ "date": { From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0) }, "id": obj?.id, "flag": false }))
      }
    }; //Yasvi Patel

    setMinimumDate(dateFormater(strtDate1, 0)); //Yasvi Patel
    setMaximumDate(dateFormater(endDate1, 0)); //Yasvi Patel}
  // }
}, [GetGeneral]); //Vara Pooja 

  //SEARCH

  useEffect(() => {
    // console.log("SearchInputValue in Datagrid", SearchInputValue);
    if (SearchInputValue == "" && flag) {
      setFlag(false)
      setSearch("")
      // console.log("InputRef",InputRef);
      // InputRef.current.value = ""
    }
  }, [SearchInputValue])



  //TEMP SEARCH
  // useEffect(() => {
  //   if (aSrcDic) {
  //     let objValueAry = Object.values(aSrcDic);
  //     let columnArray = [];
  //     objValueAry.filter((item) => {
  //       acTM?.TMFormatData?.oDCFrmt?.aDCol?.map((ele, index) => {
  //         // Search on first column
  //         // if (index === 0 && ele.DispExpr === item.cFldID) {
  //         if (ele.DispExpr === item.cFldID) {
  //           columnArray.push(item.FileID + "." + ele.SearchFld);
  //         }
  //       });
  //     });
  //     setSearchColumns(columnArray);
  //     // console.log("objValueAry", objValueAry);
  //     // console.log("acTM?.TMFormatData?.oDCFrmt?.aDCol", acTM?.TMFormatData?.oDCFrmt?.aDCol);
  //     console.log("columnArray", columnArray);
  //     // console.log(aSrcDic,"aSrcDic");
  //   }
  // }, [aSrcDic]);//Vara Pooja

  // useEffect(() => {
  //   // console.log("search", search);
  //   // console.log(query, "qr");
  //   // console.log("pagination", pagination);


  //   if (flag && search.length <= 0) {
  //     setQuery()
  //     // if (pagination.pageIndex === 0 && pagination.pageSize === 25) {
  //     //   dispatch(GetAccountList({ ...obj,pagination,StrtDt: sDate, EndDt: eDate, cWhere: query }));
  //     // }
  //     // else {
  //     //   setPagination({
  //     //     pageIndex: 0,
  //     //     pageSize: 25,
  //     //   });
  //     // }
  //     // setPagination({
  //     //   pageIndex: 0,
  //     //   pageSize: 25,
  //     // });
  //     dispatch(GetAccountList({ ...obj }))
  //     setFlag(false)
  //   }

  // }, [search])//Vara Pooja

  const searchButton = () => {
    let query = ""
    const sDate = dateFormater(currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From :date?.From, 1); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
    const eDate = dateFormater(currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.To :date?.To, 1);
    setStrtDt(sDate);
    setEndDt(eDate);
    var newobj;
    if (cfmtid == "") {
      newobj = { ...obj, p2: obj.p2 };
    } else {
      newobj = { ...obj, p2: cfmtid };
    }
    if (searchColumns && search) {
      let str = [];
      searchColumns?.map((item) => str.push(`${item} LIKE '%${search}%'`))
      query = (cWhere?cWhere+" and ":"") + str.join(' OR ')
      setQuery(query)
      setFlag(true)
    }
    if (TransactionReportIdArray.includes(obj.id) && sDate && eDate) {
      if (sDate <= eDate) {
        dispatch(GetAccountList({ ...newobj, StrtDt: props?.obj?.StrtDt ?? sDate, EndDt: props?.obj?.EndDt ?? eDate, cWhere: query })); //, cWhere: query 
        dispatch(getRangeBtnState({"id":[obj.id],"rangeBtnState":true}))
      }
      else {
        GlobalClass.Notify("warning", "", "'From date' should be less than or equals 'To date'");
      }
    }
    else {
     
      dispatch(GetAccountList({ ...newobj, StrtDt: props?.obj?.StrtDt ?? sDate, EndDt: props?.obj?.EndDt ?? eDate, cWhere: query })); //, cWhere: query 
      dispatch(getRangeBtnState({"id":[obj.id],"rangeBtnState":true}))
    }


  };//Vara Pooja

  const PLSearchButton = () => {

    // console.log("search", search);
    // console.log("searchColumns", searchColumns);

    // if (date?.From !== dDate?.From) {
      dispatch(getRangeBtnState({"id":[obj.id],"rangeBtnState":true}))
    // if (search.length > 0) {

      //Search on first column
      let query = `${cWhere?cWhere+" and ":""}${searchColumns[0] ?? ""} LIKE '%${search}%'`;
      setFlag(true)
      // setQuery(query);

      // console.log("query", query);
      // console.log("pagination", pagination);

      // let str = [];
      // searchColumns.map((item) => str.push(`${item} LIKE '%${search}%'`))
      // let query = str.join(' OR ')
      // setQuery(query)

      // if (isDataGridCalled != true && date.From && date.To && acTM?.AccountListData.length !== 0) {
      const sDate = dateFormater(currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From :date?.From, 1); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
      const eDate = dateFormater(currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To :tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From : date?.To, 1);
      setStrtDt(sDate);
      setEndDt(eDate);
      // if (date?.From && date?.To) {
      // if (date.From.valueOf() < date.To.valueOf()) {
      dispatch(GetAccountList({ ...obj, StrtDt: props?.obj?.StrtDt ? props?.obj?.StrtDt : sDate, EndDt: props?.obj?.EndDt ? props?.obj?.EndDt : eDate, cWhere: query }));
      // if (pagination.pageIndex === 0 && pagination.pageSize === 25) {
      // }
      // else {
      //   setPagination({
      //     pageIndex: 0,
      //     pageSize: 25,
      //   });
      // }

    // }
    // else {
    //   GlobalClass.Notify("warning", "", "'From date' should be less than 'To date'");
    // }
    // }

    // setMinimumDate(date?.From);
    // }

    // const sDate = dateFormater(date?.From, 1);
    // const eDate = dateFormater(date?.To, 1)
    // dispatch(GetAccountList({ ...obj, StrtDt: sDate, EndDt: eDate, pagination, cWhere: search, }));
    // }
  };//Vara Pooja

  const alphaArr = GlobalClass.keyArr;

  let HotKeys = alphaArr?.map((btn, i) => [
    btn,
    () =>
      btn == "backspace"
        ? setSearch((prev) => prev.slice(0, -1))
        : setSearch((prev) => prev + btn),
  ]);
  useHotkeys(HotKeys);

  useEffect(() => {
    if (hasError && !isLoading[obj?.id]) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  // useEffect(()=>{
  //   // console.log("........................",acTM.AccountListData,acTM.TMFormatData);
  // },[acTM.TMFormatData,acTM.TMFormatData])
  // const [searchParams, setSearchParams] = useSearchParams();

  const columnHelper = createColumnHelper();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  let summaryarr = [];

  useEffect(() => {
    // console.log("notag");
    // if (tag == 'PM') {
    //   dispatch(ProcessMasterData())
    //   console.log('ProcessMData==>', ProcessMData);
    // } else {
    // setcfmtid(selectedId);
    var newobj;
    if (cfmtid == "") {
      newobj = { ...obj, p2: obj.p2 };
    } else {
      newobj = { ...obj, p2: cfmtid };
    }

    if (props.TmData) {
      setacTM({ TMFormatData: props.TmData, AccountListData: props.data });
    } else {
      let flag = false
      if(new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime()){
        flag=true;
      }
     
      const strtDate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth()+1).padStart(2, '0') + "-" + "01"  + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01');
      const edate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth()+1).padStart(2, '0') + "-" + lom  + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate

      dispatch(
        GetTMFormat({
          ...newobj,
          // StrtDt: currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From :strtDate, // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
          // EndDt: currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.To :edate,
        })
      );
      setFlagOfReport(false)
    }

    // if (props.data) {
    //   // dispatch(GetAccountList(obj));

    // } else {
    //   dispatch(GetAccountList({...obj,pagination}));
    // }

    // }
  }, [cfmtid]);


  // console.log("query",query);
  // console.log("cWhere",cWhere);

  // useEffect(() => {
  //   if (form && props.data) {
  //     setPagination({ pageIndex: props?.pagination?.pageIndex, pageSize: props?.pagination?.pageSize })
  //   }
  // }, [props.data])//PLDATAGRID VAlUE ISSUE (2 OR 3 Page pr hoy to edit na case ma proper display krva mate)//VARA POOJA

  useEffect(() => {
    if (props.data) {
      // dispatch(GetAccountList(obj));
      // setManualPagination(false);
      setacTM({ ...acTM, AccountListData: props.data });
      // props.data.map((item,index) => {
      //   let value = Object.keys(props.selectedData)
      //   if(value ==){
      //   }
      // })
    } else {
      if(tmList?.[obj.id] && flagOfReport==false){
        setFlagOfReport(true)
         if (!ReportIdArray.includes(obj.id) || (acTM.AccountListData && acTM.AccountListData.length <= 0) || true) {
        let flag = false
        if(new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime()){
          flag=true;
        }
       
        const strtDate = flag ? date1.getFullYear()
          + "-" + String(date1?.getMonth()+1).padStart(2, '0') + "-" + "01"  + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01')
        const edate = flag ? date1.getFullYear()
          + "-" + String(date1?.getMonth()+1).padStart(2, '0') + "-" + lom  + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate
         
          let date2= {From:!flagDate1[obj?.id] ? 
          new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime() ? (date1.getFullYear()+ "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
          : GetGeneral?.dYEDate : 
          currentDate[obj?.id],To:!flagDate1[obj?.id] ? 
          new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime() ? (date1.getFullYear()+ "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
          : GetGeneral?.dYEDate : 
          currentDate[obj?.id]}
          dispatch(getRangeBtnState({"id":[obj.id],"rangeBtnState":true}))
          var newobj;
    if (cfmtid == "") {
      newobj = { ...obj, p2: obj.p2 };
    } else {
      newobj = { ...obj, p2: cfmtid };
    }
           dispatch(GetAccountList({
          ...newobj,
          // pagination: ReportIdArray.includes(obj.id) ? null : pagination,
          StrtDt: props?.obj?.StrtDt ? props?.obj?.StrtDt : StrtDt ?? currentDate[obj?.id]?.From ? dateFormater2(currentDate[obj?.id]?.From) : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From : strtDate, // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
          EndDt: props?.obj?.EndDt ? props?.obj?.EndDt : EndDt ?? currentDate[obj?.id]?.To ? dateFormater2(currentDate[obj?.id]?.To) : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From : edate,
          cWhere: cWhere ?? query,
          // ReportIdSave: ReportIdArray.includes(obj.id),
        })
        );
      }}
      // dispatch(
      //   GetPaginationObj({
      //     id: obj.id,
      //     // pagination: ReportIdArray.includes(obj.id) ? null : pagination,
      //     // ReportIdSave: ReportIdArray.includes(obj.id),
      //   })
      // );
    }
    // }
  }, [props.data,tmList[obj.id],flagOfReport]);

  let lfstcol = -1;
  let lcolno = -1;
  let nstr = "#";
  let dstr = "0";

  const Summary = (obj, i, tag) => {
    let stype = "sum";
    var value = null;
    const closingBal = cFooter?.cFooter?.[0]?.CtrlProp?.Text
    // console.log("Summary",obj);
    if (obj.cSType !== "N") {
      if (lfstcol < 0) {
        lfstcol = i;
      }
      switch (obj.cSType) {
        case "C":
        case "Y":
          stype = "count";
          // value = (props.data
          //   ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
          //   : acTM.AccountListData.reduce(
          //     (acc, curr) => acc + curr[obj.DispExpr],
          //     0
          //   ))?.toFixed(4);

          value = (props.data
            ? props.data.reduce((acc, curr) => {
              const num = parseFloat(curr[obj.DispExpr]);
              return isNaN(num) ? parseFloat(acc) : acc + num;
            }, 0)
            : acTM.AccountListData.reduce((acc, curr) => {
              const num = parseFloat(curr[obj.DispExpr]);
              return isNaN(num) ? parseFloat(acc) : acc + num;
            }, 0)).toFixed(4);
          break;
        case "A":
          stype = "avg";
          break;
        case "X":
          stype = "max";
          break;
        case "M":
          stype = "min";
          break;
        case "U":
          stype = "custom";
          break;
        default:
          // value = props.data
          //   ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
          //   : acTM.AccountListData.reduce(
          //     (acc, curr) => acc + curr[obj.DispExpr],
          //     0
          //   );
          break;
      }
    } else if (obj.ColWidth > 4 && lfstcol < 0) {
      lcolno = i;
    }
    // console.log('value',value)
    // console.log("Summaryvalue",obj.cSType);
    // if (value && !isNaN(Number(value))) {
    //   return closingBal
    // }
    return <div style={{textAlign:'right'}}>
      {(value)}
      {i == 5 ? <Text>{(value && !isNaN(Number(value))) ? closingBal?.split(" : ")[0] : ''}</Text> : null}
      {i == 6 ? <Text>{(value && !isNaN(Number(value))) ? closingBal?.split(" : ")[1] : ''}</Text> : null}
    </div>
  };
  const evaluateExpression = (expression, obj, ori) => {
    // Use a function to safely evaluate the expression
    // let result = "";
    try {
      return new Function(...Object.keys(obj), `return ${expression};`)(...Object.values(obj));
    }
    catch {
      return ori;
    }
    // return result;
  };
  const rendercolcell = (data, v, row) => {

    // console.log('data===>>',data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt = dtxt.toString()  //Vara Pooja
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      // return dtxt
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {

        if (parseFloat(dtxt) > 0) {
          dtxt = Math.abs(dtxt);
          dtxt += " CR";
        } else if (parseFloat(dtxt) == 0) {
          dtxt = 0
        }
        else {
          dtxt = Math.abs(dtxt);
          dtxt += " DB";
        }
      }
    }

   const replaceMethod =(substring,index, replacement) => {
      return substring.substring(0, index) + replacement + substring.substring(index + replacement.length);
  }
  

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : sessionSetting?.Font_Size,
          fontFamily: v.FontName ? v.FontName : sessionSetting?.Font_Family,
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };

  const rendercolcellGrp = (data, v) => {
    // console.log("EPLUS",v);
    // console.log('data===>>',data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      return dtxt;
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt += " CR";
        } else {
          dtxt += " DB";
        }
      }
    }

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : 10,
          fontFamily: v.FontName ? v.FontName : "Verdana",
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  function cellRender(data) {
    if (validURL(data.value)) {
      return <img src={data.value} />;
    } else {
      return data.value;
    }
  }
  const renderGroup = (data) => {
    //console.log(data);
    // return  <p style={
    //   {
    //     'fontSize': 12,
    //     'fontWeight':'bold',
    //     'fontFamily': 'Verdana',
    //     'color': 'red',
    //     'backgroundColor': 'pink'
    //   }
    // }
    // >

    return <div>{data.value}</div>;
    // </p>
  };

  useEffect(() => {
    // console.log("tmList", accList, tmList);
    if (accList && tmList && accList[obj.id] && tmList[obj.id] && !props.TmData) {
      setacTM({
        AccountListData: props?.data ?? (accList[obj.id] ?? []),
        TMFormatData: tmList[obj.id],
      });
      setfocusset(false);

      // console.log("SaveAccountListArr 1",[{
      //   "SaveAccountListArr":SaveAccountListArr,
      //   'accList[obj.id]':accList[obj.id]
      // }]);
      if((ReportIdArray.includes(obj?.id)) && accList[obj.id] && (SaveAccountListArr) &&(!(obj.id in SaveAccountListArr))){
        // console.log("SaveAccountListArr 11")
        dispatch(SaveAccountListData({...SaveAccountListArr,[obj.id]:accList[obj.id]}))
        dispatch(GetButtonPannel({...BtnStatus,[obj.id]:"C"}))
        dispatch(GetButtonPannelList({...BtnStatusList,[obj.id]:{...BtnStatusList[obj.id],"AdjustmentWise":"F","PendingDue":"A","AllParty":"A","DueDays":"F"}}))
        // dispatch(GetButtonPannel({obj,Btn:acTM.TMFormatData?.oS60?.DTHKey}))
      }

    }else if ( tmList && tmList[obj.id] && !props.TmData) {
      setacTM({
        AccountListData: props?.data ?? [],
        TMFormatData: tmList[obj.id],
      });

      // console.log("SaveAccountListArr 2",[{
      //   "SaveAccountListArr":SaveAccountListArr,
      //   'accList[obj.id]':accList[obj.id]
      // }]);

      if((ReportIdArray.includes(obj?.id)) && props?.data && (SaveAccountListArr) && (!(obj.id in SaveAccountListArr))){
        // console.log("SaveAccountListArr 22")
        dispatch(SaveAccountListData({...accList,[obj.id]:props?.data}))
        dispatch(GetButtonPannel({...BtnStatus,[obj.id]:"C"}))
        // dispatch(GetButtonPannel({obj,Btn:acTM.TMFormatData?.oS60?.DTHKey}))
      }
    }

  }, [accList, tmList, accList[obj.id]]);

  useEffect(() => {
    // console.log("acTM.TMFormatData", acTM?.TMFormatData);
    if (tag && tag == "PM") {
    } else {
      if (
        acTM.TMFormatData &&
        acTM.TMFormatData.oDCFrmt &&
        acTM.TMFormatData.oDCFrmt.aDCol &&
        (acTM.AccountListData || props.data)
      ) {
        
        var cols = [];
        var grp = [];
        // console.log("colset", acTM?.TMFormatData?.oDCFrmt);
        if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 0) {
          // acTM.TMFormatData.oDCFrmt?.aDGrp.map((g, i) => {
          //   // console.log('aDGrp',g)
          //   grp.push(g.DispExpr);
          // });
          if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 1 && !props?.isMultiGroup) {
            cols.push(
              columnHelper.accessor("combinedColumn", {
                header: "",
                enableSorting: false,
                sortDescFirst: false,

                // maxSize: v.ColWidth,
                accessorFn: (row) => acTM.TMFormatData.oDCFrmt?.aDGrp.map((key) => row[key?.DispExpr]).join(' - '),
                id: 'combinedColumn',
              })
            );
            setGrouping(['combinedColumn']);
          }
          else if(props?.isMultiGroup){
            let v = acTM.TMFormatData.oDCFrmt?.aDGrp;
            let grps = [];
            v.map((v, i) => {
              cols.push(
                columnHelper.accessor(v.DispExpr, {
                  header: "",
                  enableGlobalFilter: true,
                  enableSorting: false,
                  sortDescFirst: false,

                  size: v.ColWidth,
                  minSize: v.ColWidth,
                  // maxSize: v.ColWidth,
                  // accessorFn: (row) =>acTM.TMFormatData.oDCFrmt?.aDGrp.map((key) => row[key?.DispExpr]).join(' - '),
                  id: v.DispExpr,
                })
              );
              grps.push(v.DispExpr);
             
            })
            setGrouping(grps);
            
          }
          else {
            let v = acTM.TMFormatData.oDCFrmt?.aDGrp[0];
            cols.push(
              columnHelper.accessor(v.DispExpr, {
                header: "",
                enableGlobalFilter: true,
                enableSorting: false,
                sortDescFirst: false,

                size: v.ColWidth,
                minSize: v.ColWidth,
                // maxSize: v.ColWidth,
                // accessorFn: (row) =>acTM.TMFormatData.oDCFrmt?.aDGrp.map((key) => row[key?.DispExpr]).join(' - '),
                id: v.DispExpr,
              })
            );
            setGrouping([v.DispExpr]);
          }
        }
        else {
          setGrouping([])
        }
        
        acTM.TMFormatData.oDCFrmt.aDCol.map((v, i) => {
          // console.log('i=======>',i)
          // console.log("v=>>",v)

          //01340123, P_M31T = City
          //01350123, "P_M31A" = Area
          //P_PLSTATE
          // if (i == 0) {
          //   setSorting([{ id: v.DispExpr, desc: ("01340123,P_M31T,01350123,P_M31A,P_PLSTATE,P_M45I".includes(obj?.id) ? false : true) }]);
          // }
          console.log("cols",v.ColCap + " = " + v.cColID);
          
          cols.push(
            // obj.id == "01980001" ? "FIELD02" :
            columnHelper.accessor(v.DispExpr, {
              header: v.ColCap,
              id: v.cColID && v.cColID != '' ?v.cColID:Math.random(),
              enableGlobalFilter: ((v.DataType == "D" || !v.ColCap) ? false : true),
              enableColumnFilter: v.lSearchCol,
              enableSorting:v.ColCap? v.lColOrder:false,
              sortDescFirst: v.ColCap? v.lColOrder:false,

              size: v.ColCap? v.ColWidth : 0,
              minSize: 0,
              maxSize: v.ColWidth,
              mantineTableBodyCellProps: {
                align: v.DAlign == 1 ? "right" : "left",
              },
              
              mantineTableHeadCellProps: {
                align: v.HAlign == 1 ? "right" : "left",
              },
              aggregationFn: "sum",
              AggregatedCell: ({ cell }) =>
                v?.cSType !== "N" ? (
                  <div>
                    <Text style={{ fontWeight: "bold", color: "gray", fontSize: '12px' }}>
                      Total : {cell.getValue().toFixed(4)}
                    </Text>
                  </div>
                ) : (
                  ""
                ),
              Cell: ({ cell, renderedCellValue, row }) =>
                //  console.log('cell====>',cell),\

                rendercolcell(evaluateExpression(v.DispExpr, row?.original, cell.getValue()), v, row),
              Footer: () => (<div>{Summary(v, i, "c")} </div>),
              // enableGrouping: acTM.TMFormatData.oDCFrmt?.aDGrp?.includes(v.cColID),
              // Edit: ({ cell, column, table, row }) => {
              //   var val = cell.getValue();
              //   console.log('row.original==>', row.original);
              //   console.log('cell.getValue()==>', val);
              //   if (v.DataType == 'C') {
              //     return <PLTextBox />
              //   }
              //   return val
              // }
            })
          );
          v?.aChildCol?.map((c) => {
            cols.push(
              columnHelper.accessor(c.DispExpr, {
                header: c.ColCap,
                id: c.cColID && c.cColID != '' ?c.cColID:Math.random(),
                enableGlobalFilter: ((c.DataType == "D" || !c.ColCap) ? false : true),
                enableColumnFilter: c.lSearchCol,
                enableSorting:c.ColCap? c.lColOrder:false,
                sortDescFirst: c.ColCap? c.lColOrder:false,
  
                size: c.ColCap? c.ColWidth : 0,
                minSize: 0,
                maxSize: c.ColWidth,
                mantineTableBodyCellProps: {
                  align: c.DAlign == 1 ? "right" : "left",
                },
                
                mantineTableHeadCellProps: {
                  align: c.HAlign == 1 ? "right" : "left",
                },
                aggregationFn: "sum",
                AggregatedCell: ({ cell }) =>
                  c?.cSType !== "N" ? (
                    <div>
                      <Text style={{ fontWeight: "bold", color: "gray", fontSize: '12px' }}>
                        Total : {cell.getValue().toFixed(4)}
                      </Text>
                    </div>
                  ) : (
                    ""
                  ),
                Cell: ({ cell, renderedCellValue, row }) =>
                  //  console.log('cell====>',cell),\
  
                  rendercolcell(evaluateExpression(c.DispExpr, row?.original, cell.getValue()),c, row),
                Footer: () => (<div>{Summary(c, i, "c")} </div>),
                // enableGrouping: acTM.TMFormatData.oDCFrmt?.aDGrp?.includes(v.cColID),
                // Edit: ({ cell, column, table, row }) => {
                //   var val = cell.getValue();
                //   console.log('row.original==>', row.original);
                //   console.log('cell.getValue()==>', val);
                //   if (v.DataType == 'C') {
                //     return <PLTextBox />
                //   }
                //   return val
                // }
              })
            );
          });
        });


        //Port PLDataGrid aChildCol
        
        // if (form && obj?.id == "P_PLPORT") {
        //   tmList["P_PLPORT"]?.oDCFrmt?.aDCol?.map((d) => {
        //     d?.aChildCol?.map((c) => {
        //       cols.push(
        //         columnHelper.accessor(c.DispExpr, {
        //           header: c.ColCap,
        //           enableHiding: true,
        //           enableColumnFilter: c.lSearchCol,
        //           enableSorting: c.lColOrder,
        //           size: c.ColWidth,
        //           minSize: 0,
        //           maxSize: c.ColWidth,
        //         })
        //       );
        //     });
        //   });
        // }


        // console.log("grouping useEffect", grouping);
        console.log("Columns99=>>",acTM?.TMFormatData?.oDCFrmt?.aDCol);
        setColumns(cols);

        // console.log("Cols", acTM.TMFormatData.DTS62[0]);
      }
    }
    // console.log('hasError==>',hasError);
    // console.log("AccountList & TmFormate Data==>", acTM);

    // let ary = acTM?.TMFormatData?.oDCFrmt?.aDCol?.filter((item) => item.lSearchCol === true); // Search
    let ary = acTM?.TMFormatData?.oDCFrmt?.aDCol.filter(item => item.DataType != "D")
    // setlSearchCol(ary);
    if (acTM?.TMFormatData?.oDCFrmt?.aSrcDic) {
      let objValueAry = Object.values(acTM?.TMFormatData?.oDCFrmt?.aSrcDic)
      let columnArray = []
      objValueAry.filter((item) => {
        ary.map(ele => {
          if (ele.DispExpr === item.cFldID) {
            columnArray.push(item.FileID + "." + ele.SearchFld)
          }
        })
      })
      setSearchColumns(columnArray)
    }
    // setisDataGridCalled(true);
  }, [acTM.TMFormatData, acTM.AccountListData]); //acTM.AccountListData   // Deval


  // console.log("NUMBER 20230401", Number("20230401"))

  const simulateEnterKeyPress = () => {
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    if (rowElement) {
      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  };
  const ExportBtn = () => {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{obj?.text}</Text>,
        MAction: true,
        MSize: "xl",
        MBody: (i) => (
          <ReportViewer
            title={obj?.name}
            aDGrp={acTM.TMFormatData.oDCFrmt.aDGrp}
            aDcol={acTM.TMFormatData.oDCFrmt.aDCol}
            data={acTM.AccountListData}
          />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  };
  const handleSearch = (row, id, searchText) => {
    // console.log("handleSearch", searchColumns);

    if (!searchText) return true;

    // return rows.filter((row) => {
    // If row is a group, check the group key
    // if (row.isGrouped) {
    //   return row.id.toLowerCase().includes(searchText.toLowerCase());
    // }
    // Otherwise, check the individual row's data

    // console.log("handleSearch 2",
    //   row.original[id]?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
    // );

    return row.original[id] && row.original[id].toString().toLowerCase().includes(searchText.toLowerCase());
    // });
  };
  useEffect(() => {
    if (props.ValueEdit && typeof props.ValueEdit == "function" && rowSelection && !props.form) {

      if (Object.keys(rowSelection)?.length === 0) { // Nairin Salot <-
        props?.setPopOver(false);
      } else if (
        rowSelection[Object.keys(rowSelection)] == false
      ) {
        props.ValueEdit(props.IsMultiSelect ? [] : "");
        props?.setPopOver(false);
      } else {
        props.ValueEdit(
          props.IsMultiSelect
            ? tabled.getSelectedRowModel()?.flatRows
            : tabled.getSelectedRowModel()?.flatRows[0]?.original
        );
        props?.setPopOver(false);
      } // Nairin Salot -
    }
  }, [rowSelection])
  const tabled = useMantineReactTable({
    data: acTM.AccountListData ?? [],
    columns,
    //     displayColumnDefOptions: {
    // 'mrt-row-expand':{
    //   size:0
    // },
    //     },
    renderBottomToolbar: ({ table }) => (
      <Box > {/* deval (user Security) */}

        {(!form || props?.isButton || true) && isLoading[obj?.id] ? "" :
        !props?.viewOnly &&
          <ButtoPanel
            data={acTM.TMFormatData?.oS60?.DTHKey}
            FullData={acTM}
            Skey={tabled.getSelectedRowModel().flatRows[0]}
            from={obj?.id}
            btnPnlObj={obj}
            ref={childRef}
            setFormatBtn={(e) => {
              let OBJ = JSON.parse(e);
              // console.log(OBJ.p0, "e.p0", acTM?.TMFormatData?.DTS62);
              // let formatList = acTM?.TMFormatData?.DTS62;
              let len = acTM?.TMFormatData?.DTS62?.length;

              switch (OBJ.p0) {
                case "L":
                  Store.dispatch(
                    ModalFunction({
                      onclose: (e) => { },
                      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                      MAction: true,
                      MSize: "xl",
                      MBody: (i) => <PLFormat data={OBJ} index={i} />,
                      MClose: true,
                      Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                      },
                    })
                  );
                  break;
                case "P":
                  // console.log(acTM?.TMFormatData?.DTS62 , "ssss")

                  let val = acTM?.TMFormatData?.DTS62?.findIndex((obj) =>
                    Object.values(obj).includes(cfmtid)
                  );
                  if (len > 1) {
                    if (val == 0) {
                      // console.log("prev-", val)

                      // console.log("prev-")
                      setcfmtid(
                        acTM?.TMFormatData?.DTS62[len - 1]["S62F02"]
                      );
                      return;
                    } else if (val == len - 1) {
                      // console.log("prev1-")
                      setcfmtid(acTM?.TMFormatData?.DTS62[len - 2]["S62F02"]);
                      return;
                    } else {
                      setcfmtid(acTM?.TMFormatData?.DTS62[val - 1]["S62F02"]);
                      return;
                    }
                  }
                  break;
                case "N":
                  let val2 = acTM?.TMFormatData?.DTS62?.findIndex((obj) =>
                    Object.values(obj).includes(cfmtid)
                  );
                  if (val2 == len - 1) {
                    setcfmtid(acTM?.TMFormatData?.DTS62[0]["S62F02"]);
                    return;
                  } else if (val2 == 0) {
                    setcfmtid(acTM?.TMFormatData?.DTS62[1]["S62F02"]);
                    return;
                  } else if (val2 != -1) {
                    setcfmtid(
                      acTM?.TMFormatData?.DTS62[val2 + 1]["S62F02"]
                    );
                    return;
                  }

                  break;
                default:
                  break;
              }
            }}
          />
        }

        <Group
          spacing={"xs"}
          position="center"
          display={"flex"}
        ><MRT_TablePagination position="bottom" table={table} /></Group>
        
        {props.isButton == false ? "" : form &&
          <Group spacing={"xs"} position="center" display={"flex"}>
            <Button
              radius="md" size="md"
              onClick={() => {

                if (Object.keys(rowSelection)?.length === 0) { // Nairin Salot <-
                  props.ValueEdit("") // Deval (onChange event call)
                  props?.setPopOver(false);
                } else if (
                  rowSelection[Object.keys(rowSelection)] == false
                ) {
                  props.ValueEdit(props.IsMultiSelect ? [] : "");
                  props?.setPopOver(false);
                } else {
                  props.ValueEdit(
                    props.IsMultiSelect
                      ? tabled.getSelectedRowModel().flatRows
                      : tabled.getSelectedRowModel().flatRows[0].original
                  );
                  props?.setPopOver(false);
                } // Nairin Salot ->

                // props.form = false
              }}
              variant="filled"
            >
              {props.btnName ?? "Ok"}
            </Button>
          </Group>
        }

      </Box>
    ),
    paginationDisplayMode: "default",
    mantinePaginationProps: {
      showRowsPerPage: form ? false : true,
      withEdges: true,
      rowsPerPageOptions: ['25', '100', '500', '1000'],
    },
    // enableFilters:false,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableColumnResizing: false,
    columnResizeMode: "onChange",
    enableGrouping: true,
    // enableGroupBy: false,
    enableEditing: acTM?.TMFormatData?.oS60?.cRecType == "P" ?? false,
    editDisplayMode: "row",
    enableDensityToggle: false,
    enablePagination: true,

    enableRowVirtualization: false,
   
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: "flex",
    //       gap: "16px",
    //       padding: "8px",
    //       flexWrap: "wrap",
    //     }}
    //   >
    //     <Button
    //       disabled={table.getPrePaginationRowModel().rows.length === 0}
    //       //export all rows, including from the next page, (still respects filtering and sorting)
    //       onClick={() =>
    //         handleExportRows(table.getPrePaginationRowModel().flatRows)
    //       }
    //       leftIcon={<IconFileDownload />}
    //       variant="filled"
    //     >
    //       Export All Rows
    //     </Button>
    //     <Button
    //       disabled={table.getRowModel().rows.length === 0}
    //       //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
    //       onClick={() => handleExportRows(table.getRowModel().rows)}
    //       leftIcon={<IconFileDownload />}
    //       variant="filled"
    //     >
    //       Export Page Rows
    //     </Button>
    //     <Button
    //       disabled={
    //         !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
    //       }
    //       //only export selected rows
    //       onClick={() => handleExportRows(table.getSelectedRowModel().flatRows)}
    //       leftIcon={<IconFileDownload />}
    //       variant="filled"
    //     >
    //       Export Selected Rows
    //     </Button>
    //   </Box>
    // ),
    initialState: {
      grouping,
      density: ReportIdArray.includes(obj.id) ? "2px" : "4px",
      pagination,
      expanded: ReportIdArray.includes(obj.id),
    },
    state: {
      grouping,
      rowSelection,
      density: ReportIdArray.includes(obj.id) ? "2px" : "4px",
      globalFilter: search,
      pagination,
      sorting: sorting,
      showAlertBanner: hasError,
      showProgressBars: isRefetching[obj?.id],
      showLoadingOverlay: isLoading[obj?.id],
    },
    // manualFiltering:true,
    // filterFns:{mysearch:handleSearch},
    // globalFilterFn:'contains',

    onSortingChange: setSorting,
    positionToolbarAlertBanner: 'none',
    renderTopToolbarCustomActions: () => (
      <>
        {!form && acTM.TMFormatData && acTM.TMFormatData.DTS62 && (
          <>
            {isFormatDropdown && <Grid.Col m={0} p={5} mb={media ? 0 : 3} span={media ? 4 : 10}>
              {/* <Card shadow="sm" padding={5} radius="md" withBorder display={'block'}> */}
              <PLComboBox
                value={
                  cfmtid
                    ? cfmtid
                    : ReportIdArray.includes(obj.id)
                      ? obj.p2
                      : acTM.TMFormatData.DTS62[0]["S62F02"]
                }
                dispexpr="S62F04"
                valexpr="S62F02"
                data={acTM.TMFormatData.DTS62}
                setEdit={(e) => {
                  setcfmtid(e);
                }}
              />
            </Grid.Col>}
            {isRefresh &&<Grid.Col m={0} p={5} mb={media ? 0 : 3} span={2}>
              <Tooltip label="Refresh Data">
                <Button
                  size='xs'
                  variant="subtle"
                  // compact
                  onClick={() => {
                    // searchButton();
                     // dispatch(GetAccountList({ ...obj }))
                    searchButton()
                    dispatch((getFilterStatus(false)))
                  }
                  }
                ><IconRefresh /></Button>
              </Tooltip>
            </Grid.Col>}
            <Group>
         {/* <Button onClick={()=>{
            const doc = new jsPDF();
            const groupedRows = tabled.getGroupedRowModel().rows; // Get grouped rows
            const groupColumns = grouping
          
            const data = [];
          
            const processRows = (rows, groupDepth = 0) => {
              rows.forEach((row) => {
                if (row.subRows?.length) {
                  // Add a group row
                  const groupValue = row.getValue(grouping[groupDepth]);
                  data.push([{ content: `Group: ${groupValue}`, colSpan: columns.length, styles: { fontStyle: 'bold' } }]);
          
                  // Recursively process sub-rows
                  processRows(row.subRows, groupDepth + 1);
                } else {
                  // Add regular row, excluding grouped columns
                  const rowData = row.getVisibleCells()
                    .filter((cell) => !grouping.includes(cell.column.id)) // Exclude grouped columns
                    .map((cell) => cell.getValue());
                  data.push(rowData);
                }
              });
            };
          
            processRows(groupedRows);
          
            autoTable(doc, {
              head:  [columns.map((col) => col.header)], // Exclude grouped columns
              body: data,
              margin: { top: 20 },
              styles: { fontSize: 8 },
            });
          
            doc.save("table-export.pdf");          
                    
        }} leftIcon={<IconFileDownload />}>
          Export to PDF
        </Button>
        <Button onClick={()=>{
  const groupedRows = tabled.getGroupedRowModel().rows; // Get grouped rows
  const data = [];
  
  const processRows = (rows, groupDepth = 0) => {
    rows.forEach((row) => {
      
      if (row.subRows?.length) {
        // Add a group row
        const groupValue = row.getValue(grouping[groupDepth]);
        const groupRow = { [`${acTM.TMFormatData.oDCFrmt?.aDGrp.find((col) => col.DispExpr === grouping[groupDepth])?.ColCap} Group`]: `${groupValue}` };
        data.push(groupRow);

        // Recursively process sub-rows with increased group depth
        processRows(row.subRows, groupDepth + 1);
      } else {
        // Add regular row, excluding grouped columns
        const rowData = {};
        row.getVisibleCells()
          .filter((cell) => !grouping.includes(cell.column.id) && !cell.id.includes("mrt")) // Exclude grouped columns from regular rows
          .forEach((cell) => {
            rowData[columns.find((col) => col.id === cell.column.id)?.header] = cell.getValue();
          });
        data.push(rowData);
      }
    });
  };

 

  processRows(groupedRows);
  console.log("data",acTM.TMFormatData.oDCFrmt?.aDGrp,grouping);
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "table-export.xlsx");

        }} leftIcon={<IconFileDownload />}>
          Export to Excel
        </Button>  */}
      </Group>
            {/* <PLComboBox
                        value={
                          cfmtid
                            ? cfmtid
                            : ReportIdArray.includes(obj.id)
                              ? obj.p2
                              : acTM.TMFormatData.DTS62[0]["S62F02"]
                        }
                        dispexpr="S62F04"
                        valexpr="S62F02"
                        style={{flex:1}}
                        data={acTM.TMFormatData.DTS62}
                        setEdit={(e) => {
                          setcfmtid(e);
                        }}
                      /> */}

            {/* </Card> */}


            <Grid.Col m={0} p={0} mb={media ? 0 : 3} span={media ? 6 : 12} pl={5}>
              {
                isFilterApplied ? <Text style={{ fontSize: '14px' }}>Filter is on</Text> : ""
              }

              {<Paper p={4} style={{ display: 'flex', float: 'right' }}>
                <Grid gutter={1}>
                  <Grid.Col m={0} p={0} span={TransactionReportIdArray.includes(obj.id) ? 5 : 9} pl={5}>
                    <PLTextBox
                      ref={InputRef}
                      placeholder="Search here..."
                      value={search}
                      // onkeyPress={(e) => {
                      //   console.log("search event",e);
                      // }}{ 
                      onKeyDown={(e) => {
                        if (e.keyCode == 13) {
                          searchButton()
                        }
                      }}
                      rightSection={<IconSearch
                        style={{
                          color: "lightgray",
                        }}
                      />
                      }
                      setEdit={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </Grid.Col>
                  {TransactionReportIdArray.includes(obj.id) &&  tmList[obj?.id]?.oDTR?.DTType!="S" && (
                    <>
                      <Grid.Col
                        m={0} p={0} span={6} pl={5}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{ marginRight: "8px", marginLeft: "10px" }}
                        >
                          From
                        </Text>
                        <DateInput
                          // style={{ zIndex: 99 }}
                          value={flagDate1?.[obj?.id] == true ? currentDate?.[obj.id]?.From : date?.From} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                          valueFormat="DD/MM/YYYY"
                          size="xs"
                          minDate={dateFormater(GetGeneral?.dYSDate, 0)}
                          maxDate={maximumDate}
                          onChange={(e) => {
                            // console.log("eeeeee", e);
                            setDate({ ...date, From: e });
                            Store?.dispatch(getCurrentDate({ "date": { ...date, From: e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                            setIsDateChanged(true); // Pooja Vara
                            // setisDataGridCalled(false)
                          }}
                        />

                        <Text
                          style={{ marginRight: "8px", marginLeft: "10px" }}
                        >
                          To
                        </Text>
                        <DateInput
                          value={flagDate1?.[obj?.id] == true ? currentDate?.[obj?.id]?.To : date?.To} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                          valueFormat="DD/MM/YYYY"
                          size="xs"
                          minDate={minimunDate}
                          maxDate={maximumDate}
                          onChange={(e) => {
                            setDate({ ...date, To: e });
                            Store?.dispatch(getCurrentDate({ "date": { ...date, To: e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                            setIsDateChanged(true); //Pooja Vara
                            // setisDataGridCalled(false)
                          }}
                        />
                      </Grid.Col>
                    </>
                  )}
                  {
                   TransactionReportIdArray.includes(obj.id) && tmList[obj?.id]?.oDTR?.DTType=="S" && (
                    <>
                      <Grid.Col
                        m={0} p={0} span={6} pl={5}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{ marginRight: "0px", marginLeft: "3px",fontSize:"12px" }}
                        >
                         Report Date:
                        </Text>
                        <PLDateBox
                          // style={{ zIndex: 99 }}
                          value={flagDate1?.[obj?.id] == true ? currentDate?.[obj?.id]?.To : new Date(date2?.From)} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                          valueFormat="DD/MM/YYYY"
                          size="xs"
                          minDate={dateFormater(GetGeneral?.dYSDate, 0)}
                          maxDate={maximumDate}
                          setEdit={(e) => {
                            setDate2({ ...date2, From: e,To:e });
                            Store?.dispatch(getCurrentDate({ "date": { ...date2, From: e,To:e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                            setIsDateChanged(true); // Pooja Vara
                            // setisDataGridCalled(false)
                          }}
                        />
                      </Grid.Col>
                    </>

                   )
                   
                   
                  }
                  <Grid.Col m={0} p={0} span={1} pl={5} style={{ display: "flex" }}>
                    <Button size="xs" onClick={() => searchButton()}>
                      Go
                    </Button>
                  </Grid.Col>
                </Grid>
              </Paper>}

            </Grid.Col>
            {/* // Vara Pooja */}

            {/* <Grid.Col span={1} style={{ display: "flex" }}>
                      <Button size="xs" onClick={() => ExportBtn()}>
                        Export
                      </Button>
                    </Grid.Col> */}
            {/* <PLDateBox defaultval={date?.To} zIndex={2}
                    dispformat="DD/MM/YYYY"
                    minDate={minimunDate}
                    maxDate={maximumDate}
                    setEdit={(e) => {
                      let year = e.toLocaleString('en-IN', { year: 'numeric' })
                      let month = e.toLocaleString('en-IN', { month: '2-digit' })
                      let day = e.toLocaleString('en-IN', { day: '2-digit' })
                      let startDate = year + month + day;
                      setDate({ ...date, To: startDate });
                      setisDataGridCalled(false)
                    }}
                  /> */}


          </>
        )}
        {form ? (
          <Grid.Col span={12} style={{ display: 'flex' }}>
            <Grid.Col p={0} span={10} >
              <PLTextBox
                placeholder="Search here..."
                value={search}
                setEdit={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Grid.Col>
            <Grid.Col p={0} span={2} pl={5}>
              <Button size="xs" onClick={PLSearchButton}>Go</Button>
            </Grid.Col>
          </Grid.Col>

        ) : null}
      </>
    ),
    // manualPagination,
    // manualFiltering: manualPagination,
    // autoResetPageIndex: false,
    // rowCount: ReportIdArray.includes(obj.id)
    //   ? null
    //   : props.data
    //     ? props?.data?.length
    //     : rowCount[obj.id],//rathod kishan
    enableTopToolbar: props?.viewOnly ? false : true, // deval (for user Security)
    enableBottomToolbar: true,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    onGroupingChange: setGrouping,
    enableGlobalFilter: true,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilterModes: false,
    enableTableFooter: true,
    enableStickyFooter: true,
    enableColumnFilterModes: false,
    renderGlobalFilterModeMenuItems: () => {

    },
    mantineTableBodyProps: {
      ref: TableRef,
    },
    mantineTableProps:{withColumnBorders:true}, //Table Border
    // enableColumnResizing: true,
    defaultColumn: { // Deval
      minSize: 0, //allow columns to get smaller than default
      maxSize: 15, //allow columns to get larger than default
      size: 1, //make columns wider by default
    },
    mantineTableContainerProps: {
      ref: disRef,
      id: "Datagrid" + obj.id,
      sx: { height: height * (props.size??(form ? (0.2) : 0.6)) },
    },
    mantineTableHeadCellProps: {
      style: { whiteSpace: 'initial', textAlign: 'center' }, // Keeps group headers inline
    },
    groupedColumnMode: false,
    enableSelectAll: false,
    enableMultiRowSelection: props?.IsMultiSelect,
    layoutMode: "semantic",
    onPaginationChange: setPagination,
    // onRowSelectionChange: setRowSelection,
    getRowId: (row) => row[props.selectionId], //Nairin Salot

    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      tabIndex: 0,
      onFocus: () => {
        console.log("row.getIsGrouped()",row.getIsGrouped());
        if(!row.getIsGrouped()){
          if (props?.IsMultiSelect) {
            // console.log(row?.id , "setRowSelection")
            // setRowSelection((prev) => ({
            //   ...prev,
            //   [row?.id]: !prev[row?.id],
            // }));
          } else {
            // console.log("setRowSelection in else")
            setRowSelection((prev) => ({
              [row?.id]: !prev[row?.id],
            }));

          }
        }
      },
      onKeyDown: (e) => {
        if (e.key == "13" && form) {

        }
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        if (props?.IsMultiSelect) {
          // console.log(row?.id , "setRowSelection")
          setRowSelection((prev) => ({
            ...prev,
            [row?.id]: !prev[row?.id],
          }));
        }
        else {
          //   // console.log("setRowSelection in else")
          //   setRowSelection((prev) => ({
          //     [row?.id]: !prev[row?.id],
          //   }));

          // if(form){
          //   props?.ValueEdit(
          //     row?.original
          //   );
          //   // props.form = false
          //   props?.setPopOver(false);
          // }
        }
      },
      onDoubleClick: (e) => {
        // if (acTM.TMFormatData.oS60.cRecType !== 'P') {

        Promise.resolve(
          setRowSelection((prev) => ({
            [row?.id]: true,
          }))
        ).then(() => {
          if (!form) {
            // console.log("doubke");
            // simulateEnterKeyPress();
            handlekeyPress({ key: "Enter" });
          } else {
            props?.ValueEdit(
              props.IsMultiSelect
                ? tabled.getSelectedRowModel().flatRows
                : tabled.getSelectedRowModel().flatRows[0].original
            );
            // props.form = false
            props?.setPopOver(false);
          }
        });
        // }
        // else {
        //   console.log("dbl CLick ", acTM.TMFormatData.oS60.cRecType);
        // }
      },
      onContextMenu: handleRightClick,
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    //   mantineTableBodyCellProps: ({ cell,row }) => ({
    //     tabIndex: 0, // Make the cell focusable
    //     onKeyDown: (e) => handleKeyDown(e, cell,row), // Add keydown event handler
    // }),
    // mantineTableProps: {
    //   sx: {
    //     tableLayout: 'fixed',
    //   },
    // },
  });
  const handleRowKeyDown = (e, row) => {
    const table = TableRef.current;
    const rows = table.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    // console.log("TABKEY", e.key);
    switch (e.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

        } else if (
          tabled.getPageCount() > 1 &&
          pagination.pageIndex < tabled.getPageCount() - 1
        ) {
          // Navigate to the next page
          tabled.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          tabled.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        if (form) {
          e.preventDefault();
          Promise.resolve(
            setRowSelection((prev) => ({
              [row?.id]: true,
            }))
          ).then(() => {
            // if (!form) {
            //   simulateEnterKeyPress();
            // } else {
            props?.ValueEdit(
              props.IsMultiSelect
                ? tabled.getSelectedRowModel().flatRows
                : tabled.getSelectedRowModel().flatRows[0].original
            );
            // props.form = false
            props?.setPopOver(false);
            // }
          });
        }
        break;
      default:
        break;
    }
  };
  const handlekeyPress = (e) => {
    // console.log("keycode", keycode);
    var key = e.key
    var keycode = e.keyCode
    acTM?.TMFormatData?.oS60?.DTHKey?.map((btn, i) => {

      if ((btn.TOOLTIP == key) || (btn.KEYCODE == keycode)) {
        if (e?.preventDefault) {
          e.preventDefault();
        }
        childRef?.current?.handleButtonClick(btn);
      }
    })
  }
  const [contextMenu, setContextMenu] = useState(null);

  const handleRightClick = (event) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      options: acTM?.TMFormatData?.oS60?.DTHKey?.map((btn, i) => {
        return { label: btn.CAPTION, onClick: () => childRef.current.handleButtonClick(btn) }
      }),
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const focusSelected = async (table) => {

    const rows = await table?.querySelectorAll('tr');
    const body = table?.querySelectorAll('tbody');
    // Set initial focus on the first row of the current page

    if (rows?.length > 0 && props?.selectedData) {
      // console.log("props?.selectedData",props?.selectedData);

      var inn = Object.keys(props?.selectedData)[0];
      const inn2 = (tabled.getSortedRowModel().rows ?? []).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
      // const paginatedrows = (tabled.getPaginationRowModel().rows??[]).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
      // console.log("pageee",);


      if (inn) {
        const selectedRowPage = Math.floor(inn2 / pagination.pageSize);
        // let f = rows.activeElement
        if (pagination.pageIndex !== selectedRowPage && selectedRowPage >= 0) {
          // Navigate to the correct page if the selected row is not on the current page
          // setPageIndex(selectedRowPage);
          setPagination({ ...pagination, pageIndex: selectedRowPage });

        } else if (rows[inn2 % pagination.pageSize]) {
          // console.log("pageeee 2",inn2);
          // Scroll to the row when it's on the correct page
          setTimeout(() => {
            rows[inn2 % pagination.pageSize].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setfocusset(true);
          }, 500);
        }
        // rows[inn].scrollIntoView({behavior:'smooth',"block":'center'})
      }
      else if (form && !inn) {
        table.focus();
        setTimeout(() => {
          rows[0].focus();
          setfocusset(true);
        }, 300);
      }
      // 
    }
    else if (!form && rows?.length > 0) {
      table.focus();
      setTimeout(() => {
        rows[0].focus();
        setfocusset(true);
      }, 300);
    }
  }
  useEffect(() => {
    const table = TableRef.current;
    if (!focusset) {
      focusSelected(table)
    }


    const handleTableKeyDown = (e) => {
      // Handle global key events if needed
      handlekeyPress(e);
    };

    table?.addEventListener('keydown', handleTableKeyDown);

    return () => {
      table?.removeEventListener('keydown', handleTableKeyDown);
    };
  }, [pagination, props?.selectedData, TableRef]); // Re-run the effect when pagination changes
  // console.log("rowSelection=>", rowSelection);

  useEffect(() => {
    //Nairin Salot
    // console.log(props?.selectedData , "props?.selectedData")

    if (props?.selectedData && typeof props?.selectedData == "object") {
      // console.log(props?.selectedData, "props?.selectedData");

      let selectedItem = props?.selectedData;
      setRowSelection(selectedItem);
    }
    // else{
    //   const table = TableRef.current;
    //   const rows = table.querySelectorAll('tr');
    //   if (rows.length > 0) {
    //     rows[0].focus();
    //   }
    // }
  }, [props?.selectedData]);

  useEffect(()=>{
      let arr =[];
      arr.push({
        id: sortingArrangeField?.[props?.obj?.id], desc: sortingOrderField?.[props?.obj?.id]=="D"? true :false
      })
      setSorting(arr);
   },[sortingOrderField,sortingArrangeField]) //Yasvi Patel


   useEffect(()=>{
      if(tmList[obj?.id]?.oDTR?.DTType=="S"){
        // console.log("currentDate[obj?.id]==null ",currentDate[obj?.id]==null )
        // console.log("currentDate[obj?.id]==",currentDate)
          setDate2({From:!flagDate1[obj?.id] ? 
            new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime() ? (date1.getFullYear()+ "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
            : GetGeneral?.dYEDate : 
            currentDate[obj?.id],To:!flagDate1[obj?.id] ? 
            new Date(GetGeneral?.dYSDate).getTime()<=date1.getTime() && new Date(GetGeneral?.dYEDate).getTime()>=date1.getTime() ? (date1.getFullYear()+ "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
            : GetGeneral?.dYEDate : 
            currentDate[obj?.id]})
      }
   },[tmList[obj?.id]?.oDTR?.DTType])

  // console.log( "isnan",Number(acTM?.AccountListData?.FIELD02) )


  //   console.log("after AccountListData", acTM?.AccountListData)

  // let EditSelectRow = tabled.getSelectedRowModel()?.flatRows
  // console.log('EditSelectRow===>',EditSelectRow);

  // console.log("TransactionReportIdArray", TransactionReportIdArray1);



  return (
    <>
      <div ref={ref}></div>

      <Grid>

        <Grid.Col
          span={12}
          id={obj.id + "Grid"}
          m={0}
          p={0}
          pt={5}
          tabIndex={-1}
        >
          <MantineProvider theme={{ ...globalTheme, primaryShade: 9, }}>
            <MantineReactTable table={tabled} />
          </MantineProvider>
        </Grid.Col>

        {/*acTM.TMFormatData?.oS60?.DTHKey && !form && (isLoading[obj?.id] ? "" : <Grid.Col>
          <MediaQuery smallerThan={"md"} styles={{ display: 'none' }}>
            <Group
              spacing={"sm"}
              position="center"
              display={"flex"}
              style={{ marginTop: 5 }}
            >
              <Text variant="text" size={'sm'} weight={"bold"}>{"SHORTCUTS: "}</Text>
              {acTM.TMFormatData?.oS60?.DTHKey?.map((btn, i) => {
                if (btn.TOOLTIP) {
                  var t = btn.TOOLTIP.split("+");
                  return (
                    <Text key={i} variant="text" size={'sm'} weight={"bold"} align="center">
                      {t.map((e, i) => <><Kbd>{e}</Kbd>{t[i + 1] ? "+" : ""}</>)}{""}<br /><small style={{ color: "GrayText" }}>{btn.CAPTION}</small>
                    </Text>
                  );
                }
              })}
            </Group></MediaQuery>
        </Grid.Col>)*/}

      </Grid>

      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          options={contextMenu.options}
          onClose={handleCloseContextMenu}
        />
      )}
    </>
  );
}
DataGrid.defaultProps = {
  isFormatDropdown:true,
  isRefresh:true,
}
export default DataGrid;
