import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GlobalClass from '../../utils/GlobalClass';
import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { PostUpdateBalance } from '../../utils/slices/UserListSlice';
import Store from '../../utils/store';

const UpdateBalanceForm = (props) => {

    const dispatch = useDispatch();

    const GetGeneral = useSelector((state) => state?.Language?.General);

    const { addUpdateBalance, PostFinYearhasError, PostFinYearErrorMsg, PostFinYearisLoading } = useSelector((state) => state?.UserList)

    const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);

    useEffect(() => {
        //    dispatch(PostUpdateBalance())
        if (GetYearDateData) {
            console.log("GetYearDateData =>", GetYearDateData);
            new Promise((resolve) => {
                let GetYrNo = localStorage.getItem("YrNo");
                let objFind = {};
                GetYearDateData?.find((d) => {
                    if (parseInt(d?.YearNo) == GetYrNo) {
                        objFind = { ...d }
                    }
                })
                resolve(objFind)
            }).then((res) => {
                console.log("GetYearDateData objFind", res);
                dispatch(PostUpdateBalance({
                    From: res?.strtDate,
                    To: res?.endDate,
                    ModalIndex:props?.index,
                    PopUp: true
                }))
            })
        }
    }, [GetYearDateData])

    useEffect(() => {
        // PostFinYearisLoading: false,
        // PostFinYearhasError: false,
        // PostFinYearErrorMsg:""

        if (PostFinYearhasError && PostFinYearErrorMsg) {
            console.log('addFinReshasErrorErrorMsgisLoading =>', [{
                "addUpdateBalance": addUpdateBalance,
                "PostFinYearhasError": PostFinYearhasError,
                "PostFinYearErrorMsg": PostFinYearErrorMsg,
                "PostFinYearisLoading": PostFinYearisLoading
            }]);
            if (addUpdateBalance) {
                GlobalClass.Notify("error", "Error: " + addUpdateBalance, PostFinYearErrorMsg);
            }
        }
    }, [addUpdateBalance, PostFinYearhasError, PostFinYearErrorMsg])
    return (<>
        {
            PostFinYearisLoading ? <>
                {
                    GlobalClass.RenderLoader('dots')
                }
            </> : PostFinYearhasError ? <>
                {
                    <Alert variant="light" color="red" title="Error" icon={<IconInfoCircle />}>
                        {PostFinYearErrorMsg}
                    </Alert>
                }
            </> : null
        }
    </>)
}

export default UpdateBalanceForm