import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';
import Store from '../store';

export const GetAutoNumTMFormat = createAsyncThunk(
  "CompanySetupPopUp/GetAutoNumTMFormat",
  async (data) => {
    console.log('tmFormat data=>>', data)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: data.F02F01 === "GEU00004" ? "P_T95" :
          data.F02F01 === "WMARK001" ? "P_WMK" :
            '',
        cFmtID: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param
      );
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
)

export const GetAutoNumGenData = createAsyncThunk(
  "CompanySetupPopUp/GetAutoNumGenData",
  async (data) => {
    console.log('Gendata data=>>', data)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: data.F02F01 === "GEU00004" ? "P_T95" :
          data.F02F01 === "WMARK001" ? "P_WMK" :
            '',
        cFmtID: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param
      );
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
)

// Company Setup > General Setup > Alert MSG
export const GetF02MsgType = createAsyncThunk(
  "CompanySetupPopUp/GetF02MsgType",
  async (obj) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetF02MsgType + "?pa=" + param
      );
      // console.log('response=>>',response)
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// company setup
export const GetCmpSetUpPopUp = createAsyncThunk(
  "CompanySetupPopUp/GetCmpSetUpPopUp",
  async (OBJ) => {
    console.log('cmp Popup OBJ Slice=>>', OBJ)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: OBJ.F02F01 == "SWT0A013" ? "P_TICKERDT" : //setup/company setup/general setup/ticker/ticker Detail
          OBJ.F02F01 == "GE000239" ? "PD_PLUSM01" : //setup/company setup/master setup/accounts/account popup detail
            OBJ.F02F01 == "GE000240" ? "PD_PLUSM21" : //setup/company setup/master setup/Products/product popup detail
              OBJ.F02F01 == "RG000011" ? "P_APPOPTYPE" : //setup/company setup/master setup/products/product popup type
                OBJ.F02F01 == "RG000012" ? "P_APPOPTYPE1" : //setup/company setup/master setup/Accounts/acoount popup type
                  OBJ.F02F01 == "GE000342" ? "P_BRC" : //setup/company setup/advance setup/branch detail/PLUSM422
                    OBJ.F02F01 == "MKCK0010" ? "P_M81" : //setup/company setup/general setup/audit option/AUDITUSR
                      OBJ.F02F01 == "LANZOOM3" ? "P_M81" : // setup/company setup/general setup/lan zooming/LANZOOM3
                        OBJ.F02F01 == "GEPCSC12" ? "P_M03" : // setup/company setup/user option/international setting/base currency
                          OBJ.F02F01 == "GST00004" ? "P_G02" : // setup/company setup/GST/GST Details/E-commerce operator
                            OBJ.F02F01 == "GETFC004" ? "P_PLTCSDT" : //setup/company setup/TDS/TCS setup/default nature of goods in products
                              OBJ.F02F01 == "RTDS0162" ? "P_M45E" : // setup/company setup/TDS/TCS setup/PLUSM516/PLUSM525
                                OBJ.F02F01 == "RGLAN001" ? "P_PLSYSLANG" : //setup/company setup/report setup/products/secondary language
                                  OBJ.F02F01 == "POS00004" ? "P_M45I" : //setup/company setup/point of sale/entry option/default invoice type
                                    OBJ.F02F01 == "POS00005" ? "P_T92" : //setup/company setup/point of sale/entry option/bill series
                                      OBJ.F02F01 == "POS00012" ? "P_M07" : // setup/company setup/point of sale/POS hardware/POS machines
                                        OBJ.F02F01 == "POS00030" ? "P_APPOPTYPE" : // setup/company setup/point of sale/POS hardware/PLUSM554
                                          "",
        cOPara: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetF02PIDData + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/user option/entry option/required qty detail
export const GetF02ReqQtyDetails = createAsyncThunk(
  "CompanySetupPopUp/GetF02ReqQtyDetails",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02ReqQtyDetails + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup>general setup>ASMS>Alert sms for voucher
export const GetAlertMsgVouchers = createAsyncThunk(
  "CompanySetupPopUp/GetAlertMsgVouchers",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02AlertMsgVouchers + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/advance setup/serial no. option/common sufix-suffix
export const GetPrefixSuffix = createAsyncThunk(
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetT92PrefixSuffix + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/GST/E-Invoice required/EINVTYP
export const GetEInvType = createAsyncThunk(
  "CompanySetupPopUp/GetEInvType",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02AutoUpld_EInvBillType + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/point of sale/CUSTOM/Customer Master Detail
export const GetCustMasterDetail = createAsyncThunk(
  "CompanySetupPopUp/GetCustMasterDetail",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02CustMastDetail + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
)

// setup/company setup/barcode setup/barcode setup/barcode type
export const GetBarcodeType = createAsyncThunk(
  "CompanySetupPopUp/GetBarcodeType",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02BarcodeType + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/point of sale/entry option/bill type
export const GetBillType = createAsyncThunk(
  "CompanySetupPopUp/GetBillType",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02BillType + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/user option/user options/user field auto number
export const GetUserFieldAutoNum = createAsyncThunk(
  "CompanySetupPopUp/GetUserFieldAutoNum",
  async (data) => {
    console.log("slice data=>>", data)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: data?.action ? data?.action : "A",
        cCode: data?.code ? data?.code : "",
        cOPara: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetT95UsrFildAutoNo + "?pa=" + param
      );
      console.log('response=====>', response)
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

export const DeleteUserFieldAutoNum = createAsyncThunk(
  "CompanySetupPopUp/DeleteUserFieldAutoNum",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param =
        JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: obj.action ? obj.action : 'A',
          cCode: obj.code ? obj.code : '',
          cOPara: '',
        });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetT95UsrFildAutoNo + "?pa=" + param)
      if (response.data.status === "SUCCESS") {
        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
        Promise.resolve(
          GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
          .then(() => Store.dispatch(GetAutoNumGenData({ F02F01: "GEU00004" })))
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
)

// setup/company setup/barcode setup/barcode setup/multi barcode setup
export const GetMultiBarCode = createAsyncThunk(
  "CompanySetupPopUp/GetMultiBarCode",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02MultiBarcodeSetup + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/company setup/user options/entry options/broker required
export const GetBrokerRequired = createAsyncThunk(
  "CompanySetupPopUp/GetBrokerRequired",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02BrokerReq + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

// setup/report setup/water mark/water mark list
export const GetWaterMarkList = createAsyncThunk(
  "CompanySetupPopUp/GetWaterMarkList",
  async (data) => {
    console.log("GetWaterMarkList data===>", data)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: data?.action ? data?.action : "A",
        cCode: data?.code ? data?.code : "",
        cOPara: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetWMKEnt + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

export const DeleteWaterMarkList = createAsyncThunk(
  "CompanySetupPopUp/DeleteWaterMarkList",
  async (obj) => {
    console.log('DeleteWaterMarkList obj', obj)
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param =
        JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: obj.action ? obj.action : 'A',
          cCode: obj.code ? obj.code : '',
          cOPara: '',
        });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetWMKEnt + "?pa=" + param)
      if (response.data.status === "SUCCESS") {
        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
        Promise.resolve(
          GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
          .then(() => Store.dispatch(GetAutoNumGenData({ F02F01: "WMARK001" })))
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      console.log("response=>>", response)
      return response.data
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
)

// setup/company setup/TDS_TCS setup/TDS_TCS detail/TDS_TCS detail
export const GetTDS_TCSDetail = createAsyncThunk(
  "CompanySetupPopUp/GetTDS_TCSDetail",
  async () => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: ""
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.F02TDS_TCSDetails + "?pa=" + param
      );
      return response.data.data
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error };
    }
  }
);

const CompanySetUpPopUpSlice = createSlice({
  name: "CompanySetupPopUp",
  initialState: {
    cmpSetUpTMData: [],
    cmpSetUpGenData: [],
    CmpSetUpPopUpData: [],
    AlertMsgData: [],
    ReqQtyDetailsData: [],
    AlertMsgVouData: [],
    PrefixSuffixData: [],
    EInvBillTypeData: [],
    BarcodeTypeData: [],
    BillTypeData: [],
    UserFieldAutoNumData: [],
    MultiBarCodeSetUpData: [],
    BrokerRequiredData: [],
    WaterMarkListData: [],
    TDS_TCSDetailData: [],
    CustomerMasterData:[],
    isLoading: false,
    hasError: false,
    successMsg: "",
    ErrorMsg: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAutoNumTMFormat.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetAutoNumTMFormat.fulfilled, (state, action) => {
        state.cmpSetUpTMData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAutoNumTMFormat.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetAutoNumGenData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetAutoNumGenData.fulfilled, (state, action) => {
        state.cmpSetUpGenData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAutoNumGenData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetF02MsgType.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetF02MsgType.fulfilled, (state, action) => {
        state.AlertMsgData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetF02MsgType.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetCmpSetUpPopUp.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetCmpSetUpPopUp.fulfilled, (state, action) => {
        state.CmpSetUpPopUpData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetCmpSetUpPopUp.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetF02ReqQtyDetails.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetF02ReqQtyDetails.fulfilled, (state, action) => {
        state.ReqQtyDetailsData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetF02ReqQtyDetails.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetAlertMsgVouchers.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetAlertMsgVouchers.fulfilled, (state, action) => {
        state.AlertMsgVouData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAlertMsgVouchers.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetCustMasterDetail.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetCustMasterDetail.fulfilled, (state, action) => {
        state.CustomerMasterData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetCustMasterDetail.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetEInvType.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetEInvType.fulfilled, (state, action) => {
        state.EInvBillTypeData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetEInvType.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetBarcodeType.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetBarcodeType.fulfilled, (state, action) => {
        state.BarcodeTypeData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetBarcodeType.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetBillType.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetBillType.fulfilled, (state, action) => {
        state.BillTypeData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetBillType.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetUserFieldAutoNum.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetUserFieldAutoNum.fulfilled, (state, action) => {
        state.UserFieldAutoNumData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetUserFieldAutoNum.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetMultiBarCode.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetMultiBarCode.fulfilled, (state, action) => {
        state.MultiBarCodeSetUpData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetMultiBarCode.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetBrokerRequired.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetBrokerRequired.fulfilled, (state, action) => {
        state.BrokerRequiredData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetBrokerRequired.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetWaterMarkList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetWaterMarkList.fulfilled, (state, action) => {
        state.WaterMarkListData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetWaterMarkList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetTDS_TCSDetail.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetTDS_TCSDetail.fulfilled, (state, action) => {
        state.TDS_TCSDetailData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetTDS_TCSDetail.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  }
});

export const { } = CompanySetUpPopUpSlice.actions

export default CompanySetUpPopUpSlice.reducer