import React, { useState, useEffect } from "react";
import Store from "../../utils/store";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import {
  Button,
  Center,
  Checkbox,
  FileButton,
  Grid,
  Group,
  PasswordInput,
  Text,
  Textarea,
} from "@mantine/core";
import PartyMaster from "../../Transaction/MasterControl/PartyMaster";
import { GetAccountGroup } from "../../utils/slices/AccountGroupSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { modals } from "@mantine/modals";
import { IconFile, IconMail } from "@tabler/icons-react";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { useSelector, useDispatch } from "react-redux";
import AccountListSlice, {
  GetAccountList,
} from "../../utils/slices/AccountListSlice";
import { GetPaginationObj } from "../../utils/slices/DataSaveLoadingSlice";
import { GetM01List } from "../../utils/slices/M01FormSlice";
import PrintSlice, {
  GetAccountListForPrint,
  GetPrintEntData,
  GetTMFormat,
} from "../../utils/PrintSlice/PrintSlice";
import ReportViewer from "../../components/ReportViewer";
import { ProductListAPi } from "../../utils/slices/ProductList";
import GlobalClass from "../../utils/GlobalClass";
import { GetAddCmpEnt } from "../../utils/slices/UserListSlice";
import gensetting from "../../utils/gensetting";

const PLPrint = (props) => {
  // console.log("data of props", props);
  const pid = props?.data?.BtnPnlObj?.id;
  const [selectedAccNames, setSelectedAccNames] = useState([]);
  const [mode, setMode] = useState(false);
  const [handleOkClicked, setHandleOkClicked] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [groupSelected, setGroupSelected] = useState(false);
  const [areaSelected, setAreaSelected] = useState(false);
  const [citySelected, setCitySelected] = useState(false);
  const [accountSelected, setAccountSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [selectedOfGroup, setSelectedOfGroup] = useState("A");
  const [selectedOfArea, setSelectedOfArea] = useState("A");
  const [selectedOfCity, setSelectedOfCity] = useState("A");
  const [selectedOfAccount, setSelectedOfAccount] = useState("A");
  const [selectedOfCategory, setSelectedOfCategory] = useState("A");
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [isCitySelected, setIsCitySelected] = useState(false);
  const [isGroupSelected, setIsGroupSelected] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [cfmtid, setcfmtid] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [groupData1, setGroupData1] = useState([]);
  const [groupData2, setGroupData2] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [areaData1, setAreaData1] = useState([]);
  const [areaData2, setAreaData2] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityData1,setCityData1] = useState([]);
  const [cityData2,setCityData2] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryData1, setCategoryData1] = useState([]);
  const [categoryData2, setCategoryData2] = useState([]);
  const [waitForFilter, setWaitForFilter] = useState(true);
  const [TmFormatData,setTmFormatData]=useState();
  const [pageRange, setPageRange] = useState({
    value: "A",
    Label: "All Pages",
  });
  const [line, setLine] = useState({ value: "N", Label: "None" });
  const [verticalLine, setVerticalLine] = useState({
    value: "N",
    Label: "None",
  });
  const [companyNameOn, setCompanyNameOn] = useState({
    value: "3",
    Label: "All Pages",
  });
  const [reportHeaderOn, setReportHeaderOn] = useState({
    value: "3",
    Label: "All Pages",
  });
  const [footerOn, setFooterOn] = useState({ value: "3", Label: "All Pages" });
  const [isLoading1, setIsLoading1] = useState(false);
  const [AdvanceFormObj, setAdvanceFormObj] = useState({
    "nReportOn": 0,
    "cFileName": null,
    "nNoOfColumn": 0,
    "lTopToBotton": false,
    "cRepFontName": "",
    "nRepFontSize": 0,
    "nPaper": 0,
    "nLeftMargin": 5,
    "nTopMargin": 0,
    "nCNameOn": 3,
    "nRHeaderOn": 3,
    "cReportTitle": "",
    "cReportFooter": "",
    "nRFooterOn": 3,
    "lprintHeader": false,
    "cPaperSize": "",
    "PrintMode": "W",
    "HLine": "N",
    "VLine": "N"
});

const [AdvanceFormObjShow, setAdvanceFormObjShow] = useState({
  "StartPage":"1",
  "EndPage":"1",
  "PageRange":"1",
  "StartingPageNo":"1"
})


  
  const dispatch = useDispatch();

  const partyData = [
    { dispexpr: "All", valexpr: "A" },
    {
      dispexpr: "Selected",
      valexpr: "S",
    },
  ];

  const M01FormData = useSelector((state) => state?.M01FormSlice?.M01FormData);
  const M01FormLoading = useSelector((state) => state?.M01FormSlice?.isLoading);
  const ProductListData = useSelector(
    (store) => store?.ProductList?.ProductListData
  );
  // console.log("productListData",ProductListData)
  const isLoading = useSelector((state) => state?.PrintList?.isLoading);
  const hasError = useSelector((state) => state?.PrintList?.hasError);
  const ErrorMsg = useSelector((state) => state?.PrintList?.ErrorMsg);

  const TmFormat = useSelector(
    (state) => state?.PrintList?.TMFormatData[props?.data?.page]
  );
  const resultNames = useSelector((state) => state?.PrintList?.PrintData[pid]);
  const PrintEntData = useSelector((state) => state?.PrintList?.PrintEntData);

  const { oPDA,CBOPRN,CBOPPR,CBOPMD,CBOF21,CBONMON } = useSelector((state) => state?.PrintList?.PrintEntData);
  const { PrintEntDataisLoading } = useSelector((state) => state?.PrintList);




  const GetGeneral = useSelector((state) => state.Language.General);

  useEffect(() => {
    if (isLoading == true) {
      setIsLoading1(false);
    }
  }, [isLoading]);
  useEffect(()=>{
     setTmFormatData(TmFormat);
  },[TmFormat])

  useEffect(() => {
    if (accountSelected == true) {
      let resData = [];
      Promise.resolve(
        (resData = Store.getState()?.PrintList?.PrintData[pid])
      ).then(() => {
        if (isGroupSelected) {
          if (pid == "00510001") {
            let filteredArray = [];

            for (let i = 0; i < groupData?.length; i++) {
              for (let j = 0; j < resData?.length; j++) {
                if (groupData[i]?.FIELD01 == resData[j]?.FIELD05) {
                  filteredArray?.push(resData[j]);
                }
              }
            }
            resData = filteredArray;
          } else if (pid == "00930001") {
            let filteredArray = [];

            for (let i = 0; i < groupData?.length; i++) {
              for (let j = 0; j < resData?.length; j++) {
                if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
                  filteredArray?.push(resData[j]);
                }
              }
            }
            resData = filteredArray;
          }
        }
        if (isCategorySelected) {
          let filteredArray = [];

          for (let i = 0; i < categoryData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
                filteredArray?.push(resData[j]);
              }
            }
          }
          resData = filteredArray;
        }
        if (isAreaSelected) {
          let filteredArray = [];

          for (let i = 0; i < areaData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (areaData[i]?.FIELD01 == resData[j]?.FIELD18) {
                filteredArray?.push(resData[j]);
              }
            }
          }
          resData = filteredArray;
        }
        if (isCitySelected) {
          let filteredArray = [];

          for (let i = 0; i < cityData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (cityData[i]?.FIELD01 == resData[j]?.FIELD16) {
                filteredArray?.push(resData[j]);
              }
            }
          }

          resData = filteredArray;
        }
        setWaitForFilter(false);
        setSelectedAccNames(
          resData?.length == 0
            ? isCategorySelected ||
              isAreaSelected ||
              isGroupSelected ||
              isCitySelected
              ? resData
              : resultNames
            : resData
        );
      });
    }
  }, [accountSelected]);

  useEffect(() => {
    Promise.resolve(
      dispatch(
        GetTMFormat({
          ...props?.data?.BtnPnlObj,
          StrtDt: GetGeneral?.dYSDate,
          EndDt: GetGeneral?.dYEDate,
        })
      )
    ).then(() => {
      dispatch(GetAccountListForPrint(props?.data?.BtnPnlObj));
      if (pid == "00510001") {
        dispatch(GetM01List({ OBJ: "A" }));
      } else if (pid == "00930001") {
        dispatch(ProductListAPi(props?.data?.BtnPnlObj));
      }
    }).then(() => {
      if (!isLoading) {
        dispatch(GetPrintEntData())
      }
    });
  }, []);

  useEffect(() => {
    if (oPDA) {
      setAdvanceFormObj(oPDA)
      // console.log("PrintEntData oPDA=>",oPDA)
      // console.log("PrintEntData=>",PrintEntData)
    }
  }, [oPDA])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    setAreaData(M01FormData?.P_M31ADT?.jData);
    setCityData(M01FormData?.P_M31TDT?.jData);
    setGroupData(
      pid == "00510001"
        ? M01FormData?.P_M11EDT?.jData
        : ProductListData?.P_M32GDT?.jData
    );
    setAccountData(resultNames);
    setSelectedAccNames(resultNames);
    setCategoryData(ProductListData?.P_M32CDT?.jData);
  }, [M01FormData, resultNames, ProductListData]);

  useEffect(() => {
    if (handleOkClicked === true) {
      var newobj = { ...props?.data?.BtnPnlObj, p2: cfmtid };
      Promise.resolve(
        dispatch(
          GetTMFormat({
            ...newobj,
            StrtDt: GetGeneral?.dYSDate,
            EndDt: GetGeneral?.dYEDate,
          })
        )
      ).then((data) => {
        if (
          data?.meta?.requestStatus == "fulfilled" &&
          data?.payload?.error == undefined
        ) {
          let Adv = {...AdvanceFormObj,...AdvanceFormObjShow}
          Store.dispatch(
            ModalFunction({
              onclose: () => { },
              MTitle: <Text fw={700}>{props?.data?.BtnPnlObj?.name}</Text>,
              MAction: true,
              MSize: "70%",
              MBody: (i) => (
                <ReportViewer
                  title={props?.data?.BtnPnlObj?.name}
                  aDcol={TmFormatData?.oDCFrmt?.aDCol}
                  aDGrp={TmFormatData?.oDCFrmt?.aDGrp}
                  data={isAccountSelected ? accountData : selectedAccNames}
                  AdvanceFormData={Adv}
                />
              ),
              MClose: true,
              Overlay: {
                backgroundOpacity: 0.8,
                blur: 5,
              },
            })
          );

          dispatch(ModalDelete(props?.index));
        } else {
          GlobalClass.Notify(
            "error",
            data?.payload?.error,
            data?.payload?.error
          );
          return;
        }
      });
    }
  }, [handleOkClicked]);

  const modeSwitchHandler = () => {
    setMode((prev) => !prev);
  };
  const advanceController = () => {
    setShowAdvance((prev) => !prev);
  };

  const handleOk = async () => {
    let resData = [];
    // let CmpNumber = sessionStorage.getItem("cmp")
    // console.log("Advance Obj=>", AdvanceFormObj);

    setIsLoading1(true);
    Promise.resolve(
      accountSelected == false &&
      Promise.resolve(
        (resData = Store.getState()?.PrintList?.PrintData[pid])
      ).then(() => {
        if (isGroupSelected) {
          if (pid == "00510001") {
            let filteredArray = [];

            for (let i = 0; i < groupData?.length; i++) {
              for (let j = 0; j < resData?.length; j++) {
                if (groupData[i]?.FIELD01 == resData[j]?.FIELD05) {
                  filteredArray?.push(resData[j]);
                }
              }
            }
            resData = filteredArray;
          } else if (pid == "00930001") {
            let filteredArray = [];

            for (let i = 0; i < groupData?.length; i++) {
              for (let j = 0; j < resData?.length; j++) {
                if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
                  filteredArray?.push(resData[j]);
                }
              }
            }
            resData = filteredArray;
          }
        }
        if (isCategorySelected) {
          let filteredArray = [];

          for (let i = 0; i < areaData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
                filteredArray?.push(resData[j]);
              }
            }
          }
          resData = filteredArray;
        }
        if (isAreaSelected) {
          let filteredArray = [];

          for (let i = 0; i < areaData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (areaData[i]?.FIELD01 == resData[j]?.FIELD18) {
                filteredArray?.push(resData[j]);
              }
            }
          }
          resData = filteredArray;
        }
        if (isCitySelected) {
          let filteredArray = [];

          for (let i = 0; i < cityData?.length; i++) {
            for (let j = 0; j < resData?.length; j++) {
              if (cityData[i]?.FIELD01 == resData[j]?.FIELD16) {
                filteredArray?.push(resData[j]);
              }
            }
          }

          resData = filteredArray;
        }
        setSelectedAccNames(
          resData?.length == 0
            ? isCategorySelected ||
              isAreaSelected ||
              isGroupSelected ||
              isCitySelected
              ? resData
              : resultNames
            : resData
        );
      })
    ).then(()=>{
      // let PostObj = {...AdvanceFormObj};
      // console.log("PostObj==>", PostObj)
      // GlobalClass.Notify("info","Adding","Please wait while we process your data");

      // var data = {
      //     CorpID: gensetting.getcorpid(),
      //     cUser: gensetting.getcuser(),
      //     cPass: gensetting.getcpass(),
      //     CmpNo: gensetting.getcmpno(),
      //     cAction:"A",
      //     cCode: "",
      //     cSData: JSON.stringify(PostObj),
      // };

      // let param = JSON.stringify({
      //     CorpID: gensetting.getcorpid(),
      //     cUser: gensetting.getcuser(),
      //     cPass: gensetting.getcpass(),
      //     CmpNo: gensetting.getcmpno(),
      //     cAction: "A",
      //     cCode: "",
      // });
      // const requestOptions = {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify(data),
      // };
      // fetch(
      //     GlobalClass.ApiUrl + GlobalClass.PostAdvanceEnt + "?pa=" + param,
      //     requestOptions
      // )
      //     .then((response) => response.json())
      //     .then((data) => {
      //         if (data.status == "SUCCESS") {
      //           Promise.resolve(GlobalClass.Notify("success","Added","Added successfully")).then(()=>dispatch(
      //             GetAddCmpEnt({ cmpNo: sessionStorage.getItem("cmp"), action: "E" })
      //           )).then(()=>setHandleOkClicked(true))
                
      //         } else if (data.status == "FAIL") {
      //           GlobalClass.Notify("error", data?.status, data?.message)
      //         }
      //     })
      //     .catch((e) => GlobalClass.Notify("error", "Error", `${e.message}`))

      Promise.resolve(dispatch(
        GetAddCmpEnt({ cmpNo: localStorage.getItem("cmp"), action: "E" })
      )).then(()=>setHandleOkClicked(true))
    })
  };
  const customPageHandler = () => {
    modals.open({
      title: <Text fw={700}>Add Custom Page</Text>,
      zIndex: 300,
      size: "sm",
      children: (
        <>
          <Text>Enter Report Page Height & Width in Inch</Text>
          <Center>
            <Grid
              gutter={4}
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                marginTop: "7px",
                alignContent: "center",
              }}
            >
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={3}>Height</Grid.Col>
                <Grid.Col span={4}>
                  <PLNumberBox />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={3}>Width</Grid.Col>
                <Grid.Col span={4}>
                  <PLNumberBox />
                </Grid.Col>
              </Grid.Col>
            </Grid>
          </Center>
          <Center style={{ marginTop: "10px" }}>
            <Button style={{ width: "100px" }}>Ok</Button>
          </Center>
        </>
      ),
    });
  };

  // console.log("AdvanceFormObj=>",AdvanceFormObj);

  return (
    <>
    {
      PrintEntDataisLoading == true ? GlobalClass.RenderLoader("dots") :
      <>
      {!showAdvance ? (
        isLoading || isLoading1 || M01FormLoading ? (
          GlobalClass.RenderLoader("dots")
        ) : (
          <Grid
            gutter={4}
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
            }}
          >
            {pid == "00510001" && (
              <>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    maxHeight: "47px",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Group Name</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          data={partyData}
                          value={selectedOfGroup}
                          dispexpr={"dispexpr"}
                          valexpr={"valexpr"}
                          setEdit={(e) => {
                            setSelectedOfGroup(e);
                            setIsGroupSelected(false);
                            if (e == "S") {
                              setGroupSelected(true);
                            } else {
                              setGroupSelected(false);
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Area Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        data={partyData}
                        value={selectedOfArea}
                        dispexpr={"dispexpr"}
                        valexpr={"valexpr"}
                        setEdit={(e) => {
                          setSelectedOfArea(e);
                          setIsAreaSelected(false);
                          if (e == "S") {
                            setAreaSelected(true);
                          } else {
                            setAreaSelected(false);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {groupSelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Group Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            width={"500"}
                            value={{value:groupData1,label:groupData2}}
                            setEdit={(e) => {
                              let group = e?.map((v) => v?.original);
                              setGroupData(group);
                              let val2 = [];
                              let val1 = [];
                              if (e?.length > 0) {
                                e?.map((m, i) => {
                                  val2.push(m?.original?.FIELD02);
                                  val1.push(m?.original?.FIELD01);
                                });
                              }
                              setGroupData1(val1?.join(","));
                              setGroupData2(val2?.join(","));
                              setIsGroupSelected(true);
                            }}
                            isMulti={true}
                            type={"Group_Name"}
                            TmData={M01FormData?.P_M11E}
                            // data={M01FormData?.P_M11EDT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {areaSelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Area Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            width={"500"}
                            value={{value:areaData1,label:areaData2}}
                            setEdit={(e) => {
                              let area = e?.map((v) => v?.original);
                              setAreaData(area);
                              let val2 = [];
                              let val1 = [];
                              if (e?.length > 0) {
                                e?.map((m, i) => {
                                  val2.push(m?.original?.FIELD02);
                                  val1.push(m?.original?.FIELD01);
                                });
                              }
                              setAreaData1(val1?.join(","));
                              setAreaData2(val2?.join(","));
                              setIsAreaSelected(true);
                            }}
                            isMulti={true}
                            TmData={M01FormData?.P_M31A}
                            // data={M01FormData?.P_M31ADT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    maxHeight: "47px",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>City Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        data={partyData}
                        value={selectedOfCity}
                        dispexpr={"dispexpr"}
                        valexpr={"valexpr"}
                        setEdit={(e) => {
                          setSelectedOfCity(e);
                          setIsCitySelected(false);
                          if (e == "S") {
                            setCitySelected(true);
                          } else {
                            setCitySelected(false);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Account Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        data={partyData}
                        value={selectedOfAccount}
                        dispexpr={"dispexpr"}
                        valexpr={"valexpr"}
                        setEdit={(e) => {
                          setSelectedOfAccount(e);
                          setIsAccountSelected(false);
                          if (e == "S") {
                            setAccountSelected(true);
                          } else {
                            setAccountSelected(false);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {citySelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select City Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            width={"500"}
                            value={{value:cityData1,label:cityData2}}
                            setEdit={(e) => {
                              let city = e?.map((v) => v?.original);
                              setCityData(city);
                              let val2 = [];
                              let val1 = [];
                              if (e?.length > 0) {
                                e?.map((m, i) => {
                                  val2.push(m?.original?.FIELD02);
                                  val1.push(m?.original?.FIELD01);
                                });
                              }
                              setCityData1(val1?.join(","));
                              setCityData2(val2?.join(","));
                              setIsCitySelected(true);
                            }}
                            isMulti={true}
                            TmData={M01FormData?.P_M31T}
                            // data={M01FormData?.P_M31TDT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {accountSelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Account Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            data={selectedAccNames}
                            isLoading={waitForFilter}
                            TmData={M01FormData?.P_M01I}
                            width={"500"}
                            setEdit={(e) => {
                              let account = e?.map((v) => v?.original);
                              setAccountData(account);
                              setIsAccountSelected(true);
                              setWaitForFilter(false);
                            }}
                            dropdownPosition={"bottom"}
                            isMulti={true}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
              </>
            )}
            {pid == "00930001" && (
              <>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    maxHeight: "47px",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Group Name</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          data={partyData}
                          value={selectedOfGroup}
                          dispexpr={"dispexpr"}
                          valexpr={"valexpr"}
                          setEdit={(e) => {
                            setSelectedOfGroup(e);
                            setIsGroupSelected(false);
                            if (e == "S") {
                              setGroupSelected(true);
                            } else {
                              setGroupSelected(false);
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Category Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        data={partyData}
                        value={selectedOfCategory}
                        dispexpr={"dispexpr"}
                        valexpr={"valexpr"}
                        setEdit={(e) => {
                          setSelectedOfCategory(e);
                          setIsCategorySelected(false);
                          if (e == "S") {
                            setCategorySelected(true);
                          } else {
                            setCategorySelected(false);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {groupSelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Group Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            width={"500"}
                            value={{value:groupData1,label:groupData2}}
                            setEdit={(e) => {
                              let group = e?.map((v) => v?.original);
                              setGroupData(group);
                              let val2 = [];
                              let val1 = [];
                              if (e?.length > 0) {
                                e?.map((m, i) => {
                                  val2.push(m?.original?.FIELD02);
                                  val1.push(m?.original?.FIELD01);
                                });
                              }
                              setGroupData1(val1?.join(","));
                              setGroupData2(val2?.join(","));
                              setIsGroupSelected(true);
                            }}
                            isMulti={true}
                            TmData={ProductListData?.P_M32G}
                            // data={ProductListData?.P_M32GDT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {categorySelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Category Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            width={"500"}
                            value={{value:categoryData1,label:categoryData2}}
                            setEdit={(e) => {
                              let category = e?.map((v) => v?.original);
                              setCategoryData(category);
                              let val2 = [];
                              let val1 = [];
                              if (e?.length > 0) {
                                e?.map((m, i) => {
                                  val2.push(m?.original?.FIELD02);
                                  val1.push(m?.original?.FIELD01);
                                });
                              }
                              setCategoryData1(val1?.join(","));
                              setCategoryData2(val2?.join(","));
                              setIsCategorySelected(true);
                            }}
                            isMulti={true}
                            TmData={ProductListData?.P_M32C}
                            // data={ProductListData?.P_M32CDT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    maxHeight: "47px",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Product Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        data={partyData}
                        value={selectedOfAccount}
                        dispexpr={"dispexpr"}
                        valexpr={"valexpr"}
                        setEdit={(e) => {
                          setSelectedOfAccount(e);
                          setIsAccountSelected(false);
                          if (e == "S") {
                            setAccountSelected(true);
                          } else {
                            setAccountSelected(false);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {accountSelected == true ? (
                      <Grid.Col
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>
                            Select Product Names
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            isLoading={waitForFilter}
                            data={selectedAccNames}
                            TmData={"P_M21"}
                            width={"500"}
                            setEdit={(e) => {
                              let account = e?.map((v) => v?.original);
                              setAccountData(account);
                              setIsAccountSelected(true);
                              setWaitForFilter(false);
                            }}
                            dropdownPosition={"bottom"}
                            isMulti={true}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : (
                      ""
                    )}
                  </Grid.Col>
                </Grid.Col>
              </>
            )}

            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3px",
                maxHeight: "38px",
              }}
            >
              <Grid.Col
                span={6}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={3}>
                  <Text style={{ marginTop: "8px" }}>Format</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLComboBox
                    value={cfmtid ? cfmtid : TmFormatData?.DTS62?.at(0)?.S62F02}
                    dispexpr="S62F04"
                    valexpr="S62F02"
                    data={TmFormatData?.DTS62}
                    setEdit={(e) => {
                      setcfmtid(e);
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={12}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col
                span={6}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={3}>
                  <Text>Watermark</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLTextBox disabled={true} />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
              <Center>
             
                <Button onClick={advanceController} mr={4}>Advance</Button>
                <Button onClick={handleOk}>OK</Button>
              </Center>
            </Grid>
        )
      ) : (
        <Grid
          gutter={4}
          style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}
        >
          <Text
            style={{
              alignSelf: "center",
              backgroundColor: "#228be61a",
              color: "#228be6",
              fontSize: "14px",
            }}
          >
            Printing option
          </Text>
          <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Start Page</Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <PLNumberBox
                  value={AdvanceFormObjShow?.StartPage}
                  setEdit={(e) => {
                    setAdvanceFormObjShow({ ...AdvanceFormObjShow, StartPage: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>End Page</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLNumberBox
                  value={AdvanceFormObjShow?.EndPage}
                  setEdit={(e) => {
                    setAdvanceFormObjShow({ ...AdvanceFormObjShow, EndPage: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
          >
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Page Range</Text>
              </Grid.Col>
              <Grid.Col span={8} style={{ paddingBottom: "0px" }}>
                <PLComboBox
                  data={CBOPRN}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObjShow?.PageRange}
                  setEdit={(e) => {
                    setAdvanceFormObjShow({
                      ...AdvanceFormObjShow,
                      PageRange: e,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Starting Page No.</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLNumberBox
                  value={AdvanceFormObjShow?.StartingPageNo}
                  setEdit={(e) => {
                    setAdvanceFormObjShow({ ...AdvanceFormObjShow, StartingPageNo: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Left Margin</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLNumberBox
                  precision={3}
                  value={AdvanceFormObj?.nLeftMargin}
                  setEdit={(e) => {
                    setAdvanceFormObj({ ...AdvanceFormObj, nLeftMargin: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Top Margin</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLNumberBox
                  precision={3}
                  value={AdvanceFormObj?.nTopMargin}
                  setEdit={(e) => {
                    setAdvanceFormObj({ ...AdvanceFormObj, nTopMargin: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
          >
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Paper Size</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={CBOPPR}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.cPaperSize}
                  setEdit={(e) => {
                    setAdvanceFormObj({ ...AdvanceFormObj, cPaperSize: e });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
          >
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Line</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={CBOF21}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.HLine}
                  setEdit={(e) => {
                    setAdvanceFormObj({ ...AdvanceFormObj, HLine: e?.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Vertical Line</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                   data={CBOF21}
                   valexpr={"ValueMember"}
                   dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.VLine}
                  setEdit={(e) => {
                    setAdvanceFormObj({
                      ...AdvanceFormObj,
                      VLine: e?.value,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Text
            style={{
              alignSelf: "center",
              backgroundColor: "#228be61a",
              color: "#228be6",
              fontSize: "14px",
            }}
          >
            Report Title ( Advance Setup )
          </Text>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
          >
            <Grid.Col span={2}>
              <Text>Report Title</Text>
            </Grid.Col>
            {/* <Grid.Col span={1}></Grid.Col> */}
            <Grid.Col span={10}>
              <PLTextBox
                value={AdvanceFormObj?.cReportTitle}
                setEdit={(e) => {
                  setAdvanceFormObj({
                    ...AdvanceFormObj,
                    cReportTitle: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
          >
            <Grid.Col span={2}>
              <Text>Report Footer</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                value={AdvanceFormObj?.cReportFooter}
                setEdit={(e) => {
                  setAdvanceFormObj({
                    ...AdvanceFormObj,
                    cReportFooter: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", flexDirection: "row", maxHeight: "45px" }}
          >
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Company Name On</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={CBONMON}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.nCNameOn}
                  setEdit={(e) => {
                    // console.log(e);
                    setAdvanceFormObj({
                      ...AdvanceFormObj,
                      nCNameOn: e,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Report Header On</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={CBONMON}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.nRHeaderOn}
                  setEdit={(e) => {
                    setAdvanceFormObj({
                      ...AdvanceFormObj,
                      nRHeaderOn: e,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Text>Footer On</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  data={CBONMON}
                  valexpr={"ValueMember"}
                  dispexpr={"DisplayMember"}
                  value={AdvanceFormObj?.nRFooterOn}
                  setEdit={(e) => {
                    setAdvanceFormObj({
                      ...AdvanceFormObj,
                      nRFooterOn: e,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "0px",
            }}
          >
            <Grid.Col
              span={11}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Grid.Col span={4}>
                <Button
                  style={{
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    marginRight: "0px",
                  }}
                >
                  Default
                </Button>
              </Grid.Col>

              <Grid.Col span={3}>
                <Button
                  onClick={customPageHandler}
                  style={{
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    marginRight: "0px",
                  }}
                >
                  Add Custom Page
                </Button>
              </Grid.Col>
              <Grid.Col span={3}>
                <Button
                  onClick={advanceController}
                  style={{
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    marginLeft: "0px",
                  }}
                >
                  Save As Default
                </Button>
              </Grid.Col>
            </Grid.Col>
            {/* <Grid.Col span={2}></Grid.Col> */}
            <Grid.Col span={1}>
              <Button
                onClick={advanceController}
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                Back
              </Button>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      )}
    </>
    }
    </>
    
  );
};

export function TM_PRINT(data) {
  const OBJ = JSON.parse(data);
  // console.log("TM_PRINT Print btn=>", OBJ);
  Store.dispatch(
    ModalFunction({
      onclose: (e) => { },
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "xl",
      position:"right",
      MBody: (i) => <PLPrint data={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}

export default PLPrint;
