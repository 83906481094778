import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';
import { ModalDelete } from '../slices/ModalSlice';
import Store from '../store';

export const GetLeadEntryOrder = createAsyncThunk(
  "leadEntry/GetLeadEntryOrder",
  async (obj) => {
    console.log("GetLeadEntryOrder_fullfilled", obj)
    try {
      let param =
        JSON.stringify({
          "CorpID": gensetting.getcorpid(),
          "cUser": gensetting.getcuser(),
          "cPass": gensetting.getcpass(),
          "CmpNo": gensetting.getcmpno(),
          "cAction": 'A',
          "cCode": obj?.p0 == "O" ? "OSOS" : "QTQT",
          "cOPara": obj?.id,
          "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.EntryFromLead + "?pa=" + param)
      if (response.data.data == null) {
        Promise.resolve(GlobalClass.Notify("info", response.data.message)).then(() => Store.dispatch(ModalDelete(obj.modalIndex)))
      } else {
        return response.data.data;
      }
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error }
    }
  }
)

export const GetFollowUpData = createAsyncThunk(
  "transactionLeadEntry/GetFollowUpData",
  async (obj) => {
    try {
      console.log("GetFollowUpData_fullfilled", obj)

      let param =
        JSON.stringify({
          "CorpID": gensetting.getcorpid(),
          "cUser": gensetting.getcuser(),
          "cPass": gensetting.getcpass(),
          "CmpNo": gensetting.getcmpno(),
          "cAction": 'A',
          "cCode": obj?.id,
          "cOPara": "",
          "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetFollowupData + "?pa=" + param)
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", "Fail", error.message)
      console.error(error)
      return { error }
    }
  }
)

const TransactionLeadEntrySlice = createSlice({
  name: "transactionLeadEntry",
  initialState: {
    OrderData: [],
    followUpData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    successMsg: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetLeadEntryOrder.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetLeadEntryOrder.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.OrderData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetLeadEntryOrder.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

      .addCase(GetFollowUpData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetFollowUpData.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.followUpData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetFollowUpData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  }
});

export const { } = TransactionLeadEntrySlice.actions

export default TransactionLeadEntrySlice.reducer