import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import axios from "axios";
import gensetting from "../gensetting";
import { IconBooks, IconDeviceDesktopAnalytics, IconFileInvoice, IconLogout, IconReport, IconSettings, IconTool } from "@tabler/icons-react";
import Localize from "../rc";
import Store from "../store";
import { GetYearDateUrl } from "./CompanySetupSlice";
import { GetLanguageData } from "./LanguageSlice";
import { ModalDelete } from "./ModalSlice";
// import Store from "../store";
// import { GetUserMasterData } from "./UserMasterSlice";

// user List api
export const GetUserList = createAsyncThunk(
    "UserList/GetUserList",
    async (obj) => {
        // console.log('UserList/GetUserList Slice Call', obj); 
        try {
            // const user = sessionStorage.getItem("authUser");
            const user = localStorage.getItem("authUser")

            // console.log("UserList/GetUserList user =>",user); 

            if (!obj?.Action) {
                if (user) {
                    return JSON.parse(user);
                }
            }

            let param = "p1=" + gensetting.getcorpid() + "&p2=" + gensetting.getcuser() + "&p3=" + gensetting.getcpass() + "&YrNo=" + localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetCorpDet + "?" + param);
            // console.log("GetUserList response=>>", response.data.data);
            if (response.data.status === "SUCCESS") {
                // sessionStorage.setItem("authUser",JSON.stringify(response.data.data));
                localStorage.setItem("authUser", JSON.stringify(response.data.data))
                return response.data.data;
            } else {
                return { error: response.data.message }
            }

        } catch (error) {
            console.error(error);
            return { error }
        }
    }
);
let rc = new Localize().rc
function localizeCap(data) {

    return data.map((itm) => {
        if (!itm.name.includes("Separator")) {
            if (itm.capid) {
                itm.name = rc(itm.capid)
            }
            // itm.name = rc(itm.capid)

        }
        if (itm.items) {
            itm.items = localizeCap(itm.items)
        }
        return itm
    });
}
export const GetMenuList = createAsyncThunk(
    "UserList/GetMenuList",
    async () => {
        // Promise.resolve(Store.dispatch(GetUserMasterData())).then(()=>{
        //     let usermastername=Store.getState().UserMasterSlice.UserMasterData

        //     console.log('usermastername obj=>', usermastername);
        // })
        // console.log('UserList/GetUserList obj=>', obj);
        try {

            let responseData = [];
            var menu = localStorage.getItem("Menu");
            if (false) {
                var newmenu = JSON.parse(menu);
                // console.log("newmenu===>",newmenu);
                newmenu.map((itm) => {

                    if (itm.name === 'Master' || itm.name === "Transaction" || itm.name === "Report" || itm.name === "Utility" || itm.name === "Setup" || itm.name === "Exit") {
                        switch (itm.name) {
                            case 'Master':
                                // setActive(itm.name);
                                // setSubMenuarray(itm.items)
                                itm.icon = IconBooks;
                                break;
                            case 'Transaction':
                                itm.icon = IconFileInvoice;
                                break;
                            case 'Report':
                                itm.icon = IconReport;
                                break;
                            case 'Utility':
                                itm.icon = IconTool;
                                break;
                            case 'Setup':
                                itm.icon = IconSettings;
                                break;
                            case 'Exit':
                                itm.icon = IconLogout;
                                break;
                            default:
                                itm.icon = IconDeviceDesktopAnalytics;
                                break;
                        }

                    }
                    return itm;
                })
                responseData = newmenu;

            } else {
                const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetMenu + "?pa=" + GlobalClass.getdataparam());

                responseData = response.data.data;
                // console.log("GetUserList response=>>", response.data.data);

                localStorage.setItem("Menu", JSON.stringify(responseData));
                responseData = responseData.map((itm) => {
                    if (itm.name === 'Master' || itm.name === "Transaction" || itm.name === "Report" || itm.name === "Utility" || itm.name === "Setup" || itm.name === "Exit") {
                        switch (itm.name) {
                            case 'Master':
                                // setActive(itm.name);
                                // setSubMenuarray(itm.items)
                                itm.icon = IconBooks;
                                break;
                            case 'Transaction':
                                itm.icon = IconFileInvoice;
                                break;
                            case 'Report':
                                itm.icon = IconReport;
                                break;
                            case 'Utility':
                                itm.icon = IconTool;
                                break;
                            case 'Setup':
                                itm.icon = IconSettings;
                                break;
                            case 'Exit':
                                itm.icon = IconLogout;
                                break;
                            default:
                                itm.icon = IconDeviceDesktopAnalytics;
                                break;
                        }

                    }
                    return itm;
                })

            }
            return await localizeCap(responseData);
        } catch (error) {
            console.error(error);
            return { error: "Network Error!!" }  // Vara Pooja
        }
    }
);

export const GetAddCmpEnt = createAsyncThunk(
    "UserList/GetAddCmpEnt",
    async (OBJ) => {
        console.log("UserList/GetAddCmpEnt=>", OBJ);
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                // "CmpNo": gensetting.getcmpno(),
                "CmpNo": OBJ?.cmpNo ? OBJ?.cmpNo : "",
                "cAction": OBJ?.action ? OBJ?.action : "A"
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM00Ent + "?pa=" + param)
            // console.log('response', response)
            if (OBJ?.action == "D") {
                if (response.data.status === "SUCCESS") {
                    Promise.resolve(Store.dispatch(GetUserList({ Action: OBJ?.action }))).then(() =>
                        GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
                } else {
                    GlobalClass.Notify(
                        "error",
                        response?.data?.status,
                        response?.data?.message
                    );
                }
            }
            if (response.data.status === "SUCCESS") {
                return response.data.data;
            } else {
                return { error: response.data.message };
            }
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

export const PostFinYear = createAsyncThunk(
    "UserList/PostFinYear",
    async (obj) => {
        console.log("UserList/PostFinYear =>", obj);
        try {
            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cOPara: obj?.Type ?? "",
                cSDate: obj?.From ?? "",
                cEDate: obj?.To ?? ""
            }

            console.log("UserList/PostFinYear param=>", param);

            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.NewYear, param);
            console.log("UserList/PostFinYear response=>", response?.data);
            // return response?.data?.data;
            if (response.data.status === "SUCCESS") {
                if (obj?.Type == "N") {
                    Promise.resolve(GlobalClass.Notify("success", "Success", "Add Fin. Year successfully")).then(() => localStorage.setItem("YrNo", "")).then(() => window.location.reload())
                } else {
                    Store.dispatch(PostUpdateBalanceMaster(obj))
                }
                // Promise.resolve(GlobalClass.Notify("success", "Success", "Add Fin. Year successfully")).then(() => localStorage.setItem("YrNo", "")).then(() => window.location.reload())

                // Promise.resolve(dispatch(GetYearDateUrl())).then(()=>GlobalClass.Notify("success", "Deleted", "Deleted successfully")).then(()=>{
                // let newYearNo
                // localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
                // })
                return response.data.status;
            } else {
                return { error: response.data.message, status: response?.data?.status };
            }
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

export const PostUpdateBalanceMaster = createAsyncThunk(
    "UserList/PostUpdateBalanceMaster",
    async (obj) => {
        console.log("UserList/PostUpdateBalanceMaster =>", obj);
        try {
            let GetYrNo = localStorage.getItem("YrNo");
            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cOPara: obj?.Type ?? "",
                YrNo: GetYrNo ?? ""
            }

            console.log("UserList/PostUpdateBalanceMaster param=>", param);

            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.NewYear, param);
            console.log("UserList/PostFinYear response=>", response?.data);
            // return response?.data?.data;
            if (response.data.status === "SUCCESS") {

                Store.dispatch(PostUpdateBalance(obj))

                // Promise.resolve(GlobalClass.Notify("success", "Success", "Add Fin. Year successfully")).then(() => localStorage.setItem("YrNo", "")).then(() => window.location.reload())

                // Promise.resolve(dispatch(GetYearDateUrl())).then(()=>GlobalClass.Notify("success", "Deleted", "Deleted successfully")).then(()=>{
                // let newYearNo
                // localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
                // })
                return response.data.status;
            } else {
                return { error: response.data.message, status: response?.data?.status };
            }
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

export const PostUpdateBalance = createAsyncThunk(
    "UserList/PostUpdateBalance",
    async (obj) => {
        console.log("UserList/PostUpdateBalance =>", obj);
        try {
            let GetYrNo = localStorage.getItem("YrNo");
            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cSDate: obj?.From ?? "",
                cEDate: obj?.To ?? "",
                YrNo: GetYrNo ?? ""
            }

            console.log("UserList/PostUpdateBalance param=>", param);

            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.UpdBal, param);
            console.log("UserList/PostUpdateBalance response=>", response?.data);
            // return response?.data?.data;
            if (response.data.status === "SUCCESS") {
                // Store.dispatch(PostUpdateBalance(obj))
                if (obj?.PopUp) {
                    Promise.resolve(GlobalClass.Notify("success", "Success", "Update Balance SuccessFully")).then(() => Store.dispatch(ModalDelete(obj?.ModalIndex)))
                } else {
                    Promise.resolve(GlobalClass.Notify("success", "Success", "Add Fin. Year successfully")).then(() => localStorage.setItem("YrNo", "")).then(() => window.location.reload())
                }



                // Promise.resolve(dispatch(GetYearDateUrl())).then(()=>GlobalClass.Notify("success", "Deleted", "Deleted successfully")).then(()=>{
                // let newYearNo
                // localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
                // })
                return response.data.status;
            } else {
                return { error: response.data.message, status: response?.data?.status };
            }
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

export const DeleteFinYear = createAsyncThunk(
    "UserList/DeleteFinYear",
    async (obj) => {
        try {
            let param = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cOPara: obj?.Type ?? "",
                cSDate: obj?.From ?? "",
                cEDate: obj?.To ?? ""
            }

            console.log("UserList/DeleteFinYear param=>", param);

            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.YrDelete, param);
            console.log("UserList/DeleteFinYear response=>", response?.data);
            // return response?.data?.data;
            if (response.data.status === "SUCCESS") {
                Promise.resolve(Store?.dispatch(GetYearDateUrl())).then(() => GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
                return response.data.status;
            } else {
                return { error: response.data.message, status: response?.data?.status };
            }
        } catch (error) {
            console.error(error);
            return { error };
        }

    }
)

const UserListSlice = createSlice({
    name: 'UserList',
    initialState: {
        UserListData: [],
        MenuListData: [],
        AddCmpEnt: [],
        addFinRes: [],
        addUpdateBalMaster: [],
        addUpdateBalance: [],
        DelFinRes: "",
        CmpNumber: '',
        YearAndDateSend: null,
        isMenuLoading: false,
        isLoading: false,
        hasError: false,
        ErrorMsg: "",
        DelFinYearisLoading: false,
        DelFinYearhasError: false,
        DelFinYearErrorMsg: "",

        PostFinYearisLoading: false,
        PostFinYearhasError: false,
        PostFinYearErrorMsg: ""
    },
    reducers: {
        SetCmpNumber: (state, action) => {
            // console.log("GetCmpNumber=>", action.payload);
            state.CmpNumber = action.payload
        },
        SetYearAndDateData: (state, action) => {
            // console.log("SetYearAndDateFocus=>", action.payload);
            state.YearAndDateSend = action.payload
        },
        SetPostFinYearNull: (state, action) => {
            state.PostFinYearisLoading = false;
            state.PostFinYearhasError = false;
            state.PostFinYearErrorMsg = "";
        },
        SetDelFinYearNull: (state, action) => {
            state.DelFinYearisLoading = false;
            state.DelFinYearhasError = false;
            state.DelFinYearErrorMsg = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserList.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetUserList.fulfilled, (state, action) => {
                // console.log('GetUserList action',action.payload);
                if (!action.payload?.error) {
                    state.UserListData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                }
                else {
                    state.UserListData = action.payload.error;
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload.error
                }

            })
            .addCase(GetUserList.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetMenuList.pending, (state, action) => {
                state.isMenuLoading = true;
                state.hasError = false;
            })
            .addCase(GetMenuList.fulfilled, (state, action) => {
                // console.log("GetMenuList fulfilled", action.payload);

                if (action?.payload && !action.payload?.error) {
                    state.MenuListData = action.payload;
                    state.isMenuLoading = false;
                    state.hasError = false;
                } else {
                    state.isMenuLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }    // Vara Pooja


            })
            .addCase(GetMenuList.rejected, (state, action) => {
                state.hasError = true
                state.isMenuLoading = false;
            })
            .addCase(GetAddCmpEnt.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetAddCmpEnt.fulfilled, (state, action) => {
                console.log("GetAddCmpEnt action.payload =>", action);
                if (action?.payload && !action.payload?.error) {
                    state.AddCmpEnt = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.AddCmpEnt = [];
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetAddCmpEnt.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(PostFinYear.pending, (state, action) => {
                state.PostFinYearisLoading = true;
                state.PostFinYearhasError = false;
            })
            .addCase(PostFinYear.fulfilled, (state, action) => {

                // PostFinYearisLoading: false,
                // PostFinYearhasError: false,
                // PostFinYearErrorMsg: ""
                console.log("PostFinYear action.payload =>", action);
                if (action?.payload == "SUCCESS" && !action.payload?.error) {
                    state.addFinRes = action.payload;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = false;
                } else {
                    state.addFinRes = action?.payload?.status;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = true;
                    state.PostFinYearErrorMsg = action?.payload?.error
                }
            })
            .addCase(PostFinYear.rejected, (state, action) => {
                state.PostFinYearhasError = true;
                state.PostFinYearisLoading = false;
            })
            .addCase(DeleteFinYear.pending, (state, action) => {
                state.DelFinYearisLoading = true;
                state.DelFinYearhasError = false;
            })
            .addCase(DeleteFinYear.fulfilled, (state, action) => {
                console.log("DeleteFinYear action.payload =>", action);
                if (action?.payload == "SUCCESS" && !action.payload?.error) {
                    state.DelFinRes = action.payload;
                    state.DelFinYearisLoading = false;
                    state.DelFinYearhasError = false;
                } else {
                    state.DelFinRes = action?.payload?.status;
                    state.DelFinYearisLoading = false;
                    state.DelFinYearhasError = true;
                    state.DelFinYearErrorMsg = action?.payload?.error
                }
            })
            .addCase(DeleteFinYear.rejected, (state, action) => {
                state.DelFinYearhasError = true;
                state.DelFinYearisLoading = false;
            })
            .addCase(PostUpdateBalanceMaster.pending, (state, action) => {
                state.PostFinYearisLoading = true;
                state.PostFinYearhasError = false;
            })
            .addCase(PostUpdateBalanceMaster.fulfilled, (state, action) => {

                // PostFinYearisLoading: false,
                // PostFinYearhasError: false,
                // PostFinYearErrorMsg: ""
                console.log("PostUpdateBalanceMaster action.payload =>", action);
                if (action?.payload == "SUCCESS" && !action.payload?.error) {
                    state.addUpdateBalMaster = action.payload;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = false;
                } else {
                    state.addFinRes = action?.payload?.status;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = true;
                    state.PostFinYearErrorMsg = action?.payload?.error
                }
            })
            .addCase(PostUpdateBalanceMaster.rejected, (state, action) => {
                state.PostFinYearhasError = true;
                state.PostFinYearisLoading = false;
            })
            .addCase(PostUpdateBalance.pending, (state, action) => {
                state.PostFinYearisLoading = true;
                state.PostFinYearhasError = false;
            })
            .addCase(PostUpdateBalance.fulfilled, (state, action) => {

                // PostFinYearisLoading: false,
                // PostFinYearhasError: false,
                // PostFinYearErrorMsg: ""
                console.log("PostUpdateBalanceMaster action.payload =>", action);
                if (action?.payload == "SUCCESS" && !action.payload?.error) {
                    state.addUpdateBalance = action.payload;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = false;
                } else {
                    state.addFinRes = action?.payload?.status;
                    state.PostFinYearisLoading = false;
                    state.PostFinYearhasError = true;
                    state.PostFinYearErrorMsg = action?.payload?.error
                }
            })
            .addCase(PostUpdateBalance.rejected, (state, action) => {
                state.PostFinYearhasError = true;
                state.PostFinYearisLoading = false;
            })

    }
})

export const { SetCmpNumber, SetYearAndDateData, SetPostFinYearNull, SetDelFinYearNull } = UserListSlice.actions

export default UserListSlice.reducer

