import { Alert, Button, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDateBox from '../../PlusComponent/PLDateBox'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PostFinYear } from '../../utils/slices/UserListSlice'
import Store from '../../utils/store'
import GlobalClass from '../../utils/GlobalClass'
import { IconInfoCircle } from '@tabler/icons-react'

const NewFinYearForm = (props) => {
    const { obj } = props

    console.log("NewFinYearForm props =>", props);

    const [yearData, setYearData] = useState({
        "Type": "",
        "From": "",
        "To": ""
    })

    const [TypeCombo, setTypeCombo] = useState([
        { value: "Y", label: "Forward" },
        { value: "N", label: "Reverse" }
    ])

    const [YearErrStatus, setYearErrStatus] = useState(false)

    const dispatch = useDispatch();

    const GetGeneral = useSelector((state) => state?.Language?.General);

    const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);

    const { addFinRes, addUpdateBalance, addUpdateBalMaster, PostFinYearhasError, PostFinYearErrorMsg, PostFinYearisLoading } = useSelector((state) => state?.UserList)



    useEffect(() => {
        if (GetGeneral && GetYearDateData) {

            let yearNo = GetGeneral?.cYrNo;

            let findData1 = GetYearDateData.filter((d) => d?.YearNo == (parseInt(yearNo) + 1))
            let findData2 = GetYearDateData.filter((d) => d?.YearNo == (parseInt(yearNo) - 1))

            

             if (findData1?.length > 0 && findData2?.length > 0) {
                // console.log("Conditiontrue 1",[{
                //     findData1:findData1,
                //     findData2:findData2
                // }])
                setYearErrStatus(true)
            }else if (findData1?.length > 0) {
                // console.log("Conditiontrue 2",[{
                //     findData1:findData1,
                //     findData2:findData2
                // }])
                setTypeCombo([{ value: "N", label: "Reverse" }])
                setYearData({ ...yearData, Type: "N" })
            } else if (findData2?.length > 0) {
                // console.log("Conditiontrue 3",[{
                //     findData1:findData1,
                //     findData2:findData2
                // }])
                setTypeCombo([{ value: "Y", label: "Forward" }])
                setYearData({ ...yearData, Type: "Y" })
            } else {
                // console.log("Conditiontrue 4",[{
                //     findData1:findData1,
                //     findData2:findData2
                // }])
                setYearData({ ...yearData, Type: "Y" })
            }

        }

    }, [GetGeneral, GetYearDateData])

    // useEffect(() => {
    //     if (GetGeneral) {
    //         console.log("GetGeneral =>", GetGeneral);
    //         // dYEDate: "2024-03-31T00:00:00+05:30"
    //         // dYSDate: "2023-04-01T00:00:00+05:30"
    //         let FromDate = new Date(GetGeneral?.dYSDate);
    //         FromDate.setFullYear(FromDate?.getFullYear() + 1)
    //         let ToDate = new Date(GetGeneral?.dYEDate);
    //         ToDate?.setFullYear(ToDate?.getFullYear() + 1)

    //         let year1 = FromDate.toLocaleString("en-IN", {
    //             year: "numeric",
    //         });
    //         let month1 = FromDate.toLocaleString("en-IN", {
    //             month: "2-digit",
    //         });
    //         let day1 = FromDate.toLocaleString("en-IN", {
    //             day: "2-digit",
    //         });

    //         let year2 = ToDate.toLocaleString("en-IN", {
    //             year: "numeric",
    //         });
    //         let month2 = ToDate.toLocaleString("en-IN", {
    //             month: "2-digit",
    //         });
    //         let day2 = ToDate.toLocaleString("en-IN", {
    //             day: "2-digit",
    //         });

    //         let NewFromDate = year1 + month1 + day1;
    //         let NewToDate = year2 + month2 + day2;



    //         setYearData({ ...yearData, From: NewFromDate, To: NewToDate })
    //     }
    // }, [GetGeneral])

    useEffect(() => {
        //Y = Forward
        //N = Reverse
        console.log("Conditiontrue yearDataType", yearData)
        if (yearData?.Type == "Y") {
            let FromDate = new Date(GetGeneral?.dYSDate);
            FromDate.setFullYear(FromDate?.getFullYear() + 1)
            let ToDate = new Date(GetGeneral?.dYEDate);
            ToDate?.setFullYear(ToDate?.getFullYear() + 1)

            let year1 = FromDate.toLocaleString("en-IN", {
                year: "numeric",
            });
            let month1 = FromDate.toLocaleString("en-IN", {
                month: "2-digit",
            });
            let day1 = FromDate.toLocaleString("en-IN", {
                day: "2-digit",
            });

            let year2 = ToDate.toLocaleString("en-IN", {
                year: "numeric",
            });
            let month2 = ToDate.toLocaleString("en-IN", {
                month: "2-digit",
            });
            let day2 = ToDate.toLocaleString("en-IN", {
                day: "2-digit",
            });

            let NewFromDate = year1 + month1 + day1;
            let NewToDate = year2 + month2 + day2;

            setYearData({ ...yearData, From: NewFromDate, To: NewToDate })

        } else if (yearData?.Type == "N") {
            let FromDate = new Date(GetGeneral?.dYSDate);
            FromDate.setFullYear(FromDate?.getFullYear() - 1)
            let ToDate = new Date(GetGeneral?.dYEDate);
            ToDate?.setFullYear(ToDate?.getFullYear() - 1)

            let year1 = FromDate.toLocaleString("en-IN", {
                year: "numeric",
            });
            let month1 = FromDate.toLocaleString("en-IN", {
                month: "2-digit",
            });
            let day1 = FromDate.toLocaleString("en-IN", {
                day: "2-digit",
            });

            let year2 = ToDate.toLocaleString("en-IN", {
                year: "numeric",
            });
            let month2 = ToDate.toLocaleString("en-IN", {
                month: "2-digit",
            });
            let day2 = ToDate.toLocaleString("en-IN", {
                day: "2-digit",
            });

            let NewFromDate = year1 + month1 + day1;
            let NewToDate = year2 + month2 + day2;

            setYearData({ ...yearData, From: NewFromDate, To: NewToDate })

        }
    }, [yearData?.Type])

    useEffect(() => {
        // PostFinYearisLoading: false,
        // PostFinYearhasError: false,
        // PostFinYearErrorMsg:""

        if (PostFinYearhasError && PostFinYearErrorMsg) {
            console.log('addFinReshasErrorErrorMsgisLoading =>', [{
                "addFinRes": addFinRes,
                "addUpdateBalance": addUpdateBalance,
                "addUpdateBalMaster": addUpdateBalMaster,
                "PostFinYearhasError": PostFinYearhasError,
                "PostFinYearErrorMsg": PostFinYearErrorMsg,
                "PostFinYearisLoading": PostFinYearisLoading
            }]);
            if (addFinRes) {
                GlobalClass.Notify("error", "Error: " + addFinRes, PostFinYearErrorMsg);
            } else if (addUpdateBalance) {
                GlobalClass.Notify("error", "Error: " + addUpdateBalance, PostFinYearErrorMsg);
            } else if (addUpdateBalMaster) {
                GlobalClass.Notify("error", "Error: " + addUpdateBalMaster, PostFinYearErrorMsg);
            }
        }

    }, [addFinRes, addUpdateBalance, addUpdateBalMaster, PostFinYearhasError, PostFinYearErrorMsg])

    const handleSaveFinYear = () => {
        console.log("yearData =>", yearData);
        // let data = JSON.stringify(yearData)
        dispatch(PostFinYear(yearData))
    }

    return (
        <>
            {
                PostFinYearisLoading ? GlobalClass.RenderLoader('dots') : YearErrStatus ? <>
                    {
                        <Alert variant="light" color="blue" title="Error" icon={<IconInfoCircle />}>
                            Current Year is not First or Last Year Cannot Create New Fin. Year
                        </Alert>
                    }
                </> :
                    <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={2}>
                                <Text size={12}>Type</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <PLComboBox
                                    data={TypeCombo}
                                    value={yearData?.Type}
                                    dispexpr={"label"}
                                    valexpr={"value"}
                                    setEdit={(e) => {
                                        console.log("Type", e)
                                        setYearData({ ...yearData, Type: e })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center' }}>

                                <Grid.Col span={4}>
                                    <Text size={12}>From</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLDateBox
                                        disabled={yearData?.Type == "Y"}
                                        defaultval={yearData?.From}
                                        dispformat="DD/MM/YYYY"
                                        setEdit={(e) => {
                                            let year = e.toLocaleString("en-IN", {
                                                year: "numeric",
                                            });
                                            let month = e.toLocaleString("en-IN", {
                                                month: "2-digit",
                                            });
                                            let day = e.toLocaleString("en-IN", {
                                                day: "2-digit",
                                            });

                                            let StringDate = year + month + day;

                                            setYearData({ ...yearData, From: StringDate })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid.Col span={4}>
                                    <Text size={12} style={{ display: 'flex', justifyContent: 'center' }}>To</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLDateBox
                                        disabled={yearData?.Type == "N"}
                                        defaultval={yearData?.To}
                                        dispformat="DD/MM/YYYY"
                                        setEdit={(e) => {
                                            let year = e.toLocaleString("en-IN", {
                                                year: "numeric",
                                            });
                                            let month = e.toLocaleString("en-IN", {
                                                month: "2-digit",
                                            });
                                            let day = e.toLocaleString("en-IN", {
                                                day: "2-digit",
                                            });

                                            let StringDate = year + month + day;
                                            setYearData({ ...yearData, To: StringDate })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Group>
                                <Button
                                    style={{ height: "30px", width: "100px" }}
                                    onClick={() => handleSaveFinYear()}
                                >OK</Button>
                                <Button
                                    style={{ height: "30px", width: "100px" }}
                                    onClick={() => {
                                        dispatch(ModalDelete(props.index))
                                    }}
                                >Cancel</Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
            }
        </>
    )
}

export default NewFinYearForm