import React, { useEffect, useState, forwardRef } from 'react';
import { TextInput } from '@mantine/core';

export default function PLTextBox(props) {
  // console.log("props",props);

  const [textValue, setTextValue] = useState()

  function setmaxlength(e) {
    if (e && e > 0) {
      return e;
    }
    return null;
  }
  useEffect(() => {
    setTextValue(props.value)
  }, [props.value])

  function setmode(e) {
    if (e === 'PASSWORD') {
      return 'password';
    } else if (e === 'EMAIL') {
      return 'email';
    } else if (e === 'NUMERIC') {
      return 'number'
    }
    return 'text';
  }
  function getFormat() {
    var frmt = '';
    var nstr = '#';
    var dstr = '0';
    frmt = nstr + '.' + dstr.repeat(2);
    return frmt;
  }
  function setmask() {
    if (props.ldigit) {
      return "\d+\\?\d*";
    }
    else if (props.lalphachar) {
      return "[a-zA-Z\s]+";
    }
    else if (props.lalphanumeric) {
      return "^\w*$";
    }
    return "";
  }
  function setClass(e) {
    console.log("setclass")
    switch (e) {
      case "U":
        return "ucase"
        break;
      case "L":
        return "lcase";
        break;
      case "P":
        return "pcase";
        break;
      default:
        return ""
        break;
    }
  }

  // name={focusedCell.column.dataField}
  // value={e.data.value}
  // nmaxlength={e.data.data.F02F31}
  // onvalchange={onValChanged}
  // entrytype = 'PASSWORD'

  function settxt(e) {
    if (props.setText && typeof props?.setText == 'function') {
      // console.log(props.setText, typeof (props?.setText), "settxt......");
      props.setText(e);
    }
  }

  function onKeyDown(e) {
    if (props?.onKeyDown) {
      props?.onKeyDown(e)
    }
  }//Vara Pooja

  // console.log(textValue,"value");

  return (
    <TextInput
      type={props.type??setmode(props.entrytype)}
      autoFocus={props?.autoFocus}
      style={{ width: props.width ? props.width : '', color: props?.color ? props?.color : '' }}
      placeholder={props.placeholder}

      error={props.error}
      label={props.label}
      id={props.id}
      name={props.name}
      {...props}
      // onFocusCapture={}
      value={textValue}
      defaultValue={props.defaultValue}
      disabled={props?.disabled}
      onChange={(e) => {
        // console.log('text input edit',e.target.value);
        setTextValue(e.target.value);
        if (props.setEdit) {
          props.setEdit(e)
        }

      }}
      onFocus={(e) => {
        if (props.setOnFocus) {
          props.setOnFocus(e)
        }
      }}
      onBlur={(e) => settxt(e)}
      size={'xs'}
      fz={'xs'}
      // ref={props?.ref ? props?.ref : null}
      maxLength={props?.nmaxlength}
      // inputMode={setmode(props.entrytype)}
      // inputMode='numeric'

      // inputMode='numeric'
      // onKeyDown={(e) => onKeyDown(e)}//Vara Pooja 
      onKeyDown={(e) => props?.onKeyDown && typeof props?.onKeyDown == "function" ? props?.onKeyDown(e) : null}
    // label="Custom layout"
    // placeholder="Custom layout"
    // description="Description below the input"
    // inputWrapperOrder={['input']}
    />
  )
}


// const PLTextBox = forwardRef((props, ref) => {
//   const [textValue, setTextValue] = useState()

//   useEffect(() => {
//     setTextValue(props.value)
//   }, [props.value])

//   function setmode(e) {
//     if (e === 'PASSWORD') {
//       return 'password';
//     }
//     else if (e === 'EMAIL') {
//       return 'email';
//     }
//     return 'text';
//   }

//   function setmaxlength(e) {
//     if (e && e > 0) {
//       return e;
//     }
//     return null;
//   }
//   function getFormat() {
//     var frmt = '';
//     var nstr = '#';
//     var dstr = '0';
//     frmt = nstr + '.' + dstr.repeat(2);
//     return frmt;
//   }
//   function setmask() {
//     if (props.ldigit) {
//       return "\d+\\?\d*";
//     }
//     else if (props.lalphachar) {
//       return "[a-zA-Z\s]+";
//     }
//     else if (props.lalphanumeric) {
//       return "^\w*$";
//     }
//     return "";
//   }
//   function setClass(e) {
//     console.log("setclass")
//     switch (e) {
//       case "U":
//         return "ucase"
//         break;
//       case "L":
//         return "lcase";
//         break;
//       case "P":
//         return "pcase";
//         break;
//       default:
//         return ""
//         break;
//     }
//   }

//   // name={focusedCell.column.dataField}
//   // value={e.data.value}
//   // nmaxlength={e.data.data.F02F31}
//   // onvalchange={onValChanged}
//   // entrytype = 'PASSWORD'

//   function settxt(e) {
//     if (props.setText && typeof props?.setText == 'function') {
//       // console.log(props.setText, typeof (props?.setText), "settxt......");
//       props.setText(e);
//     }
//   }

//   function onKeyDown(e) {
//     if (props?.onKeyDown) {
//       props?.onKeyDown(e)
//     }
//   }//Vara Pooja

//   return (
//     <TextInput
//       autoFocus={props?.autoFocus}
//       style={{ width: props.width ? props.width : '', color: props?.color ? props?.color : '' }}
//       placeholder={props.placeholder}
//       error={props.error}
//       label={props.label}
//       id={props.id}
//       name={props.name}
//       {...props}
//       value={textValue}
//       defaultValue={props.defaultValue}
//       disabled={props?.disabled}
//       onChange={(e) => {
//         // console.log('text input edit',e.target.value);
//         setTextValue(e.target.value);
//         if (props.setEdit) {
//           props.setEdit(e)
//         }

//       }}
//       onFocus={(e) => {
//         if (props.setOnFocus) {
//           props.setOnFocus(e)
//         }
//       }}
//       onBlur={(e) => settxt(e)}
//       size={props?.size ? props?.size : 'xs'}
//       fz={props?.fz ? props?.fz : 'sm'}
//       ref={ref ?? ""}
//       maxLength={props?.nmaxlength}

//       inputMode={setmode(props.entrytype)}
//       // onKeyDown={(e) => onKeyDown(e)}//Vara Pooja
//       onKeyDown={(e) => props?.onKeyDown && typeof props?.onKeyDown == "function" ? props?.onKeyDown(e) : null}
//     // label="Custom layout"
//     // placeholder="Custom layout"
//     // description="Description below the input"
//     // inputWrapperOrder={['input']}
//     />
//   )

// });

// export default PLTextBox;