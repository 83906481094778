import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../utils/GlobalClass'

const AccountPopUpDetail = (props) => {
    console.log("props", props)
    const { obj, value, setEditSend } = props
    // console.log('obj=>>', obj)
    const AccPopUpDetailData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    console.log('AccPopUpDetailData==>>', AccPopUpDetailData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const PD_PLUSM01DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.PD_PLUSM01DT)
    const PD_PLUSM01 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.PD_PLUSM01)
    console.log('PD_PLUSM01DT=>>', PD_PLUSM01DT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    function extractFields(input) {
        let parts = input.split('||');
        // console.log("parts",parts)
        const lastElements = parts.map(item => item.split('##').pop());
        // console.log("lastElements",lastElements)

        PD_PLUSM01DT?.map((a) => {
            lastElements?.map((m,i) => {
                if (a["S34F02"].includes(m)) {
                    lastElements.splice(i, 1);
                    lastElements.push(a?.S34F02);
                }
            })
        })
        return lastElements.join(",")
    }

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                    AccPopUpDetailData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        TmData={PD_PLUSM01}
                        data={PD_PLUSM01DT}
                        dispexpr={"S34F03E"}
                        valexpr={"S34F02"}
                        isMulti={true}
                        value={extractFields(value)}
                        setEdit={(e) => {
                            // console.log('e===>', e)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m, i) => {
                                    const sequence = i + 1;
                                    const field = m?.original?.S34F03;
                                    const number = m?.original?.S34F04;
                                    const S34F02 = m?.original?.S34F02;

                                    const formattedString = `${sequence}##${field}##${number}##${S34F02}`;
                                    val.push(formattedString)
                                })
                                const result = val.join('||').replaceAll(" ", "");
                                console.log("result", result)
                                setEditSend(result)
                            }else {
                                setEditSend("")
                            }
                            // setEditSend(e.S34F00)
                        }}
                    />
            }
        </div>
    )
}

export default AccountPopUpDetail