import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import axios from "axios";

export const GetPLData = createAsyncThunk(
  "TSalesSlices/GetPLData",
  async (obj) => {
    // console.log("TSalesSlices/GetPLData", obj);
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cCode": obj?.priceListCode ?? "PLHDFOC4",
        "cSData": obj?.productCode ?? "PHXANJRA",
        "cOPara": obj?.accountCode ?? "AHY9LHO4",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetPLData + "?pa=" + param);
      return response?.data?.data;
    } catch (error) {
      // console.error(error);
      return { error };
    }
  }
)

export const GetMstinfoData = createAsyncThunk(
  "TSalesSlices/GetMstinfoData",
  async (obj) => {
    console.log("TSalesSlices/GetMstinfoData", obj);
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cCode": obj?.cCode,
        "cSData": "",
        "cOPara": "SALES",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.mstinfo + "?pa=" + param);
      console.log("TSalesSlices/GetMstinfoData response =>", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
)

export const GetExpenseData = createAsyncThunk(
  "TSalesSlices/GetExpenseData",
  async (obj) => {
    console.log("TSalesSlices/GetExpenseData", obj);
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": obj?.action ? obj.action : "A",
        "cCode": obj?.code ? obj.code : "SSSS",
        "cOPara": "",
        "cInvCode": obj?.invCode ? obj?.invCode : "",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.InvExpData + "?pa=" + param);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error: error };
    }
  }
)

export const GetBillFromChallanData = createAsyncThunk(
  "TSalesSlices/GetBillFromChallanData",
  async (obj) => {
    try {
      let newObj = {
        cPCode: "",
        cACode: obj?.code,
        cDate: obj?.date,
        cPrcCode: obj?.pRc, //Nairin
        cType: obj?.ctype,
      };
      console.log(newObj, "newObj")
      let param = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode:
          obj?.page == "SSSS"
            ? "HS"
            : obj?.page == "PPPP"
              ? "HP"
              : obj?.page == "JJJJ" //Nairin
                ? obj?.ctype == "SJ"
                  ? "JZ"
                  : "JJ"
                : obj?.page == "JZJZ"
                  ? "JZ"
                  : "SS",
        cSData: JSON.stringify(newObj),
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.GetChlnData,
        param
      );
      // console.log("response==>", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const GetPendingQuotationData = createAsyncThunk(
  "TSalesSlices/GetPendingQuotationData",
  async (obj) => {
    console.log("TSalesSlices/GetPendingQuotationData obj =>", obj);
    try {
      let newObj = {
        "cPCode": "",
        "cACode": obj?.code,
        "cDate": obj?.date,
        "cType": ""
      }

      console.log("TSalesSlices/GetPendingQuotationData newObj =>", newObj)
      let param = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode:
          obj?.page == "SSSS" ? "SS" : // sales invoice
            obj?.page == "HSHS" ? "HS" : //sales challan
              obj?.page == "OSOS" ? "OS" : "SS", //sales order
        cSData: JSON.stringify(newObj),
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.GetQuoData,
        param
      );
      console.log("TSalesSlices/GetPendingQuotationData response==>", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const GetSingleSelectedOrder = createAsyncThunk(
  "TSalesSlices/GetSingleSelectedOrder",
  async (obj) => {
    // console.log("TSalesSlices/GetSingleSelectedOrder", obj);
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": obj?.code ? obj.code : "",
        "cOPara": "",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetT02Quo + "?pa=" + param);
      // console.log("TSalesSlices/GetSingleSelectedOrder response", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
)

export const GetPendingOrderData = createAsyncThunk(
  "TSalesSlices/GetPendingOrderData",
  async (obj) => {
    // console.log("TSalesSlices/GetPendingOrderData obj =>", obj);
    try {
      let newObj = {
        "cPCode": "",
        "cACode": obj?.code,
        "cDate": obj?.date,
        "cType": ""
      }

      // console.log("TSalesSlices/GetPendingOrderData newObj =>", newObj)
      let param = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: obj?.page == "SSSS" ? "SS" :
          obj?.page == "HSHS" ? "HS" :
            obj?.page == "HPHP" ? "HP" :
              obj?.page == "PPPP" ? "PP" : "SS",
        cSData: JSON.stringify(newObj),
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.GetOrdData,
        param
      );
      // console.log("TSalesSlices/GetPendingOrderData response==>", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const GetSingleOrder = createAsyncThunk(
  "TSalesSlices/GetSingleOrder",
  async (obj) => {
    console.log("TSalesSlices/GetSingleOrder", obj);
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": obj?.code ? obj.code : "",
        "cOPara": "",

      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetT02Ord + "?pa=" + param);
      console.log("TSalesSlices/GetSingleOrder response", response?.data?.data);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
)

const TSalesSlices = createSlice({
  name: "TSalesSlices",
  initialState: {
    TSalesSlicesTMFormatData: [],
    TransactionSalesPLdata: {},
    MstinfoData: {},
    ExpenseData: [],
    PendingChallanData: [],
    PendingQuotationData: [],
    SingleSelectedOrderRow: {},
    PendingOrderData: [],
    SingleOrderRow: {},
    isLoadingExpenseData: false,
    hasErrorExpenseData: false,
    ErrorMsgExpenseData: "",
    isLoading: false,
    hasError: false,
    productName: '',
    CasePartyRequired: {
      DropDownValue: '',
      CasePartyFormName: ''
    },
    InvTypeDropDown: null,
    PreFillTransactionFormData: {
      CashDebit: "",
      PartyAc: "",
      PartyAcName:"",
      DeliveryAt: "",
      DeliveryAtName:"",
      InvoiceType: "",
      BillDate: ""
    }
  },
  reducers: {
    GetExpenseDataNull: (state, action) => {
      state.ExpenseData = action.payload
    },
    GetCasePartyRequired: (state, action) => {
      state.CasePartyRequired = action.payload
    },
    GetInvTypeDropDown: (state, action) => {
      // console.log("GetInvTypeDropDown",action?.payload);
      state.InvTypeDropDown = action.payload
    },
    getProductName: (state, action) => {
      state.productName = action.payload
    },
    GetPendingQuotationDataNull: (state, action) => {
      state.PendingQuotationData = action.payload
    },
    GetSingleSelectedOrderRowNull: (state, action) => {
      state.SingleSelectedOrderRow = action.payload
    },
    GetPendingOrderDataNull: (state, action) => {
      state.PendingOrderData = action.payload
    },
    GetSingleOrderRowNull: (state, action) => {
      state.SingleOrderRow = action.payload
    },
    GetPreFillTransactionFormData: (state, action) => {
      state.PreFillTransactionFormData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetPLData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetPLData.fulfilled, (state, action) => {
        state.TransactionSalesPLdata = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetPLData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetMstinfoData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetMstinfoData.fulfilled, (state, action) => {
        state.MstinfoData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetMstinfoData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetExpenseData.pending, (state, action) => {
        state.isLoadingExpenseData = true;
        state.hasErrorExpenseData = false;
      })
      .addCase(GetExpenseData.fulfilled, (state, action) => {
        // console.log("GetExpenseData action.payload",action.payload);
        if (!action.payload?.error) {
          state.ExpenseData = action.payload;
          state.isLoadingExpenseData = false;
          state.hasErrorExpenseData = false;
        } else {
          state.isLoadingExpenseData = false;
          state.hasErrorExpenseData = true;
          state.ErrorMsgExpenseData = action.payload?.error;
        }
      })
      .addCase(GetExpenseData.rejected, (state, action) => {
        state.hasErrorExpenseData = true;
        state.isLoadingExpenseData = false;
      })
      .addCase(GetBillFromChallanData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetBillFromChallanData.fulfilled, (state, action) => {
        state.PendingChallanData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetBillFromChallanData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetPendingQuotationData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetPendingQuotationData.fulfilled, (state, action) => {
        state.PendingQuotationData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetPendingQuotationData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetSingleSelectedOrder.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetSingleSelectedOrder.fulfilled, (state, action) => {
        state.SingleSelectedOrderRow = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetSingleSelectedOrder.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetPendingOrderData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.PendingOrderData = []
      })
      .addCase(GetPendingOrderData.fulfilled, (state, action) => {
        state.PendingOrderData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetPendingOrderData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetSingleOrder.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetSingleOrder.fulfilled, (state, action) => {
        state.SingleOrderRow = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetSingleOrder.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })

  }
})

export const {GetPreFillTransactionFormData, GetExpenseDataNull, GetPendingOrderDataNull, GetSingleOrderRowNull, GetCasePartyRequired, GetInvTypeDropDown, getProductName, GetPendingQuotationDataNull, GetSingleSelectedOrderRowNull } = TSalesSlices.actions

export default TSalesSlices.reducer