import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCashPartyDetail } from '../../utils/slices/VoucherSetupPopUpSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'
import GlobalClass from '../../utils/GlobalClass'

const CashPartyDetail = (props) => {

    const { setEditSend, value } = props
    console.log('value=>>', value)
    console.log('setEditSend', setEditSend)
    const isLoading = useSelector((state) => state.VoucherSetupPopUp?.isLoading)
    const CashPartyData = useSelector((state) => state.VoucherSetupPopUp?.CashPartyDetailData)
    // console.log('CashPartyData',CashPartyData)
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState([])
    console.log("SelectedItem=>>", selectedItem)
    // const [val, setVal] = useState([])

    useEffect(() => {
        dispatch(GetCashPartyDetail())
    }, [])

    useEffect(() => {
        if (selectedItem && selectedItem.length > 0) {
            setEditSend(selectedItem.toString())
        }
    }, [selectedItem])

    const seperate = (a) => {
        let value = []
        value = a.split(",")
        return value
    }

    return (
        <div style={{ width: '100%' }}>
            {

                isLoading ? GlobalClass.RenderLoader('dots') :
                    CashPartyData &&
                    <PLComboBox
                        dropdownPosition='top'
                        setComboBox={() => props?.closeCombo(true)}
                        multiple={true}
                        data={CashPartyData}
                        value={seperate(value)}
                        dispexpr="Name"
                        valexpr="ID"
                        setEdit={(e) => {
                            // console.log('e===>', e)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m)
                                    console.log('val', val)
                                })
                                const result = val.join(",")
                                setEditSend(result)
                            } else {
                                setEditSend("")
                            }
                        }}
                    />
            }
        </div>
    )
}

export default CashPartyDetail