import { Button, Center, Grid, Modal, Skeleton, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import {
  ECommerceOperatorApi,
  ECommerceOperatorPostApi,
} from "../../utils/slices/ECommerceOperator";
import { useDispatch, useSelector } from "react-redux";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

function ECommerceOperatorForm(props) {
  const { OBJ } = props;

  const dispatch = useDispatch();
  const ECommerceOperatorData = useSelector((store) => store?.ECommerceOperator?.ECommerceOperatorData);
  const isLoading = useSelector((store) => store?.ECommerceOperator?.isLoading);
  const SaveLoading = useSelector((store) => store?.DataSaveLoadingSlice?.SaveLoading);
  const hasError = useSelector((state) => state?.ECommerceOperator?.hasError);
  const ErrorMsg = useSelector((state) => state?.ECommerceOperator?.ErrorMsg);

  const formRef = useRef(null);

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: () => { },
  };

  const [DrawerObj, setDrawerObj] = useState(dra);

  const [state, setState] = useState();
  const [dataObj, setDataObj] = useState();
  const [validation, setValidation] = useState({
    gst: true,
    // aadhar: true,
    // pan: true,
  });

  useEffect(() => {
    dispatch(ECommerceOperatorApi(OBJ));
  }, []);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    setState(ECommerceOperatorData);
    setDataObj(ECommerceOperatorData)
  }, [ECommerceOperatorData]);

  const submit = () => {

    if (state?.G02F02.trim() !== "" && validation.gst) {

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: "",
        cOPara: "",
        cSData: JSON.stringify(state),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara: "",
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      if (OBJ.p0 == "E" && JSON.stringify(state) === JSON.stringify(dataObj)) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
        // GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // dispatch(GetAccountList({
        //   id: "14950123",
        //   name: "E-Commerce Operator",
        //   p0: "MT",
        //   p1: "T_G02",
        //   p2: "",
        //   p3: "",
        //   type: "A",
        //   pagination: PaginationObj["14950123"],
        // })
        // );

      }
      else {

        dispatch(DataSaveLoading(true));
        GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

        fetch(GlobalClass.ApiUrl + GlobalClass.PostG02Ent + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "SUCCESS") {
              Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
              GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              dispatch(GetAccountList({
                id: "14950123",
                name: "E-Commerce Operator",
                p0: "MT",
                p1: "T_G02",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["14950123"],
              })
              );
            } else if (data.status === "FAIL") {
              Promise.resolve(dispatch(DataSaveLoading(false)));
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => {
            Promise.resolve(dispatch(DataSaveLoading(false)));
            GlobalClass.Notify("error", "Error", `${e?.message}`);
          });
      }
    } else {
      GlobalClass.Notify("warning", "Validation error", "Blank or Invalid Fields");
    }
  };

  const ChkGSTINCheckSum = (cGSTIN) => {
    if (cGSTIN.length !== 15) return false;

    cGSTIN = cGSTIN.toUpperCase();
    let nI = 65;
    let nFVal = 0,
      nTOt = 0,
      nData = 0;
    let cFChar = "\0";
    let aRForSum = new Array(14);
    let cChkSum = cGSTIN[cGSTIN.length - 1];

    let arrABCD = new Array(35).fill(null).map(() => new Array(2));
    for (let i = 1; i <= 35; i++) {
      if (i <= 9) {
        arrABCD[i - 1][0] = i.toString();
        arrABCD[i - 1][1] = i.toString();
      } else {
        arrABCD[i - 1][0] = String.fromCharCode(nI);
        arrABCD[i - 1][1] = i.toString();
        nI++;
      }
    }

    for (let i = 0; i < 14; i++) {
      let cChar = cGSTIN[i];
      let cVal = arrABCD.findIndex((elem) => elem[0] === cChar.toString());
      if (cVal !== -1) {
        if ((i + 1) % 2 === 0) nData = parseInt(arrABCD[cVal][1]) * 2;
        else nData = parseInt(arrABCD[cVal][1]);

        let Quo = Math.abs(Math.floor(nData / 36));
        let rem = Math.abs(nData % 36);
        aRForSum[i] = Quo + rem;
      } else aRForSum[i] = 0;

      nTOt += aRForSum[i];
    }

    nTOt %= 36;

    nFVal = (36 - nTOt) % 36;

    if (nFVal === 0) cFChar = "0";
    else {
      for (let i = 0; i < 35; i++) {
        if (arrABCD[i][1] === nFVal.toString()) {
          cFChar = arrABCD[i][0];
          break;
        }
      }
    }

    return cFChar === cChkSum;
  };

  const handleGstBlur = () => {
    if (state?.G02F03?.length > 0) {
      const result = ChkGSTINCheckSum(state?.G02F03?.trim());
      if (!result) {
        console.log("result", result);
        GlobalClass.Notify("warning", "Warning", "Invalid GSTIN No.", false, 3000, "GSTIN")
        // GlobalClass.Notifyupdate("warning",props.index,)
      }

      setValidation({ ...validation, gst: result });
    } else {
      setValidation({ ...validation, gst: true });
    }

  };

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  return (
    <div ref={formRef}>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => { }}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
              setDrawerObj(dra);
            }
        }
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
      {
        hasError ?
          <>
            <Text>{ErrorMsg?.message}</Text>
            <Button
              onClick={() => {
                if (OBJ) {
                  dispatch(ECommerceOperatorApi(OBJ))
                }
              }}
            >
              Retry
            </Button>
          </>
          :
          isLoading == true || SaveLoading == true ? (GlobalClass.RenderLoader("dots")) : (
            <Grid gutter={4}>
              <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Operator Name</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLTextBox
                    autoFocus={true}
                    value={state?.G02F02}
                    setEdit={(e) => {
                      setState({ ...state, G02F02: e.target.value });
                    }}
                    setText={(e) => {
                      if (e.target.value.trim()?.length <= 0) {
                        GlobalClass.Notify("warning", "Warning", "Blank name is not allowed.", false, 3000)
                      }
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>GSTIN No.</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLTextBox
                    value={state?.G02F03}
                    setText={handleGstBlur}
                    setEdit={(e) => {
                      setState({ ...state, G02F03: e.target.value.toUpperCase() });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Merchant ID</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <PLTextBox
                    value={state?.G02F04}
                    setEdit={(e) => {
                      setState({ ...state, G02F04: e.target.value });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col span={12}>
                <Center>
                  <Button onClick={submit}
                    onKeyDown={(e) => {
                      if (e.key == "Tab" || e.key == "Enter") {
                        submit()
                      }
                    }}
                  >Ok</Button>
                </Center>
              </Grid.Col>
            </Grid>
          )}
    </div>
  );
}
export default ECommerceOperatorForm;
