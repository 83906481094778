import { useEffect, useRef, useState } from "react";
import {
    createStyles,
    rem,
    Group,
    Button,
    Grid,
    Box,
    Text,
    Card,
    Paper,
    MantineProvider,
    useMantineTheme,
    Kbd,
    MediaQuery,
    Tooltip,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList, GetTMFormat, SaveAccountListData, getCurrentDate, getRangeBtnState } from "../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";

import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React from "react";
import { useHotkeys, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import GlobalClass from "../utils/GlobalClass";
import useWindowDimensions from "../utils/UseWindowDimensions";
const useStyles = createStyles((theme) => ({
    header: {
        position: "sticky",
        top: 0,
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        transition: "box-shadow 150ms ease",

        "&::after": {
            content: '""',
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark"
                ? theme.colors.dark[3]
                : theme.colors.gray[2]
                }`,
        },
    },

    scrolled: {
        boxShadow: theme.shadows.sm,
    },
}));

function UserSecurityGrid(props) {
    // console.log("UserSecurityGrid Props==>", props);
    const globalTheme = useMantineTheme();
    const { height, width } = useWindowDimensions();
    const { tag, obj, form, id, cWhere } = props;
    const date1 = new Date(); //Yasvi Patel
    const [focusset, setfocusset] = useState(false);
    const disRef = useRef();
    const dispatch = useDispatch();
    const TableRef = useRef();
    const childRef = useRef();
    const {
        hasError,
        ErrorMsg,
        isLoading,
        AccountListData: accList,
        TMFormatData: tmList,
        cFooter,
        rowCount,
        isRefetching,
    } = useSelector((state) => state.AccountList);
    // const PaginationObj = useSelector(state => state?.DataSaveLoadingSlice?.PaginationObj)

    const [acTM, setacTM] = useState({
        AccountListData: [],
        TMFormatData: [],
    });
    // console.log('TMFormatData', TMFormatData);
    const [cfmtid, setcfmtid] = useState("");
    const [columns, setColumns] = useState([]);
    const [grouping, setGrouping] = useState([]);
    const [rowSelection, setRowSelection] = useState({}); //{ 0: true }
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });

    const [sessionSetting, setSessionSetting] = useLocalStorage({
        key: 'session_setting',
        defaultValue: {
            Primary_Color: 'blue',
            Font_Family: 'Arial',
            Font_Size: 12,
            Font_XS: 10,
            Font_SM: 12,
            Font_MD: 14,
            Font_LG: 16,
            Font_XL: 20
        },
        getInitialValueInEffect: true,
    })

    const ReportIdArray = useSelector((state) => state.DataSaveLoadingSlice.ReportIdArray);
    const GetGeneral = useSelector((state) => state.Language.General);

    useEffect(() => {
        if (hasError && !isLoading[obj?.id]) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    const columnHelper = createColumnHelper();
    const ref = useRef(null);

    useEffect(() => {
        var newobj;
        if (cfmtid == "") {
            newobj = { ...obj, p2: obj.p2 };
        } else {
            newobj = { ...obj, p2: cfmtid };
        }

        if (props.TmData) {
            setacTM({ TMFormatData: props.TmData, AccountListData: props.data });
        } else {
            let flag = false
            if (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()) {
                flag = true;
            }

            dispatch(
                GetTMFormat({
                    ...newobj
                })
            );

        }

    }, [cfmtid]);

    const evaluateExpression = (expression, obj, ori) => {
        // Use a function to safely evaluate the expression
        // let result = "";
        try {
            return new Function(...Object.keys(obj), `return ${expression};`)(...Object.values(obj));
        }
        catch {
            return ori;
        }
        // return result;
    };
    const rendercolcell = (data, v, row) => {

        // console.log('data===>>',data)
        let dtxt = data;
        var datatype =
            v.DataType === "N"
                ? "number"
                : v.ColClick !== "" || v.DispExpr === "M01F68"
                    ? "custom"
                    : "string";
        if (datatype === "custom") {
            if (dtxt === "A") {
                dtxt = "✓";
            } else {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("A")) {
            dtxt = Math.abs(dtxt);
        }
        if (v.DataType.includes("D")) {
            // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
            //  dtxt = "2023/12/12"
            if (data != null && data != "" && data != undefined) {
                dtxt = dtxt.toString()  //Vara Pooja
                dtxt =
                    dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
            } else {
                dtxt = "";
            }
            // return dtxt
        }
        if (v.DispFormat.includes("Z")) {
            if (dtxt === ".00") {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("X")) {
            if (dtxt !== "") {

                if (parseFloat(dtxt) > 0) {
                    dtxt = Math.abs(dtxt);
                    dtxt += " CR";
                } else if (parseFloat(dtxt) == 0) {
                    dtxt = 0
                }
                else {
                    dtxt = Math.abs(dtxt);
                    dtxt += " DB";
                }
            }
        }

        return (
            <p
                style={{
                    fontSize: v.FontSize ? v.FontSize : sessionSetting?.Font_Size,
                    fontFamily: v.FontName ? v.FontName : sessionSetting?.Font_Family,
                    color: v.ForeColor,
                    backgroundColor: v.BackColor,
                }}
            >
                {dtxt}
            </p>
        );
    };

    useEffect(() => {
        // console.log("tmList", accList, tmList);
        if (accList && tmList && accList[obj.id] && tmList[obj.id] && !props.TmData) {
            setacTM({
                AccountListData: props?.data ?? (accList[obj.id] ?? []),
                TMFormatData: tmList[obj.id],
            });
            setfocusset(false);

            // console.log("SaveAccountListArr 1",[{
            //   "SaveAccountListArr":SaveAccountListArr,
            //   'accList[obj.id]':accList[obj.id]
            // }]);
        } else if (tmList && tmList[obj.id] && !props.TmData) {
            setacTM({
                AccountListData: props?.data ?? [],
                TMFormatData: tmList[obj.id],
            });
        }

    }, [accList, tmList, accList[obj.id]]);

    useEffect(() => {
        // console.log("acTM.TMFormatData", acTM?.TMFormatData);
        if (
            acTM.TMFormatData &&
            acTM.TMFormatData.oDCFrmt &&
            acTM.TMFormatData.oDCFrmt.aDCol &&
            (acTM.AccountListData || props.data)
        ) {

            var cols = [];
            var grp = [];
            // console.log("colset", acTM?.TMFormatData?.oDCFrmt);
            if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 0) {
                 if (props?.isMultiGroup) {
                    let v = acTM.TMFormatData.oDCFrmt?.aDGrp;
                    let grps = [];
                    v.map((v, i) => {
                        cols.push(
                            columnHelper.accessor(v.DispExpr, {
                                header: "",
                                enableGlobalFilter: true,
                                enableSorting: false,
                                sortDescFirst: false,

                                size: v.ColWidth,
                                minSize: v.ColWidth,
                                // maxSize: v.ColWidth,
                                // accessorFn: (row) =>acTM.TMFormatData.oDCFrmt?.aDGrp.map((key) => row[key?.DispExpr]).join(' - '),
                                id: v.DispExpr,
                            })
                        );
                        grps.push(v.DispExpr);

                    })
                    setGrouping(grps);
                }
                else {
                    let v = acTM.TMFormatData.oDCFrmt?.aDGrp[0];
                    cols.push(
                        columnHelper.accessor(v.DispExpr, {
                            header: "",
                            enableGlobalFilter: true,
                            enableSorting: false,
                            sortDescFirst: false,

                            size: v.ColWidth,
                            minSize: v.ColWidth,
                            // maxSize: v.ColWidth,
                            // accessorFn: (row) =>acTM.TMFormatData.oDCFrmt?.aDGrp.map((key) => row[key?.DispExpr]).join(' - '),
                            id: v.DispExpr,
                        })
                    );
                    setGrouping([v.DispExpr]);
                }
            }
            else {
                setGrouping([])
            }

            acTM.TMFormatData.oDCFrmt.aDCol.map((v, i) => {
                // console.log('i=======>',i)
                // console.log("v=>>",v)

                //01340123, P_M31T = City
                //01350123, "P_M31A" = Area
                //P_PLSTATE
                // if (i == 0) {
                //   setSorting([{ id: v.DispExpr, desc: ("01340123,P_M31T,01350123,P_M31A,P_PLSTATE,P_M45I".includes(obj?.id) ? false : true) }]);
                // }
                console.log("cols", v.ColCap + " = " + v.cColID);

                cols.push(
                    // obj.id == "01980001" ? "FIELD02" :
                    columnHelper.accessor(v.DispExpr, {
                        header: v.ColCap,
                        id: v.cColID && v.cColID != '' ? v.cColID : Math.random(),
                        enableGlobalFilter: ((v.DataType == "D" || !v.ColCap) ? false : true),
                        enableColumnFilter: v.lSearchCol,
                        enableSorting: v.ColCap ? v.lColOrder : false,
                        sortDescFirst: v.ColCap ? v.lColOrder : false,

                        size: v.ColCap ? v.ColWidth : 0,
                        minSize: 0,
                        maxSize: v.ColWidth,
                        mantineTableBodyCellProps: {
                            align: v.DAlign == 1 ? "right" : "left",
                        },

                        mantineTableHeadCellProps: {
                            align: v.HAlign == 1 ? "right" : "left",
                        },
                        aggregationFn: "sum",
                        AggregatedCell: ({ cell }) =>
                            v?.cSType !== "N" ? (
                                <div>
                                    <Text style={{ fontWeight: "bold", color: "gray", fontSize: '12px' }}>
                                        Total : {cell.getValue().toFixed(4)}
                                    </Text>
                                </div>
                            ) : (
                                ""
                            ),
                        Cell: ({ cell, renderedCellValue, row }) =>
                            //  console.log('cell====>',cell),\

                            rendercolcell(evaluateExpression(v.DispExpr, row?.original, cell.getValue()), v, row)
                    })
                );
            });

            console.log("Columns99=>>", acTM?.TMFormatData?.oDCFrmt?.aDCol);
            setColumns(cols);

        }
    }, [acTM.TMFormatData, acTM.AccountListData]); //acTM.AccountListData   // Deval

    useEffect(() => {
        if (props.ValueEdit && typeof props.ValueEdit == "function" && rowSelection) {
            props.ValueEdit({
                parent: tabled.getGroupedSelectedRowModel()?.flatRows[0]
            });
        }
    }, [rowSelection])

    const tabled = useMantineReactTable({
        data: acTM.AccountListData ?? [],
        columns,
        renderBottomToolbar: ({ table }) => (
            <Box >
                <Group
                    spacing={"xs"}
                    position="center"
                    display={"flex"}
                ><MRT_TablePagination position="bottom" table={table} />
                </Group>
            </Box>
        ),
        paginationDisplayMode: "default",
        mantinePaginationProps: {
            showRowsPerPage: form ? false : true,
            withEdges: true,
            rowsPerPageOptions: ['25', '100', '500', '1000'],
        },
        // enableFilters:false,
        enableColumnOrdering: false,
        enableStickyHeader: true,
        enableColumnResizing: false,
        columnResizeMode: "onChange",
        enableGrouping: true,
        // enableGroupBy: false,
        enableEditing: acTM?.TMFormatData?.oS60?.cRecType == "P" ?? false,
        editDisplayMode: "row",
        enableDensityToggle: false,
        enablePagination: true,

        enableRowVirtualization: false,
        initialState: {
            grouping,
            density: ReportIdArray.includes(obj.id) ? "2px" : "4px",
            pagination,
            expanded: ReportIdArray.includes(obj.id),
        },
        state: {
            grouping,
            rowSelection,
            density: ReportIdArray.includes(obj.id) ? "2px" : "4px",
            pagination,
            showAlertBanner: hasError,
            showProgressBars: isRefetching[obj?.id],
            showLoadingOverlay: isLoading[obj?.id],
        },
        positionToolbarAlertBanner: 'none',
        enableTopToolbar: false, // deval (for user Security)
        enableBottomToolbar: true,
        enableColumnFilters: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        onGroupingChange: setGrouping,
        enableGlobalFilter: true,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableGlobalFilterModes: false,
        enableTableFooter: true,
        enableStickyFooter: true,
        enableColumnFilterModes: false,
        renderGlobalFilterModeMenuItems: () => {

        },
        mantineTableBodyProps: {
            ref: TableRef,
        },
        mantineTableProps: { withColumnBorders: true }, //Table Border
        // enableColumnResizing: true,
        defaultColumn: { // Deval
            minSize: 0, //allow columns to get smaller than default
            maxSize: 15, //allow columns to get larger than default
            size: 1, //make columns wider by default
        },
        mantineTableContainerProps: {
            ref: disRef,
            id: "Datagrid" + obj.id,
            sx: { height: height * (form ? (props.size ?? 0.2) : 0.6) },
        },
        mantineTableHeadCellProps: {
            style: { whiteSpace: 'initial', textAlign: 'center' }, // Keeps group headers inline
        },
        groupedColumnMode: false,
        enableSelectAll: false,
        layoutMode: "semantic",
        onPaginationChange: setPagination,
        // onRowSelectionChange: setRowSelection,
        getRowId: (row) => row[props.selectionId], //Nairin Salot

        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({

            tabIndex: 0,
            onFocus: () => {
                setRowSelection(() => ({
                    [row.id]: [row.id],
                  }));
            },
            // onClick: () => {
            //     setRowSelection(() => ({
            //         [row.id]: [row.id],
            //     }));
            // },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    });
    console.log("selectedGroup", tabled.getGroupedSelectedRowModel()?.flatRows)

    const handlekeyPress = (e) => {
        // console.log("keycode", keycode);
        var key = e.key
        var keycode = e.keyCode
        acTM?.TMFormatData?.oS60?.DTHKey?.map((btn, i) => {

            if ((btn.TOOLTIP == key) || (btn.KEYCODE == keycode)) {
                if (e?.preventDefault) {
                    e.preventDefault();
                }
                childRef?.current?.handleButtonClick(btn);
            }
        })
    }

    const focusSelected = async (table) => {

        const rows = await table?.querySelectorAll('tr');
        const body = table?.querySelectorAll('tbody');
        // Set initial focus on the first row of the current page

        if (rows?.length > 0 && props?.selectedData) {
            // console.log("props?.selectedData",props?.selectedData);

            var inn = Object.keys(props?.selectedData)[0];
            const inn2 = (tabled.getSortedRowModel().rows ?? []).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
            // const paginatedrows = (tabled.getPaginationRowModel().rows??[]).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
            // console.log("pageee",);


            if (inn) {
                const selectedRowPage = Math.floor(inn2 / pagination.pageSize);
                // let f = rows.activeElement
                if (pagination.pageIndex !== selectedRowPage && selectedRowPage >= 0) {
                    // Navigate to the correct page if the selected row is not on the current page
                    // setPageIndex(selectedRowPage);
                    setPagination({ ...pagination, pageIndex: selectedRowPage });

                } else if (rows[inn2 % pagination.pageSize]) {
                    // console.log("pageeee 2",inn2);
                    // Scroll to the row when it's on the correct page
                    setTimeout(() => {
                        rows[inn2 % pagination.pageSize].scrollIntoView({ behavior: 'smooth', block: 'center' });
                        setfocusset(true);
                    }, 500);
                }
                // rows[inn].scrollIntoView({behavior:'smooth',"block":'center'})
            }
            else if (form && !inn) {
                table.focus();
                setTimeout(() => {
                    rows[0].focus();
                    setfocusset(true);
                }, 300);
            }
            // 
        }
        else if (!form && rows?.length > 0) {
            table.focus();
            setTimeout(() => {
                rows[0].focus();
                setfocusset(true);
            }, 300);
        }
    }
    useEffect(() => {
        const table = TableRef.current;
        if (!focusset) {
            focusSelected(table)
        }


        const handleTableKeyDown = (e) => {
            // Handle global key events if needed
            handlekeyPress(e);
        };

        table?.addEventListener('keydown', handleTableKeyDown);

        return () => {
            table?.removeEventListener('keydown', handleTableKeyDown);
        };
    }, [pagination, props?.selectedData, TableRef]);

    useEffect(() => {

        if (props?.selectedData && typeof props?.selectedData == "object") {

            let selectedItem = props?.selectedData;
            console.log("selectedItem",selectedItem)
            setRowSelection(selectedItem);
        }
    }, [props?.selectedData]);

    return (
        <>
            <div ref={ref}></div>

            <Grid>

                <Grid.Col
                    span={12}
                    id={obj.id + "Grid"}
                    m={0}
                    p={0}
                    pt={5}
                    tabIndex={-1}
                >
                    <MantineProvider theme={{ ...globalTheme, primaryShade: 9, }}>
                        <MantineReactTable table={tabled} />
                    </MantineProvider>
                </Grid.Col>

            </Grid>
        </>
    );
}

export default UserSecurityGrid;
